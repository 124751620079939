import { XMarkIcon } from "@heroicons/react/24/outline";

type Item = {
  id: string;
  name: string;
};

type ListModalProps = {
  onClose: () => void;
  heading: string;
  items: Item[];
  onSelect: (itemId: string) => void;
  selectedItemId?: string;
};

export default function ListModal({
  heading,
  onClose,
  items,
  selectedItemId,
  onSelect,
}: ListModalProps) {
  return (
    <>
      <button className="fixed inset-0 bg-black opacity-20 z-30" onClick={onClose}></button>
      <div
        className="fixed bottom-0 left-0 right-0 z-40 flex justify-center max-w-md mx-auto overflow-hidden"
        style={{ boxShadow: "0px -1px 4px 0px rgba(0, 0, 0, 0.075)" }}
      >
        <div className="bg-white rounded-t-lg overflow-y-auto w-full max-h-screen scrollbar-hide">
          <div className="flex items-center justify-between p-3">
            <div className="font-medium text-base">{heading}</div>
            <button className="cursor-pointer" onClick={onClose}>
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="py-2">
            {items.map((item) => (
              <button
                key={item.id}
                onClick={() => onSelect(item.id)}
                className={`block w-full text-base px-4 py-3 text-left ${
                  selectedItemId === item.id
                    ? "bg-special-blue bg-opacity-10 text-special-blue"
                    : "hover:bg-gray-100"
                }`}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
