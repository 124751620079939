type Props = {
  readonly mediaKey: string;
  readonly isRoundedFully?: boolean;
  readonly width?: number;
  readonly height?: number;
  readonly shouldContainObject?: boolean;
};

// async function getMediaUrl(mediaKey: string) {
//   try {
//     const res = await axios.post(
//       process.env.NEXT_PUBLIC_API_URL + "upload/get-presigned-url",
//       {
//         file_key: mediaKey,
//       }
//     );
//     return res.data;
//   } catch (e) {
//     console.log(e);
//   }
// }

export default function UpdateImage({
  mediaKey,
  width = 36,
  height = 36,
  isRoundedFully = true,
  shouldContainObject = false
}: Props) {
  // const [mediaUrl, setMediaUrl] = useState<string>("");

  // useEffect(() => {
  //   const getUrl = async () => {
  //     try {
  //       if (mediaKey && mediaKey.length > 0) {
  //         const { url } = await getMediaUrl(mediaKey);
  //         setMediaUrl(url);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   getUrl();
  // }, [mediaKey]);

  return (
    <img
      src={mediaKey ? mediaKey : ""}
      alt="no media"
      width={width}
      height={height}
      className={`${isRoundedFully ? "rounded-full" : "rounded"} ${
        shouldContainObject ? "object-cover" : ""
      }`}
      style={{ width, height }}
    />
  );
}
