type TabId = "saved" | "foryou" | "guide" | "places";

type Tab = {
  name: string;
  id: TabId;
  showDot: boolean;
};

const ResultsPageTabs = ({
  tabsToShow,
  setActiveTab,
  activeTabId,
  page,
}: {
  tabsToShow: Tab[];
  setActiveTab: (state: Tab) => void;
  activeTabId: string;
  page?: string;
}) => {
  return (
    <div className=" items-center text-center bg-white px-3 pt-2 pb-1 flex gap-4 text-secondary-text">
      {tabsToShow.map((tab, index) => (
        <button
          key={index}
          onClick={() => {
            setActiveTab(tab);
          }}
          className={`flex gap-1 items-center cursor-pointer whitespace-nowrap  ${
            activeTabId === tab.id
              ? "border-black border-b-[1px] text-black font-medium"
              : "border-white border-b-[1px]"
          }`}
        >
          {tab.showDot && <span className="text-[#F1511B] text-[10px]">•</span>}
          <div
            className="text-base leading-normal"
            style={{ letterSpacing: "-0.24px" }}
          >
            {tab.name}
          </div>
        </button>
      ))}
    </div>
  );
};

export default ResultsPageTabs;
