import DirectionsSVG from "./custom-icons/DirectionsSVG";
import { AroundlyPlaces, SupportedActions } from "../common/global.types";
import ButtonControl from "./controls/ButtonControl";
import { Mixpanel } from "../services/mixpanel";
import { performUserActionHistory } from "../services/aroundly-apis";

export const DirectionBtn = ({
  placeDetails,
  isMini,
}: {
  placeDetails?: AroundlyPlaces;
  isMini?: boolean;
}) => {
  return (
    <div className="max-w-fit">
      <ButtonControl
        buttonId="share-button"
        size="lg"
        theme={isMini ? "white" : "primary"}
        name={isMini ? "" : "Go"}
        icon={{ component: DirectionsSVG }}
        iconPlacement="start"
        onClick={async () => {
          Mixpanel.track("Direction Button Clicked", {
            place_id: placeDetails?._id,
            ui_type: "direction-button",
          });
          await performUserActionHistory(SupportedActions.DIRECTIONS, {
            locationDetail: placeDetails?.google_maps_details,
            ui_type: "direction-button",
            place_id: placeDetails?._id,
          });
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${placeDetails?.google_maps_details?.formatted_address}&query_place_id=${placeDetails?.place_id}`,
            "_blank"
          );
        }}
      />
    </div>
  );
};
