import { PushNotifications } from "@capacitor/push-notifications";
// import { Capacitor } from "@capacitor/core";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import { DEFAULT_NOTIFICATION_CHANNEL } from "./global.constants";
import { Mixpanel } from "../services/mixpanel";
import { saveDeviceNotificationToken } from "../services/aroundly-apis";
import {
  getNotificationTokenFromStorage,
  removeNotificationTokenFromStorage,
  setNotificationTokenToStorage,
} from "../services/local-storage.service";
// import { DeviceId } from "@capacitor/device";
// import common_axios from "./axios";
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";
// import { firebaseConfig } from "./firebase";

// const app = initializeApp(firebaseConfig);

// export const storeNotificationTokenToDb = async () => {
//   const isLoggedIn = await getTokenFromStorage();
//   const deviceId = await getDeviceId();
//   const platform = Capacitor.getPlatform();
//   const token = await getNotificationTokenToStorage();
//   if (isLoggedIn && deviceId) {
//     await common_axios.put("/user_data/update-notification-token", {
//       token,
//       platform,
//       deviceId,
//     });
//   }
// };

// export const removeNotificationTokenFromDb = async () => {
//   const isLoggedIn = await getTokenFromStorage();
//   const deviceId = await getDeviceId();
//   if (isLoggedIn && deviceId) {
//     await common_axios.delete(`/user_data/notification-token/${deviceId}`);
//   }
// };

export const addListeners = async () => {
  await PushNotifications.addListener("registration", async (token) => {
    console.info("Registration token: ", token.value);
    await setNotificationTokenToStorage(token.value);
  });

  await PushNotifications.removeAllDeliveredNotifications();

  await PushNotifications.addListener("registrationError", async (err) => {
    console.error("Registration error: ", err.error);
    await removeNotificationTokenFromStorage();
  });

  await PushNotifications.addListener(
    "pushNotificationReceived",
    (notification) => {
      console.log("Push notification received: ", notification);
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      console.log(
        "Push notification action performed",
        notification.actionId,
        notification.inputValue
      );
    }
  );
};

export const registerNotifications = async (
  callback: any,
  platform: string,
  deviceId: string
) => {
  const status = await PushNotifications.requestPermissions();
  if (status.receive === "granted") {
    // addListeners(callback, platform, deviceId);
    await PushNotifications.register();
    if (platform === "ios") {
      const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
      await delay(5000);
    }
    const token = await getNotificationTokenFromStorage();
    if (token) {
      await saveDeviceNotificationToken(token, platform, deviceId);
      Mixpanel.track("Notification Token Registered", {
        token: token,
        platform,
      });
      callback(false);
    }
  } else if (status.receive === "denied") {
    // Open app settings if permissions are denied
    await NativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification,
      optionIOS: IOSSettings.App,
    });
  } else if (status.receive === "prompt-with-rationale") {
    // Show a rationale and then request permissions
  } else {
    // alert(status.receive);
  }
};

export const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log("delivered notifications", notificationList);
};

export const checkAndCreateAndroidNotificationChannel = async () => {
  const channels = await PushNotifications.listChannels();
  const exists =
    channels.channels.filter(
      (item) => item.id === DEFAULT_NOTIFICATION_CHANNEL.id
    ).length > 0;
  if (!exists) {
    await PushNotifications.createChannel(DEFAULT_NOTIFICATION_CHANNEL);
  }
};

// export const requestWebNotificationPermission = async () => {
//   const permission = await Notification.requestPermission();
//   if (permission === "granted") {
//     return true;
//   }
// };

// export const initializeWebNotification = async () => {
//   const messaging = getMessaging(app);
//   try {
//     const permission = await requestWebNotificationPermission();
//     if (permission) {
//       const token = await getToken(messaging, {
//         vapidKey: process.env.REACT_APP_FCM_WEB_VAPID,
//       });
//       if (token) {
//         setNotificationTokenToStorage(token);
//       }
//     }
//   } catch (e) {
//     console.log(e);
//   }
// };
