import { XMarkIcon } from "@heroicons/react/24/outline";
import WishlistContainer from "./WishlistContainer";
import { useEffect, useState } from "react";
import { useCollabListsContext } from "../context/CollabListsContext";
import { updateWishlists } from "../services/aroundly-apis";
import { toast } from "react-toastify";
import { CollabWishlistType } from "../common/global.types";
import ButtonControl from "./controls/ButtonControl";

const BotWishTray = ({
  onClose,
  selectedWishlist,
}: {
  onClose: (data?: any) => void;
  selectedWishlist: string;
}) => {
  const { collabLists, placeData, updateData } = useCollabListsContext();
  const [selectedWishlists, setSelectedWishlists] = useState<
    Record<string, boolean>
  >({});

  const [isUpdateListLoading, setIsUpdateListLoading] = useState(false);
  const [sortedcollablist, setSortedCollablist] = useState<
    CollabWishlistType[]
  >([]);

  useEffect(() => {
    if (collabLists.length > 0 && placeData && updateData) {
      const selectedWishlists = collabLists.reduce((acc, list) => {
        list.places
          .filter((p) => p.place_id?._id === placeData?._id)
          .forEach((p) => {
            if (p.updates.some((u) => u.update_id._id === updateData._id)) {
              acc[list._id] = true;
            }
          });
        return acc;
      }, {} as Record<string, boolean>);
      setSelectedWishlists(selectedWishlists);
    }
  }, [collabLists, placeData, updateData]);

  useEffect(() => {
    const selectedItem = collabLists.find((u) => u.name === selectedWishlist);
    let selectedlistfrompage: Record<string, boolean> = {};
    if (selectedItem !== undefined) {
      selectedlistfrompage[selectedItem._id] = true;
      setSelectedWishlists((prevSelectedWishlists) => ({
        ...prevSelectedWishlists,
        ...selectedlistfrompage,
        [selectedItem?._id as string]: true,
      }));
    }
  }, [collabLists, selectedWishlist]);

  useEffect(() => {
    const temp = [...collabLists].sort((a, b) => {
      const aSelected = selectedWishlists[a._id];
      const bSelected = selectedWishlists[b._id];

      if (aSelected && !bSelected) {
        return -1;
      }
      if (!aSelected && bSelected) {
        return 1;
      }
      return 0;
    });
    setSortedCollablist(temp);
  }, [collabLists, selectedWishlists]);

  const handleWishlistUpdate = async () => {
    try {
      setIsUpdateListLoading(true);
      const updatedData = await updateWishlists({
        place_id: placeData?._id as string,
        update_id: updateData?._id as string,
        selected_wishlists: Object.keys(selectedWishlists),
      });
      toast.success("Updated List Successfully");
      setIsUpdateListLoading(false);
      onClose(updatedData);
    } catch (e) {
      setIsUpdateListLoading(false);
      console.log(e);
    }
  };
  return (
    <>
      <button
        style={{
          zIndex: 90,
        }}
        className="fixed inset-0 bg-black opacity-20 "
        onClick={() => onClose()}
      ></button>
      <div
        style={{
          zIndex: 90,
        }}
        className="fixed bg-white bottom-0 left-0 right-0 rounded-t-xl flex flex-col justify-center max-w-md mx-auto overflow-y-scroll overflow-x-hidden  scrollbar-hide"
      >
        <div
          className="text-2xl p-3 font-bold flex w-full justify-between"
          style={{ letterSpacing: "-0.72px" }}
        >
          <div>Save to List</div>
          <div onClick={() => onClose()} className="cursor-pointer">
            <XMarkIcon className="h-8 w-8 text-[#9CAAC6]" />
          </div>
        </div>
        <div className="overflow-y-scroll scrollbar-hide max-h-[320px]">
          <WishlistContainer
            sortedcollablist={sortedcollablist}
            isSelectable
            onSelect={(id) => {
              if (selectedWishlists[id]) {
                delete selectedWishlists[id];
                setSelectedWishlists({ ...selectedWishlists });
              } else {
                setSelectedWishlists({ ...selectedWishlists, [id]: true });
              }
            }}
            selectedWishlists={Object.keys(selectedWishlists)}
          />
        </div>
        <div className="p-4 flex justify-center items-center">
          <ButtonControl
            buttonId="bot-wishlist-select-button"
            size="lg"
            theme="primary"
            name="Select"
            isLoading={isUpdateListLoading}
            onClick={() => handleWishlistUpdate()}
            // disabled={Object.keys(selectedWishlists).length === 0}
          />
        </div>
      </div>
    </>
  );
};
export default BotWishTray;
