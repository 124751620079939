// import { circularStd } from "../common/fonts/fonts";
import { Link } from "react-router-dom";
import { useCapacitorInfo } from "../context/CapacitorContext";

export default function Footer() {
  const { currentAppVersion } = useCapacitorInfo();
  return (
    <footer className="pt-9 pb-10 bg-text flex justify-start items-start w-full flex-col px-4 z-10 bg-[#17191C]">
      <div className="flex w-full justify-between flex-col">
        <div className="w-full">
          <div className={`text-xs text-grey-l mt-3 text-center circularStd`}>
            We believe there’s about 100,000 sq km of world meant for each of us
            to explore in a lifetime.
            <br /> We wish to bring it closer, or better - around us.
          </div>
          <div className={`text-xs text-grey text-center mt-3 circularStd`}>
            Aroundly® is a registered trademark and a brand of Coytus Lifestyle
            Pvt. Ltd.
          </div>
          <div className={`text-xs text-grey text-center mt-3 circularStd`}>
            <span>
              <Link
                to={"/privacy-policy"}
                className="text-grey text-xs underline hover:text-white"
              >
                Privacy Policy
              </Link>
              <Link
                to={"/terms"}
                className="text-grey text-xs ml-3 underline hover:text-white"
              >
                Terms of Use
              </Link>
            </span>
          </div>
        </div>
        <div className="flex mt-3 justify-center items-center">
          <a target="_blank" href="https://linkedin.com/company/aroundly">
            <img
              src="/images/linkedin.svg"
              alt="Linkedin logo"
              height="32"
              width="32"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://instagram.com/my.aroundly"
            className="mx-4"
          >
            <img
              src="/images/instagram.svg"
              alt="Linkedin logo"
              height="32"
              width="32"
            />
          </a>
          <a target="_top" href="mailto:srinivas@myaroundly.com">
            <img
              src={"/images/email.svg"}
              alt="Linkedin logo"
              height={32}
              width={32}
            />
          </a>
        </div>
        {currentAppVersion && (
          <div className="text-white flex items-center justify-center mt-3">
            {currentAppVersion}
          </div>
        )}
      </div>
    </footer>
  );
}
