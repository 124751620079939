import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CollabWishlistType } from "../common/global.types";

const Collabs = ({
  data,
  onClose,
}: {
  data: CollabWishlistType;
  onClose: () => void;
}) => {
  return (
    <div
      id="modal"
      className={`fixed left-0 right-0 top-0 bottom-0 bg-black/90 z-50 max-w-md mx-auto`}
    >
      <div className="absolute w-full h-full bg-white overflow-y-scroll scrollbar-hide">
        <div className={`sticky top-0 bg-white p-3 shadow-sm`}>
          <div className="justify-start items-center gap-1 flex text-2xl  font-medium">
            <button
              onClick={onClose}
              className="text-zinc-700 mr-8 cursor-pointer"
            >
              {" "}
              <ArrowLeftIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="mt-3 mx-3">
          <p className="text-secondary-text text-xs  font-medium ">
            To add more people to this wishlist, ask the Owner to share the{" "}
            <br /> invite
          </p>
          <div className="mt-6 ">
            <div className="flex justify-between">
              <div className="flex gap-3 items-center ">
                <img
                  src="/images/Male.svg"
                  alt="avatar"
                  className="h-6 w-6 object-cover rounded-full left-[8px] top-0 "
                />
                <div
                  className="text-base font-450"
                  style={{ letterSpacing: "-0.48px" }}
                >
                  {data.owner_id.name}
                </div>
              </div>
              <div className="text-xs font-450 items-center flex">Owner</div>
            </div>
            {data.collaborators.map((w, index) => (
              <div className="flex justify-between mt-7">
                <div className="flex gap-3 items-center ">
                  <img
                    src="/images/Male.svg"
                    alt="avatar"
                    className="h-6 w-6 object-cover rounded-full left-[8px] top-0 "
                  />
                  <div
                    className="text-base font-450"
                    style={{ letterSpacing: "-0.48px" }}
                  >
                    {w.user_id.name}
                  </div>
                </div>
                <div className="text-xs font-450 items-center flex">Collab</div>
              </div>
            ))}
            
          </div>
        </div>
      </div>
    </div>
  );
};
export default Collabs;
