export default function DirectionsSVG({ className }: { className: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_11327_44047)">
        <path
          d="M21.7103 11.2896L12.7103 2.28957C12.3203 1.89957 11.6903 1.89957 11.3003 2.28957L2.30031 11.2896C1.91031 11.6796 1.91031 12.3096 2.30031 12.6996L11.3003 21.6996C11.6903 22.0896 12.3203 22.0896 12.7103 21.6996L21.7103 12.6996C22.1003 12.3196 22.1003 11.6896 21.7103 11.2896ZM14.0003 14.4996V11.9996H10.0003V13.9996C10.0003 14.5496 9.55031 14.9996 9.00031 14.9996C8.45031 14.9996 8.00031 14.5496 8.00031 13.9996V10.9996C8.00031 10.4496 8.45031 9.99957 9.00031 9.99957H14.0003V7.49957L17.1503 10.6496C17.3503 10.8496 17.3503 11.1596 17.1503 11.3596L14.0003 14.4996Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11327_44047">
          <rect className="h-6 w-6" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}
