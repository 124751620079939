"use client";

import { ReactElement } from "react";
import { Mixpanel } from "../services/mixpanel";

interface SocialButtonProps {
  icon: ReactElement;
  url: string;
  update?:boolean
}

export default function SocialButton({ icon, url,update }: SocialButtonProps) {
  const handleClick = () => {
    window.open(url);
    Mixpanel.track(`Social button clicked`, {
      url: url,
      name:icon
    });
  };
  return (
    <button onClick={handleClick} className={`${update? "h-16 w-16 p-0" : "p-1"} border rounded  bg-white`}>
      {icon}
    </button>
  );
}
