import { BellIcon } from "@heroicons/react/24/outline";

export default function GetNotified({
  onBtnClick,
}: {
  onBtnClick?: () => void;
}) {
  return (
    // <div className="fixed bottom-24  max-w-md px-5">
    <div className="p-2 bg-white rounded-lg shadow border border-zinc-200 justify-between items-center gap-2 flex">
      <div className="justify-start items-center gap-2 flex">
        <div className="bg-special-blue/10 rounded-full p-1 flex-shrink-0">
          <BellIcon className="h-5 w-5 text-special-blue" />
        </div>
        <div className=" text-xs leading-none">
          Get notified when you have new recommendations here, and more
        </div>
      </div>
      <button
        className="px-5 py-1 bg-special-blue rounded-full border text-center text-white text-xs font-bold font-inter leading-6"
        onClick={onBtnClick}
      >
        Enable
      </button>
    </div>
    // </div>
  );
}
