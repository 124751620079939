import { useNavigate } from "react-router-dom";
import {
  AroundlyPlaces,
  CollabWishlistType,
  UserProps,
} from "../common/global.types";
import RenderUserAvatar from "../components/RenderUserAvatar";
import { useAuth } from "../context/auth";
import { useEffect, useState } from "react";
import { Mixpanel } from "../services/mixpanel";
import { useCollabListsContext } from "../context/CollabListsContext";
import { useYourSavedPageContext } from "../context/Yoursaved";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFeedData } from "../context/FeedDataContext";

const getParticipants = (
  authUser: UserProps,
  collabListData: CollabWishlistType
) => {
  let participantsList: { _id: string; name: string }[] = [];

  if (collabListData) {
    participantsList = [
      collabListData.owner_id,
      ...collabListData.collaborators.map((u) => u.user_id),
    ].filter((participant) => participant._id !== authUser._id);
  }
  return participantsList;
};

const checkLength = (places: AroundlyPlaces[]) => {
  return places && places.reduce((total, u) => total + u.updates.length, 0);
};

const checkLengthwishlist = (value: CollabWishlistType) => {
  return value.places.reduce((total, u) => total + u.updates.length, 0);
};

export default function CollabListCard({
  wishlistData,
}: {
  wishlistData: CollabWishlistType;
}) {
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const { collabLists } = useCollabListsContext();
  const [totalParticipants, setTotalParticipants] = useState<
    { _id: string; name: string }[]
  >([]);
  const { allCollablists, loading } = useYourSavedPageContext();
  const [showDot, setShowdot] = useState(false);

  useEffect(() => {
    let check = JSON.parse(localStorage.getItem(wishlistData._id) as string);
    setShowdot(check as boolean);
  }, [wishlistData._id]);

  useEffect(() => {
    const val: { _id: string; name: string }[] = getParticipants(
      authUser as UserProps,
      wishlistData
    );

    setTotalParticipants(val);
  }, [authUser, wishlistData]);

  const findIndexById = (array: CollabWishlistType[], id: string) => {
    return array.findIndex((item) => item._id === id);
  };
  const index = findIndexById(collabLists, wishlistData._id as string);
  const updates = allCollablists[index];
  const length: number = checkLength(updates);
  const lengthupdatesave: number = checkLengthwishlist(wishlistData);
  return (
    <div
      className="border border-[#DCDEE3] rounded-lg flex-shrink-0 flex flex-col  w-40 p-2"
      onClick={() => {
        Mixpanel.track("Wishlist Clicked", {
          wishlist_id: wishlistData._id,
          ui_type: "wishlist-card-your-saved",
        });
        navigate("/app/wishlist/" + wishlistData._id);
      }}
    >
      <div className="flex justify-start items-center gap-1 grow mb-[10px]">
        <div
          className={`${
            wishlistData ? "" : "hidden"
          } w-4 h-4 flex justify-center items-center`}
        >
          <RenderUserAvatar gender={authUser?.gender as string} />
        </div>
        {wishlistData && (
          <div className="text-secondary-text text-xs">
            Me{" "}
            {totalParticipants.length > 0
              ? ", " + totalParticipants.map((u) => u.name).join(", ")
              : ""}
          </div>
        )}
      </div>
      <div
        className="text-base line-clamp-1"
        style={{ letterSpacing: "-0.48px" }}
      >
        {wishlistData.name}
      </div>
      {loading ? (
        <Skeleton count={1} baseColor="#dadada" />
      ) : length > 0 ? (
        <div className="flex gap-[2px]">
          {showDot && <span className="text-[#F1511B] text-[10px]">•</span>}
          <div
            className={`${
              showDot ? "text-[#007AFF]" : "text-secondary-text"
            }  text-[10px]`}
          >
            {length > 10 ? "10+" : length} updates
          </div>
        </div>
      ) : (
        <div className="text-secondary-text text-[10px]">
          {lengthupdatesave} Saved here
        </div>
      )}
    </div>
  );
}
