import {
  AroundlyPlaces,
  SocialMediaUpdate,
  SupportedActions,
} from "../common/global.types";
import moment from "moment";
import "moment-timezone";
import LoadMedia from "../components/LoadMedia";
import { Mixpanel } from "../services/mixpanel";
import {
  UPDATE_TYPE_EVENT,
  UPDATE_TYPE_OFFER,
} from "../common/global.constants";
import LikeButton from "./likeBtn";
import SourceModal from "./SourceModal";
import NotInterested from "./NotInterested";
import { useState } from "react";
import {
  performUserAction,
  performUserActionHistory,
} from "../services/aroundly-apis";
import { useFeedData } from "../context/FeedDataContext";
import { useSearchParams } from "react-router-dom";
import HandPicked from "./HandPicked";
import { useCollabListsContext } from "../context/CollabListsContext";

export default function MiniFeedCard({
  item,
  isLast,
  timezone,
  hideActionButtons = false,
  placeData,
  updateText,
  isfullView,
  miniFeedCard,
}: {
  readonly item: SocialMediaUpdate;
  readonly isLast: boolean;
  readonly timezone: string;
  readonly hideActionButtons?: boolean;
  readonly placeData?: AroundlyPlaces;
  readonly updateText?: string;
  readonly isfullView?: boolean;
  readonly miniFeedCard?: boolean;
}) {
  // const navigate = useNavigate();
  const { sources } = useFeedData();
  const { toggleStoreModal, toggleUpdateModal, showStore } =
    useCollabListsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const results_type = searchParams.get("results_type");
  const imgSrc = sources.filter((source) => {
    if (item.source === "paytm insider") {
      return source.source_key.includes("paytm");
    } else {
      return item.source === source.source_key;
    }
  });

  const truncatedSummary =
    item.caption_summary.length > 100
      ? item.caption_summary.slice(0, 100) + "..."
      : item.caption_summary;

  const [showSourceModal, setShowSourceModal] = useState(false);

  const [buttonPosition, setButtonPosition] = useState({
    top: "0px",
  });

  const [showNotInterestOptions, setShowNotInterestedOptions] = useState(false);

  const notOnClick = async () => {
    Mixpanel.track("Not Interested Clicked", {
      update_id: item._id,
      ui_type: "mini-card",
      update_type: item.update_type,
    });
    setShowSourceModal(false);
    setShowNotInterestedOptions(!showNotInterestOptions);
    try {
      await performUserAction(item._id, SupportedActions.NOT_INTERESTED);

      await performUserActionHistory(SupportedActions.NOT_INTERESTED, {
        update_id: item._id,
        ui_type: "mini-card",
        update_type: item.update_type,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const postClickedEvent = async () => {
    Mixpanel.track("Post Clicked", {
      update_id: item._id,
      ui_type: "mini-card",
      update_type: item.update_type,
    });
    try {
      await performUserActionHistory(SupportedActions.VIEW_POST, {
        update_id: item._id,
        ui_type: "mini-card",
        update_type: item.update_type,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={`  ${isLast ? "mr-3" : ""}  `}>
      <div
        className="w-full h-[336px] relative"
        onClick={() => {
          postClickedEvent();
        }}
      >
        <LoadMedia
          isFullView={isfullView}
          isMini={false}
          source={item.source}
          // thumbnailKey={item?.social_media_update_details.thumbnail_url}
          // mediaKey={item?.social_media_update_details?.media_url}
          mediaKey={
            isfullView || miniFeedCard
              ? (item?.media_metadata?.media_original.url as string)
              : (item?.media_metadata?.media_compressed.url as string)
          }
          thumbnailKey={item?.media_metadata?.media_compressed.url as string}
          mediaType={item?.media_metadata?.media_original.type as any}
        />
        <div
          className="absolute bottom-0 left-0  text-white h-full  w-full rounded-xl"
          style={{
            background: `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 20.71%, #000 88.69%)`,
          }}
          onClick={() => {
            // navigate(`/app/update/${}`);
            item.update_type !== "general_update_generated" &&
              toggleUpdateModal(true, item._id);

            localStorage.setItem("postId", placeData?._id || item._id);
          }}
        >
          <div className="absolute bottom-4 left-4  text-white">
            {item.update_type === UPDATE_TYPE_EVENT && (
              <div className="text-xs  mt-2">
                {moment(item?.expires_at).tz(timezone)?.format("Do MMM")}
              </div>
            )}
            {item.update_type === UPDATE_TYPE_OFFER && (
              <div className="text-xs  mt-2">
                Until {moment(item?.expires_at).tz(timezone)?.format("Do MMM")}
              </div>
            )}

            {item.update_type === "general_update_generated" &&
            item.aroundly_generated ? (
              <>
                <div
                  className="text-xs  mt-2"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Known for
                </div>
                <div
                  id="source-link"
                  className={`text-white  cursor-pointer  text-xl leading-6 line-clamp-6 tracking-tight font-medium my-2`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleStoreModal(true, placeData?._id);
                  }}
                >
                  {item.caption_title}
                </div>
              </>
            ) : (
              <div
                id="source-link"
                className={`text-white w-[212px] ${
                  item.update_type === "review" ? "quote" : ""
                } cursor-pointer text-ellipsis text-xl leading-6 tracking-tight font-medium  line-clamp-2 my-2`}
                onClick={(e) => {
                  e.stopPropagation();
                  postClickedEvent();
                  // navigate(`/app/update/${item._id}`);
                  toggleUpdateModal(true, item._id);

                  localStorage.setItem("postId", placeData?._id || item._id);
                }}
              >
                {item.caption_title}
              </div>
            )}
            <div
              id="source-link"
              className={`text-[#DADADA] mb-1 text-xs  w-[212px] cursor-pointer ${
                item.update_type === UPDATE_TYPE_EVENT ? "" : "hidden"
              }  font-light leading-4 grow text-ellipsis`}
              onClick={(e) => {
                e.stopPropagation();
                postClickedEvent();
                // navigate(`/app/update/${item._id}`);
                toggleUpdateModal(true, item._id);
                localStorage.setItem("postId", placeData?._id || item._id);
              }}
            >
              {truncatedSummary}
            </div>
            {(placeData || item?.related_aroundly_place_ids) &&
              item.update_type !== UPDATE_TYPE_EVENT && (
                <div
                  className={`font-inter w-[212px] text-white ${
                    results_type === "search" && !showStore && "hidden"
                  }`}
                >
                  <div className="flex text-xs leading-none tracking-tight font-normal">
                    {/* <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        Mixpanel.track("Store Clicked", {
                          update_id: item._id,
                          ui_type: "mini-card",
                          place_id: placeData?.place_id,
                        });
                      }}
                      to={`/app/store/${
                        placeData?._id ||
                        (item.related_aroundly_place_ids &&
                          item?.related_aroundly_place_ids[0]?._id) ||
                        item.placeid
                      }
                      `}
                      className="underline cursor-pointer truncate "
                    >
                      {placeData?.name ||
                        (item.related_aroundly_place_ids &&
                          item?.related_aroundly_place_ids[0]?.name) ||
                        item.placeName}
                    </Link> */}
                    <div
                      onClick={async (e) => {
                        e.stopPropagation();
                        Mixpanel.track("Store Clicked", {
                          update_id: item._id,
                          ui_type: "mini-card",
                          place_id: placeData?.place_id,
                        });
                        toggleStoreModal(
                          true,
                          placeData?._id ||
                            (item.related_aroundly_place_ids &&
                              item?.related_aroundly_place_ids[0]?._id) ||
                            item.placeid
                        );
                      }}
                      className="underline cursor-pointer truncate "
                    >
                      {placeData?.name ||
                        (item.related_aroundly_place_ids &&
                          item?.related_aroundly_place_ids[0]?.name) ||
                        item.placeName}
                    </div>
                    <span className="mx-1"> &bull; </span>
                    <span className="text-nowrap">
                      {placeData?.google_maps_details.rating ||
                        (item.related_aroundly_place_ids &&
                          item?.related_aroundly_place_ids[0]
                            ?.google_maps_details?.rating) ||
                        item.rating}
                      ⭐
                    </span>
                  </div>
                </div>
              )}
            {results_type === "search" && !showStore && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  Mixpanel.track("Post Source Clicked", {
                    update_id: item._id,
                    ui_type: "mini-card",
                    update_type: item.update_type,
                    source: item.source,
                  });
                  setButtonPosition({
                    top: `${e.currentTarget.offsetTop}px`,
                  });
                  item.update_type === "general_update_generated" &&
                  item.aroundly_generated
                    ? toggleStoreModal(true, placeData?._id)
                    : setShowSourceModal(true);
                }}
                className="flex items-center gap-1   text-ellipsis text-[10px]  text-[#dadada] capitalize"
              >
                {item.update_type === "general_update_generated" &&
                item.aroundly_generated ? (
                  <>
                    <img
                      src={"/images/aroundly.svg"}
                      alt={`logo of aroundly`}
                      width={12}
                      height={12}
                    />
                    <p>Summarized by Aroundly</p>
                  </>
                ) : (
                  <>
                    <img
                      src={
                        imgSrc[0]?.source_logo
                          ? imgSrc[0].source_logo
                          : item.source === "aroundly"
                          ? "/images/aroundly.svg"
                          : `/images/sources/${item.source}.png`
                      }
                      alt={`logo of ${item.source}`}
                      width={12}
                      height={12}
                    />

                    {item.source === "instagram"
                      ? item.social_media_handle
                      : item.source}
                  </>
                )}
              </button>
            )}

            {item.update_type === UPDATE_TYPE_EVENT &&
              item.price !== undefined && (
                <div className="flex justify-between items-center mt-2">
                  <div className="text-zinc-700 text-lg leading-none font-medium">
                    ₹ {item.price}
                  </div>
                </div>
              )}
          </div>
        </div>

        {(results_type !== "search" || showStore) && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              Mixpanel.track("Post Source Clicked", {
                update_id: item._id,
                ui_type: "mini-card",
                update_type: item.update_type,
                source: item.source,
              });
              setButtonPosition({
                top: `${e.currentTarget.offsetTop}px`,
              });
              setShowSourceModal(true);
            }}
            className="flex items-center text-center gap-1 absolute top-4 right-4 text-ellipsis text-[10px]  text-[#dadada] capitalize"
          >
            <img
              src={
                imgSrc[0]?.source_logo
                  ? imgSrc[0].source_logo
                  : item.source === "aroundly"
                  ? "/images/aroundly.svg"
                  : `/images/sources/${item.source}.png`
              }
              alt={`logo of ${item.source}`}
              width={12}
              height={12}
            />
            <span className="items-center text-center mt-[2px]">
              {item.source === "instagram"
                ? item.social_media_handle
                : item.source}
            </span>
          </button>
        )}

        <HandPicked item={item} />

        {!hideActionButtons &&
          item.update_type !== "general_update_generated" && (
            <div className="absolute bottom-4 right-4 bg-white rounded-full">
              <LikeButton item={item} placeData={placeData} isMini />
            </div>
          )}
      </div>

      {showSourceModal && (
        <SourceModal
          onModalClose={() => setShowSourceModal(false)}
          item={item}
          position={buttonPosition}
          notOnClick={notOnClick}
        />
      )}
      {showNotInterestOptions && (
        <NotInterested
          item={item}
          onClose={() => setShowNotInterestedOptions(false)}
        />
      )}
    </div>
  );
}
