import { SocialMediaUpdate } from "../common/global.types";

const HandPicked = ({
  item,
  pastFeedCard = false,
}: {
  item: SocialMediaUpdate;
  pastFeedCard?: boolean;
}) => {
  return (
    <div>
      {item.is_best && (
        <div
          className={`absolute ${
            pastFeedCard ? "top-2 left-2" : "top-4 left-4"
          }  px-1 py-[2px]  text-[10px] font-medium   bg-white  rounded-full   `}
          style={{ lineHeight: "13px", letterSpacing: "-0.3px" }}
        >
          🔥 Handpicked for you
        </div>
      )}
    </div>
  );
};
export default HandPicked;
