import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import MoodsModal from "./MoodsModal";
import { useFeedData } from "../context/FeedDataContext";
import ModalBeforeMoods from "./ModalBeforeMoods";
import { Mixpanel } from "../services/mixpanel";
import { useSearchParams } from "react-router-dom";

type SearchButtonProps = {
  location: "results" | "home" | "explore" | "wishlist";
  query?: string;
  showModal?: boolean;
  toggleModal?: () => void;
  showBeforeModal?: boolean;
  toggleBeforeModal?: () => void;
};
export default function SearchButton({
  location = "explore",
  query = "",
  showBeforeModal,
}: // toggleBeforeModal,
SearchButtonProps) {
  const {
    showModal,
    toggleModal,
    searchtext,
    toggleBeforeModal,
    handleSearchText,
  } = useFeedData();
  const [searchParams] = useSearchParams();
  const cuisine = searchParams.get("cuisine");
  const [text, setText] = useState("romantic dinner");
  useEffect(() => {
    const texts = ["singles mixers", "dining experiences", "best pizza"];
    let index = 0;

    const intervalId = setInterval(() => {
      setText(texts[index]);
      index = (index + 1) % texts.length; // Loop back to the first text after the last one
    }, 2000); // Change text every 1 second

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);
  if (query?.includes("?")) {
    query = query.split("?")[0];
  }
  return (
    <button
      onClick={() => {
        Mixpanel.track(`Search clicked`);
        if (location === "results") {
          // toggleModal(true);
          toggleBeforeModal && toggleBeforeModal(true);
        } else if (location === "wishlist") {
        } else if (location === "home") {
          handleSearchText("");
          toggleBeforeModal && toggleBeforeModal(true);
        }
      }}
      className={`${location !== "explore" ? "w-full" : ""} `}
    >
      {(location === "results" || location === "wishlist") && (
        <div className="  truncate border-neutral-200  justify-between items-center gap-2 inline-flex w-full">
          <input
            className={`focus:ring-0 m-0    grow w-1/2  border-none p-0 focus:border-none text-zinc-800 text-sm font-450 font-inter leading-normal truncate bg-transparent outline-none`}
            // autoFocus
            readOnly
            placeholder="Enter anything"
            value={cuisine ? cuisine : query}
          />
        </div>
      )}
      {location === "explore" && <MagnifyingGlassIcon className="h-6 w-6" />}
      {location === "home" && (
        <div className="px-3 py-3 rounded-xl bg-white   justify-between items-center inline-flex w-full shadow-custom">
          <div className="justify-start items-center gap-1 flex truncate">
            <img
              alt="sparkles"
              src="/images/sparkles.svg"
              className="h-5 w-5 flex font-bold mr-2 text-special-blue items-center justify-center"
            />
            <div className="text-secondary-text text-base items-center text-center">
              Type "{text}"
            </div>
          </div>
        </div>
      )}
    </button>
  );
}
