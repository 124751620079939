import ButtonControl from "../components/controls/ButtonControl";
import { Mixpanel } from "../services/mixpanel";

const AroundeMe = () => {
  return (
    <div className="h-dvh overflow-y-scroll scrollbar-hide">
      <div
        className="p-3 text-2xl font-medium text-primary"
        style={{ letterSpacing: "-0.72px" }}
      >
        Discover 'Around Me'
      </div>
      <div className=" h-[65%] mb-80">
        <div
          className="text-xs font-medium px-3"
          style={{ letterSpacing: "-0.36px" }}
        >
          Get exclusive access to Around Me.
          <br />
          Watch the video to know more.
        </div>
        <div className="h-full mt-3 px-3 ">
          <iframe
            className="w-full h-full rounded-xl aspect-video"
            src="https://www.youtube.com/embed/2YycyMaxXEo?autoplay=1&mute=0&controls=0&modestbranding=1&rel=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder="0"
          ></iframe>
        </div>
        <div className="flex flex-col">
          <div className="fixed bottom-20 w-full max-w-md bg-white py-3">
            <div className="flex justify-center px-4">
              <ButtonControl
                buttonId="around-me-button"
                size="lg"
                theme="primary"
                name="GET ACCESS"
                onClick={() => {
                  Mixpanel.track("Around Me - Get Access clicked");
                  window.open(
                    "https://chat.whatsapp.com/HlK6TmGIaZBLibimohAA1H",
                    "_blank"
                  );
                }}
              />
            </div>
            <p
              className="text-secondary-text text-[10px] font-450  mt-1   flex justify-center items-center"
              style={{ letterSpacing: "-0.3px" }}
            >
              You’ll be redirected to Aroundly’s Whatsapp community of early
              users
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AroundeMe;
