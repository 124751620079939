import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { useCapacitorInfo } from "./CapacitorContext";
import {
  getDeviceNotification,
  updateNotificationToken,
} from "../services/aroundly-apis";
// import GetNotified from "../components/GetNotified";
import {
  addListeners,
  checkAndCreateAndroidNotificationChannel,
  registerNotifications,
} from "../common/notification";
import { getNotificationTokenFromStorage } from "../services/local-storage.service";
import { PushNotifications } from "@capacitor/push-notifications";

interface DeviceNotificationsContextProps {
  shouldShowNotificationsPopUp: boolean;
  handleGetNotifiedClick: () => void;
}

const DeviceNotificationsContext = createContext(
  {} as DeviceNotificationsContextProps
);

export function DeviceNotificationsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { loading, authUser } = useAuth();
  const { isNative, platform, deviceId } = useCapacitorInfo();
  const [shouldShowNotificationsPopUp, setShouldShowNotificationsPopUp] =
    useState(false);

  useEffect(() => {
    const checkNotificationsPermissions = async () => {
      const notificationObject = await getDeviceNotification(
        deviceId?.identifier as string
      );

      if (isNative) {
        const notificationPermission =
          await PushNotifications.checkPermissions();

        if (notificationPermission.receive !== "granted") {
          setShouldShowNotificationsPopUp(true);
        }
      }

      if (!notificationObject) {
        console.log("No notification object found");
        // const permStatus = await PushNotifications.checkPermissions();

        // // 1. Should check if the notification permissions are granted.
        // // 2. If granted, should register for notifications and save to db.
        // // 3. If not granted, should show the notification popup.
        // if (permStatus.receive === "granted") {
        //   //   // Call register notifications
        //   registerNotifications(
        //     setShouldShowNotificationsPopUp,
        //     platform,
        //     deviceId?.identifier as string
        //   );
        // } else {
        // Show the notification popup
        setShouldShowNotificationsPopUp(true);
        // }
      } else {
        const token = await getNotificationTokenFromStorage();

        if (token && token !== notificationObject.notification_token) {
          // Update the token in the database
          await updateNotificationToken(
            notificationObject._id,
            token as string
          );
        }
      }
    };

    if (!loading && authUser && platform && deviceId && isNative)
      checkNotificationsPermissions();
  }, [authUser, loading, platform, deviceId, isNative]);

  useEffect(() => {
    if (isNative) {
      addListeners();
    }
  }, [isNative]);

  // TODO: When user clicks on the button, check for permissions and register for notifications.

  const handleGetNotifiedClick = async () => {
    // Check for permissions
    // Register for notifications
    // Close the popup
    if (platform === "android") {
      checkAndCreateAndroidNotificationChannel();
    }
    registerNotifications(
      setShouldShowNotificationsPopUp,
      platform,
      deviceId?.identifier as string
    );
    // } else {
    // initializeWebNotification();
    // }
  };

  return (
    <DeviceNotificationsContext.Provider
      value={{
        shouldShowNotificationsPopUp,
        handleGetNotifiedClick,
      }}
    >
      {children}
    </DeviceNotificationsContext.Provider>
  );
}

export const useDeviceNotificationsInfo = () =>
  useContext(DeviceNotificationsContext);
