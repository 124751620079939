import { useState } from "react";
import { Mixpanel } from "../services/mixpanel";
import {
  performUserAction,
  performUserActionHistory,
} from "../services/aroundly-apis";
import { SupportedActions } from "../common/global.types";
import { useAuth } from "../context/auth";
import { getDeviceId } from "../services/local-storage.service";
// import { useLocation } from "react-router-dom";
import { shareLogic } from "../common/functions";
import { ShareIcon } from "@heroicons/react/24/outline";
import ButtonControl from "./controls/ButtonControl";

type ButtonSize = "sm" | "md" | "lg";

export default function ShareButton({
  _id,
  captionTitle,
  isStore = false,
  showText = false,
  size = "lg",
}: {
  readonly _id: string;
  readonly captionTitle: string;
  readonly captionSummary?: string;
  readonly preventShare?: boolean;
  readonly isStore?: boolean;
  readonly showText?: boolean;
  readonly size?: string;
}) {
  const [loading, setLoading] = useState(false);
  const { authUser } = useAuth();
  // const location = useLocation();
  // const isResult = location.pathname;

  const onShare = async () => {
    const shareDetails = isStore ? { place_id: _id } : { update_id: _id };
    Mixpanel.track(`Share Clicked`, {
      ...shareDetails,
      ui_type: "share-button",
      share_type: isStore ? "place" : "update",
    });
    await performUserActionHistory(
      isStore ? SupportedActions.SHARE_STORE : SupportedActions.SHARE_POST,
      {
        ...shareDetails,
        ui_type: "share-button",
        share_type: isStore ? "place" : "update",
      }
    );
    try {
      setLoading(true);
      let URL_TO_SHARE = "";
      let device_id = await getDeviceId();

      if (authUser) {
        let referral_id = authUser.referal_id;
        URL_TO_SHARE = `${process.env.REACT_APP_FRONTEND_URL}/app/${
          isStore ? "store" : "update"
        }/${_id}?referral_id=${referral_id}`;
      } else {
        URL_TO_SHARE = `${process.env.REACT_APP_FRONTEND_URL}/app/${
          isStore ? "store" : "update"
        }/${_id}`;
      }

      const shareText = `Hey! I have discovered this on Aroundly. Check out ${captionTitle} -`;
      await shareLogic(shareText, URL_TO_SHARE);
      await performUserAction(_id, SupportedActions.SHARE);
      setLoading(false);
    } catch (e) {
      console.log("Unable to share the post");
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className="max-w-fit">
      <ButtonControl
        buttonId="share-button"
        size={size as ButtonSize}
        theme="white"
        name={showText ? "Share" : ""}
        isLoading={loading}
        icon={{ component: ShareIcon }}
        iconPlacement="start"
        onClick={onShare}
      />
    </div>
  );
}
