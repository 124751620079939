import { CollabWishlistType } from "../common/global.types";
import { Mixpanel } from "../services/mixpanel";

const JoinModal = ({
  data,
  onClose,
  onJoin,
}: {
  data: CollabWishlistType;
  onClose: () => void;
  onJoin: () => void;
}) => {
  const handleInsideClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm h-svh"
    >
      <div className="flex justify-center items-center shadow-lg mx-8">
        <div
          className="check p-6 z-50 bg-white rounded-lg"
          onClick={handleInsideClick}
        >
          <div
            className="text-2xl font-medium"
            style={{ letterSpacing: "-0.72px" }}
          >
            Join {data?.owner_id.name}’s
            <br />
            <span className="text-primary">{data?.name}</span> wishlist
            <br />
            to add places and step out <br />
            together
          </div>
          <div className="my-6 flex justify-between items-center">
            <div className="text-base font-450">
              {data?.places.length} Place{data?.places.length > 1 && "s"}
            </div>
            <div className="flex w-1/2 justify-start">
              {data?.collaborators.length > 0 && (
                <div className="flex items-center">
                  <img
                    src="/images/Male.svg"
                    alt="avatar"
                    className="h-4 w-4 object-cover rounded-full mr-1"
                  />
                  <p className="text-xs font-450">
                    {data.collaborators[0].user_id.name}
                    {data.collaborators.length > 1 &&
                      ` + ${data.collaborators.length - 1} more`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="bottom-8 mx-auto max-w-md w-full bg-primary flex justify-center rounded-full flex-1 h-12">
            <button
              onClick={() => {
                Mixpanel.track("Collaborator Join Clicked", {
                  wishlist_id: data._id,
                  ui_type: "join-modal",
                });
                onJoin();
                onClose();
              }}
              style={{ letterSpacing: "4px" }}
              className={`leading-6 px-6 py-1 w-[90%] rounded-full text-white text-xl font-bold shadow`}
            >
              JOIN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinModal;
