import { SparklesIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const CustomisePreference = () => {
  return (
    <div className="flex items-center justify-around font-inter min-w-80 mt-8 bg-white border text-primary border-primary focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-base leading-5 text-center px-10 py-4 focus:outline-none">
      <Link
        // to="/app/onboarding/customize-interests?referrer=custom"
        to="/app/onboarding/customize-interests"
        className="flex"
      >
        <SparklesIcon className="h-5 w-5 flex font-bold mr-2 text-primary items-center justify-center" />
        <p className="whitespace-nowrap">Customise Preferences</p>
      </Link>
    </div>
  );
};
export default CustomisePreference;
