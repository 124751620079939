import { PhoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  AroundlyPlaces,
  SocialMediaUpdate,
  UpdateType,
} from "../common/global.types";
import SocialButton from "./socialButton";
import BetaBadge from "./BetaBadge";
import { Link } from "react-router-dom";
import { Mixpanel } from "../services/mixpanel";
import { useAuth } from "../context/auth";
import { FOOD_AND_BEV_INTEREST_ID } from "../common/global.constants";

const generatePhoneUrl = (phone: string) => {
  return `tel:${phone}`;
};
const BookBotCard = ({
  placeDetails,
  handleBookTray,
  item,
}: {
  placeDetails?: AroundlyPlaces;
  handleBookTray: (staus: boolean) => void;
  item: SocialMediaUpdate;
}) => {
  const { authUser } = useAuth();
  return (
    <div
      id="modal"
      onClick={() => {
        handleBookTray(false);
      }}
      className={`    fixed left-0 right-0 top-0 bottom-0 bg-black/60 z-40 max-w-md mx-auto`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="fixed bottom-0 rounded-t-lg min-h-96 left-0 right-0 z-50 max-w-md mx-auto overflow-hidden bg-white"
      >
        <div className="flex justify-between p-3 mt-3">
          <div
            className="font-bold text-2xl  leading-tight"
            style={{
              letterSpacing: "-0.72px",
            }}
          >
            Book Options
          </div>
          <div
            onClick={(e) => {
              handleBookTray(false);
            }}
            className="cursor-pointer"
          >
            <XMarkIcon className="h-8 w-8" />
          </div>
        </div>

        <div className="flex items-start gap-1 px-3">
          {(placeDetails?.google_maps_details.formatted_phone_number ||
            (placeDetails?.socials && placeDetails?.socials?.length > 0)) && (
            <div className="flex-col items-center  justify-center  p-2">
              {placeDetails?.google_maps_details.formatted_phone_number && (
                <div className="h-16 w-16">
                  <SocialButton
                    url={generatePhoneUrl(
                      placeDetails.google_maps_details.formatted_phone_number
                    )}
                    icon={
                      <PhoneIcon className="h-10 w-10 inline-block text-zinc-700" />
                    }
                    update={true}
                  />
                </div>
              )}
              <p
                className="flex items-center justify-center mt-2 text-xs font-450"
                style={{ letterSpacing: "-0.33px" }}
              >
                Call
              </p>
            </div>
          )}
          {item.interest_categories &&
            item.interest_categories.some(
              (val) => val._id === FOOD_AND_BEV_INTEREST_ID
            ) && (
              <>
                <div className="p-2 flex flex-col gap-2 items-center">
                  <Link
                    id="zomato"
                    rel="noreferrer"
                    to={`https://www.zomato.com/`}
                    className="no-underline "
                    target="_blank"
                  >
                    <img
                      src="/images/sources/zomato.png"
                      className="h-16 w-16 rounded-lg"
                      alt="zomato"
                    />
                  </Link>

                  <p className="text-xs font-450">Zomato</p>
                </div>
                <div className="p-2 flex flex-col gap-2 items-center justify-center">
                  <Link
                    id="dineout"
                    rel="noreferrer"
                    to={`https://www.swiggy.com/swiggy-dineout`}
                    className="no-underline "
                    target="_blank"
                  >
                    <img
                      src="/images/sources/dineout.png"
                      className="h-16 w-16"
                      alt="dineout"
                    />
                  </Link>
                  <p className="text-xs font-450">
                    Swiggy
                    <br /> Dineout
                  </p>
                </div>
              </>
            )}
          <div className="p-2 flex flex-col gap-2  items-center justify-center">
            <Link
              id="google-place-link"
              rel="noreferrer"
              to={`https://www.google.com/maps/search/?api=1&query=${placeDetails?.google_maps_details?.formatted_address}&query_place_id=${placeDetails?.place_id}`}
              className="no-underline   rounded-lg justify-center"
              target="_blank"
            >
              <img
                src="/images/Google Maps logo.png"
                className="h-16 w-16 rounded-lg"
                alt="google map"
              />
            </Link>
            <div>
              <p
                className="flex justify-center  text-xs font-450"
                style={{ letterSpacing: "-0.33px" }}
              >
                View on
              </p>
              <p
                className="flex justify-center  text-xs font-450 whitespace-nowrap"
                style={{ letterSpacing: "-0.33px" }}
              >
                Google Maps
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-2 px-3">
          <div className="w-1/12 h-3">
            <BetaBadge />
          </div>
          <p
            className="text-secondary-text text-xs font-450"
            style={{
              letterSpacing: "-0.33px",
            }}
          >
            We're redirecting you to the website or app for now, so you might
            need to search for it, but soon we'll get you straight to the exact
            booking link.
          </p>
        </div>
      </div>
    </div>
  );
};
export default BookBotCard;
