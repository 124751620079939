/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { XMarkIcon } from "@heroicons/react/24/outline";
import GetAroundlyInput from "./getAroundlyInput";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface BotProp {
  showTray: boolean;
  toggle: () => void;
}
export const BottomTray = ({ showTray, toggle }: BotProp) => {
  const [closeCount, setCloseCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const storedCount = sessionStorage.getItem("closeCount") || 0;
    const lastClosed = sessionStorage.getItem("lastClosed");

    if (storedCount && lastClosed) {
      const today = new Date().toDateString();
      if (lastClosed !== today && parseInt(storedCount) >= 2) {
        sessionStorage.setItem("closeCount", "0");
        setCloseCount(0);
      } else {
        setCloseCount(Number(storedCount));
      }
    }
  }, []);

  const handleClose = () => {
    toggle();
    const newCount = closeCount + 1;
    setCloseCount(newCount);
    sessionStorage.setItem("closeCount", String(newCount));
    sessionStorage.setItem("lastClosed", new Date().toDateString());
  };

  if (location.pathname.includes("feed")) {
    useEffect(() => {
      const timer = setTimeout(() => {
        toggle();
      }, 10000);

      return () => clearTimeout(timer);
    }, []);
  }

  if (closeCount >= 2) {
    return null;
  }

  return (
    <div
      id="modal"
      className={` ${
        showTray ? " " : "hidden"
      } fixed left-0 right-0 top-0 bottom-0 bg-black/60 z-40 max-w-md mx-auto`}
    >
      <div className="fixed bottom-0 rounded-t-lg min-h-96 left-0 right-0 z-40 max-w-md mx-auto overflow-hidden bg-white">
        <div className="flex justify-between p-3 mt-3">
          <div className="font-bold text-[2rem] leading-tight">
            Find the latest of <br />{" "}
            <span className="text-primary">Bengaluru,</span> always
          </div>
          <div
            onClick={handleClose}
            className="cursor-pointer"
          >
            <XMarkIcon className="h-8 w-8" />
          </div>
        </div>
        <div className="text-base leading-5 mx-3 mb-4 text-secondary-text">
          Aroundly is your one app to discover local food, offers & experiences
          around you.
        </div>
        <div className="mx-3 pb-20">
          <GetAroundlyInput />
        </div>
      </div>
    </div>
  );
};
