import BackButton from "../components/BackButton";
import FeedCard from "../components/FeedCard";
import { SocialMediaUpdate } from "../common/global.types";
import { DEFAULT_TIMEZONE } from "../common/global.constants";
import LoadingComp from "../components/LoadingComp";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSocialMediaUpdate } from "../services/aroundly-apis";
import { Mixpanel } from "../services/mixpanel";
import ButtonControl from "./controls/ButtonControl";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useCollabListsContext } from "../context/CollabListsContext";
const UpdateContent = ({ updateId }: { updateId: string }) => {
  const [updateDetails, setUpdateDetails] = useState<SocialMediaUpdate>();
  const { socialMediaUpdateId } = useParams();
  const socialMediaIdtoFetch = socialMediaUpdateId || updateId;
  const [dataLoading, setDataLoading] = useState(false);
  const { toggleUpdateModal, showUpdate } = useCollabListsContext();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoading(true);
        const updateResponse = await fetchSocialMediaUpdate(
          socialMediaIdtoFetch
        );
        // TODO: need to add user details here.
        Mixpanel.track("Post: Viewed", {
          post_id: socialMediaUpdateId,
          post_source: updateResponse.source,
        });

        setUpdateDetails(updateResponse);
        setDataLoading(false);
      } catch (error) {
        setDataLoading(true);
        console.error("Error fetching social media update details:", error);
      }
    };

    fetchData();
  }, [socialMediaIdtoFetch, socialMediaUpdateId]);
  return (
    <>
      {/* <GtagPathView /> */}
      {dataLoading ? (
        <div className="relative m-auto flex justify-center items-center h-dvh ">
          <LoadingComp
            defaultText="Update"
            primaryText="Loading"
            showSources={false}
          />
        </div>
      ) : (
        <div className="">
          {/* <ButtonControl
            buttonId="save-unsave-button"
            size="lg"
            theme={"white"}
            icon={{
              component: ArrowLeftIcon,
            }}
            iconColor={"black"}
            onClick={() => {
              if (showUpdate) {
                toggleUpdateModal(false);
              } else {
                navigate("/");
              }
            }}
          /> */}

          {updateDetails && (
            <FeedCard
              item={updateDetails}
              isFullView
              timezone={DEFAULT_TIMEZONE}
            />
          )}
        </div>
      )}
    </>
  );
};
export default UpdateContent;
