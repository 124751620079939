import {
  ChangeEvent,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from "react";
import NewButton from "./newButton";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { JOIN_BUTTON_TEXT } from "../common/global.constants";
// import { useNavigate } from "react-router-dom";
import { login } from "../services/aroundly-apis";
import VerifyUi from "./verifyUi";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

const getOpacityClass = (opacity: number) => {
  switch (opacity) {
    case 20:
      return "opacity-20";
    case 30:
      return "opacity-30";
    case 40:
      return "opacity-40";
    case 50:
      return "opacity-50";
    case 60:
      return "opacity-60";
    case 70:
      return "opacity-70";
    case 80:
      return "opacity-80";
    case 90:
      return "opacity-90";
    case 100:
      return "opacity-100";
    default:
      return "opacity-100";
  }
};

export default function GetAroundlyInput({ title }: { title?: string }) {
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [opacityy, setOpacity] = useState(20);

  // const navigate = useNavigate();
  // const onChange = (val: string) => {
  //   setPhone(val);
  // };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let phoneValue = "+91" + e.target.value;
    setPhone(phoneValue);
    let newOpacity = Math.round(((phoneValue.length - 3) * 10) / 5) * 5;
    if (newOpacity < 20) {
      newOpacity = 20;
    }
    setError(false);
    setOpacity(newOpacity);
  };

  const onLoginClick = async () => {
    try {
      if (phone.length < 13) {
        setError(true);
        return;
      }
      setIsLoading(true);
      const loginResp = await login(phone);
      if (loginResp.msg === "OTP sent successfully") {
        setShowVerify(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (showVerify) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [showVerify]);

  return (
    <div className="">
      <div>
        <div className="relative flex flex-1  h-12 border  border-gray-700 p-1 rounded-full bg-white  pl-3">
          {/* <PhoneInput
            international
            country="IN"
            countries={["IN"]}
            countryCallingCodeEditable={false}
            placeholder="Enter phone mobile"
            value={phone}
            defaultCountry="IN"
            inputComponent={Input}
            onChange={onChange}
          />
          <NewButton
            isAbsolute
            className="hidden right-1 top-1 bottom-1"
            onNext={onLoginClick}
            loading={isLoading}
          >
            {title ? title : JOIN_BUTTON_TEXT}
          </NewButton> */}
          <div className="relative">
            <input
              aria-label="phonenumber"
              maxLength={10}
              type="tel"
              className={`focus:ring-0 m-0 font-medium ml-4 mt-1 items-center grow border-none p-0 focus:border-none text-[#3F3F46] text-xl leading-normal truncate bg-transparent outline-none pl-6`}
              placeholder="Enter mobile"
              onChange={handleChange}
            />
            <div className="absolute left-0 top-1 ml-1 my-auto text-[#3F3F46] text-xl font-medium items-center leading-normal">
              +91
            </div>
          </div>
        </div>
        <NewButton
          loading={isLoading}
          className={`block w-full mt-2 ${
            phone.length === 0 ? "opacity-100" : getOpacityClass(opacityy)
          }`}
          onNext={onLoginClick}
        >
          {title ? title : JOIN_BUTTON_TEXT}
        </NewButton>
        {error && (
          <p className="text-[#D98E1F] mb-3 text-sm flex items-center justify-center mt-2">
            <ExclamationCircleIcon className="h-5 w-5 mr-1" /> Enter a valid
            mobile number
          </p>
        )}
      </div>
      {showVerify && (
        <VerifyUi
          phone={phone}
          onClose={() => {
            setShowVerify(false);
            document.body.style.overflow = "unset";
          }}
        />
      )}
    </div>
  );
}

// const Input = forwardRef<any, InputHTMLAttributes<HTMLInputElement>>(
//   (props, ref) => {
//     const { onChange, value } = props;
//     return (
//       <input
//         aria-label="phonenumber"
//         ref={ref}
//         onChange={onChange}
//         value={value}
//         className={`h-full w-full border-none focus:border-transparent focus:ring-0 circularStd text-xl`}
//         type="tel"
//         id="phone-input"
//         placeholder="Enter mobile"
//       />
//     );
//   }
// );
// Input.displayName = "Input name";
