import { ArrowLeftIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useFeedData } from "../context/FeedDataContext";
import {
  createList,
  deleteList,
  updateListname,
} from "../services/aroundly-apis";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Placecompo from "./Placecompo";
import UpdatesForYou from "./UpdatesForYou";
import {
  AroundlyPlaces,
  SocialMediaUpdate,
  CollabWishlistType,
} from "../common/global.types";
import { Mixpanel } from "../services/mixpanel";
// import { useNavigate } from "react-router-dom";
import { useCollabListsContext } from "../context/CollabListsContext";
import ButtonControl from "./controls/ButtonControl";

const CreateNewModal = ({
  placeData,
  updateData,
  onClose,
  collabdata,
}: {
  placeData: AroundlyPlaces;
  updateData: SocialMediaUpdate;
  onClose: (data?: any, id?: string) => void;
  collabdata: CollabWishlistType;
}) => {
  const { selectedLocation } = useFeedData();
  const [collabListName, setCollabListName] = useState("");
  const [isCreateAPILoading, setIsCreateAPILoading] = useState(false);
  const { toggleUpdateModal } = useCollabListsContext();
  const [isInputFocused, setIsInputFocused] = useState(true); // Initially true
  const [isdeleteloading, setIsdeleteLoading] = useState(false);

  const handleCreateList = async () => {
    toggleUpdateModal(false, updateData?._id);
    try {
      setIsCreateAPILoading(true);
      const data = await createList(
        collabListName,
        placeData ? placeData._id : "",
        updateData ? updateData._id : ""
      );
      setIsCreateAPILoading(false);
      if (data.new?._id) {
        Mixpanel.track("Collaborative List Created", {
          from_update_id: updateData?._id || "",
          from_place_id: placeData?._id || "",
          wishlist_id: data.new._id,
        });
        toast.success("Wishlist Created");
      }
      onClose(data);
    } catch (e) {
      setIsCreateAPILoading(false);
      toast.error("Could not Create Wishlist");
      console.log(e);
    }
  };

  const handleEditList = async () => {
    try {
      setIsCreateAPILoading(true);
      const data = await updateListname(collabdata._id, collabListName);
      setIsCreateAPILoading(false);
      if (data.length) {
        Mixpanel.track("Collaborative List Edited", {
          wishlist_id: collabdata._id,
        });
        toast.success("Wishlist Updated");
      }
      onClose(data, collabdata?._id);
    } catch (e) {
      setIsCreateAPILoading(false);
      toast.error("Could not Update Wishlist");
      console.log(e);
    }
  };

  const handleDeleteList = async () => {
    setIsdeleteLoading(true);
    try {
      const data = await deleteList(collabdata._id);
      Mixpanel.track("Collaborative List Deleted", {
        wishlist_id: collabdata._id,
      });
      toast.success("Wishlist Deleted");
      onClose(data, collabdata?._id);
      setIsdeleteLoading(false);
    } catch (e) {
      setIsCreateAPILoading(false);
      toast.error("Could not Update Wishlist");
      console.log(e);
      setIsdeleteLoading(false);
    }
  };

  const handleText = (e: ChangeEvent<HTMLInputElement>) => {
    setCollabListName(e.target.value);
  };

  useEffect(() => {
    if (collabdata) {
      setCollabListName(collabdata.name);
    }
  }, [collabdata]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        event.target &&
        !(event.target as HTMLElement).closest("#inputField")
      ) {
        setIsInputFocused(false);
      }
    };

    const handleScroll = () => {
      setIsInputFocused(false);
    };

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      id="modal"
      style={{
        zIndex: 100,
      }}
      className={`fixed left-0 right-0 top-0 bottom-0 bg-black/90  max-w-md mx-auto`}
    >
      <div
        className={`w-full h-full bg-white overflow-y-scroll scrollbar-hide mb-5 overflow-x-hidden`}
      >
        <div
          className={`${
            isInputFocused && updateData && placeData && "moved-up"
          } bg-white p-3 shadow-sm`}
        >
          <div className="justify-start items-center gap-1 flex text-2xl font-medium">
            <button
              onClick={() => onClose()}
              className="text-zinc-700 mr-3 cursor-pointer"
            >
              <ArrowLeftIcon className="h-6 w-6" />
            </button>
            <div
              style={{ letterSpacing: "-0.72px" }}
              className="flex justify-start items-center font-bold text-2xl leading-6 hover:text-opacity-80"
            >
              {collabdata ? "Edit List" : "Create New"}
            </div>
          </div>
        </div>

        {updateData && placeData && (
          <div
            className={`py-3 border-b-2 ${
              isInputFocused && updateData && placeData && "moved-up"
            }`}
          >
            <div className="pointer-events-none">
              <UpdatesForYou
                hideActionButtons
                timezone={selectedLocation?.timezone as string}
                updates={[updateData]}
                isLoading={false}
                updateText=""
              />
              <div className="px-3">
                <Placecompo item={placeData} hideActionButtons />
              </div>
            </div>
          </div>
        )}

        <div
          className={`mt-6 ${
            isInputFocused && updateData && placeData && "moved-up"
          }`}
        >
          <div className="mx-4 text-xs flex w-full justify-start font-medium mb-3">
            List name
          </div>
          <input
            id="inputField"
            className={`focus:ring-0 mx-4 py-2 mb-5 border border-b-[1px] border-[#DCDEE3] border-l-0 border-t-0 border-r-0 font-medium mt-1 items-center grow w-[90%] p-0 placeholder-[#dadada] text-xl leading-normal truncate bg-transparent outline-none`}
            placeholder="Dinner plans"
            maxLength={30}
            value={collabListName}
            onChange={handleText}
            onFocus={() => setIsInputFocused(true)}
            autoFocus={true}
          />

          <div className="mx-auto w-[95%] max-w-md flex flex-col justify-center rounded-full flex-1 h-12">
            <ButtonControl
              buttonId="update-button"
              size="lg"
              theme="primary"
              name={collabdata ? "Update" : "Create List"}
              isLoading={isCreateAPILoading}
              disabled={isCreateAPILoading}
              onClick={() => {
                collabdata ? handleEditList() : handleCreateList();
              }}
            />
          </div>

          {collabdata && (
            <div className="mt-20 mx-auto w-[95%] max-w-md flex justify-center rounded-full flex-1 h-12">
              <ButtonControl
                buttonId="delete-button"
                size="lg"
                theme="white"
                name={"Delete Wishlist"}
                isLoading={isdeleteloading}
                disabled={isdeleteloading}
                onClick={() => {
                  collabdata && handleDeleteList();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateNewModal;
