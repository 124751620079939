import { useNavigate } from "react-router-dom";
import { UserProps } from "../common/global.types";
import { createContext, useContext, useEffect, useState } from "react";
import { getUser, logout } from "../services/aroundly-apis";
import {
  getTokenFromStorage,
  removeTokenFromStorage,
} from "../services/local-storage.service";
import { Mixpanel } from "../services/mixpanel";
// import { removeNotificationTokenFromDb } from "../common/notification";

interface UserContextProps {
  authUser: UserProps | null | undefined;
  loading: boolean;
  // verifyLogin: ((body: any, cb: (val: boolean) => void) => void) | null;
  setAuthUser: ((data: UserProps) => void) | null;
  logout: (() => void) | null;
}

const AuthUserContext = createContext({
  authUser: null,
  loading: true,
  login: null,
  // verifyLogin: null,
  setAuthUser: null,
  logout: () => {},
} as UserContextProps);

export function AuthUserProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [authUser, setAuthUser] = useState<UserProps | null>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const setUserData = (data: any) => {
    Mixpanel.identify(data._id);
    setAuthUser(data);
  };

  const removeUserData = async () => {
    setAuthUser(null);
    await removeTokenFromStorage();
  };

  const performLogout = async () => {
    try {
      const resp = await logout();
      if (resp.msg === "Logged out") {
        await removeUserData();
        // await removeNotificationTokenFromDb();
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const isLoggedIn = async () => {
      try {
        const token = await getTokenFromStorage();
        if (!token) {
          setLoading(false);
          return;
        }
        setLoading(true);
        const resp = await getUser();
        if (resp) {
          setUserData({ ...resp });
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    isLoggedIn();
  }, []);

  return (
    <AuthUserContext.Provider
      value={{
        authUser: authUser,
        loading: loading,
        setAuthUser: setUserData,
        logout: performLogout,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
}

// custom hook to use the authUserContext and access authUser and loading
export const useAuth = () => useContext(AuthUserContext);
