import { XMarkIcon } from "@heroicons/react/24/outline";
import GetItOnContainer from "./GetItOnContainer";

export default function GetTheApp({ onClose }: { onClose: () => void }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm bg-black/30 h-svh max-w-md mx-auto">
      <div className="p-6 bg-white rounded-xl shadow-xl z-50 my-12 mx-4">
        <div className="flex justify-between">
          <div className="text-stone-900 text-[40px] font-medium">
            Get the App
          </div>
          <button onClick={() => onClose()}>
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="mt-3 text-secondary-text text-base font-medium">
          Aroundly BETA is now available on Apple & Google store. If you are
          already our early user, simply opt in.
        </div>
        <div className="mt-3">
          <GetItOnContainer size="large"/>
        </div>
        <div className="text-slate-400 text-xs font-medium mt-3">
          *Terms & Conditions apply
        </div>
      </div>
    </div>
  );
}
