import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ButtonControl from "./controls/ButtonControl";
import { Mixpanel } from "../services/mixpanel";

const HomeNav = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between px-3 py-2 w-full absolute top-0 z-10 items-center text-center">
        <div
          onClick={() => {
            Mixpanel.track("Aroundly Icon Click", {
              ui_type: "home-aroundly-icon",
            });
            navigate("/app/whatisaroundly");
          }}
          className=" "
        >
          <img
            src="/images/logo-white.png"
            alt="background"
            className="max-h-8"
          />
        </div>
        <div>
          <ButtonControl
            buttonId="user-icon"
            size="lg"
            theme="white"
            icon={{ component: UserCircleIcon }}
            iconPlacement="end"
            onClick={() => {
              Mixpanel.track("Profile Icon Click", {
                ui_type: "home-profile-icon",
              });
              navigate("wishlist");
            }}
          />
        </div>
      </div>
    </>
  );
};
export default HomeNav;
