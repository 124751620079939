import Axios from "axios";
import {
  getDeviceId,
  getTokenFromStorage,
} from "../services/local-storage.service";

const common_axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

common_axios.interceptors.request.use(
  async (config: any) => {
    const token = await getTokenFromStorage();
    const deviceId = await getDeviceId();
    config.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (deviceId) {
      config.headers["device-id"] = deviceId;
    }

    return config;
  },
  async (error) => {
    return await Promise.reject(error);
  }
);

export default common_axios;
