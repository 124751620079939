import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Geolocation, Position } from "@capacitor/geolocation";
import { useCapacitorInfo } from "./CapacitorContext";
import LoadingComp from "../components/LoadingComp";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { LocationType } from "../common/global.types";
import {
  AROUND_ME_DISTANCE_IN_KM,
  DEFAULT_TIMEZONE,
} from "../common/global.constants";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

interface LocationContextProps {
  userCurrentPosition: Position;
  aroundMeLocation: LocationType;
}

const LocationContext = createContext({} as LocationContextProps);

export function LocationProvider({ children }: { children: React.ReactNode }) {
  // Other Contexts
  const { isNative, platform } = useCapacitorInfo();

  // States
  const [locationPermission, setLocationPermission] =
    useState<PermissionState>();
  const [hasSystemLocationError, setHasSystemLocationError] = useState(false);
  const [userCurrentPosition, setUserCurrentPosition] = useState<
    Position | undefined
  >();
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [aroundMeLocation, setAroundMeLocation] = useState<
    LocationType | undefined
  >();

  useEffect(() => {
    if (userCurrentPosition) {
      setAroundMeLocation({
        location_name: `around me`,
        location_id: "around_me",
        radius: AROUND_ME_DISTANCE_IN_KM * 1000,
        latitude: userCurrentPosition.coords.latitude,
        longitude: userCurrentPosition.coords.longitude,
        timezone: DEFAULT_TIMEZONE,
      });
    }
  }, [userCurrentPosition]);

  const getGeoLocation = useCallback(async () => {
    try {
      setIsFetchingLocation(true);
      const coordinates = await Geolocation.getCurrentPosition();
      const recheckPermission = await Geolocation.checkPermissions();
      setLocationPermission(recheckPermission.location as PermissionState);
      setUserCurrentPosition(coordinates);
      setIsFetchingLocation(false);
    } catch (error) {
      if ((error as any).code === 1) {
        setLocationPermission("denied");
      } else if (
        (error as any).code === 3 &&
        (error as any).message === "Timeout expired"
      ) {
        toast.error("Location fetch timed out. Please try again manually.");
      }
      setIsFetchingLocation(false);
      // code: 3, message: "Timeout expired"
      // code: 2, message: "Canceled by user"
      // code: 1, message: "User denied Geolocation"
      console.log(error);
    }
  }, []);

  const getLocationPermission = useCallback(async () => {
    try {
      if (!isNative) await getGeoLocation();
      else {
        const requestPermission = await Geolocation.requestPermissions({
          permissions: ["location"],
        });
        setLocationPermission(requestPermission.location as PermissionState);

        if (requestPermission.location === "granted") {
          await getGeoLocation();
        }
      }
    } catch (error) {
      if ((error as any).code === 1) {
        setLocationPermission("denied");
      }
      console.log(error);
    }
  }, [getGeoLocation, isNative]);

  useEffect(() => {
    const getLocation = async () => {
      try {
        const initialPermission = await Geolocation.checkPermissions();
        setLocationPermission(initialPermission.location as PermissionState);

        if (initialPermission.location === "granted" && platform === "web")
          await getGeoLocation();
        else await getLocationPermission();
      } catch (e) {
        setHasSystemLocationError(true);
        // // TODO: below should happen on a button click
        // if (isNative) {
        //   // Open app settings if permissions are denied
        //   await NativeSettings.open({
        //     optionAndroid: AndroidSettings.Location,
        //     optionIOS: IOSSettings.App, // TODO: Figure out if we can have Location services here.
        //   });
        // }
        console.log(e);
      }
    };

    getLocation();
  }, [getLocationPermission, platform, getGeoLocation, isNative]);

  const openLocationSettings = async (isAppSetting: boolean = false) => {
    // Open app settings if permissions are denied
    const settingsStatusObj = await NativeSettings.open({
      optionAndroid: isAppSetting
        ? AndroidSettings.ApplicationDetails
        : AndroidSettings.Location,
      optionIOS: IOSSettings.App, // TODO: Figure out if we can have Location services here.
    });

    if (settingsStatusObj.status) {
      getLocationPermission();
    }
  };

  const renderContent = () => {
    if (aroundMeLocation) return children;
    // return children;

    return (
      <div className="h-dvh flex flex-col justify-center items-center">
        <div>
          <LoadingComp
            defaultText="your location"
            primaryText="Fetching"
            showSources={false}
          />
        </div>
        {/* {locationPermission === "granted" && ( */}
        <div className="mt-6 flex flex-col items-center text-center mx-6">
          {isFetchingLocation ? (
            "Fetching your location ..."
          ) : (
            <>
              {hasSystemLocationError && (
                <>
                  <div className="text-lg font-medium">
                    Location Services Disabled on your device
                  </div>
                  <p className="text-xs my-4 text-red-500 mx-2">
                    Aroundly needs to know your location to show you what's
                    around. Enable it to continue.
                  </p>
                  {isNative && (
                    <>
                      <button
                        onClick={() => openLocationSettings()}
                        className="px-6 py-2 bg-special-blue rounded-full text-white flex gap-2 items-center"
                      >
                        <Cog6ToothIcon className="h-5 w-5" />{" "}
                        <div>Enable Location</div>
                      </button>
                    </>
                  )}
                </>
              )}
              {locationPermission === "denied" && !hasSystemLocationError && (
                <>
                  <div className="text-lg font-medium">
                    Location Access Denied
                  </div>
                  <p className="text-xs mt-2 my-4 text-red-500 mx-2">
                    Aroundly needs to know your location to show you what's
                    around. Enable it to continue.
                  </p>
                  {!isNative && (
                    <p className="text-xs mt-2 my-4 text-red-500 mx-2">
                      Note: If you are on a desktop, check location settings on
                      your laptop.
                    </p>
                  )}
                  {isNative && (
                    <>
                      <button
                        onClick={() => openLocationSettings(true)}
                        className="px-6 py-2 bg-special-blue rounded-full text-white flex gap-2 items-center"
                      >
                        <Cog6ToothIcon className="h-5 w-5" />{" "}
                        <div>Enable Location</div>
                      </button>
                    </>
                  )}
                </>
              )}
              {locationPermission !== "denied" && !hasSystemLocationError && (
                <button
                  onClick={() => getLocationPermission()}
                  className="px-6 py-2 bg-special-blue rounded-full text-white flex gap-2 items-center"
                >
                  <MapPinIcon className="h-5 w-5" />{" "}
                  <div>Fetch Location Manually</div>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <LocationContext.Provider
      value={{
        userCurrentPosition: userCurrentPosition as Position,
        aroundMeLocation: aroundMeLocation as LocationType,
      }}
    >
      {renderContent()}
    </LocationContext.Provider>
  );
}

export const useLocationInfo = () => useContext(LocationContext);
