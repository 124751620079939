import { SocialMediaUpdate } from "../common/global.types";
import moment from "moment";
import "moment-timezone";
import {
  DEFAULT_TIMEZONE,
  FOOD_AND_BEV_INTEREST_ID,
  UPDATE_TYPE_EVENT,
} from "../common/global.constants";
const SHOPPINGID = "6508dcea7d90ea7d81e8a01c";
export const filterDataBySearchParams = (
  updates: SocialMediaUpdate[],
  searchParams: URLSearchParams
) => {
  let filteredData = updates;

  if (searchParams.get("interest")) {
    filteredData = filteredData.filter((u) => {
      const interestIds = u.interest_categories?.map((i) => i._id) || [];
      const hasFoodBevId = interestIds.includes(FOOD_AND_BEV_INTEREST_ID);

      if (searchParams.get("interest") === SHOPPINGID) {
        return !hasFoodBevId;
      }

      return interestIds.includes(searchParams.get("interest") as string);
    });
  }

  if (searchParams.get("update_type")) {
    filteredData = filteredData.filter(
      (u) => u.update_type === searchParams.get("update_type")
    );
  }

  if (searchParams.get("is_best") === "true") {
    filteredData = filteredData.filter((u) => u.is_best);
  }

  if (searchParams.get("time")) {
    if (searchParams.get("time") === "today") {
      filteredData = filteredData.filter(
        (u) =>
          moment(u.expires_at).tz(DEFAULT_TIMEZONE).unix() <
          moment().tz(DEFAULT_TIMEZONE).endOf("day").unix()
      );
    } else if (searchParams.get("time") === "tomorrow") {
      filteredData = filteredData.filter(
        (u) =>
          moment(u.expires_at).tz(DEFAULT_TIMEZONE).unix() <
            moment().tz(DEFAULT_TIMEZONE).add(1, "days").endOf("day").unix() &&
          moment(u.expires_at).unix() >
            moment().tz(DEFAULT_TIMEZONE).add(1, "days").startOf("day").unix()
      );
    } else if (searchParams.get("time") === "week") {
      const startOfWeek = moment().tz(DEFAULT_TIMEZONE).startOf("isoWeek"); // Start of the week (Monday)
      const endOfWeek = moment().tz(DEFAULT_TIMEZONE).endOf("isoWeek"); // End of the week (Sunday)

      filteredData = filteredData.filter(
        (u) =>
          moment(u.expires_at)
            .tz(DEFAULT_TIMEZONE)
            .isSameOrAfter(startOfWeek, "day") &&
          moment(u.expires_at)
            .tz(DEFAULT_TIMEZONE)
            .isSameOrBefore(endOfWeek, "day")
      );
    } else if (searchParams.get("time") === "weekend") {
      const currentDayOfWeek = moment().tz(DEFAULT_TIMEZONE).day();

      if (currentDayOfWeek >= 1 && currentDayOfWeek <= 5) {
        filteredData = filteredData.filter(
          (u) =>
            moment(u.expires_at)
              .tz(DEFAULT_TIMEZONE)
              .isSameOrAfter(
                moment().tz(DEFAULT_TIMEZONE).endOf("week").subtract(1, "days"),
                "day"
              ) &&
            moment(u.expires_at)
              .tz(DEFAULT_TIMEZONE)
              .isBefore(
                moment().tz(DEFAULT_TIMEZONE).endOf("week").add(2, "days"),
                "day"
              )
        );
      } else if (currentDayOfWeek === 6) {
        // If it's Saturday, filter for today and tomorrow
        filteredData = filteredData.filter(
          (u) =>
            moment(u.expires_at)
              .tz(DEFAULT_TIMEZONE)
              .isSameOrAfter(moment().tz(DEFAULT_TIMEZONE), "day") &&
            moment(u.expires_at)
              .tz(DEFAULT_TIMEZONE)
              .isBefore(moment().tz(DEFAULT_TIMEZONE).add(2, "days"), "day")
        );
      } else if (currentDayOfWeek === 0) {
        // If it's Sunday, filter for today only
        filteredData = filteredData.filter((u) =>
          moment(u.expires_at)
            .tz(DEFAULT_TIMEZONE)
            .isSame(moment().tz(DEFAULT_TIMEZONE), "day")
        );
      }
    } else if (searchParams.get("time") === "month") {
      filteredData = filteredData.filter(
        (u) =>
          moment(u.expires_at).tz(DEFAULT_TIMEZONE).unix() <
          moment().tz(DEFAULT_TIMEZONE).endOf("month").unix()
      );
    } else if (searchParams.get("time") === "next-month") {
      filteredData = filteredData.filter(
        (u) =>
          moment(u.expires_at).tz(DEFAULT_TIMEZONE).unix() <
            moment()
              .tz(DEFAULT_TIMEZONE)
              .add(1, "month")
              .endOf("month")
              .unix() &&
          moment(u.expires_at).tz(DEFAULT_TIMEZONE).unix() >
            moment()
              .tz(DEFAULT_TIMEZONE)
              .add(1, "month")
              .startOf("month")
              .unix()
      );
    }
  }

  return filteredData;
};
