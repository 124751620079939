import common_axios from "../common/axios";
import {
  CollabWishlistType,
  LocationType,
  UpdateType,
} from "../common/global.types";
import {
  getReferIdFromStorage,
  setTokenToStorage,
} from "./local-storage.service";

const ENDPOINTS = {
  SOCIAL_MEDIA_UPDATES: "social-media-updates",
  POST_EMBEDDINGS: "post-embeddings",
  INTERESTS: "interest",
  CITY: "city",
  // USER_SUBSCRIPTION: "user-subscription",
  BUSINESS: "business",
  AROUNDLY_PLACES: "aroundly-places",
  USERACTION: "user-action",
  USERDATA: "user_data",
  SOURCES: "/sources",
  WISHLIST: "wishlist",
  USERACTIONHISTORY: "user-action-history",
  DEVICE_NOTIFICATIONS: "device-notifications",
};

export const getUpdates = async (aroundlyPlaceId: string | undefined) => {
  try {
    const res = await common_axios.get(
      `${ENDPOINTS.SOCIAL_MEDIA_UPDATES}/for-place/${aroundlyPlaceId}`
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getDeviceNotification = async (deviceId: string) => {
  try {
    const res = await common_axios.get(
      `${ENDPOINTS.DEVICE_NOTIFICATIONS}/${deviceId}`
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const saveDeviceNotificationToken = async (
  token: string,
  platform: string,
  deviceId: string
) => {
  try {
    const res = await common_axios.post(ENDPOINTS.DEVICE_NOTIFICATIONS, {
      notification_token: token,
      device_id: deviceId,
      platform,
    });

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateNotificationToken = async (
  notificationId: string,
  token: string
) => {
  try {
    const res = await common_axios.patch(
      `${ENDPOINTS.DEVICE_NOTIFICATIONS}/${notificationId}`,
      {
        notification_token: token,
      }
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getCities = async () => {
  try {
    const res = await common_axios.get("city");
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getCuration = async (location: LocationType) => {
  try {
    const res = await common_axios.post(
      `${ENDPOINTS.POST_EMBEDDINGS}/curation/`,
      { filters: { location } }
    );

    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// export const patchUserSubscription = async (
//   // userSubscription?: UserSubscriptionProps,
//   topics?: string[],
//   city?: string
// ) => {
//   try {
//     const payload: any = {
//       city,
//       topic_id: CONSTANTS.WHATSAPP_SUBSCRIPTION_TOPIC_ID,
//     };

//     if (topics) {
//       payload.interests = topics;
//     }

//     const res = await common_axios.patch(
//       `user-subscription/${?._id}`,
//       payload
//     );
//     return res.data;
//   } catch (e) {
//     console.error(e);
//   }
// };

// export const postUserSubscription = async (
//   topics?: string[],
//   city?: string
// ) => {
//   try {
//     const res = await common_axios.post("user-subscription/", {
//       interests: topics,
//       topic_id: CONSTANTS.WHATSAPP_SUBSCRIPTION_TOPIC_ID,
//       city,
//     });
//     return res.data;
//   } catch (e) {
//     console.log(e);
//   }
// };

export const similartySearchOnPosts = async (
  query: string,
  location: LocationType,
  updateType: UpdateType
) => {
  try {
    const res = await common_axios.post(
      `${ENDPOINTS.POST_EMBEDDINGS}/places-search/`,
      { query, filters: { location, update_type: updateType } }
    );

    return res.data.results;
  } catch (e) {
    throw e;
  }
};

export const getRecentQueries = async () => {
  try {
    const res = await common_axios.get(
      `${ENDPOINTS.POST_EMBEDDINGS}/recent-queries`
    );
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchInterests = async () => {
  try {
    const res = await common_axios.get(`${ENDPOINTS.INTERESTS}/interests-lite`);

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchMoods = async () => {
  try {
    const res = await common_axios.get(`${ENDPOINTS.INTERESTS}/moods`);

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchFeed = async (postDetails: any) => {
  try {
    const res = await common_axios.post(
      `${ENDPOINTS.SOCIAL_MEDIA_UPDATES}/places-feed`,
      postDetails
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchWishlistCount = async (postDetails: any) => {
  try {
    const res = await common_axios.post(
      `${ENDPOINTS.USERACTION}/wishCount`,
      postDetails
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchLocations = async () => {
  try {
    const res = await common_axios.get(`${ENDPOINTS.CITY}/locations`);

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

// export const fetchSubscriptionData = async () => {
//   try {
//     const res = await common_axios.get(
//       `${ENDPOINTS.USER_SUBSCRIPTION}/${CONSTANTS.WHATSAPP_SUBSCRIPTION_TOPIC_ID}`,
//       {
//         withCredentials: true,
//       }
//     );
//     return res.data;
//   } catch (e) {
//     console.log(e);
//   }
// };

export const fetchSocialMediaUpdate = async (
  socialMediaUpdateId: string | undefined
) => {
  try {
    const res = await common_axios.get(
      `${ENDPOINTS.SOCIAL_MEDIA_UPDATES}/one-update/${socialMediaUpdateId}`
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getPlaceDetails = async (aroundlyPlaceId: string | undefined) => {
  try {
    const res = await common_axios.get(
      `${ENDPOINTS.AROUNDLY_PLACES}/${aroundlyPlaceId}`
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getBusinessDetails = async (
  aroundlyPlaceId: string | undefined
) => {
  try {
    const res = await common_axios.get(
      `${ENDPOINTS.BUSINESS}/${aroundlyPlaceId}`
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getBusinessPlaces = async (searchText: string) => {
  try {
    // Pass the searchText as a query parameter
    const res = await common_axios.get(ENDPOINTS.BUSINESS, {
      params: { searchText },
    });
    return res.data;
  } catch (e) {
    console.error("Error while fetching business places:", e);
  }
};
export const login = async (phone: string) => {
  try {
    const resp = await common_axios.post("/login", {
      phone,
    });

    return resp.data;
  } catch (e) {
    console.log(e);
  }
};

export const logout = async () => {
  try {
    const resp = await common_axios.get("/logout");

    return resp.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateUser = async (
  phone: string,
  data: {
    birthdate?: Date;
    gender?: string;
    stepout?: any;
    is_onboarding_completed?: boolean;
    name?: string;
    city?: string;
    interests?: string[];
  }
) => {
  const resp = await common_axios.patch(`${ENDPOINTS.USERDATA}/update`, {
    phone,
    ...data,
  });
  return resp.data;
};

export const loginVerify = async (
  phone: string,
  otp: string,
  isOnboardingEnabled: boolean
) => {
  try {
    const referral_id = await getReferIdFromStorage();
    const response = await common_axios.post("/login/verify", {
      phone,
      otp,
      is_onboarding_enabled: isOnboardingEnabled,
      invited_by: referral_id,
    });
    const data = response.data;
    const token = data.accessToken;
    await setTokenToStorage(token);

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async () => {
  try {
    const response = await common_axios.get(ENDPOINTS.USERDATA);
    return response.data;
  } catch (error) {
    console.log("Error fetching user data:", error);
  }
};

export const getAvatar = async () => {
  const data = await common_axios.get("/avatar");
  return data;
};

export const performUserActionHistory = async (
  action: string,
  additional_info?: any
) => {
  try {
    const requestData = {
      action_taken: action,
      additional_info,
    };
    const response = await common_axios.post(
      `${ENDPOINTS.USERACTIONHISTORY}`,
      requestData
    );
    return response.data;
  } catch (error) {
    console.error("Error occurred while adding wishlist:", error);
    throw error; // Re-throwing the error for handling elsewhere if needed
  }
};

export const performUserAction = async (
  socialMediaUpdateId: string,
  action: string,
  reason?: string
) => {
  try {
    const requestData = {
      action,
      updateId: socialMediaUpdateId,
      reason: reason || undefined, // If reasons is not provided, pass undefined
    };
    const response = await common_axios.post(
      `${ENDPOINTS.USERACTION}`,
      requestData
    );
    return response.data;
  } catch (error) {
    console.error("Error occurred while adding wishlist:", error);
    throw error; // Re-throwing the error for handling elsewhere if needed
  }
};

export const getUserData = async () => {
  try {
    const response = await common_axios.get(`${ENDPOINTS.USERACTION}/userdata`);
    return response.data;
  } catch (error) {
    console.error("Error occurred while fetching total likes:", error);
  }
};
export const getSourceList = async () => {
  try {
    const response = await common_axios.get(ENDPOINTS.SOURCES);
    return response.data;
  } catch (e) {
    console.log("error occured whille fetching sources", e);
  }
};

export const createList = async (
  name: string,
  placeId?: string,
  updateId?: string,
  isNameSearchable?: boolean
): Promise<{ new: CollabWishlistType; all: CollabWishlistType[] }> => {
  try {
    const response = await common_axios.post(`${ENDPOINTS.WISHLIST}`, {
      name,
      update_id: updateId,
      place_id: placeId,
      is_name_searchable: isNameSearchable || false,
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updateListname = async (wishlistId: string, name: string) => {
  try {
    const response = await common_axios.patch(
      `${ENDPOINTS.WISHLIST}/${wishlistId}`,
      {
        name,
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const deleteList = async (wishlistId: string) => {
  try {
    const response = await common_axios.delete(
      `${ENDPOINTS.WISHLIST}/${wishlistId}`
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getWishlistData = async () => {
  try {
    const response = await common_axios.get(`${ENDPOINTS.WISHLIST}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSingleWishlistData = async (wishlistId: string) => {
  try {
    const response = await common_axios.get(
      `${ENDPOINTS.WISHLIST}/${wishlistId}`
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateWishlist = async (
  wishlistId: string,
  updateData: {
    type: "add" | "remove";
    update_id?: string;
    place_id?: string;
    collaborator_id?: string;
    referral_id?: string;
  }
) => {
  try {
    const response = await common_axios.patch(
      `${ENDPOINTS.WISHLIST}/${wishlistId}/update`,
      updateData
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateWishlists = async (updateData: {
  place_id: string;
  update_id: string;
  selected_wishlists: string[];
}) => {
  try {
    const response = await common_axios.post(
      `${ENDPOINTS.WISHLIST}/update-lists`,
      updateData
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getCollabdata = async (
  wishlist_id: string,
  location: LocationType
) => {
  try {
    const res = await common_axios.post(
      `${ENDPOINTS.POST_EMBEDDINGS}/plan-personalisation/`,
      { wishlist_id, filters: { location } }
    );
    return res.data;
  } catch (e) {
    console.log(e)
    throw e;
  }
};
