import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { useFeedData } from "../context/FeedDataContext";
import MapComp from "./MapComp";
import { Mixpanel } from "../services/mixpanel";
import { useNavigate, useSearchParams } from "react-router-dom";
import LocationSelectModal from "./LocationSelectModal";
import { ChangeEvent, useEffect, useState } from "react";
import { ChevronLeftIcon as ChevronLeftIconSolid } from "@heroicons/react/24/solid";

const LOCATIONS_STR = "locations";

const suggestions = [
  "fun activities with friends",
  "dining experiences",
  "singles mixers",
  "gaming events",
  "something creative",
];

const searchCategories = [
  {
    updateType: "review",
    value: "Eat",
  },
  {
    updateType: "event",
    value: "Experiences",
  },
];

type SearchButtonProps = {
  toggleBeforeModal?: (status: boolean) => void;
};

const ReusableSearchComp = ({ toggleBeforeModal }: SearchButtonProps) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("");

  const {
    selectedLocation,
    locations,
    onLocationSelect,
    searchtext,
    handleSearchText,
  } = useFeedData();

  useEffect(() => {
    handleSearchText("");
  }, [handleSearchText]);

  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") || "";

  const placeHolderText = query?.length > 0 ? query : "";

  useEffect(() => {
    if (placeHolderText.length > 0) {
      handleSearchText(placeHolderText);
    }
  }, [handleSearchText, placeHolderText]);

  const [searchtextlocal, setSearchTextlocal] = useState(searchtext);

  const handleClick = (update_type: string, searchText: string) => {
    handleSearchText(searchtextlocal);
    navigate(
      `/app/results?results_type=search&query=${searchtextlocal}&update_type=${update_type}`
    );
    localStorage.setItem("postId", "0");
    toggleBeforeModal && toggleBeforeModal(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTextlocal(e.target.value);
  };

  const checkLength = (text: string, stype: string) => {
    if (stype === "eq") {
      return text?.length === 0;
    }
    return text?.length > 0;
  };

  return (
    <div className=" w-full h-dvh max-w-md fixed overflow-y-scroll bg-white overflow-x-hidden scrollbar-hide">
      {searchtextlocal.length === 0 && <MapComp height={100} />}

      <div className="w-full items-center justify-center flex   absolute top-2">
        <div className="flex bg-white justify-start items-center gap-2 w-[95%] rounded-xl shadow-custom    p-3  mx-3   max-w-full ">
          <ChevronLeftIconSolid
            className="h-6 w-6 inline-block  cursor-pointer flex-shrink-0"
            onClick={() => {
              Mixpanel.track("Back Button Clicked", {
                ui_type: "moods-before-modal",
              });
              toggleBeforeModal && toggleBeforeModal(false);
            }}
          />

          <div className="flex items-center  w-full">
            <input
              style={{
                letterSpacing: "-0.32px",
              }}
              className="focus:ring-0 p-0 m-0 placeholder-[#9CAAC6] leading-normal text-base font-400 border-none  grow"
              placeholder="where to, today?"
              autoFocus
              type="search"
              value={searchtextlocal}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick("review", searchtextlocal);
                }
              }}
            />
          </div>
        </div>
      </div>

      {searchtextlocal.length === 0 && (
        <div className="absolute top-14 left-0 right-0  flex flex-col items-center">
          <div className="scrollbar-hide flex items-center pt-2 pb-5 pl-2 animate-infinite-scroll">
            {[0, 1, 2].map((t) =>
              suggestions.map((value) => (
                <button
                  key={value}
                  className="p-3 bg-white rounded-lg mr-2 text-black text-xs tracking-tight flex-shrink-0 max-w-fit shadow flex items-center"
                  onClick={() => {
                    Mixpanel.track("Suggested Search Clicked", {
                      query: value,
                      location_id: selectedLocation?.location_id,
                      ui_type: "moods-before-modal",
                    });
                    toggleBeforeModal && toggleBeforeModal(false);
                    navigate(
                      `/app/results?results_type=search&query=${value}&update_type=event`
                    );
                    localStorage.setItem("postId", "0");
                  }}
                >
                  <SparklesIcon className="w-4 h-4 mr-2 text-special-blue" />
                  {value}
                </button>
              ))
            )}
          </div>
        </div>
      )}
      <div
        className={`absolute top-[68px] mx-3 w-full  bg-white overflow-y-scroll scrollbar-hide`}
      >
        <div className="flex flex-col">
          <div>
            {searchtextlocal.length > 0 &&
              searchCategories.map((value) => (
                <>
                  <div
                    className={`flex   items-center my-2 gap-3 min-h-12 leading-normal text-black  cursor-pointer`}
                    key={value.value}
                    onClick={() =>
                      handleClick(value.updateType, searchtextlocal)
                    }
                  >
                    <MagnifyingGlassIcon className="h-4 min-h-4  min-w-4 text-[#9CAAC6]" />

                    <p
                      className={` w-auto  ${
                        value.updateType === "explore"
                          ? "line-clamp-0"
                          : "line-clamp-1"
                      }`}
                    >
                      <span className=""> {searchtextlocal}</span>{" "}
                      <span className="text-secondary-text"> in </span>
                      <span className={`  text-special-blue`}>
                        {" "}
                        {value.value}
                      </span>
                    </p>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>

      {selectedTab === LOCATIONS_STR && (
        <LocationSelectModal
          onClose={() => setSelectedTab("")}
          selectedLocationOption={selectedLocation?.location_id}
          locationsList={locations}
          onSubmit={(locationDetails) => {
            onLocationSelect(locationDetails);
            setSelectedTab("");
          }}
        />
      )}
    </div>
  );
};
export default ReusableSearchComp;
