import { SparklesIcon } from "@heroicons/react/24/outline";

import GetItOn from "./GetItOn";

function AddToHomeScreen() {
  return (
    <div
      id="add-to-homescreen"
      className="fixed bottom-24 left-1/2 -translate-x-1/2 w-full max-w-md hidden"
    >
      <div className="mx-3 p-2 flex justify-between items-center rounded-lg bg-[#E7EFFE] border shadow">
        <SparklesIcon className="h-6 w-6 text-special-blue flex-shrink-0 mr-2" />
        <h4 className="text-black text-xs tracking-tight">
          Get the latest of your city in a mobile app
        </h4>
        <div className="flex-shrink-0">
          <GetItOn type="play-store" size="normal" />
        </div>
      </div>
    </div>
  );
}

export default AddToHomeScreen;
