import { useState } from "react";
import { useFeedData } from "../context/FeedDataContext";
import LocationSelectModal from "./LocationSelectModal";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { MarkerWithInfowindow } from "./MarkerWIthInfoWindow";
import { LocationType } from "../common/global.types";
import { CENTER_OF_BANGALORE } from "../common/global.constants";
import { Mixpanel } from "../services/mixpanel";

const LOCATIONS_STR = "locations";

const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;

const MapComp = ({
  height,
  hideLocation,
}: {
  height: number;
  hideLocation?: boolean;
}) => {
  const { selectedLocation, onLocationSelect, locations } = useFeedData();
  const [selectedTab, setSelectedTab] = useState("");
  const position = {
    lat: (selectedLocation?.latitude || CENTER_OF_BANGALORE.lat) + 0.005,
    lng: selectedLocation?.longitude || CENTER_OF_BANGALORE.lng,
  };

  return (
    <>
      <APIProvider apiKey={MAP_API_KEY as string} libraries={["marker"]}>
        <Map
          style={{
            height: height === 100 ? "100%" : height + "px",
            width: "100%",
          }}
          mapId={"6010736ecac520fa"}
          disableDefaultUI={true}
          disableDoubleClickZoom={true}
          keyboardShortcuts={false}
          draggable={false}
          clickableIcons={false}
          zoom={13}
          center={position}
        >
          {!hideLocation && (
            <MarkerWithInfowindow
              selectedLocation={selectedLocation as LocationType}
              onClick={() => {
                Mixpanel.track("Map Location Popup Click", {
                  ui_type: "map-location-popup",
                  location_id: selectedLocation?.location_id,
                })
                setSelectedTab(LOCATIONS_STR);
              }}
            />
          )}
        </Map>
      </APIProvider>

      {selectedTab === LOCATIONS_STR && (
        <LocationSelectModal
          onClose={() => setSelectedTab("")}
          selectedLocationOption={selectedLocation?.location_id}
          locationsList={locations}
          onSubmit={(locationDetails) => {
            onLocationSelect(locationDetails);
            setSelectedTab("");
          }}
        />
      )}
    </>
  );
};
export default MapComp;
