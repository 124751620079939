import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { LocationType } from "../common/global.types";

export const MarkerWithInfowindow = ({
  selectedLocation,
  onClick,
}: {
  selectedLocation: LocationType;
  onClick: () => void;
}) => {
  const [markerRef] = useAdvancedMarkerRef();

  return (
    <div>
      <AdvancedMarker
        position={{
          lat: (selectedLocation?.latitude as number) - 0.002575 || 0,
          lng: (selectedLocation?.longitude as number) || 0,
        }}
        ref={markerRef}
      >
        <img
          src="/animated/radar-loader.gif"
          alt="radar"
          className="h-6 w-6 rounded-full"
        />
      </AdvancedMarker>

      <InfoWindow
        disableAutoPan={true}
        headerDisabled={true}
        shouldFocus={false}
        position={{
          lat: selectedLocation?.latitude || 0,
          lng: selectedLocation?.longitude || 0,
        }}
      >
        <div
          className="flex-col flex justify-center text-center bg-white tracking-tight leading-5 font-circular"
          onClick={onClick}
        >
          <div className="flex justify-center text-base">
            {selectedLocation?.location_name === "around me" && (
              <div className="text-secondary-text">5km&nbsp;</div>
            )}
            <div className="whitespace-nowrap font-medium text-[#1e1e1e]">
              {selectedLocation?.location_name}
            </div>
          </div>
          <div className="text-special-blue text-xs cursor-pointer">
            Tap to change
          </div>
        </div>
      </InfoWindow>
    </div>
  );
};
