import {
  // ChevronDownIcon,
  MagnifyingGlassIcon,
  // MapPinIcon,
  // RssIcon,
  // SparklesIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon as ChevronLeftIconSolid } from "@heroicons/react/24/solid";

import { Mixpanel } from "../services/mixpanel";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useFeedData } from "../context/FeedDataContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import LocationSelectModal from "./LocationSelectModal";

const searchCategories = [
  {
    updateType: "review",
    value: "Eat",
  },
  {
    updateType: "event",
    value: "Experiences",
  },
];
const LOCATIONS_STR = "locations";
type SearchButtonProps = {
  toggleBeforeModal?: (status: boolean) => void;
};
export default function MoodsModal({ toggleBeforeModal }: SearchButtonProps) {
  const {
    selectedLocation,
    locations,
    onLocationSelect,
    toggleModal,
    searchtext,
    handleSearchText,
  } = useFeedData();
  const [selectedTab, setSelectedTab] = useState("");

  const [searchParams] = useSearchParams();
  const cuisine = searchParams.get("cuisine");
  const query = searchParams.get("query") || "";


  const placeHolderText = query?.length > 0 ? query : "";

  useEffect(() => {
    if (placeHolderText.length > 0) {
      handleSearchText(placeHolderText);
    }
  }, [handleSearchText, placeHolderText]);

  const [searchtextlocal, setSearchTextlocal] = useState(searchtext);


  const navigate = useNavigate();


  const handleClick = (update_type: string, searchText: string) => {
    handleSearchText(searchtextlocal);
    navigate(
      `/app/results?results_type=search&query=${searchtextlocal}&update_type=${update_type}`
    );
    localStorage.setItem("postId", "0");
    toggleModal(false);
  };



  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTextlocal(e.target.value);
  };


  const checkLength = (text: string, stype: string) => {
    if (stype === "eq") {
      return text?.length === 0;
    }
    return text?.length > 0;
  };


  return (
    <div
      id="modal"
      className={` fixed left-0 right-0 top-0 bottom-0 bg-black/90 z-40 max-w-md mx-auto`}
    >
     
    </div>
  );
}

