import { ReactElement } from 'react';

/**
 * Component for 404 page.
 *
 * @returns - 404 page.
 */
export default function NoMatch(): ReactElement {
  return <div className='text-center'>Page not found</div>;
}
