import { Channel } from "@capacitor/push-notifications";
import { UpdateType } from "./global.types";

export const CONSTANTS = {
  avatar_1_id: "6507783b32e2674853371dbf",
  avatar_2_id: "65077867a67b959c9516ff64",
  avatar_3_id: "65077872a67b959c9516ff66",
  avatar_4_id: "6507787aa67b959c9516ff68",
  avatar_5_id: "65077882a67b959c9516ff6a",
  // WHATSAPP_SUBSCRIPTION_TOPIC_ID: "65322b928943200e4195b904",
  META_TITLE: "Aroundly - Don't Search. Discover your places.",
  META_DESCRIPTION:
    "Discover things to do around you with Aroundly, including events near me, offers near me, experiences near me in one single platform without the need to search, switch multiple apps or get lucky suggestions from friends and influencers.",
};

export const JOIN_BUTTON_TEXT = "CONTINUE";

export const EVENTS_DISPLAY_NAME_STR = "Events 🎪";
export const PLACES_DISPLAY_NAME_STR = "Places 📌";
export const OFFERS_DISPLAY_NAME_STR = "Offers 🏷️";
export const EXPLORE_ALL_DISPLAY_NAME_STR = "Explore All";

export const UPDATE_TYPE_EVENT = "event";
export const UPDATE_TYPE_OFFER = "offer";
export const UPDATE_TYPE_REVIEW = "review";
export const UPDATE_TYPE_GENERAL_UPDATE = "general_update_generated";

export const UPDATE_TYPE_MAP = {
  [UPDATE_TYPE_EVENT]: "Event",
  [UPDATE_TYPE_OFFER]: "Offer",
  [UPDATE_TYPE_REVIEW]: "Review",
  [UPDATE_TYPE_GENERAL_UPDATE]: "General Update",
};

export const AROUND_ME_DISTANCE_IN_KM = 5;

export const EAT_CATEGORY = "Eat";
export const EXPERIENCES_CATEGORY = "Experiences";

export const CENTER_OF_BANGALORE = {
  lat: 12.9716,
  lng: 77.5946,
};

export const FOOD_AND_BEV_INTEREST_ID = "6508dcea7d90ea7d81e8a01d";

export const imageName = [
  "aroundly-wishlist-dining-dates",
  "aroundly-wishlist-gym-gang",
  "aroundly-wishlist-drinking-plans",
  "aroundly-wishlist-new-events-with-girls",
];

// export const TIMES_LIST = [
//   {
//     id: "anytime",
//     name: "Anytime",
//   },
//   {
//     id: "today",
//     name: "Today",
//   },
//   {
//     id: "tomorrow",
//     name: "Tomorrow",
//   },
//   {
//     id: "week",
//     name: "This Week",
//   },
//   {
//     id: "weekend",
//     name: "This Weekend",
//   },
//   {
//     id: "month",
//     name: "This Month",
//   },
//   {
//     id: "next-month",
//     name: "Next Month",
//   },
// ];

export const TIMES_LIST = [
  {
    id: "today",
    name: "Today",
  },
  {
    id: "tomorrow",
    name: "Tomorrow",
  },
  {
    id: "week",
    name: "This Week",
  },
  {
    id: "anytime",
    name: "Anytime",
  },
];

export const DEFAULT_TIMEZONE = "Asia/Kolkata";

export const TABS_TO_SHOW: { name: string; id: UpdateType | "explore-all" }[] =
  [
    { name: EXPLORE_ALL_DISPLAY_NAME_STR, id: "explore-all" },
    { name: EVENTS_DISPLAY_NAME_STR, id: UPDATE_TYPE_EVENT },
    { name: OFFERS_DISPLAY_NAME_STR, id: UPDATE_TYPE_OFFER },
  ];

export const TAB_NAME_MAP: any = {
  "explore-all": EXPLORE_ALL_DISPLAY_NAME_STR,
  [UPDATE_TYPE_EVENT]: EVENTS_DISPLAY_NAME_STR,
  [UPDATE_TYPE_OFFER]: OFFERS_DISPLAY_NAME_STR,
  [UPDATE_TYPE_REVIEW]: PLACES_DISPLAY_NAME_STR,
};

export const DEFAULT_NOTIFICATION_CHANNEL: Channel = {
  id: "aroundly-updates",
  name: "Aroundly Updates",
  description: "Main channel for notifications",
  importance: 5,
  visibility: 1,
  lights: true,
  lightColor: "#FFFFFF",
  vibration: true,
};
export const NO_OF_COLUMNS = 2;

export const eventCategories = [
  { name: "Food & Drinks", id: "6508dcea7d90ea7d81e8a01d" },
  { name: "Social & Nightlife", id: "6598f054818b59e35e596019" },
  { name: "Art & Craft", id: "6508dcea7d90ea7d81e8a018" },
  { name: "Adventure & Outdoor", id: "6508dcea7d90ea7d81e8a023" },
  { name: "Music & Dance", id: "6508dcea7d90ea7d81e8a022" },
  { name: "Shows & Performances", id: "6508dcea7d90ea7d81e8a026" },
  { name: "Books & Literature", id: "6508dcea7d90ea7d81e8a019" },
  { name: "Shopping & Lifestyle", id: "6508dcea7d90ea7d81e8a01c" },
];
