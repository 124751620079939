import {
  AroundlyPlaces,
  InterestProps,
  SocialMediaUpdate,
} from "../common/global.types";
import Filters from "./Filters";
import PlacesTab from "./PlacesTab";
interface Props {
  isLoading?: boolean;
  isUpdatesTab: boolean;
  width: number;
  updateTypeToShow: string;
  lastSearchText?: string;
  searchText?: string | null;
  interests: InterestProps[];
  setIsUpdatesTab: React.Dispatch<React.SetStateAction<boolean>>;
  relevance: string;
  setRelevance: React.Dispatch<React.SetStateAction<string>>;
  isBest: boolean;
  setisBest: React.Dispatch<React.SetStateAction<boolean>>;
  resultsType: string | null;
  showErrorModal: boolean;
  filteredData: AroundlyPlaces[];
}
const PlacesComponent = ({
  isLoading,
  isUpdatesTab,
  width,
  updateTypeToShow,
  resultsType,
  searchText,
  interests,
  setIsUpdatesTab,
  relevance,
  setRelevance,
  isBest,
  setisBest,
  lastSearchText,
  filteredData,
  showErrorModal,
}: Props) => {
  return (
    <div>
      <div
        className={`pl-3 sticky top-[107px]  py-2  z-10  mb-2 ${
          resultsType === "search" && lastSearchText !== searchText && "hidden"
        }  bg-white w-full  z-0`}
      >
        <Filters
          interests={interests}
          resultsType={resultsType}
          updateTypeToShow={updateTypeToShow}
          istemplate={isUpdatesTab}
          setIsTemplate={setIsUpdatesTab}
          relevance={relevance}
          setRelevance={setRelevance}
          isBest={isBest}
          setIsbest={setisBest}
        />
      </div>
      {!isLoading && (
        <PlacesTab data={filteredData} showError={showErrorModal} />
      )}
    </div>
  );
};
export default PlacesComponent;
