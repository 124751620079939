import { XMarkIcon } from "@heroicons/react/24/outline";
import { CollabWishlistType } from "../common/global.types";

const JoinedModal = ({
  data,
  onClose,
}: {
  data: CollabWishlistType;
  onClose: () => void;
}) => {
  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm h-svh"
    >
      <div className="flex justify-between items-center shadow-lg mx-8">
        <div className="z-50 flex p-6 text-xl font-semibold bg-white rounded-lg">
          <div className=" mr-4 ">
            You already joined the{" "}
            <span className="text-primary">{data?.name}</span> wishlist!
          </div>
          <div onClick={onClose} className="cursor-pointer">
            <XMarkIcon className="h-6 w-6 text-black" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default JoinedModal;
