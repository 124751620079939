import { CtaLink, SocialMediaUpdate } from "../common/global.types";

type BookingOptionsProps = {
  item: SocialMediaUpdate;
};

export default function BookingOptions({ item }: BookingOptionsProps) {
  return (
    <div className="mt-4 mb-20">
      {item?.cta_links?.length > 0 ? (
        <div className="text-[10px] mb-2">Booking options</div>
      ) : (
        false
      )}
      {item?.cta_links?.map((link: CtaLink) => (
        <div
          className={`grid grid-cols-2 gap-4 items-center text-sm font-inter mb-3`}
          key={link.text}
        >
          <div className="col-span-1 flex gap-1 items-center">
            <img
              src={`${
                link.source === "aroundly"
                  ? "/images/aroundly.svg"
                  : `/images/sources/${link.source.toLowerCase()}.png`
              }`}
              alt={`logo of ${link.source}`}
              width={16}
              height={16}
            />
            {link.source.toUpperCase()}
          </div>
          <div className="col-span-1 text-end mr-3">
            {link.price === 0 ? "Free" : link.price ? `₹ ${link.price}` : ""}
          </div>
          {/* <div className="col-span-1">
            <Button
              isExternal
              label={link.text}
              href={link.url}
              theme="primary"
              size="sm"
              updateDetails={item}
            />
          </div> */}
        </div>
      ))}
    </div>
  );
}
