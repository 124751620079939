import { memo } from "react";

const RenderUserAvatar = ({ gender }: { gender: string }) => (
  <img
    src={`/images/${gender}.svg`}
    alt="avatar"
    className="h-4 w-4 object-cover rounded-full"
  />
);

export default memo(RenderUserAvatar);
