import { createContext, useContext, useEffect, useState } from "react";
import { getWishlistData } from "../services/aroundly-apis";
import {
  AroundlyPlaces,
  SocialMediaUpdate,
  CollabWishlistType,
} from "../common/global.types";
import CreateNewModal from "../components/CreateNewModal";
import BotWishTray from "../components/BotWIshTray";
import { useAuth } from "./auth";
import { useNavigate } from "react-router-dom";
import CommunityFeedback from "../components/CommunityFeedback";
import UpdateModal from "../components/UpdateModal";
import StoreModal from "../components/StoreModal";

interface CollabListsContextProps {
  collabLists: CollabWishlistType[];
  // setCollabLists: Dispatch<SetStateAction<WishlistType[]>>;
  isCreateModalShown: boolean;
  toggleCreateNewModal: (
    status: boolean,
    updateInfo?: SocialMediaUpdate,
    placeInfo?: AroundlyPlaces
  ) => void;
  toggleWishTrayModal: (
    status: boolean,
    updateInfo?: SocialMediaUpdate,
    placeInfo?: AroundlyPlaces
  ) => void;
  toggleEditModal: (
    shouldShowModal: boolean,
    collabdata: CollabWishlistType
  ) => void;
  updateData: SocialMediaUpdate | undefined;
  placeData: AroundlyPlaces | undefined;
  setCollabLists: (data: CollabWishlistType[]) => void;
  toggleUpdateModal: (status: boolean, id?: string) => void;
  toggleStoreModal: (status: boolean, id?: string) => void;
  showUpdate: boolean;
  showStore: boolean;
  toggleShowCommunity: (status: boolean) => void;
  showCommunity: boolean;
  setSelectedWishlist: (value: string) => void;
  showErrorCollabdata: boolean;
}

const CollabListsContext = createContext({
  collabLists: [],
  // setCollabLists: () => {},
  isCreateModalShown: false,
  toggleCreateNewModal: () => {},
  toggleWishTrayModal: () => {},
  toggleEditModal: () => {},
  updateData: undefined,
  placeData: undefined,
  setCollabLists: () => {},
  toggleUpdateModal: (status: boolean, id?: string) => {},
  toggleStoreModal: (status: boolean, id?: string) => {},
  showUpdate: false,
  showStore: false,
  toggleShowCommunity: (status: boolean) => {},
  showCommunity: false,
  setSelectedWishlist: (value: string) => {},
  showErrorCollabdata: false,
} as CollabListsContextProps);

export function CollabListsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Contexts
  const { authUser, loading } = useAuth();
  const navigate = useNavigate();

  // State Variables
  const [collabLists, setCollabLists] = useState<CollabWishlistType[]>([]);
  const [showWishTray, setShowWishTray] = useState(false);
  const [updateData, setUpdateData] = useState<SocialMediaUpdate>();
  const [placeData, setPlaceData] = useState<AroundlyPlaces>();
  const [isCreateModalShown, setIsCreateModalShown] = useState(false);
  const [collabdata, setCollabdata] = useState<CollabWishlistType | null>();
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [showStore, setShowStore] = useState(false);
  const [showCommunity, setShowCommunity] = useState(false);
  const [selectedWishlist, setSelectedWishlist] = useState("");
  const [showErrorCollabdata, setShowErrorCollabdata] = useState(false);
  const toggleShowCommunity = (status: boolean) => {
    setShowCommunity(status);
  };

  const toggleUpdateModal = (status: boolean, id?: string) => {
    setShowUpdate(status);
    if (id) setUpdateId(id);
  };

  const toggleStoreModal = (status: boolean, id?: string) => {
    setShowStore(status);
    if (id) setStoreId(id);
  };

  // Use Effects
  useEffect(() => {
    async function getWishlist() {
      setShowErrorCollabdata(false);
      try {
        const data = await getWishlistData();
        setCollabLists(data);
      } catch (e) {
        setShowErrorCollabdata(true);
      }
    }
    if (!loading && authUser) getWishlist();
  }, [authUser, loading]);

  // Reusable Functions
  const toggleCreateNewModal = (
    shouldShowModal: boolean,
    updateInfo?: SocialMediaUpdate,
    placeInfo?: AroundlyPlaces
  ) => {
    setIsCreateModalShown(shouldShowModal);
    setCollabdata(null);

    if (!showWishTray) {
      setUpdateData(updateInfo);
      setPlaceData(placeInfo);
    }

    if (showWishTray && !shouldShowModal) toggleWishTrayModal(false);
  };

  const toggleEditModal = (
    shouldShowModal: boolean,
    collabdata: CollabWishlistType
  ) => {
    setIsCreateModalShown(shouldShowModal);
    setCollabdata(collabdata);
  };

  const toggleWishTrayModal = (
    shouldShowModal: boolean,
    updateInfo?: SocialMediaUpdate,

    placeInfo?: AroundlyPlaces
  ) => {
    setShowWishTray(shouldShowModal);
    setUpdateData(updateInfo);
    setPlaceData(placeInfo);
  };

  // Component JSX
  return (
    <CollabListsContext.Provider
      value={{
        collabLists,
        isCreateModalShown,
        toggleCreateNewModal,
        toggleWishTrayModal,
        updateData,
        placeData,
        toggleEditModal,
        setCollabLists,
        toggleUpdateModal,
        toggleStoreModal,
        showStore,
        showUpdate,
        toggleShowCommunity,
        showCommunity,
        setSelectedWishlist,
        showErrorCollabdata,
      }}
    >
      {children}

      {showCommunity && (
        <CommunityFeedback toggleShowCommunity={toggleShowCommunity} />
      )}

      {showUpdate && (
        <UpdateModal
          updateId={updateId}
          toggleUpdateModal={toggleUpdateModal}
        />
      )}
      {showStore && (
        <StoreModal storeId={storeId} toggleStoreModal={toggleUpdateModal} />
      )}
      {showWishTray && (
        <BotWishTray
          selectedWishlist={selectedWishlist}
          onClose={(data) => {
            toggleWishTrayModal(false);
            if (data) setCollabLists(data);
          }}
        />
      )}
      {isCreateModalShown && (
        <CreateNewModal
          placeData={placeData as AroundlyPlaces}
          updateData={updateData as SocialMediaUpdate}
          onClose={(data, id) => {
            toggleCreateNewModal(false);
            if (id) {
              if (data) setCollabLists(data);
              navigate(`/app/wishlist/${id}`);
            } else {
              if (data?.all) setCollabLists(data.all);
              if (data?.new.places?.length === 1) {
                navigate(`/app/wishlist/${data.new._id}`);
              }
            }
          }}
          collabdata={collabdata as CollabWishlistType}
        />
      )}
    </CollabListsContext.Provider>
  );
}

export const useCollabListsContext = () => useContext(CollabListsContext);
