import React from "react";

interface SavedCircleBarProps {
  isSaved: boolean;
  setIsSaved: (value: boolean) => void;
}

const SavedCircleBar: React.FC<SavedCircleBarProps> = ({
  isSaved,
  setIsSaved,
}) => {
  return (
    <div className="flex gap-6 px-3 py-1 font-450 textbase text-secondary-text">
      <p
        className={`cursor-pointer ${
          isSaved ? "font-medium text-black border-black border-b-2" : ""
        }`}
        onClick={() => setIsSaved(true)}
      >
        Saved
      </p>
      <p
        className={`cursor-pointer ${
          !isSaved ? "font-medium text-black border-black border-b-2" : ""
        }`}
        onClick={() => setIsSaved(false)}
      >
        Circle
      </p>
    </div>
  );
};

export default SavedCircleBar;
