// import GtagPathView from "../../components/GtagPageView";

export default function Policy() {
  const fontstyle = {
    fontFamily: "Gilroy-Light"
  }
  return (
    <div style={fontstyle}>
      {/* <GtagPathView /> */}
      <div className="bg-[#202020] w-full  py-[10rem] ">
        <div className="mx-5">
          <h3 className="text-[25px] font-bold text-[#ffffff]">
            Privacy Policy
            <br />
            <br />
          </h3>
          <p className="trust_note text-[35px] text-[#b4b4b4]">
            When you use our services, you’re trusting us with your information.
            We understand this is a big responsibility and work hard to protect
            your information and put you in control.
            <br />
            <br />
          </p>
          <p className="text-[#b4b4b4]">
            Your privacy is important to us, so Aroundly (Coytus Lifestyle Pvt.
            Ltd.), a India, has created the following Privacy Policy
            (&quot;Policy&quot;) to let you know what information we collect
            when you visit our Site{" "}
            <a
              className="text-[#ffffff] underline"
              href="https://myaroundly.com"
            >
              https://myaroundly.com
            </a>{" "}
            (&quot;Site&quot;), why we collect it and how we use it. <br />
            <br />
            &quot; refer to the entity/person/organization using our Site. When
            this Policy mentions &quot;We&quot;, &quot;Us,&quot; and
            &quot;Our&quot; it refers to Aroundly (Coytus Lifestyle Pvt. Ltd.)
            and its subsidiaries and affiliates.
            <br />
            <br /> This Privacy Policy is governed by our{" "}
            <a
              className="text-[#ffffff] underline"
              href="https://myaroundly.com/terms"
            >
              Terms of Services
            </a>
            .<br />
            <br /> For any questions regarding this Policy or any requests
            regarding the processing of personal data, please contact us at
            support@myaroundly.com.
            <br />
            <br />
          </p>
          <h1 className="text-[#ffffff] text-[25px]">
            1. INFORMATION WE COLLECT FROM YOU
          </h1>
          <div className="text-[#ffffff] ">
            We collect the information You provide to us and this information is
            necessary for the adequate performance of the contractual
            arrangement which is in place between You and us and allow us to
            comply with our legal obligations.
            <br />
            <ul>
              <li>
                Account Signup Information. When You create the account, we ask
                You to provide the signup information, such as Email, Username,
                Name, Personal Number.
              </li>
              <li>
                Login information. We collect Login information if You are
                logging to our account with Authentication Data.
              </li>
              <li>
                Other Personal Information Provided by You. We may collect other
                data provided by You from surveys, feedback, financial
                information (purchase history), Other data that we may ask for
                is access to camera, contacts, and voice to deliver the
                experience needed to post, edit and search information you seek
                and other similar data.
              </li>
            </ul>
          </div>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">
            2. INFORMATION WE COLLECT AUTOMATICALLY
          </h1>
          <div className="text-[#ffffff] ">
            When you use our Site or contact us directly we may collect
            information, including your personal information, about the way you
            act in our Site, the services You use and how You use them.
            <br /> This information is necessary for the adequate performance of
            the contract between You and us, to enable us to comply with legal
            obligations and given our legitimate interest in being able to
            provide and improve the functionalities of the Site.
            <br />
            <ul>
              <li>
                Log data and Device information. We automatically collect log
                data and device information when you access and use the Site,
                even if you have not created an Account or logged in. That
                information includes, among other things: Internet protocol (IP)
                addresses, Browser type, Operating system, Date/time stamp,
                Referring/exit pages.
              </li>
              <li>
                Tracking technologies and Cookies. We use Cookies, Phone model,
                Device ID. We also automatically collect information about
                device’s operating system, .{" "}
              </li>
              <li>
                Geo-location data. We collect information about your approximate
                location as determined by data such as your IP address to offer
                you an improved user experience. Such data may be collected only
                when you access the Site using your device.
              </li>
              <li>
                Usage information. We use a tool called &quot;Google
                Analytics&quot; to collect information about your interactions
                with the Site (what pages you visit, such as the pages or
                content you view, your searches for Listings, bookings you have
                made, and other actions on the Site. In consequence, Google,
                Inc. plants a permanent cookie on your web browser to identify
                you as a unique user the next time you visit this Site). For
                more information please visit Google.
              </li>
            </ul>
          </div>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">
            3. THE WAY WE USE YOUR INFORMATION
          </h1>
          <div className="text-[#ffffff]">
            We process your information adhering to the general data processing
            principles.
            <br /> We may use the information we collect through our Site for a
            number of reasons, including to: <br />
            <ul>
              <li>to identify user </li>
              <li>to create account </li>
              <li>to create trusted environment</li>
              <li>to create statistics and analyze market</li>
              <li>to stay connected to customize marketing</li>
              <li>to contact user to improve services</li>
              <li>to ensure data security and prevent fraud</li>
              <li>to comply with applicable laws</li>
              <li>to request feedback</li>
              <li>to post testimonials</li>
              <li>to provide support</li>
            </ul>{" "}
            <br />
            We will normally collect personal information from you only where we
            have your consent to do so, where we need the personal information
            to perform a contract with you, or where the processing is in our
            legitimate business interests.
          </div>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">4. DIRECT MARKETING</h1>
          <p className="text-[#b4b4b4] ">
            We may use your provided contact details for direct marketing. These
            direct marketing offers, depending on your preferences, may be
            personalized taking into account any other information which you
            have provided to us (e.g. location, social media profile
            information, etc.) or we have collected or generated from other
            sources as described below.
            <br />
            If you wish to withdraw the consent for direct marketing, and refuse
            to receive information from us, you may exercise such option at any
            time you wish by updating your preferences in your account, clicking
            on a link for that purpose at the bottom of our e-mail with the
            newsletters.
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">5. COOKIES</h1>
          <p className="text-[#b4b4b4] ">
            Cookies are small text files stored by your browser on your computer
            when you visit our Site. We use cookies to improve our Site and make
            it easier to use. Cookies permit us to recognize users and avoid
            repetitive requests for the same information. Please check our{" "}
            <a
              className="text-[#ffffff] underline"
              href="https://portal.termshub.com/myaroundly.com#cookie_policy"
            >
              Cookie Policy
            </a>{" "}
            to find more information about cookies we use. <br />
            You may find more information about how to delete cookies, as well
            as the other useful information related to the use of the cookies,
            on the website http://www.allaboutcookies.org/.
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">
            6. SENSITIVE INFORMATION
          </h1>
          <p className="text-[#b4b4b4] ">
            We do not collect sensitive information such as political opinions,
            religious or philosophical beliefs, racial or ethnic origin, genetic
            data, biometric data, health data or data related a sexual
            orientation.
            <br />
            Please do not send, upload, or provide us any sensitive data and
            contact us using the contact details below if you believe that we
            might have such information. We have a right to delete any
            information we believe it might contain sensitive data.
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">7. RETENTION</h1>
          <p className="text-[#b4b4b4] ">
            We retain your personal information to provide services to you and
            as otherwise necessary to comply with our legal obligation, resolve
            disputes, and enforce our agreements. <br />
            We will retain your personal information not longer than 3 after you
            terminate your account, unless we are otherwise required by law or
            regulations to retain your personal information longer. <br />
            If you would like to stop us using your personal information, you
            shall request that we erase your personal information and close your
            Account. Please note that even after deletion of your account we may
            keep some of your information for tax, legal reporting and auditing
            obligations.
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">8. SECURITY</h1>
          <p className="text-[#b4b4b4]">
            We have implemented security measures designed to protect the
            personal information you share with us, including physical,
            electronic and procedural measures. Among other things, we regularly
            monitor our systems for possible vulnerabilities and attacks. <br />{" "}
            Regardless of the measures and efforts taken by us, the transmission
            of information via internet, email or text message is not completely
            secure. We do not guarantee the absolute protection and security of
            your personal information or any other User Content you upload,
            publish or otherwise share with us or anyone else.
            <br /> We therefore encourage you to avoid providing us or anyone
            with any sensitive information of which you believe its disclosure
            could cause you substantial or irreparable harm.
            <br />
            If you have any questions regarding the security of our Site or
            Services, you are welcome to contact us at support@myaroundly.com.
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">9. YOUR RIGHTS</h1>
          <div className="text-[#ffffff] ">
            You are entitled to a range of rights regarding the protection of
            your personal information. Those rights are:
            <ul>
              <li>
                The right to access the information we have about you. If you
                wish to access your personal information that we collect, you
                can do so at any time by contacting us using the contact details
                provided below.
              </li>
              <li>
                The right to rectify inaccurate information about you. You can
                correct, update or request deletion of your personal information
                by contacting us using the contact details provided below.
              </li>
              <li>
                The right to object the processing. When we rely on your consent
                to process your personal information, you may withdraw consent
                at any time by contacting us using the contact details provided
                below. This will not affect the lawfulness of processing prior
                to the withdrawal of your consent.
              </li>
              <li>
                The right to lodge a complaint. You can raise questions or
                complaints to the national Data Protection Agency in your
                country of residence in the event where your rights may have
                been infringed. However, we recommend attempting to reach a
                peaceful resolution of the possible dispute by contacting us
                first.
              </li>
              <li>
                The right to erase any data concerning you. You may demand
                erasure of data without undue delay for legitimate reasons, e.g.
                where data is no longer necessary for the purposes it was
                collected, or where the data has been unlawfully processed.
              </li>
            </ul>
          </div>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">
            10. APPLICATION OF POLICY
          </h1>
          <p className="text-[#b4b4b4] ">
            This Policy was created with the help of the TermsHub.io and the
            Privacy Policy Generator and applies only to the services offered by
            our Company. Our Policy does not apply to services offered by other
            companies or individuals, including products or sites that may be
            displayed to you in search results, sites that may include our
            services or other sites linked from our Site or Services.
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">11. AMENDMENTS</h1>
          <p className="text-[#b4b4b4] ">
            Our Policy may change from time to time. We will post any Policy
            changes on our Site and, if the changes are significant, we may
            consider providing a more explicit notice (including, for certain
            services, email notification of Policy changes).
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">
            12. ACCEPTANCE OF THIS POLICY
          </h1>
          <p className="text-[#b4b4b4] ">
            We assume that all Users of this Site have carefully read this
            document and agree to its contents. If someone does not agree with
            this Policy, they should refrain from using our Site. We reserve the
            right to change our Policy at any time and inform by using the way
            as indicated in Section 11. Continued use of this Site implies
            acceptance of the revised Policy.
          </p>
          <br />

          <h1 className="text-[#ffffff] text-[25px]">
            13. FURTHER INFORMATION
          </h1>
          <p className="text-[#b4b4b4] ">
            If you have any further questions regarding the data we collect, or
            how we use it, then please feel free to contact us at the details as
            indicated above.
          </p>
        </div>
      </div>
    </div>
  );
}
