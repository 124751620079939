import {
  AroundlyPlaces,
  CityProps,
  InterestProps,
  LocationType,
  Sources,
  UserAction,
} from "../common/global.types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  fetchFeed,
  fetchInterests,
  fetchLocations,
  getCities,
  getSourceList,
  getUserData,
} from "../services/aroundly-apis";
import {
  getLocationFromStorage,
  setLocationToStorage,
} from "../services/local-storage.service";
import { getDistanceFromLatLonInKm } from "../common/distance-utils";
import MoodsModal from "../components/MoodsModal";
import ModalBeforeMoods from "../components/ModalBeforeMoods";
import { useLocationInfo } from "./LocationContext";

interface FeedDataContextProps {
  showModal: boolean;
  toggleModal: (status: boolean) => void;
  locations: LocationType[];
  interests: InterestProps[];
  updates: AroundlyPlaces[];
  userUpdate: UserAction;
  lastSearchText: string;
  pastSelectedLocation: LocationType | null;
  selectedLocation: LocationType | null;
  onLocationSelect: (location: LocationType) => void;
  setPastselectedLocation: (location: LocationType) => void;
  searchtext: string;
  handleSearchText: (data: string) => void;
  setLastSearchText: (value: string) => void;
  setUserUpdate: any;
  sources: Sources[];
  toggleBeforeModal: (status: boolean) => void;
  cities: CityProps[];
  showBeforeModal: boolean;
  feedLoading: boolean;
}

const FeedDataContext = createContext({
  searchtext: "",
  showModal: false,
  toggleModal: (status: boolean) => {},
  locations: [],
  interests: [],
  updates: [],
  sources: [],
  lastSearchText: "",
  userUpdate: { update_id: "" },
  selectedLocation: null,
  pastSelectedLocation: null,
  onLocationSelect: () => {},
  setPastselectedLocation: () => {},
  setLastSearchText: () => {},
  setUserUpdate: () => {},
  handleSearchText: (value: string) => {},
  toggleBeforeModal: (status: boolean) => {},
  cities: [],
  showBeforeModal: false,
  feedLoading: true,
} as FeedDataContextProps);

const getDefaultLocation = async (
  locations: LocationType[]
): Promise<LocationType | undefined> => {
  const storageNonParsedLocation = await getLocationFromStorage();

  const storageLocation = storageNonParsedLocation
    ? JSON.parse(storageNonParsedLocation)
    : "";
  return storageLocation?.location_id === "around_me"
    ? storageLocation
    : locations?.filter(
        (l) => l.location_id === (storageLocation?.location_id || "bengaluru")
      )[0];
};

export function FeedDataProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [feedLoading, setFeedLoading] = useState(true);
  const { userCurrentPosition } = useLocationInfo();
  const [locationsList, setLocationsList] = useState<LocationType[]>([]);
  const [interests, setInterests] = useState<InterestProps[]>([]);
  const [updates, setUpdates] = useState<AroundlyPlaces[]>([]);
  const [notificationeat, setNotificationeat] = useState(false);
  const [notificationsaved, setNotificationsaved] = useState(false);
  const [notificationexp, setNotificationexp] = useState(false);

  const [userUpdate, setUserUpdate] = useState<UserAction>({
    update_id: "",
  });
  const [lastSearchText, setLastSearchText] = useState("");

  const [selectedLocation, setSelectedLocation] = useState<LocationType | null>(
    null
  );
  const [showBeforeModal, setBeforeModal] = useState(false);

  const [pastSelectedLocation, setPastselectedLocation] =
    useState<LocationType>({
      location_name: "Indiranagar",
      location_id: "indiranagar",
      radius: 50000,
      latitude: 12.9716,
      longitude: 77.5946,
      timezone: "Asia/Kolkata",
    });

  const [showModal, setShowModal] = useState(false);
  const [sources, setSources] = useState([]);
  const [searchtext, setSearchText] = useState("");
  const [cities, setCities] = useState<CityProps[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [locationsResp, interestsResp] = await Promise.all([
          fetchLocations(),
          fetchInterests(),
        ]);
        setLocationsList(locationsResp);
        setInterests(interestsResp?.result);
        const location = await getDefaultLocation(locationsResp);
        if (location) {
          setSelectedLocation(location);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        setUserUpdate(userData.userAction);
      } catch (e) {
        console.log(e);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    async function getFeed() {
      setFeedLoading(true);
      try {
        const updatesRes = await fetchFeed({ location: selectedLocation });
        if (userCurrentPosition) {
          updatesRes.forEach((place: AroundlyPlaces) => {
            // TODO: Calculate distance using a formula
            place.distance = getDistanceFromLatLonInKm(
              userCurrentPosition.coords.latitude,
              userCurrentPosition.coords.longitude,
              place.geo_location.coordinates[1],
              place.geo_location.coordinates[0]
            );
          });
        }
        setUpdates(updatesRes);
        setFeedLoading(false);
      } catch (e) {
        console.log(e);
        setFeedLoading(false);
      }
    }
    if (selectedLocation) {
      getFeed();
    }
  }, [selectedLocation, userCurrentPosition]);

  useEffect(() => {
    async function getSources() {
      try {
        const data = await getSourceList();
        setSources(data);
      } catch (e) {
        console.log(e);
      }
    }
    getSources();
  }, []);

  useEffect(() => {
    async function getCity() {
      try {
        const getcitydata = await getCities();
        setCities(getcitydata);
      } catch (e) {
        console.log(e);
      }
    }
    getCity();
  }, []);

  const onLocationSelect = async (locationDetails: LocationType) => {
    await setLocationToStorage(JSON.stringify(locationDetails));
    setSelectedLocation(locationDetails);
  };

  const toggleModal = (status: boolean) => {
    setShowModal(status);
  };

  const handleSearchText = (value: string) => {
    setSearchText(value);
  };

  const toggleBeforeModal = (status: boolean) => {
    setBeforeModal(status);
  };

  return (
    <FeedDataContext.Provider
      value={{
        pastSelectedLocation,
        setPastselectedLocation,
        searchtext,
        handleSearchText,
        locations: locationsList,
        interests,
        userUpdate,
        updates,
        selectedLocation,
        onLocationSelect,
        setUserUpdate,
        lastSearchText,
        setLastSearchText,
        showModal,
        toggleModal,
        sources,
        toggleBeforeModal,
        cities,
        showBeforeModal,
        feedLoading,
      }}
    >
      {children}

      {showBeforeModal && (
        <ModalBeforeMoods toggleBeforeModal={toggleBeforeModal} />
      )}

      {showModal && <MoodsModal toggleBeforeModal={toggleBeforeModal} />}
    </FeedDataContext.Provider>
  );
}

export const useFeedData = () => useContext(FeedDataContext);
