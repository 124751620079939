import { useEffect, useState } from "react";
// import { getDeviceId } from "../services/local-storage.service";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { Mixpanel } from "../services/mixpanel";

const AroundlyShare = () => {
  const [urlToShare, setUrlToShare] = useState("");
  const { authUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // let device_id = getDeviceId();

    if (authUser) {
      let referral_id = authUser.referal_id;
      let newUrlToShare = `${process.env.REACT_APP_FRONTEND_URL}/s/${referral_id}`;
      setUrlToShare(newUrlToShare);
    } else {
      navigate("/login");
    }
  }, [authUser, navigate]);

  const onShare = () => {
    try {
      const copyResponse = copy(urlToShare);
      if (copyResponse) {
        toast.success("Copied. Share with a friend!");
        Mixpanel.track("Profile page share", { urlToShare });
      }
    } catch (e) {
      console.log("Unable to share the post");
      console.log(e);
    }
  };
  return (
    <div className="p-2 rounded-lg bg-opacity-10 bg-secondary items-center mx-3">
      <p className="font-medium text-xl leading-6">You got 5 Invites </p>
      <p
        className=" font-400 text-xs leading-4 mt-1"
        style={{ letterSpacing: "-0.333px" }}
      >
        They say{" "}
        <span className="  font-medium">
          ‘You are the average of the 5 you hang out with’
        </span>
        . Share your invite with your friends to personalize your feeds and go
        out together.{" "}
      </p>
      <div className="mt-3 py-1 flex border border-[#00B078] rounded-full bg-white">
        <input
          className="focus:ring-0 p-0 truncate text-[#00B078] rounded-full border-none grow outline-none focus:outline-none px-3"
          defaultValue={urlToShare}
          type="text"
          readOnly
        />
        <div
          onClick={onShare}
          className="bg-[#00B078] px-3 py-[15px] rounded-full cursor-pointer flex items-center justify-center"
          style={{ marginRight: "4px", minHeight: "40px", minWidth: "80px" }}
        >
          <span
            className="font-bold text-sm text-white items-center justify-center flex"
            style={{ letterSpacing: "4px" }}
          >
            SHARE
          </span>
        </div>
      </div>
    </div>
  );
};
export default AroundlyShare;
