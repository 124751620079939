// import GtagPathView from "../../components/GtagPageView";
import { ArrowLeftIcon, PhoneIcon } from "@heroicons/react/24/outline";
import "moment-timezone";
import SocialButton from "../components/socialButton";
import { ReactElement, useEffect, useState } from "react";
import {
  getPlaceDetails,
  getUpdates,
  performUserActionHistory,
} from "../services/aroundly-apis";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AroundlyPlaces,
  SocialMediaUpdate,
  SupportedActions,
} from "../common/global.types";
import {
  UPDATE_TYPE_EVENT,
  UPDATE_TYPE_OFFER,
} from "../common/global.constants";
import { useAuth } from "../context/auth";
import { useFeedData } from "../context/FeedDataContext";
import MiniFeedCard from "../components/MiniFeedCard";
import { EmptyCard } from "../components/Emptycard";
import ShareButton from "../components/shareBtn";
import { Mixpanel } from "../services/mixpanel";
import PastFeedCard from "../components/PastFeedCard";
import LoadingComp from "../components/LoadingComp";
import { useCollabListsContext } from "../context/CollabListsContext";
import useShortAddress from "../hooks/useShortAddress";
import { DirectionBtn } from "../components/Directionbtn";
import LikeButton from "../components/likeBtn";

const getIcon = (type: "Zomato" | "Instagram" | "Bookmyshow"): ReactElement => {
  switch (type) {
    case "Bookmyshow": {
      return (
        <img
          height={24}
          width={24}
          alt="Bookmyshow"
          className="h-6 w-6"
          src={"/icons/bookmyshow-icon.svg"}
        />
      );
    }
    case "Zomato": {
      return (
        <img
          height={24}
          width={24}
          alt="Bookmyshow"
          className="h-6 w-6"
          src={"/icons/zomato.png"}
        />
      );
    }
    case "Instagram": {
      return (
        <img
          height={24}
          width={24}
          alt="Bookmyshow"
          className="h-6 w-6"
          src={"/icons/instagram.png"}
        />
      );
    }
    default:
      return <div />;
  }
};

export default function Store({ storeId }: { storeId?: string }) {
  // const [businessDetails, setBusinessDetails] = useState<Business>();
  const [placeDetails, setPlaceDetails] = useState<AroundlyPlaces>();
  const [updates, setUpdates] = useState<any | null>(null);
  const { aroundlyPlaceId } = useParams();
  const fetchStoreDataId = aroundlyPlaceId || storeId;
  const { authUser } = useAuth();
  const { selectedLocation } = useFeedData();
  const [dataLoading, setDataLoading] = useState(false);
  const [dining_options, setDining] = useState<string[]>([]);
  const [atmosphere_and_crowd, setAtmosphere] = useState<string[]>([]);
  const [events, setEvents] = useState<SocialMediaUpdate[]>([]);
  const [offers, setOffers] = useState<SocialMediaUpdate[]>([]);
  const [others, setOthers] = useState<SocialMediaUpdate[]>([]);
  const [uniquecuisines, setUniquecuisines] = useState<string>();
  const { toggleStoreModal, showStore } = useCollabListsContext();
  // const location = useLocation();
  const navigate = useNavigate();
  const scrolling = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrolling();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoading(true);
        const placeInfo = await getPlaceDetails(fetchStoreDataId);
        setPlaceDetails(placeInfo);
        const updateData = await getUpdates(fetchStoreDataId);
        setUpdates(updateData);
      } catch (error) {
        setDataLoading(true);
        console.error("Error fetching data:", error);
      } finally {
        setDataLoading(false);
      }
    };
    fetchData();
  }, [fetchStoreDataId]);

  useEffect(() => {
    const events =
      updates?.filter(
        (u: SocialMediaUpdate) => u.update_type === UPDATE_TYPE_EVENT
      ) || [];
    setEvents(events);
    const offers =
      updates?.filter(
        (u: SocialMediaUpdate) => u.update_type === UPDATE_TYPE_OFFER
      ) || [];
    setOffers(offers);
    const others =
      updates?.filter(
        (u: SocialMediaUpdate) =>
          u.update_type !== UPDATE_TYPE_OFFER &&
          u.update_type !== UPDATE_TYPE_EVENT
      ) || [];

    setOthers(others);
  }, [updates]);

  const generatePhoneUrl = (phone: string) => {
    return `tel:${phone}`;
  };

  // const firstValidDetails = updates
  //   ?.map((item: SocialMediaUpdate) => item.social_media_update_details)
  //   ?.find((details: any) => details.media_url || details.thumbnail_url);

  // const imageUrl =
  //   firstValidDetails?.thumbnail_url || firstValidDetails?.media_url;

  useEffect(() => {
    const offerings =
      placeDetails?.google_maps_details?.additional_details?.offerings || [];
    const diningOptions =
      placeDetails?.google_maps_details?.additional_details?.dining_options ||
      [];

    const combinedOptions = [...offerings, ...diningOptions];

    const dining_options = combinedOptions.reduce((acc, option) => {
      if (!acc.includes(option)) {
        acc.push(option);
      }
      return acc;
    }, [] as string[]);

    setDining(dining_options);
  }, [
    placeDetails?.google_maps_details?.additional_details?.dining_options,
    placeDetails?.google_maps_details?.additional_details?.offerings,
  ]);

  useEffect(() => {
    const crowd =
      placeDetails?.google_maps_details?.additional_details?.crowd || [];
    const atmosphere =
      placeDetails?.google_maps_details?.additional_details?.atmosphere || [];

    const combinedatmosphere = [...crowd, ...atmosphere];

    const atmosphere_and_crowd = combinedatmosphere.reduce((acc, option) => {
      if (!acc.includes(option)) {
        acc.push(option);
      }
      return acc;
    }, [] as string[]);
    setAtmosphere(atmosphere_and_crowd);
  }, [
    placeDetails?.google_maps_details?.additional_details?.atmosphere,
    placeDetails?.google_maps_details?.additional_details?.crowd,
  ]);

  const shortAddress = useShortAddress(placeDetails as AroundlyPlaces);

  function cleanString(str: string) {
    return str.split(",").map((s) => s.trim());
  }

  useEffect(() => {
    const placeCategory = placeDetails?.place_category
      ? cleanString(placeDetails.place_category)
      : "";

    const cuisine = placeDetails?.swiggy_dine_out_details?.cuisine
      ? cleanString(placeDetails.swiggy_dine_out_details.cuisine)
      : "";

    let cuisineAndCategory = "";

    if (placeCategory.length > 0) {
      cuisineAndCategory += placeCategory;
    }

    if (cuisine.length > 0) {
      if (placeCategory.length > 0) {
        cuisineAndCategory += ",";
      }
      cuisineAndCategory += cuisine;
    }

    const arrayofcuisine = cuisineAndCategory?.split(",");
    const uniquecuisines = arrayofcuisine.reduce((acc, option) => {
      if (!acc.includes(option)) {
        acc.push(option);
      }
      return acc;
    }, [] as string[]);

    setUniquecuisines(uniquecuisines.join(","));
  }, [
    placeDetails?.place_category,
    placeDetails?.swiggy_dine_out_details?.cuisine,
  ]);

  const [showKnownFor, setShowKnownFor] = useState(false);
  const [hasDiningOptions, setHasDiningOptions] = useState(false);
  const [hasAtmosphereAndCrowd, setHasAtmosphereAndCrowd] = useState(false);
  const [hasBestSellingItems, setHasBestSellingItems] = useState(false);
  const [bestsellingitems, setBestsellingitems] = useState<string | undefined>(
    ""
  );
  useEffect(() => {
    const diningOptionsAvailable = dining_options && dining_options.length > 0;
    const atmosphereAndCrowdAvailable =
      atmosphere_and_crowd && atmosphere_and_crowd.length > 0;
    const bestSellingItems =
      placeDetails?.swiggy_dine_out_details?.best_selling_items
        ?.replace(",", "")
        ?.trim();
    setBestsellingitems(bestSellingItems);
    const bestSellingItemsAvailable =
      bestSellingItems && bestSellingItems.length > 0;

    setHasDiningOptions(diningOptionsAvailable);
    setHasAtmosphereAndCrowd(atmosphereAndCrowdAvailable);
    setHasBestSellingItems(bestSellingItemsAvailable as boolean);

    const shouldShowKnownFor =
      diningOptionsAvailable ||
      atmosphereAndCrowdAvailable ||
      bestSellingItemsAvailable;
    setShowKnownFor(shouldShowKnownFor as boolean);
  }, [dining_options, atmosphere_and_crowd, placeDetails]);
  return (
    <>
      {dataLoading ? (
        <div className="relative m-auto flex justify-center items-center h-dvh ">
          <LoadingComp
            defaultText="Store Profile"
            showSources={false}
            primaryText="Loading"
          />
        </div>
      ) : (
        <div className="h-screen overflow-x-hidden">
          <div className=" text-white  leading-none h-auto ">
            <div className=" bottom-0 left-0 text-white h-full w-full">
              <div
                className={`p-3 flex justify-between w-full  z-20 top-0  text-black
                 `}
              >
                <button
                  onClick={() => {
                    if (showStore) {
                      toggleStoreModal(false);
                    } else {
                      navigate("/");
                    }
                  }}
                >
                  {showStore ? (
                    <ArrowLeftIcon className="h-6 w-6 inline-block " />
                  ) : (
                    <div>
                      <img
                        src="/images/aroundly-logo.png"
                        className="h-10"
                        alt="Aroundly Logo"
                      />
                    </div>
                  )}
                </button>
                <div className="rounded justify-center items-center">
                  <img alt="google" className="" src="/images/google.svg" />
                </div>
              </div>
              <div className="flex px-3   flex-col  w-full text-black  my-3">
                <div className={`text-xl font-medium  text-black`}>
                  {placeDetails?.name}
                </div>
                <div
                  className={` text-xs text-secondary-text font-400  font-inter mt-1`}
                >
                  <span>{uniquecuisines}</span>
                </div>
                <Link
                  id="google-place-link"
                  onClick={async (e) => {
                    e.stopPropagation();
                    Mixpanel.track("Store Address Clicked", {
                      locationDetail: placeDetails?.google_maps_details,
                      userId: authUser?._id,
                      place_id: placeDetails?._id,
                      ui_type: "store-page",
                    });
                    await performUserActionHistory(
                      SupportedActions.DIRECTIONS,
                      {
                        locationDetail: placeDetails?.google_maps_details,
                        userId: authUser?._id,
                        place_id: placeDetails?._id,
                        ui_type: "store-page",
                      }
                    );
                  }}
                  rel="noreferrer"
                  to={`https://www.google.com/maps/search/?api=1&query=${placeDetails?.google_maps_details?.formatted_address}&query_place_id=${placeDetails?.place_id}`}
                  className="no-underline "
                  target="_blank"
                >
                  <div
                    style={{
                      letterSpacing: "-0.33px",
                    }}
                    className={` text-xs text-secondary-text font-medium  font-inter my-2 `}
                  >
                    <span className="underline">
                      {shortAddress.length > 0
                        ? shortAddress
                        : placeDetails?.google_maps_details.formatted_address}
                    </span>
                  </div>
                </Link>
                <div className="flex gap-2 items-center">
                  {placeDetails?.google_maps_details &&
                    (placeDetails?.google_maps_details?.rating ||
                      placeDetails?.google_maps_details?.reviews) && (
                      <div className="flex items-center gap-1">
                        <img
                          src="/images/googlelogo.svg"
                          className="h-4 w-4"
                          alt="google"
                        />
                        {placeDetails?.google_maps_details?.rating && (
                          <span
                            style={{
                              letterSpacing: "-0.33px",
                            }}
                            className={`text-xs  font-medium`}
                          >
                            {placeDetails?.google_maps_details?.rating} ⭐
                          </span>
                        )}
                        {/* {placeDetails?.google_maps_details?.reviews?.length >
                          0 && (
                          <span className="text-secondary-text font-400 text-xs">
                            {placeDetails?.google_maps_details?.reviews?.length}{" "}
                            reviews
                          </span>
                        )} */}
                      </div>
                    )}
                  {placeDetails?.swiggy_dine_out_details &&
                    (placeDetails?.swiggy_dine_out_details?.rating ||
                      placeDetails?.swiggy_dine_out_details?.reviews_count) && (
                      <div className="flex items-center gap-1">
                        <img
                          src="/images/sources/swiggy.png"
                          className="h-4 w-4"
                          alt="swiggy"
                        />
                        {placeDetails?.swiggy_dine_out_details?.rating && (
                          <span
                            style={{
                              letterSpacing: "-0.33px",
                            }}
                            className={`text-xs  font-medium`}
                          >
                            {placeDetails?.swiggy_dine_out_details?.rating} ⭐
                          </span>
                        )}

                        {/* {placeDetails?.swiggy_dine_out_details
                          ?.reviews_count && (
                          <span className="text-secondary-text font-400 text-xs">
                            {placeDetails?.swiggy_dine_out_details?.reviews_count
                              .toString()
                              .toLowerCase()}{" "}
                            {!placeDetails?.swiggy_dine_out_details?.reviews_count
                              .toString()
                              .toLowerCase()
                              .includes("reviews") && "reviews"}
                          </span>
                        )} */}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {(placeDetails?.google_maps_details.formatted_phone_number ||
            (placeDetails?.socials && placeDetails?.socials?.length > 0)) && (
            <div className="flex items-center justify-start gap-4 border-y-2 p-3">
              {placeDetails?.google_maps_details.formatted_phone_number && (
                <SocialButton
                  url={generatePhoneUrl(
                    placeDetails.google_maps_details.formatted_phone_number
                  )}
                  icon={
                    <PhoneIcon className="h-6 w-6 inline-block text-zinc-700" />
                  }
                />
              )}
              {placeDetails?.socials?.map((item: any) => (
                <SocialButton
                  key={item.source}
                  url={item.url}
                  icon={getIcon(item.source)}
                />
              ))}
            </div>
          )}
          <hr />
          {showKnownFor && (
            <div className="mx-3 mt-3">
              <div className="mb-3">
                <p className="text-[#1e1e1e] text-base font-medium">
                  Known for
                </p>
                <div className="flex">
                  <p
                    className="text-secondary-text text-xs font-450"
                    style={{ letterSpacing: "-0.33px" }}
                  >
                    AI generated summary based on recent updates in
                  </p>
                  <span className="flex gap-1">
                    <img
                      src="/images/sources/swiggy.png"
                      className="h-4 w-4"
                      alt="swiggy"
                    />
                    <img
                      src="/images/sources/zomato.png"
                      className="h-4 w-4"
                      alt="zomato"
                    />
                    <img
                      src="/images/googlelogo.svg"
                      className="h-4 w-4"
                      alt="google"
                    />
                  </span>
                </div>
              </div>
              <div
                className="p-2 bg-[#707070] bg-opacity-5 text-xs"
                style={{ letterSpacing: "-0.33px" }}
              >
                <ol>
                  {hasDiningOptions && (
                    <li>
                      <span className="font-medium">Dining Options :</span>
                      <br />
                      <div className="ml-3 font-450">
                        {dining_options.join(", ")}
                      </div>
                    </li>
                  )}
                  {hasAtmosphereAndCrowd && (
                    <li className="my-3">
                      <span className="font-medium">Atmosphere & Crowd :</span>
                      <br />
                      <div className="ml-3 font-450">
                        {atmosphere_and_crowd.join(", ")}
                      </div>
                    </li>
                  )}
                  {hasBestSellingItems && (
                    <li>
                      <span className="font-medium">Best Selling Items :</span>
                      <br />
                      <div className="ml-3 font-450">{bestsellingitems}</div>
                    </li>
                  )}
                </ol>
              </div>
            </div>
          )}
          {placeDetails?.swiggy_dine_out_details?.price_for_two &&
            placeDetails?.swiggy_dine_out_details?.cuisine &&
            placeDetails?.swiggy_dine_out_details.url && (
              <div className="my-6 mx-3">
                <p className="text-[#1e1e1e] text-base font-medium mb-3">
                  Menu
                </p>
                <div className="p-2 bg-[#707070] bg-opacity-5 text-xs font-450">
                  {placeDetails?.swiggy_dine_out_details?.price_for_two && (
                    <div>
                      •
                      <span className="ml-2">
                        <span className="font-medium">
                          Price Range For Two:
                        </span>{" "}
                        <span className="font-450">
                          {placeDetails?.swiggy_dine_out_details?.price_for_two}{" "}
                          Rs.
                        </span>
                      </span>
                    </div>
                  )}

                  {placeDetails?.swiggy_dine_out_details?.cuisine && (
                    <div className="my-3 flex">
                      <span className="h-auto">•</span>
                      <span className="ml-2 font-450">
                        <span className="font-medium">Cuisine: </span>
                        {placeDetails?.swiggy_dine_out_details?.cuisine}
                      </span>
                    </div>
                  )}
                  {placeDetails?.swiggy_dine_out_details && (
                    <div className="flex">
                      <span className="h-auto mr-2">•</span>

                      <Link
                        rel="noreferrer"
                        to={placeDetails?.swiggy_dine_out_details.url as string}
                        className="text-special-blue font-medium underline"
                        target="_blank"
                      >
                        Menu Link
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )}
          <div className=" bg-white pb-24">
            {events?.length === 0 &&
              offers?.length === 0 &&
              others?.length === 0 && (
                <div className="text-center font-xs text-[#626569] mt-16">
                  Not much going on here for now.
                  <br /> But you are very welcome to drop by.
                </div>
              )}

            {events?.length > 0 && (
              <div className="mt-3 ">
                <div className="font-medium text-base leading-5 px-3 pb-3">
                  Events
                </div>
                <div className="flex overflow-x-scroll scrollbar-hide w-full gap-3  ">
                  {/* {events.map((update: SocialMediaUpdate, index: number) => (
                  <MiniFeedCard
                    home={false}
                    updateType={UPDATE_TYPE_EVENT}
                    timezone={selectedLocation?.timezone as string}
                    item={update}
                    key={update._id}
                    isLast={index === events.length - 1}
                  />
                ))} */}
                  {events.map((update: SocialMediaUpdate, index: number) => (
                    <PastFeedCard
                      placeData={placeDetails as AroundlyPlaces}
                      updateType={update.update_type}
                      timezone={selectedLocation?.timezone as string}
                      item={update}
                      isLast={index === updates.length - 1}
                      key={index}
                      isfirst={index === 0}
                    />
                  ))}
                  {events.length === 0 && <EmptyCard locationName={""} />}
                </div>
              </div>
            )}

            {offers?.length > 0 && (
              <div className="mt-6 ">
                <div className="font-medium text-base leading-5 px-3 pb-3">
                  Offers
                </div>
                <div className="flex overflow-x-scroll scrollbar-hide w-full gap-3 ml-3 mr-3">
                  {offers?.map((update: SocialMediaUpdate, index: number) => (
                    <PastFeedCard
                      placeData={placeDetails as AroundlyPlaces}
                      updateType={update.update_type}
                      timezone={selectedLocation?.timezone as string}
                      item={update}
                      isLast={index === updates.length - 1}
                      key={index}
                    />
                  ))}
                  {offers.length === 0 && <EmptyCard locationName={""} />}
                </div>
              </div>
            )}

            {others?.length > 0 && (
              <div className="mt-3">
                <div className=" px-3 mb-3 ">
                  <div className="font-medium text-base leading-5 ">
                    Reviews
                  </div>
                </div>
                <div className="flex overflow-x-scroll w-full scrollbar-hide">
                  {others?.map((item: SocialMediaUpdate, index: number) => (
                    <div
                      className={`flex-none ${
                        others.length > 1 ? "w-[90%]" : "w-95"
                      }  ${index === 0 ? "ml-3" : ""} mr-2 shadow-sm`}
                      key={item._id}
                    >
                      <MiniFeedCard
                        isfullView={false}
                        timezone={selectedLocation?.timezone as string}
                        item={item}
                        isLast={index === others.length - 1}
                        miniFeedCard={true}
                      />
                    </div>
                  ))}
                  {others.length === 0 && <EmptyCard locationName={""} />}
                </div>
              </div>
            )}

            {/* <div className="flex overflow-x-scroll scrollbar-hide w-full ">
                {offers.map((update: SocialMediaUpdate, index: number) => (
                  <MiniFeedCard
                    home={false}
                    updateType={UPDATE_TYPE_EVENT}
                    timezone={selectedLocation?.timezone as string}
                    item={update}
                    key={update._id}
                    isLast={index === offers.length - 1}
                  />
                ))}
                {offers.length === 0 && <EmptyCard locationName={""} />}
              </div> */}
          </div>
          <div
            className={`grid grid-cols-4 shadow  gap-4 bg-white fixed bottom-0 border-t w-full max-w-md p-3 `}
          >
            {/* <div
            className="col-span-2 flex items-center justify-end"
            onClick={() => {
              Mixpanel.track(`Stay Updated Button clicked`, {
                userId: authUser?._id,
              });
            }}
          >
            <div className="p-2 rounded-full border border-zinc-200 text-zinc-200 justify-end items-center gap-2.5 flex">
              <BellAlertIcon className="h-6 w-6 inline-block " />
              <span className="text-[12px] font-medium leading-6 items-center font-inter">
                STAY UPDATED
              </span>
            </div>
          </div> */}

            <div className="flex justify-start items-center gap-2 col-span-2">
              <ShareButton
                _id={placeDetails?._id || ""}
                captionTitle={placeDetails?.name || ""}
                isStore={true}
              />
              <DirectionBtn placeDetails={placeDetails} isMini />
            </div>
            <div className="flex justify-end items-center gap-2 col-span-2">
              <LikeButton placeData={placeDetails} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
