import Percentage from "../components/Percentage";
import { ChangeEvent, useEffect, useState } from "react";
import { Birthdate } from "../common/global.types";
import { updateUser } from "../services/aroundly-apis";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonControl from "../components/controls/ButtonControl";
const constgender = ["Male", "Female", "Non Binary"];
const months = [
  { show: "Jan", value: "01" },
  { show: "Feb", value: "02" },
  { show: "Mar", value: "03" },
  { show: "Apr", value: "04" },
  { show: "May", value: "05" },
  { show: "Jun", value: "06" },
  { show: "Jul", value: "07" },
  { show: "Aug", value: "08" },
  { show: "Sep", value: "09" },
  { show: "Oct", value: "10" },
  { show: "Nov", value: "11" },
  { show: "Dec", value: "12" },
];

const dates = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const DEFAULT_DATE = {
  day: "01",
  month: "01",
  year: 2000,
};

const OnboardingStep3 = () => {
  const { authUser, setAuthUser } = useAuth();
  const [birthdate, setBirthdate] = useState<Birthdate>(DEFAULT_DATE);
  const [isBirthdayChanged, setIsBirthdayChanged] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState<string>("");
  const navigate = useNavigate();

  const handleGender = (value: string) => {
    setGender(value);
  };
  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleBirthdate = (value: Birthdate) => {
    setBirthdate(value);
  };

  const years: number[] = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleBirthdateChange =
    (part: string) => (event: ChangeEvent<HTMLSelectElement>) => {
      setIsBirthdayChanged(true);
      handleBirthdate({ ...birthdate, [part]: event.target.value });
    };

  const handleGenderClick = (value: string) => {
    handleGender(value);
  };

  const updateData = async () => {
    const birthdateObj = new Date(
      `${birthdate.year}-${birthdate.month}-${birthdate.day}`
    );
    let phone = "";
    if (authUser !== null && authUser !== undefined) {
      phone = authUser?.phone;
    }

    const data = await updateUser(phone, {
      birthdate: birthdateObj,
      gender: gender,
      name: name,
    });

    return data;
  };

  const handleClickbutton = async () => {
    const data = await updateData();

    if (data.msg === "User Data successfully saved") {
      if (setAuthUser) {
        if (data && data.updatedUser) {
          setAuthUser({
            ...authUser,
            ...data.updatedUser,
          } as any);
        }
      }
      navigate("/app/whatisaroundly");
    } else {
      toast.error("Data is not saved successfully.");
    }
  };

  useEffect(() => {
    if (authUser?.date_of_birth && authUser?.gender && authUser?.name) {
      let datevalue = authUser?.date_of_birth
        .toString()
        .split("T")[0]
        .split("-");
      setBirthdate({
        day: datevalue[2],
        month: datevalue[1],
        year: Number(datevalue[0]),
      });
      setIsBirthdayChanged(true);
      setGender(authUser?.gender);
      setName(authUser?.name);
    }
  }, [authUser, navigate]);

  return (
    <div className="h-dvh">
      <div className="pt-16">
        <Percentage step={4} total={4} />
      </div>
      <div className="mx-5  mt-[48px]">
        <div className="text-2xl font-bold">To show you better results...</div>
      </div>

      <div className="mx-5 mt-8">
        <p className="mb-3">Your name</p>
        <div className="flex">
          <input
            className="focus:ring-0  border  border-[#DCDEE3] rounded-lg grow outline-none focus:outline-none p-2 min-h-12"
            value={name}
            placeholder="Your name"
            type="text"
            onChange={handleName}
          />
        </div>
      </div>

      <div className="mx-5 mt-7">
        <p className="mb-3">Your gender</p>
        <div className="flex">
          {constgender.map((one, index) => (
            <div
              onClick={() => handleGenderClick(one)}
              key={index}
              className={`min-w-20 ${
                gender === one ? "bg-[#E8F0FF]" : ""
              } mr-1 h-12 p-2 justify-center cursor-pointer flex mb-1 items-center  text-[#626569] rounded-lg text-sm border border-[#DCDEE3]`}
            >
              {one}
            </div>
          ))}
        </div>
      </div>
      <div className="mx-5 my-7">
        <p className="mb-3">Your birthdate</p>
        <div className="flex ">
          <div className="flex mr-1">
            <select
              value={birthdate.day}
              onChange={handleBirthdateChange("day")}
              className="rounded-lg h-12 border border-[#DCDEE3] min-w-20 text-[#626569] date"
            >
              {dates.map((date, index) => (
                <option key={index} value={date} className="flex justify-end ">
                  {date}
                </option>
              ))}
            </select>
          </div>
          <div className="flex mr-1">
            <select
              value={birthdate.month}
              onChange={handleBirthdateChange("month")}
              className="rounded-lg h-12 border border-[#DCDEE3] min-w-20 text-[#626569]"
            >
              {months.map((month, index) => (
                <option key={index} value={month.value}>
                  {month.show}
                </option>
              ))}
            </select>
          </div>
          <div className="flex ">
            <select
              value={birthdate.year}
              onChange={handleBirthdateChange("year")}
              className="rounded-lg h-12 border border-[#DCDEE3] min-w-20 text-[#626569]"
            >
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="my-5 text-secondary-text">
          <p className="text-xs">
            🔒 Your personal details are never shared with anyone outside.
          </p>
        </div>
      </div>
      <div className="absolute bottom-8 mx-auto max-w-md w-full">
        <div className="flex justify-center px-4">
          <ButtonControl
            buttonId="user-detail-button"
            size="lg"
            theme="primary"
            name="Next"
            onClick={() => handleClickbutton()}
            disabled={!isBirthdayChanged || gender.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
export default OnboardingStep3;
