import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import { SafeArea } from "capacitor-plugin-safe-area";
// import { Capacitor } from "@capacitor/core";
// import { useEffect } from "react";
import HomePage from "./pages/home.page";
import MainLayout from "./components/MainLayout";
import NoMatch from "./components/NoMatch";
import FeedPage from "./pages/feed.page";
import Wishlist from "./components/Wishlist";
import LoginPage from "./pages/login.page";
// import ExplorePage from "./components/FeedList";
import ResultsPage from "./pages/results.page";
import WaComponent from "./pages/wa.page";
import BizComponent from "./pages/biz.page";
import Terms from "./pages/terms.page";
import Policy from "./pages/privacy-policy.page";
import Update from "./pages/update.page";
import Store from "./pages/store.page";
import { Profile } from "./components/Profile";
import AddToHomeScreen from "./components/AddToHomeScreenToast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthUserProvider } from "./context/auth";
import { CapacitorProvider } from "./context/CapacitorContext";
import Share from "./pages/share.page";
import GtagPathView from "./components/GtagPageView";
import AroundlyPage from "./pages/AroundlyPage.page";
import Edit from "./components/Edit";
// import {
//   addListeners,
//   checkAndCreateAndroidNotificationChannel,
//   initializeWebNotification,
//   registerNotifications,
// } from "./common/notification";
import AppUrlListener from "./components/appUrlListner";
// import {
//   AppUpdate,
//   AppUpdateAvailability,
// } from "@capawesome/capacitor-app-update";
import OnboardingStep2 from "./pages/GroupType";
import OnboardingStep3 from "./pages/UserDetail";
import OnboardingStep4 from "./pages/UpdateLocation";
import OnboardingStep1 from "./pages/CustomiseInterest";
import CollabWishlist from "./pages/CollabWishlist";
import CompleteRegistration from "./pages/CompleteRegistration.page";
import FoodAndDrinks from "./pages/FoodAndDrinks";
import SearchPage from "./pages/SearchPage.page";
import AroundeMe from "./pages/AroundMe.page";
import { DeviceNotificationsProvider } from "./context/DeviceNotificationsContext";
import Event from "./pages/Event.page";
import CollabWishlistt from "./pages/CollabWishlistt";

// import MainOnboardingLayout from "./components/MainOnboardingLayout";

// const performImmediateUpdate = async () => {
//   const result = await AppUpdate.getAppUpdateInfo();
//   if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
//     return;
//   }
//   if (result.immediateUpdateAllowed) {
//     await AppUpdate.performImmediateUpdate();
//   }
// };

function App() {
  // useEffect(() => {
  //   (async function () {
  //     const safeAreaData = await SafeArea.getSafeAreaInsets();
  //     const { insets } = safeAreaData;
  //     for (const [key, value] of Object.entries(insets)) {
  //       document.documentElement.style.setProperty(
  //         `--safe-area-inset-${key}`,
  //         `${value}px`
  //       );
  //     }
  //   })();
  // }, []);

  // useEffect(() => {
  //   if (platform === "android") {
  //     performImmediateUpdate();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (platform !== "web") {
  //     checkAndCreateAndroidNotificationChannel();
  //     registerNotifications();
  //     addListeners();
  //   } else {
  //     initializeWebNotification();
  //   }
  // }, []);

  // useEffect(() => {
  //   SafeArea.getSafeAreaInsets()
  //     .then(({ insets }) => {
  //       console.log(JSON.stringify(insets), "getSafeAreaInsets");
  //     })
  //     .catch((err) => console.log(err, "getSafeAreaInsetsErr"));
  //   SafeArea.getStatusBarHeight()
  //     .then(({ statusBarHeight }) => {
  //       console.log(JSON.stringify(statusBarHeight), "getStatusBarHeight");
  //     })
  //     .catch((err) => console.log(err, "getStatusBarHeight"));
  // }, []);
  return (
    <div className={`max-w-md mx-auto bg-white relative toolbar`}>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="light"
      />
      <BrowserRouter>
        <AuthUserProvider>
          <CapacitorProvider>
            <DeviceNotificationsProvider>
              <GtagPathView />
              <AppUrlListener />
              <Routes>
                <Route index element={<LoginPage />} />
                <Route path="/app" element={<MainLayout />}>
                  <Route index element={<Navigate to="feed" />} />
                  <Route path="feed" element={<FeedPage />}>
                    <Route path="aroundme" element={<AroundeMe />} />

                    <Route index element={<HomePage />} />
                    {/* <Route path="explore" element={<ExplorePage />} /> */}
                    <Route path="wishlist" element={<Wishlist />} />
                    <Route path="search" element={<SearchPage />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="edit" element={<Edit />} />
                  </Route>
                  <Route
                    path="wishlist/:wishlistId"
                    element={<CollabWishlistt />}
                  />
                  <Route
                    path="onboarding/customize-interests"
                    element={<OnboardingStep1 />}
                  />
                  <Route
                    path="onboarding/group-type"
                    element={<OnboardingStep2 />}
                  />
                  <Route
                    path="onboarding/user-details"
                    element={<OnboardingStep3 />}
                  />
                  <Route
                    path="onboarding/enable-location"
                    element={<OnboardingStep4 />}
                  />
                  <Route
                    path="category/food-and-drinks"
                    element={<FoodAndDrinks />}
                  />
                  <Route path="category/experiences" element={<Event />} />
                  <Route path="whatisaroundly" element={<AroundlyPage />} />

                  <Route
                    path="update/:socialMediaUpdateId"
                    element={<Update />}
                  />
                  <Route path="store/:aroundlyPlaceId" element={<Store />} />
                  <Route path="results" element={<ResultsPage />} />
                </Route>

                <Route path="wa/:whatsAppUpdateId" element={<WaComponent />} />
                <Route path="biz/" element={<BizComponent />} />
                <Route path="s/:referralId" element={<Share />} />
                <Route
                  path="login"
                  element={<Navigate to="/" replace={true} />}
                />
                <Route
                  path="feed"
                  element={<Navigate to="/" replace={true} />}
                />
                <Route
                  path="complete-registration"
                  element={<CompleteRegistration />}
                />
                <Route path="privacy-policy" element={<Policy />} />
                <Route path="terms" element={<Terms />} />

                <Route path="*" element={<NoMatch />} />
              </Routes>
              <AddToHomeScreen />
            </DeviceNotificationsProvider>
          </CapacitorProvider>
        </AuthUserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
