import MoodsModal from "./MoodsModal";
import ReusableSearchComp from "./ReusableSearchComp";

type SearchButtonProps = {
  toggleBeforeModal?: (status: boolean) => void;
};

const ModalBeforeMoods = ({ toggleBeforeModal }: SearchButtonProps) => {
  return (
    <div
      id="modal"
      className="fixed left-0 right-0 top-0 bottom-0 bg-black/90 z-50  max-w-md mx-auto flex items-center justify-center"
    >
      <ReusableSearchComp toggleBeforeModal={toggleBeforeModal} />
      {/* <MoodsModal /> */}
    </div>
  );
};

export default ModalBeforeMoods;
