import ButtonControl from "./controls/ButtonControl";

const EventShowMore = ({
  location,
  setShowlessdata,
  showlessData,
}: {
  location: string;
  setShowlessdata: (state: boolean) => void;
  showlessData: boolean;
}) => {
  return (
    <div className="pb-3 mt-3">
      <div className="p-2 flex flex-col gap-2 items-center text-center bg-white rounded-xl mx-3 ">
        <span>
          Showing experiences matching your interests. <br />
          Click below to view all experiences
        </span>

        <ButtonControl
          buttonId=""
          size="lg"
          theme="white"
          name={`VIEW ${showlessData ? "MORE" : "LESS"}`}
          iconPlacement="end"
          onClick={() => {
            window.scrollTo(0, 0);
            if (showlessData) {
              setShowlessdata(false);
            } else setShowlessdata(true);
          }}
        />
      </div>
    </div>
  );
};
export default EventShowMore;
