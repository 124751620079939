import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { Mixpanel } from "../services/mixpanel";
import { setReferIdToStorage } from "../services/local-storage.service";

export default function Share() {
  let navigate = useNavigate();
  let { referralId } = useParams();
  const update = useCallback(async () => {
    try {
      await setReferIdToStorage(referralId as string);
      Mixpanel.track(`Referrral link accessed`, { referral_id: referralId });
    } catch (e) {
      console.log(e);
    }
    navigate("/");
  }, [referralId, navigate]);
  useEffect(() => {
    update();
  }, [update]);
  return <div />;
}
