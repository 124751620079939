import { useFeedData } from "../context/FeedDataContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import LoadingComp from "../components/LoadingComp";
import {
  createList,
  deleteList,
  similartySearchOnPosts,
} from "../services/aroundly-apis";
import {
  AroundlyPlaces,
  CollabWishlistType,
  LocationType,
  SocialMediaUpdate,
  UpdateType,
} from "../common/global.types";
import { useAuth } from "../context/auth";
import { BottomTray } from "../components/BottomTrayLogin";
import { toast } from "react-toastify";
import { Mixpanel } from "../services/mixpanel";
import SearchButton from "../components/SearchButton";
import {
  ArrowLeftIcon,
  BookmarkIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import {
  BookmarkIcon as BookmarkIconSolid,
  ChevronLeftIcon as ChevronLeftIconSolid,
} from "@heroicons/react/24/solid";
import {
  eventCategories,
  // FOOD_AND_BEV_INTEREST_ID,
} from "../common/global.constants";
import OptionSelectControl from "../components/OptionsSelectControl";
import { VirtuosoHandle } from "react-virtuoso";
import { useResultsPageContext } from "../context/ResultsPageContext";
import { useLocationInfo } from "../context/LocationContext";
import { getDistanceFromLatLonInKm } from "../common/distance-utils";
import { getImageUrl, shareLogic } from "../common/functions";
import { useFoodandDrinks } from "../context/FoodAndDrinks";

import DiscoverGeneralComp from "../components/DiscoverGeneralComp";
import ForYouPlacesComp from "../components/ForYouPlacesComp";
import MapComp from "../components/MapComp";
import { useCollabListsContext } from "../context/CollabListsContext";
import useUpcomingLength from "../hooks/useUpcomingLength";
import ButtonControl from "../components/controls/ButtonControl";
import filterBystate from "../utils/filterBystate";
import LocationSelectModal from "../components/LocationSelectModal";
import SavedComponent from "../components/SavedComponent";
import PlacesComponent from "../components/PlacesComponent";
import ForyouComponent from "../components/ForyouComponent";
const LOCATIONS_STR = "locations";

export default function ResultsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [resultsData, setResultsData] = useState<AroundlyPlaces[]>([]);
  const [wishlistData, setWishlistData] = useState<AroundlyPlaces[]>([]);
  const [showTray, setShowTray] = useState(false);
  const location = useLocation();
  const updateTypeToShow =
    searchParams.get("update_type") || "general_update_generated";
  const query = searchParams.get("query");
  const resultsType = searchParams.get("results_type");
  const cuisineType = searchParams.get("cuisine");
  const interest = searchParams.get("interest");
  const foodType = searchParams.get("food_type");
  let searchText = searchParams.get("query");
  const UPDATE_HEIGHT = 300;
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<AroundlyPlaces[]>([]);
  const { userCurrentPosition } = useLocationInfo();
  const { data } = useFoodandDrinks();
  const {
    updates,
    selectedLocation,
    interests,
    lastSearchText,
    setLastSearchText,
    setPastselectedLocation,
    pastSelectedLocation,
    handleSearchText,
    toggleBeforeModal,
    onLocationSelect,
    locations,
  } = useFeedData();
  const [activeSection, setActiveSection] = useState<
    "saved" | "foryou" | "guide" | "places"
  >("foryou");

  useEffect(() => {
    handleSearchText(query as string);
  }, [handleSearchText, query]);

  const {
    setSearchResultsData,
    searchResultsData,
    pastUpdateType,
    setPastUpdateType,
  } = useResultsPageContext();
  const [savedrelevance, setsavedRelevance] = useState("relevance");

  const [placesrelevance, setplacesRelevance] = useState("relevance");

  const [selectedTime, setSelectedtime] = useState("week");
  const [isUpdateTypeOpen, setIsUpdateTypeOpen] = useState(false);
  const { authUser } = useAuth();

  const updateHeader = interests.find(
    (val) => val._id === interest
  )?.interest_name;
  const [isdeleteloading, setIsdeleteLoading] = useState(false);
  const [issaveloading, setIssaveLoading] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isUpdatesTab, setIsUpdatesTab] = useState(true);
  const [SavedBookmark, setIsSavedBookmark] = useState<CollabWishlistType>();
  const { collabLists, setSelectedWishlist, setCollabLists } =
    useCollabListsContext();
  const [forYouUpdatesWishlistData, setforYouUpdatesWishlistData] = useState<
    SocialMediaUpdate[]
  >([]);
  const [forYouUpdatesSearchData, setforYouUpdatesSearchData] = useState<
    SocialMediaUpdate[]
  >([]);
  const [isBest, setisBest] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");

  //api call for places search

  useEffect(() => {
    const fetchSearchResults = async () => {
      Mixpanel.track("Search Called", {
        text: searchText,
        location: selectedLocation,
        updateType: updateTypeToShow,
      });
      try {
        setIsLoading(true);

        const filteredUpdates = await similartySearchOnPosts(
          searchText as string,
          selectedLocation as LocationType,
          updateTypeToShow as UpdateType
        );

        setIsLoading(false);

        setResultsData(filteredUpdates);

        setSearchResultsData(filteredUpdates);
        setPastselectedLocation(selectedLocation as LocationType);
        setLastSearchText(searchText as string);
        setPastUpdateType(updateTypeToShow);
      } catch (e) {
        setIsLoading(false);
        setShowErrorModal(true);
      }
    };

    if (resultsType === "search" && searchText && selectedLocation) {
      if (
        lastSearchText !== searchText ||
        selectedLocation.location_name !==
          pastSelectedLocation?.location_name ||
        pastUpdateType !== updateTypeToShow
      ) {
        fetchSearchResults();
      } else {
        setResultsData(searchResultsData);
      }
    }
  }, [
    resultsType,
    searchText,
    selectedLocation,
    setSearchResultsData,
    searchResultsData,
    lastSearchText,
    setLastSearchText,
    setPastselectedLocation,
    pastSelectedLocation,
    pastUpdateType,
    setPastUpdateType,
    updateTypeToShow,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (resultsType === "wishlist") {
      // Do nothing
    } else if (resultsType === "search") {
      // Do nothing
    } else if (searchParams.get("food_type")) {
      if (foodType === "upcoming_event") {
        setResultsData(data?.upcoming_events as AroundlyPlaces[]);
      } else if (foodType === "Top rated") {
        setResultsData(data?.top_rated as AroundlyPlaces[]);
      } else if (foodType === "Trending now") {
        setResultsData(data?.trending_now as AroundlyPlaces[]);
      } else if (foodType === "Public favorites") {
        setResultsData(data?.public_favourites as AroundlyPlaces[]);
      } else if (foodType === "New additions") {
        setResultsData(data?.new_addition as AroundlyPlaces[]);
      }
    } else {
      setResultsData(updates);
    }
  }, [
    resultsType,
    updates,
    setResultsData,
    searchResultsData,
    interest,
    searchParams,
    data,
    foodType,
  ]);

  useEffect(() => {
    setSelectedWishlist(searchText as string);

    return () => {
      setSelectedWishlist("");
    };
  }, [searchText, setSelectedWishlist]);

  useEffect(() => {
    let tempData = resultsData;

    if (userCurrentPosition) {
      tempData = tempData?.map((place) => ({
        ...place,
        distance: getDistanceFromLatLonInKm(
          userCurrentPosition.coords.latitude,
          userCurrentPosition.coords.longitude,
          place.geo_location.coordinates[1],
          place.geo_location.coordinates[0]
        ),
      }));
    }

    if (cuisineType) {
      tempData = tempData.filter(
        (item) =>
          item.swiggy_dine_out_details &&
          item.swiggy_dine_out_details.cuisine &&
          item.swiggy_dine_out_details.cuisine
            .toLowerCase()
            .includes(cuisineType.toLowerCase())
      );
    }

    if (placesrelevance === "distance") {
      tempData = tempData.sort((a, b) => a.distance - b.distance);
    }

    setFilteredData(tempData);
  }, [resultsData, userCurrentPosition, placesrelevance, cuisineType]);

  useEffect(() => {
    const updates = filteredData.flatMap((place) => {
      const placeUpdates = place.updates || [];
      return placeUpdates.map((update) => ({
        ...update,
        related_aroundly_place_ids: [place],
      }));
    });

    let filteredUpdates: SocialMediaUpdate[] = updates.filter(
      (update) => update.update_type !== "general_update_generated"
    );

    if (updateTypeToShow === "event") {
      filteredUpdates = filterBystate(
        filteredUpdates,
        selectedTime,
        true,
        placesrelevance
      );
    }
    if (isBest) {
      filteredUpdates = filteredUpdates.filter((u) => u.is_best === true);
    }
    setforYouUpdatesSearchData(filteredUpdates);
  }, [filteredData, selectedTime, updateTypeToShow, placesrelevance, isBest]);

  useEffect(() => {
    const savedList = collabLists && collabLists?.find((u) => u.name === query);

    const places: AroundlyPlaces[] =
      savedList?.places.flatMap((val) => ({
        ...val.place_id,
        updates: val.updates.map((u) => u.update_id),
      })) || [];

    let tempData = places;

    if (userCurrentPosition) {
      tempData = tempData?.map((place) => ({
        ...place,
        distance: getDistanceFromLatLonInKm(
          userCurrentPosition.coords.latitude,
          userCurrentPosition.coords.longitude,
          place.geo_location.coordinates[1],
          place.geo_location.coordinates[0]
        ),
      }));
    }

    if (cuisineType) {
      tempData = tempData.filter(
        (item) =>
          item.swiggy_dine_out_details &&
          item.swiggy_dine_out_details.cuisine &&
          item.swiggy_dine_out_details.cuisine
            .toLowerCase()
            .includes(cuisineType.toLowerCase())
      );
    }

    if (savedrelevance === "distance") {
      tempData = tempData.sort((a, b) => a.distance - b.distance);
    }

    setWishlistData(tempData);
  }, [collabLists, userCurrentPosition, query, cuisineType, savedrelevance]);
  
  useEffect(() => {
    const updates = wishlistData.flatMap((place) => {
      const placeUpdates = place.updates || [];
      return placeUpdates.map((update) => ({
        ...update,
        related_aroundly_place_ids: [place],
      }));
    });

    let filteredUpdates: SocialMediaUpdate[] = updates.filter(
      (update) => update.update_type !== "general_update_generated"
    );

    if (updateTypeToShow === "event") {
      filteredUpdates = filterBystate(
        filteredUpdates,
        selectedTime,
        true,
        savedrelevance
      );
    }
    if (isBest) {
      filteredUpdates = filteredUpdates.filter((u) => u.is_best === true);
    }
    setforYouUpdatesWishlistData(filteredUpdates);
  }, [selectedTime, wishlistData, updateTypeToShow, savedrelevance, isBest]);

  const handleClose = () => {
    setShowTray(!showTray);
  };

  // const forYouUpdatesWishlistData = useForYouUpdates(wishlistData);
  // const forYouUpdatesSearchData = useForYouUpdates(filteredData);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrolledUpdates = Math.floor(scrollTop / UPDATE_HEIGHT);

      if (scrolledUpdates >= 8) {
        setShowTray(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onShare = async () => {
    try {
      // const URL_TO_SHARE = `${process.env.REACT_APP_FRONTEND_URL}${location.pathname}${location.search}`;

      // const copyResponse = copy(URL_TO_SHARE);
      // if (copyResponse) {
      //   toast.success("Copied. Share with a friend!");
      // }
      let URL_TO_SHARE = "";
      // let device_id = await getDeviceId();

      if (authUser) {
        let referral_id = authUser.referal_id;
        URL_TO_SHARE = `${process.env.REACT_APP_FRONTEND_URL}${
          location.pathname
        }?${searchParams.toString()}?referral_id=${referral_id}`;
      } else {
        URL_TO_SHARE = `${process.env.REACT_APP_FRONTEND_URL}${
          location.pathname
        }?${searchParams.toString()}`;
      }

      const shareText = `Hey! I have discovered this on Aroundly.`;
      await shareLogic(shareText, URL_TO_SHARE);
    } catch (e) {
      console.log("Unable to share the post");
    }
  };

  const imageUrl = getImageUrl(filteredData);

  const [, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const virtuoso = useRef<VirtuosoHandle | null>(null);

  useEffect(() => {
    const postId = localStorage.getItem("postId");
    if (postId && virtuoso.current) {
      let index = -1;
      index = filteredData?.findIndex((post) => post._id === postId);
      if (index !== -1) {
        setTimeout(() => {
          virtuoso.current?.scrollToIndex({ index });
          localStorage.removeItem("postId");
        }, 500);
      }
    }
  }, [filteredData, resultsType]);

  const [isFirstItemVisible] = useState(true);

  // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
  //   const scrollTop = (event.currentTarget as HTMLDivElement).scrollTop;
  //   let itemHeight = 300;
  //   if (searchParams.get("results_type") === "search") {
  //     itemHeight = 100;
  //   }
  //   setIsFirstItemVisible(scrollTop < itemHeight);
  // };

  const navigator = () => {
    if (resultsType === "search") {
      if (authUser === undefined) {
        navigate("/");
      } else {
        navigate(`/app/feed`);
      }
      handleSearchText("");
    } else if (cuisineType) {
      navigate(-1);
    } else {
      if (authUser === undefined) {
        navigate("/");
      } else {
        navigate(-1);
      }
    }
  };

  const handleCreateList = async () => {
    setIssaveLoading(true);
    try {
      const data = await createList(query as string, "", "", true);
      if (data.new?._id) {
        Mixpanel.track("Results List Created", {
          from_update_id: "",
          from_place_id: "",
          wishlist_id: data.new._id,
        });

        setCollabLists([...collabLists, data.new]);
        toast.success(`A plan for "${query}" is created`);
        setIssaveLoading(false);
      }
    } catch (e) {
      toast.error("Could not Create Wishlist");
      console.log(e);
      setIssaveLoading(false);
    }
  };

  const handleDeleteList = async () => {
    setIsdeleteLoading(true);

    try {
      const data = await deleteList(SavedBookmark?._id as string);
      if (data && data.length > 0) {
        setCollabLists([...data]);

        Mixpanel.track("Collaborative List Deleted", {
          wishlist_id: SavedBookmark?._id as string,
        });
        toast.success("Wishlist Deleted");
        setIsdeleteLoading(false);
      }
    } catch (e) {
      toast.error("Could not Update Wishlist");
      console.log(e);
      setIsdeleteLoading(false);
    }
  };

  useEffect(() => {
    setIsSavedBookmark(
      collabLists?.filter((val) => val.name === searchText)[0]
    );
  }, [collabLists, searchText]);

  const [iswhite, setIsBGWhite] = useState(false);
  // const stickyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsBGWhite(window.scrollY > 275);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const { width: screenWidth } = window.screen;
    setWidth(screenWidth);
  }, []);

  const upcomingLength = useUpcomingLength(forYouUpdatesWishlistData);

  return (
    <>
      {
        <div className="min-h-screen bg-slate-100">
          <div
            className={` ${
              isFirstItemVisible || interest ? "" : "hidden"
            }  max-w-md w-full  fixed items-center top-0  ${
              isUpdateTypeOpen ? "z-20" : "z-10"
            }`}
          >
            <div
              className={` flex   ${
                resultsType === "search" ||
                (updateTypeToShow === "event" && interest !== null) ||
                cuisineType
                  ? ""
                  : "hidden"
              } ${
                iswhite ? "bg-white" : ""
              }  items-center justify-between gap-1 p-3  w-full`}
            >
              {/* <div className="bg-white border border-solid border-[#DCDEE3] flex p-2 gap-2 rounded-xl h-[50px] items-center text-center w-full">
                <div onClick={() => navigate(-1)} className="flex-shrink-0">
                  <ChevronLeftIconSolid className="h-6 w-6" />
                </div>

                <OptionSelectControl
                  selectedId={searchParams.get("update_type") || ""}
                  defaultText="Eat"
                  listHeading=""
                  items={[
                    {
                      id: "review",
                      name: "Eat",
                    },
                    {
                      id: "event",
                      name: "Experiences",
                    },
                  ]}
                  onListSelect={(id) => {
                    Mixpanel.track(`Updatetype Clicked`, {
                      old_option: searchParams.get("update_type") || "",
                      new_option: id,
                      ui_type: "results-page-updatetype-filter",
                    });
                    searchParams.set("update_type", id);
                    setSearchParams(searchParams);
                    window.scrollTo(0, 0);
                  }}
                />

                <div className="grow ">
                  {(resultsType === "search" ||
                    cuisineType ||
                    interest !== undefined) && (
                    <div className="w-full">
                      <SearchButton
                        location="results"
                        query={
                          updateTypeToShow === "event" &&
                          resultsType !== "search"
                            ? "Events & Experiences"
                            : (searchText as string)
                        }
                      />
                    </div>
                  )}
                </div>

                <div
                  onClick={() => {
                    toggleModal(true);
                    handleSearchText("");
                    searchParams.delete("query");
                    setSearchParams(searchParams);
                  }}
                  className="flex-shrink-0 cursor-pointer"
                >
                  <XMarkIconSolid className="h-5 w-5    text-[#9CAAC6]" />
                </div>
              </div> */}
              <div className="bg-white border border-solid border-[#DCDEE3] flex p-2 gap-2 rounded-xl h-[50px] items-center text-center w-full">
                <ChevronLeftIconSolid
                  className="h-6 w-6 inline-block  cursor-pointer flex-shrink-0"
                  onClick={() => {
                    Mixpanel.track("Back Button Clicked", {
                      ui_type: "moods-before-modal",
                    });
                    navigate("/app/feed");
                  }}
                />

                <OptionSelectControl
                  selectedId={searchParams.get("update_type") || ""}
                  defaultText="Eat"
                  listHeading=""
                  items={[
                    {
                      id: "review",
                      name: "Eat",
                    },
                    {
                      id: "event",
                      name: "Experiences",
                    },
                  ]}
                  onClick={() => {
                    setIsUpdateTypeOpen(true);
                  }}
                  onDismiss={() => {
                    setIsUpdateTypeOpen(false);
                  }}
                  onListSelect={(id) => {
                    Mixpanel.track(`Updatetype Clicked`, {
                      old_option: searchParams.get("update_type") || "",
                      new_option: id,
                      ui_type: "results-page-updatetype-filter",
                    });
                    setIsUpdateTypeOpen(false);
                    searchParams.set("update_type", id);
                    setSearchParams(searchParams);
                    window.scrollTo(0, 0);
                  }}
                />

                <div className="grow ">
                  {(resultsType === "search" ||
                    cuisineType ||
                    interest !== undefined) && (
                    <div className="w-full">
                      <SearchButton
                        location="results"
                        query={
                          updateTypeToShow === "event" &&
                          resultsType !== "search"
                            ? "Events & Experiences"
                            : (searchText as string)
                        }
                      />
                    </div>
                  )}
                </div>
                {/* <div
                  onClick={() => {
                    toggleModal(true);
                    handleSearchText("");
                    searchParams.delete("query");
                    setSearchParams(searchParams);
                  }}
                  className="flex-shrink-0 cursor-pointer"
                >
                  <XMarkIconSolid className="h-5 w-5    text-[#9CAAC6]" />
                </div> */}
              </div>

              {/* <button
                onClick={() => {
                  Mixpanel.track("Share Clicked", {
                    location: selectedLocation?.location_id,
                    url: location.pathname + location.search,
                    share_type: "search-results",
                    query,
                  });
                  SavedBookmark ? handleDeleteList() : handleCreateList();
                }}
                className={`p-3   rounded-xl  items-center bg-white border border-solid border-[#DCDEE3] `}
              >
                {SavedBookmark ? (
                  <BookmarkIconSolid className="h-6 w-6  text-[#007AFF]" />
                ) : (
                  <BookmarkIcon className="h-6 w-6  text-black" />
                )}
              </button> */}

              <ButtonControl
                buttonId="save-unsave-button"
                size="lg"
                theme={"white"}
                // isLoading={isdeleteloading || issaveloading}
                icon={{
                  component: SavedBookmark ? BookmarkIconSolid : BookmarkIcon,
                }}
                disabled={isdeleteloading || issaveloading}
                iconColor={SavedBookmark ? "special-blue" : "black"}
                onClick={() => {
                  Mixpanel.track("Share Clicked", {
                    location: selectedLocation?.location_id,
                    url: location.pathname + location.search,
                    share_type: "search-results",
                    query,
                  });
                  SavedBookmark ? handleDeleteList() : handleCreateList();
                }}
              />
              <ButtonControl
                buttonId=""
                size="lg"
                theme="white"
                name=""
                icon={{ component: ShareIcon }}
                iconPlacement="end"
                onClick={() => {
                  Mixpanel.track("Share Clicked", {
                    location: selectedLocation?.location_id,
                    url: location.pathname + location.search,
                    share_type: "search-results",
                    query,
                  });
                  onShare();
                }}
              />
            </div>

            <div
              className={` flex   ${
                resultsType === "search" ||
                interest ||
                updateTypeToShow === "event"
                  ? "hidden"
                  : ""
              }  items-center p-3 border-b`}
            >
              <button
                onClick={navigator}
                className="cursor-pointer flex-shrink-0"
              >
                <ArrowLeftIcon className="h-6 w-6 inline-block" />
              </button>

              <div className="grow px-3">
                {/* {isBest === "true" && <div>Handpicked for you</div>} */}

                {resultsType !== "search" && isBest !== true && (
                  <div>Explore</div>
                )}

                {resultsType === "category" && !cuisineType && (
                  <div>
                    {
                      interests?.filter((i) => i._id === interest)[0]
                        ?.interest_name
                    }
                  </div>
                )}
              </div>
              {resultsType !== "wishlist" && (
                <button
                  onClick={() => {
                    Mixpanel.track("Share Clicked", {
                      location: selectedLocation?.location_id,
                      url: location.pathname + location.search,
                      share_type: "search-results",
                      query,
                    });
                    onShare();
                  }}
                >
                  <ShareIcon className="h-6 w-6 inline-block text-black" />
                </button>
              )}
            </div>

            <DiscoverGeneralComp
              eventCategories={eventCategories}
              interest={interest}
              resultsType={resultsType as string}
              updateTypeToShow={updateTypeToShow}
              imageUrl={imageUrl}
              toggleBeforeModal={toggleBeforeModal}
              cuisineType={cuisineType as string}
              updateHeader={updateHeader as string}
            />
          </div>
          {!interest && <MapComp height={260} />}

          {isLoading && (
            <div className="min-h-[calc(100vh-184px)] relative m-auto flex justify-start items-start mt-20">
              <LoadingComp />
            </div>
          )}

          {!isLoading && (
            <div className="bg-white sticky top-[64px] z-10  py-1 ">
              <ForYouPlacesComp
                tabsToShow={[
                  { name: "Saved", id: "saved", showDot: false },
                  { name: "For You", id: "foryou", showDot: false },
                  { name: "Places", id: "places", showDot: false },
                ]}
                activeTabId={activeSection}
                setActiveTab={(tab) => {
                  Mixpanel.track("Tab Clicked", {
                    ui_type: tab.id,
                    has_red_dot: tab.showDot,
                    page: "results-page",
                  });
                  setActiveSection(tab.id);
                }}
              />
            </div>
          )}

          {!isLoading && activeSection === "foryou" && (
            <ForyouComponent
              interests={interests}
              isBest={isBest}
              isLoading={isLoading}
              isUpdatesTab={isUpdatesTab}
              lastSearchText={lastSearchText}
              relevance={placesrelevance}
              resultsType={resultsType}
              searchText={searchText}
              selectedTime={
                updateTypeToShow === "event" ? selectedTime : undefined
              }
              setIsUpdatesTab={setIsUpdatesTab}
              setRelevance={setplacesRelevance}
              setSelectedtime={setSelectedtime}
              setisBest={setisBest}
              updateTypeToShow={updateTypeToShow}
              width={width}
              showErrorModal={showErrorModal}
              forYouUpdatesSearchData={forYouUpdatesSearchData}
              activeSection={activeSection}
              showhandpicked={updateTypeToShow === "event"}
            />
          )}

          {!isLoading && activeSection === "places" && (
            <PlacesComponent
              interests={interests}
              isBest={isBest}
              isLoading={isLoading}
              isUpdatesTab={isUpdatesTab}
              lastSearchText={lastSearchText}
              relevance={placesrelevance}
              resultsType={resultsType}
              searchText={searchText}
              setIsUpdatesTab={setIsUpdatesTab}
              setRelevance={setplacesRelevance}
              setisBest={setisBest}
              updateTypeToShow={updateTypeToShow}
              width={width}
              filteredData={filteredData}
              showErrorModal={showErrorModal}
            />
          )}

          {!isLoading && activeSection === "saved" && (
            <SavedComponent
              forYouUpdatesWishlistData={forYouUpdatesWishlistData}
              interests={interests}
              isBest={isBest}
              isLoading={isLoading}
              isUpdatesTab={isUpdatesTab}
              lastSearchText={lastSearchText}
              relevance={savedrelevance}
              resultsType={resultsType}
              searchText={searchText}
              selectedTime={
                updateTypeToShow === "event" ? selectedTime : undefined
              }
              setIsUpdatesTab={setIsUpdatesTab}
              setRelevance={setsavedRelevance}
              setSelectedtime={setSelectedtime}
              setisBest={setisBest}
              updateTypeToShow={updateTypeToShow}
              width={width}
              wishlistData={wishlistData}
              upcomingLength={upcomingLength}
              showhandpicked={true}
            />
          )}
        </div>
      }

      {selectedTab === LOCATIONS_STR && (
        <LocationSelectModal
          onClose={() => setSelectedTab("")}
          selectedLocationOption={selectedLocation?.location_id}
          locationsList={locations}
          onSubmit={(locationDetails) => {
            onLocationSelect(locationDetails);
            setSelectedTab("");
          }}
        />
      )}
      {!authUser && <BottomTray showTray={showTray} toggle={handleClose} />}
    </>
  );
}
