import React from "react";
import { AroundlyPlaces } from "../common/global.types";
import AroundlyPlaceComp from "./AroundlyPlaceComp";
import ErrorModal from "./ErrorModal";
import FooterComp from "./FooterComp";
import EmptySave from "./EmptySave";

interface PlacesTabProps {
  data: AroundlyPlaces[];
  showError?: boolean;
}

const PlacesTab: React.FC<PlacesTabProps> = ({ data, showError }) => {
  if (showError) {
    return <ErrorModal />;
  }
  return (
    <div className="w-full max-w-md flex flex-col gap-2">
      {data?.map((data, index) => (
        <div key={index} className="px-2">
          <AroundlyPlaceComp placeDetails={data} />
        </div>
      ))}
      {data.length > 0 ? (
        <FooterComp showDateFooter={false} />
      ) : (
        <div className="mx-3">
          <EmptySave />
        </div>
      )}
    </div>
  );
};

export default PlacesTab;
