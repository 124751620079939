import { memo } from "react";

const Badge = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        letterSpacing: "-0.36px",
      }}
      className="whitespace-nowrap bg-[#F0FFFA] text-xs text-secondary-text font-450  leading-4 rounded-full px-1 border-[0.5px] border-[#DCDEE3]"
    >
      {text}
    </div>
  );
};

export default memo(Badge);
