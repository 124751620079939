import { pageview } from "../services/gtag";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GtagPathView() {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);

  return <div className="hidden"></div>;
}
