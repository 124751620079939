import Tabs from "../components/Tabs";
import {
  BookmarkIcon,
  MapPinIcon,
  UserIcon,
  HomeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  RssIcon as RssIconSolid,
  BookmarkIcon as BookmarkIconSolid,
  MagnifyingGlassIcon as MagnifyingGlassIconSolid,
  MapPinIcon as MapPinIconSolid,
  HomeIcon as HomeIconSolid,
  UserIcon as UserIconSolid,
} from "@heroicons/react/24/solid";
import { Outlet } from "react-router-dom";

const FEED_TAB = "explore";
const SEARCH = "search";

// const PLACES_TAB = "places";
const WISHLIST_TAB = "wishlist";
const PROFILE_TAB = "profile";
const NAV_TABS = [
  // {
  //   name: "HOME",
  //   id: FEED_TAB,
  //   url: "/app/feed",
  //   selected: HomeIconSolid,
  //   unselected: HomeIcon,
  // },
  // {
  //   name: "SEARCH",
  //   id: SEARCH,
  //   url: "/app/feed/search",
  //   selected: MagnifyingGlassIconSolid,
  //   unselected: MagnifyingGlassIcon,
  // },
  // {
  //   name: "PROFILE",
  //   id: WISHLIST_TAB,
  //   url: "/app/feed/wishlist",
  //   selected: UserIconSolid,
  //   unselected: UserIcon,
  // },
  // {
  //   name: "PROFILE",
  //   id: PROFILE_TAB,
  //   url: "/app/feed/profile",
  //   selected: UserIconSolid,
  //   unselected: UserIcon,
  // },
];

export default function FeedPage() {
  return (
    <>
      <Outlet />
      <Tabs tabsList={[]} />
    </>
  );
}
