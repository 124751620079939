import {
  AroundlyPlaces,
  InterestProps,
  SocialMediaUpdate,
} from "../common/global.types";
import EmptySave from "./EmptySave";
import Filters from "./Filters";
import MasonryComponent from "./MasonryComponent";
import PlacesTab from "./PlacesTab";
import SavedToggle from "./SavedToggle";

interface Props {
  isLoading?: boolean;
  forYouUpdatesWishlistData: SocialMediaUpdate[];
  isUpdatesTab: boolean;
  wishlistData: AroundlyPlaces[];
  width: number;
  selectedTime?: string;
  setSelectedtime?: React.Dispatch<React.SetStateAction<string>>;
  updateTypeToShow: string;
  lastSearchText?: string;
  searchText?: string | null;
  interests: InterestProps[];
  setIsUpdatesTab: React.Dispatch<React.SetStateAction<boolean>>;
  relevance: string;
  setRelevance: React.Dispatch<React.SetStateAction<string>>;
  isBest: boolean;
  setisBest: React.Dispatch<React.SetStateAction<boolean>>;
  resultsType: string | null;
  upcomingLength: number;
  showError?: boolean;
  isResults?: boolean;
  showhandpicked?: boolean;
}

const SavedComponent = ({
  isLoading,
  forYouUpdatesWishlistData,
  isUpdatesTab,
  wishlistData,
  width,
  selectedTime,
  setSelectedtime,
  updateTypeToShow,
  resultsType,
  searchText,
  interests,
  setIsUpdatesTab,
  relevance,
  setRelevance,
  isBest,
  setisBest,
  lastSearchText,
  upcomingLength,
  showError,
  isResults = false,
  showhandpicked,
}: Props) => {
  return (
    <div>
      {wishlistData?.length > 0 && (
        <div
          className={`pl-3  sticky py-2 top-[107px] z-10 mb-2 ${
            resultsType === "search" &&
            lastSearchText !== searchText &&
            "hidden"
          }  bg-white w-full  z-0`}
        >
          <Filters
            interests={interests}
            resultsType={resultsType}
            updateTypeToShow={updateTypeToShow}
            istemplate={isUpdatesTab}
            setIsTemplate={setIsUpdatesTab}
            relevance={relevance}
            setRelevance={setRelevance}
            selectedTime={isUpdatesTab ? selectedTime : ""}
            setSelectedtime={setSelectedtime}
            isBest={isBest}
            setIsbest={setisBest}
            showhandpicked={showhandpicked && isUpdatesTab}
          />
        </div>
      )}
      {wishlistData?.length > 0 && (
        <SavedToggle
          istemplate={isUpdatesTab}
          setIsTemplate={setIsUpdatesTab}
          places={wishlistData?.length || 0}
          upcoming={upcomingLength}
          updates={forYouUpdatesWishlistData?.length || 0}
        />
      )}
      {!isLoading && (
        <>
          {wishlistData?.length > 0 ? (
            <div className="w-full max-w-md flex flex-col gap-2">
              {!isUpdatesTab ? (
                <PlacesTab data={wishlistData} showError={showError} />
              ) : (
                <MasonryComponent
                  updates={forYouUpdatesWishlistData}
                  isResults={isResults}
                  selectedTime={selectedTime}
                  setSelectedtime={setSelectedtime}
                  showDateFooter={updateTypeToShow === "event" ? true : false}
                  showErrorModal={showError}
                />
              )}
            </div>
          ) : (
            <div className="mx-3">
              <EmptySave />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default SavedComponent;
