const ErrorModal = () => {
  return (
    <div className="flex items-center justify-center  my-4 mx-3">
      <div className="w-full  max-w-lg px-10 mx-auto bg-white rounded-lg ">
        <div className="max-w-md mx-auto space-y-6">
          <div className=" px-4 mx-auto ">
            <div className="mx-auto max-w-screen-sm text-center">
              <p className="mb-4 text-3xl tracking-tight font-bold  ">
                Oops!
              </p>
              <p className="mb-4 text-lg font-light  ">
                We have an issue and our team is intimated about it. Thank you
                for your patience. Do come back in 10mins and check what’s
                happening here!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorModal;
