interface ParagraphProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

export default function Paragraph({ children, ...rest }: ParagraphProps) {
  return (
    <p
      // style={{ letterSpacing: "-3%" }}
      className={`leading-[140%] mt-3 mb-6  text-zinc-700 tracking-tight text-xl`}
      {...rest}
    >
      {children}
    </p>
  );
}
