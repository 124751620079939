import Masonry from "react-responsive-masonry";
import LoadingComp from "./LoadingComp";
import { NO_OF_COLUMNS } from "../common/global.constants";
import PastFeedCard from "./PastFeedCard";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../context/auth";
import { useFeedData } from "../context/FeedDataContext";
import { EmptyCard } from "./Emptycard";
import {
  AroundlyPlaces,
  CollabWishlistType,
  SocialMediaUpdate,
  UpdateType,
} from "../common/global.types";
import GetNotified from "./GetNotified";
import { useCapacitorInfo } from "../context/CapacitorContext";
import { useDeviceNotificationsInfo } from "../context/DeviceNotificationsContext";
import ErrorModal from "./ErrorModal";
import FooterComp from "./FooterComp";
import EventShowMore from "./EventShowMore";
import EmptyMasonryCard from "./EmptyMasonryCard";
import { useEffect, useRef, useState } from "react";

const MasonryComponent = ({
  ispageScrolled,
  updates,

  selectedCollabListData,
  showErrorModal,
  placeData,
  isResults = false,
  activeSection,
  showlessData,
  setShowlessdata,
  setSelectedtime,
  selectedTime,
  updateType,
  showDateFooter,
}: {
  selectedCollabListData?: CollabWishlistType;
  ispageScrolled?: boolean;
  updates: SocialMediaUpdate[];

  showErrorModal?: boolean;
  placeData?: AroundlyPlaces;
  isResults?: boolean;
  activeSection?: "foryou" | "saved" | "guide" | "places";
  setShowlessdata?: (state: boolean) => void;
  showlessData?: boolean;
  setSelectedtime?: (status: string) => void;
  selectedTime?: string;
  updateType?: UpdateType;
  showDateFooter?: boolean;
}) => {
  const { selectedLocation } = useFeedData();
  const { isNative } = useCapacitorInfo();
  const { shouldShowNotificationsPopUp, handleGetNotifiedClick } =
    useDeviceNotificationsInfo();

  const [width, setWidth] = useState(0);
  const masonryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (masonryRef.current) {
      const masonryDiv = masonryRef.current.offsetWidth;
      setWidth(masonryDiv);
    }
  }, []);

  if (showErrorModal) {
    return <ErrorModal />;
  }
  return (
    <div ref={masonryRef} className=" bg-slate-100 pb-3">
      {updates && updates.length > 0 ? (
        <Masonry
          columnsCount={NO_OF_COLUMNS}
          className="px-3 pb-3"
          gutter="12px"
        >
          {updates.map((value, index) => (
            <PastFeedCard
              key={index}
              home={true}
              isfullview={false}
              showFixedHeight
              container_width={
                (width - 24 - (NO_OF_COLUMNS - 1) * 12) / NO_OF_COLUMNS
              }
              isLast={false}
              item={value}
              updateType={value.update_type}
              placeData={
                (value.related_aroundly_place_ids
                  ? value.related_aroundly_place_ids[0]
                  : placeData) as AroundlyPlaces
              }
              timezone={selectedLocation?.timezone as string}
            />
          ))}
        </Masonry>
      ) : (
        <EmptyMasonryCard
          text={
            isResults
              ? "Nothing matches your query."
              : "Save updates to this plan to start seeing personalised recommendations."
          }
        />
      )}

      {activeSection === "foryou" && updates.length > 0 && (
        <EventShowMore
          setShowlessdata={setShowlessdata as (state: boolean) => void}
          showlessData={showlessData as boolean}
          location={selectedLocation?.location_name as string}
        />
      )}
      {updates && updates.length > 0 && (
        <FooterComp
          setSelectedtime={setSelectedtime}
          selectedTime={selectedTime}
          isEvent={updateType ? true : false}
          activeSection={activeSection}
          showDateFooter={showDateFooter}
        />
      )}
      {isNative && shouldShowNotificationsPopUp ? (
        <div className="mx-3 mb-2">
          <GetNotified onBtnClick={handleGetNotifiedClick} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default MasonryComponent;
