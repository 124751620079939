import { useEffect, useState } from "react";
import Footer from "../components/newFooter";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import Paragraph from "../components/paragraph";
import GetAroundlyInput from "../components/getAroundlyInput";
import { HeaderWithLogoAndName } from "../components/HeaderLogoWithName";
import GetTheApp from "../components/GetTheApp";
import { useCapacitorInfo } from "../context/CapacitorContext";
import { shareLogic } from "../common/functions";
import GetItOnContainer from "../components/GetItOnContainer";
import moment from "moment";
import {
  ArrowLongRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { imageName } from "../common/global.constants";

const scrollLogos = [
  "scroll - allevents",
  "scroll - bookmyshow",
  "scroll - dineout",
  "scroll - eazy diner",
  "scroll - highape",
  "scroll - insider",
  "scroll - instagram",
  "scroll - magicpin",
  "scroll - meetup",
  "scroll - nearbuy",
  "scroll - things2do",
  "scroll - trove",
  "scroll - urbanaut",
  "scroll - zomato",
];

const PreRegistrationComplete = ({ referrerId }: { referrerId: string }) => {
  const releaseDate = "03/07/2024";
  const referralLink = `https://myaroundly.com/s/${referrerId}`;

  const copyLink = `Hey there! I found out Aroundly, an app that helps us discover the best places and events in our city. You can create wishlists, get real-time updates, and step out together!
Click the link below to join me and get started:
👉 https://myaroundly.com/s/${referrerId}
Let’s explore, save our favorite spots, and plan our evenings together! 🎉 `;

  const daysDifference = moment(releaseDate, "DD/MM/YYYY").diff(
    moment(),
    "days"
  );
  const { authUser } = useAuth();
  return (
    <div className="px-3 py-3  overflow-x-hidden">
      {/* <div className="">
        <div
          style={{
            lineHeight: "120%",
            letterSpacing: "-0.72px",
          }}
        >
          <span className="text-2xl font-medium leading-normal">
            Thanks for joining! Aroundly is
          </span>
          <br />

          <span className=" text-2xl font-medium leading-normal">
            coming in{" "}
          </span>
          <span className="text-[#00B078] text-2xl font-medium leading-normal">
            {" "}
            {Math.abs(daysDifference)} days🎉
          </span>

          <div className="text-secondary-text mt-3 text-xs font-medium">
            We’ll send the Play Store or App Store link to your registered
            <br />
            number on <span className="text-[#00B078]">July 3rd 9:00 AM</span>
          </div>
        </div>
      </div> */}
      <div className="mb-8 relative z-40 bg-[#EBB376] rounded-xl bg-opacity-20 p-3">
        <div
          className="text-[32px] font-bold pb-4"
          style={{
            letterSpacing: "-0.96px",
          }}
        >
          Welcome
          <br />
          to Aroundly 🎉
        </div>
        {/* isonboardingenabled */}
        {authUser?.is_onboarding_enabled ? (
          <div className="flex flex-col gap-4">
            <p
              className="text-xl font-medium"
              style={{ letterSpacing: "-0.6px" }}
            >
              You are one of our early users we opened access to.
            </p>
            <div className="relative w-full aspect-video h-80">
              <iframe
                className="absolute top-0 left-0 w-full h-full rounded-xl z-10"
                src="https://www.youtube.com/embed/WQ9JFDon30Q?autoplay=1&mute=0&controls=0&modestbranding=1&rel=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                frameBorder="0"
              ></iframe>
            </div>
            <div className="flex flex-col gap-2">
              <p
                className="text-special-blue text-xs  font-bold"
                style={{ letterSpacing: "-0.36px" }}
              >
                Click the app or playstore below to install
              </p>
              <GetItOnContainer
                isClickable={Boolean(authUser)}
                handleOnClick={() => window.scrollTo(0, 0)}
              />
            </div>
            <p
              className="text-base font-450 leading-8"
              style={{ letterSpacing: "-0.48px" }}
            >
              Thank you for joining as Aroundly’s early folks.
            </p>
          </div>
        ) : (
          <div>
            <p
              className="text-xl font-medium "
              style={{ letterSpacing: "-0.6px" }}
            >
              You are queued up at <span className="text-[#00B078]">834</span>
            </p>
            <p
              className="text-secondary-text text-base font-450 my-1"
              style={{ letterSpacing: "-0.48px" }}
            >
              Est. time to access:{" "}
              <span className="text-[#00B078]">1 week</span>{" "}
            </p>
            <p
              className="text-[#9CAAC6] text-[10px] font-450 "
              style={{ letterSpacing: "-0.3px" }}
            >
              Refresh the page to get the latest status
            </p>
            <p
              className="text-base font-450 leading-8 my-4"
              style={{ letterSpacing: "-0.48px" }}
            >
              Why the queue? We are building a new way of stepping out. And we
              are doing that by adding people gradually in the waitlist, so we
              make sure we give a smooth experience.
            </p>
            <p
              className="text-base font-450 leading-8 my-4"
              style={{ letterSpacing: "-0.48px" }}
            >
              Thank you for joining as one of Aroundly’s early members. We will
              personally drop a Whatsapp message when we open access for you.
            </p>
          </div>
        )}
        <div>
          <p
            className="text-base font-450 leading-8"
            style={{ letterSpacing: "-0.48px" }}
          >
            Happy discovering!
          </p>
        </div>
        <div
          className="text-base font-450 leading-8 mt-4"
          style={{ letterSpacing: "-0.48px" }}
        >
          - Team Aroundly
        </div>
      </div>

      {/* <div className="mt-3">
        <MissedRegistration isMissed />
      </div> */}
    </div>
  );
};

const Section1 = () => {
  const { isNative } = useCapacitorInfo();
  const { authUser } = useAuth();
  const [showRedirectionText, setShowRedirectionText] = useState(false);
  return (
    <div className="py-8">
      <div
        className="text-[32px] font-bold leading-[40px]  mx-5"
        style={{
          letterSpacing: "-0.96px",
        }}
      >
        Still searching, scrolling, or switching apps to go out?
      </div>
      <Paragraph className=" text-base mt-3  font-400  mx-5">
        <b>Introducing Aroundly.</b> Your one app to discover <b>latest food</b>{" "}
        🍕 and <b>events</b> 🌟 around you from all of web{" "}
        <b>into simple and shareable lists</b>
      </Paragraph>
      <div className="scrollbar-hide overflow-x-hidden mt-6">
        <div className=" flex items-center gap-2 animate-infinite-scroll ">
          {scrollLogos.map((val, index) => (
            <img
              alt={val}
              src={`/images/scroll-logos/${val}.png`}
              className="h-4 w-4"
              key={index}
            />
          ))}
          {[0, 1].map((t) =>
            scrollLogos.map((val, index) => (
              <img
                alt={val}
                src={`/images/scroll-logos/${val}.png`}
                className="h-4 w-4"
                key={index}
              />
            ))
          )}
        </div>
      </div>
      <div className="relative  z-10 flex flex-col items-center overflow-x-hidden bg-clip-content my-6">
        <div className="scrollbar-hide flex items-center gap-2 animate-infinite-scroll">
          {imageName.map((value, index) => (
            <img
              className="h-auto w-auto max-h-44"
              key={index}
              alt="card"
              src={`/images/${value}.png`}
            />
          ))}
          {[0, 1, 2, 3].map((t) =>
            imageName.map((value, index) => (
              <img
                className="h-auto w-auto max-h-44"
                alt="card0"
                key={index}
                src={`/images/${value}.png`}
              />
            ))
          )}
        </div>
      </div>
      <div className=" mx-5">
        <GetAroundlyInput title={isNative ? "CONTINUE" : "JOIN BETA"} />
      </div>
      <div className="mt-8 mb-16 mx-5">
        <GetItOnContainer
          isAlignLeft
          isClickable={Boolean(authUser)}
          handleOnClick={() => setShowRedirectionText(true)}
        />
        {showRedirectionText && (
          <div className="flex items-start mt-3">
            <InformationCircleIcon className="h-6 w-6 mr-1" />{" "}
            <div className="text-xs font-medium">
              We’ll send the Play Store or App Store link to your
              <br /> registered number on
              <span className="text-primary"> July 3rd 9:00 AM</span>
            </div>
          </div>
        )}
      </div>
      <div className="mt-8 relative z-40 bg-[#EBB376] rounded-xl bg-opacity-20 p-3 mx-2">
        <div
          className="text-[32px] mb-3 font-bold "
          style={{
            letterSpacing: "-0.96px",
          }}
        >
          “Make <span className="text-special-blue">Plans</span>
          <br />
          out of the group chat”
          <br />
        </div>
        <div
          className="text-base font-medium mt-3 mb-6"
          style={{
            letterSpacing: "-0.48px",
          }}
        >
          Like Spotify playlists, save food & events you discovered into lists
          with friends that daily auto-update with new recommendations
        </div>

        <img
          alt="aroundly categories"
          src="/images/Aroundly Categories Image.png"
        />
      </div>
      {/* <GoToWhatsappButton /> */}
    </div>
  );
};

const Section2 = () => {
  const productDemoImagesfirst = ["01", "02", "03", "04"];
  const productDemoImagessecond = ["05", "06", "07", "08"];

  return (
    <>
      <div
        className=" text-2xl items-center  font-bold flex mx-5"
        style={{
          letterSpacing: "-0.96px",
        }}
      >
        <p className="mr-2">What’s Aroundly?</p>
        <div className="">
          <ArrowLongRightIcon className="h-12 w-12" />
          {/* <span className="text-xs text-secondary-text ">swipe</span> */}
        </div>
      </div>
      <div className="flex items-center gap-5 px-5 py-5 overflow-x-auto scrollbar-hide">
        {productDemoImagesfirst.map((i, index) => (
          <img
            src={`/images/product-demo/${i}.png`}
            alt={`product demo ${i}`}
            className="h-[520px]"
            key={index}
          />
        ))}
      </div>

      <div
        className=" text-2xl items-center mt-7  font-bold flex mx-5"
        style={{
          letterSpacing: "-0.96px",
        }}
      >
        <p className="mr-2 ">Why is it cooler?</p>
        <div className="">
          <ArrowLongRightIcon className="h-12 w-12" />
          {/* <span className="text-xs text-secondary-text ">swipe</span> */}
        </div>
      </div>
      <div className="flex items-center gap-5 px-5 pt-5 pb-16  overflow-x-auto scrollbar-hide">
        {productDemoImagessecond.map((i, index) => (
          <img
            src={`/images/product-demo/${i}.png`}
            alt={`product demo ${i}`}
            className="h-[520px]"
            key={index}
          />
        ))}
      </div>
    </>
  );
};

const Section3 = () => {
  return (
    <div className="relative ">
      <img
        src="/images/aroundly-login-bg.gif"
        // style={{
        //   objectFit: "cover",
        //   width: "100%",
        //   height: "100%",
        // }}
        className="object-cover h-dvh"
        alt="aroundly-login-bg"
      />
      <div
        className="absolute bottom-0 left-0 text-white h-full w-full flex flex-col justify-center gap-14 px-5"
        style={{
          background: `linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)`,
        }}
      >
        <div className="text-white text-[40px] font-medium leading-[48px] tracking-tighter1">
          Everyone talks <br /> about cancelled <br /> Goa plans
        </div>
        <div className="text-white text-[40px] font-medium leading-[48px] tracking-tighter1">
          What about <br /> evening plans? <br /> breakfast plans? <br /> or
          just... plans?
        </div>
        <div className="font-medium tracking-tight">
          - An unreported 28yr old victim planning her evening with friends
        </div>
      </div>
    </div>
  );
};

const Section4 = () => {
  const { authUser } = useAuth();

  return (
    <div className="text-center py-12">
      <div className="mb-12 px-3">
        <span className="text-zinc-800 text-3xl font-bold">
          “It’s like having all local stepping out plans in one, right?
          <br />
          Coooooool!”
          <br />
          <br />
        </span>
        <span className="text-zinc-800 text-lg font-bold">- early user</span>
      </div>
      <div className="my-12 mx-auto w-full">
        <GetItOnContainer
          isClickable={Boolean(authUser)}
          handleOnClick={() => window.scrollTo(0, 0)}
        />
      </div>
    </div>
  );
};

export default function LoginPage() {
  const { authUser, loading } = useAuth();
  const navigate = useNavigate();
  const { isNative } = useCapacitorInfo();
  const [showEarlyUserModal, setShowEarlyUserModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [authUser]);

  useEffect(() => {
    if (!loading && authUser && authUser.is_onboarding_enabled && isNative) {
      navigate("/app/feed");
    }
  }, [authUser, loading, navigate, isNative]);

  useEffect(() => {
    if (showEarlyUserModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [showEarlyUserModal]);

  return !loading ? (
    <>
      <div className="bg-white">
        <div className="w-full flex items-center justify-between px-2">
          <HeaderWithLogoAndName preventClick />
          {/* {!isNative && (
            <button
              className="justify-between items-center inline-flex text-slate-400"
              onClick={() => setShowEarlyUserModal(true)}
            >
              <div className="text-xs leading-normal">
                Already our early user?
              </div>
              <ArrowRightIcon className="w-5 h-5 -rotate-45" />
            </button>
          )} */}
          {showEarlyUserModal && (
            <GetTheApp
              onClose={() => {
                setShowEarlyUserModal(false);
              }}
            />
          )}
        </div>
        {authUser && !isNative ? (
          <PreRegistrationComplete referrerId={authUser.referal_id as string} />
        ) : (
          <Section1 />
        )}
        <Section2 />
        <Section3 />
        <Section4 />
      </div>
      <Footer />
    </>
  ) : (
    <></>
  );
}
