import { NavLink } from "react-router-dom";

export const HeaderWithLogoAndName = ({
  preventClick = false,
}: {
  preventClick?: boolean;
}) => (
  <NavLink to={preventClick ? "" : "/app/feed"} className="flex items-center py-2">
    <img src="/images/aroundly-logo.png" className="h-10" alt="Aroundly Logo" />
  </NavLink>
);