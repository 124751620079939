import { useFeedData } from "../context/FeedDataContext";
import { ChevronDownIcon as ChevronDownIconSolid } from "@heroicons/react/16/solid";
import LocationSelectModal from "./LocationSelectModal";
import { useState } from "react";
import { Mixpanel } from "../services/mixpanel";
import RenderUserAvatar from "./RenderUserAvatar";
import { CollabWishlistType, UserProps } from "../common/global.types";
import Skeleton from "react-loading-skeleton";

const CategoryCard = ({
  category,
  query,
  collabListData,
  loggedInUserData,
  dataLoading,
}: {
  category: string;
  query: string;
  collabListData?: CollabWishlistType;
  loggedInUserData?: UserProps;
  dataLoading?: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const { selectedLocation, locations, onLocationSelect } = useFeedData();
  let participantsList: { _id: string; name: string }[] = [];

  if (collabListData) {
    participantsList = [
      collabListData.owner_id,
      ...collabListData.collaborators.map((u) => u.user_id),
    ].filter(
      (participant) =>
        participant._id !== (loggedInUserData && loggedInUserData._id)
    );
  }
  return (
    <>
      {showModal && (
        <LocationSelectModal
          onClose={() => setShowModal(false)}
          selectedLocationOption={selectedLocation?.location_id}
          locationsList={locations}
          onSubmit={(locationDetails) => {
            Mixpanel.track("Location Changed", {
              old_location: selectedLocation?.location_id,
              new_location: locationDetails.location_id,
              ui_type: "category-card-header",
            });
            onLocationSelect(locationDetails);
            // navigate(`/app/results?results_type=search&query=${query}`);
            setShowModal(false);
          }}
        />
      )}
      <div className="py-2 px-3 rounded-xl bg-white">
        <p
          className="text-[10px] font-medium capitalize"
          style={{ letterSpacing: "-0.3px" }}
        >
          {category}
        </p>
        {dataLoading ? (
          <Skeleton />
        ) : (
          <p
            className="text-2xl font-medium text-black line-clamp-1"
            style={{ letterSpacing: "-0.72px" }}
          >
            {query}
            <span className="text-secondary-text"> in</span>
          </p>
        )}

        <p
          onClick={() => {
            Mixpanel.track("Location Picker Clicked", {
              ui_type: "category-card-header",
            });
            setShowModal(true);
          }}
          className="text-[#007AFF] text-2xl font-medium flex gap-1 items-center justify-start"
          style={{ letterSpacing: "-0.72px" }}
        >
          <span>{selectedLocation?.location_name}</span>
          <ChevronDownIconSolid className="h-6 w-6" />
        </p>

        {dataLoading ? (
          <Skeleton />
        ) : (
          <div
            className={`${
              category === "Your Saved" ? "" : "hidden"
            } justify-start items-center gap-1 inline-flex line-clamp-1`}
          >
            <div
              className={`${
                collabListData ? "" : "hidden"
              }   w-4 h-4 justify-center items-center flex`}
            >
              <RenderUserAvatar gender={loggedInUserData?.gender as string} />
            </div>
            {collabListData && (
              <div
                className="text-zinc-800 text-sm"
                style={{ letterSpacing: "-0.42px" }}
              >
                Me{" "}
                {participantsList.length > 0
                  ? ", " + participantsList.map((u) => u.name).join(", ")
                  : ""}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default CategoryCard;
