import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";
const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // TODO: Check this deeplinks code to fix the issue.
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // slug = /tabs/tab2
      const slug = event.url.split(".com").pop();
      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};

export default AppUrlListener;
