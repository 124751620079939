import { UserProps } from "./global.types";

export const fetchOnboardingStep = (userAuth: UserProps): string => {
  if (userAuth?.interests.length === 0) {
    return "/app/onboarding/customize-interests";
  } else if (userAuth?.interests.length > 0 && userAuth.stepout.length === 0) {
    return "/app/onboarding/group-type";
  } else if (
    userAuth?.interests.length > 0 &&
    userAuth.stepout.length > 0 &&
    !userAuth.date_of_birth
  ) {
    return "/app/onboarding/user-details";
  } else {
    return "/app/feed";
  }
};
