import { useLocation, useNavigate } from "react-router-dom";
import { useFeedData } from "../context/FeedDataContext";

import { useEffect, useState } from "react";
import Percentage from "../components/Percentage";
import UpdateCard from "../components/UpdateCard";
import { useAuth } from "../context/auth";

import { updateUser } from "../services/aroundly-apis";
import { toast } from "react-toastify";
import ButtonControl from "../components/controls/ButtonControl";

const OnboardingStep1 = () => {
  const { interests } = useFeedData();
  const { authUser, loading, setAuthUser } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const referrer = queryParams.get("referrer");

  const [localInterests, setLocalInterests] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleClick = (value: string) => {
    const isSelected = localInterests.includes(value);

    if (isSelected) {
      setLocalInterests(localInterests.filter((name) => name !== value));
    } else {
      setLocalInterests([...localInterests, value]);
    }
  };

  useEffect(() => {
    if (authUser?.interests && authUser?.interests.length > 0) {
      setLocalInterests(authUser?.interests);
    }
  }, [authUser, navigate]);

  const updateTopics = async () => {
    try {
      if (localInterests.length > 0) {
        if (!loading && authUser) {
          return await updateUser(authUser.phone, {
            interests: localInterests,
          });
        }
      }
      return null;
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickbutton = async () => {
    const data = await updateTopics();

    if (data.msg === "User Data successfully saved") {
      if (setAuthUser) {
        if (data && data.updatedUser.interests.length > 0) {
          setAuthUser({
            ...data.updatedUser,
          } as any);
        }
      }
      if (referrer === "custom") {
        navigate("/app/feed");
      } else {
        navigate("/app/onboarding/group-type");
      }
    } else {
      toast.error("Data is not saved successfully.");
    }
  };

  useEffect(() => {
    const handleBackButton = (event: any) => {
      if (referrer === "custom") {
        navigate("/app/onboarding/customize-interests?referrer=custom");
      } else {
        navigate("/app/onboarding/customize-interests/");
      }
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div className="h-dvh  ">
      <div className="pt-16">
        <Percentage step={1} total={4} />
      </div>
      <div className="mx-5   mt-6">
        <div className="text-2xl font-bold">
          What do you like
          <br /> going out to?
        </div>
        {/* <div className=" text-base  mt-8">
          <span className="text-primary ">{localInterests.length} of 16</span>{" "}
          selected. You can always change later.
        </div> */}
        <div
          className=" text-base text-secondary-text font-450   mt-2"
          style={{
            letterSpacing: "-0.48px",
          }}
        >
          Start with 5 or more. We’ll update as you use.
        </div>
      </div>
      <div className="pb-20 pt-3 px-3 overflow-y-scroll    scrollbar-hide overflow-x-hidden bg-white">
        <div className="grid grid-cols-4 gap-y-4 gap-x-2 py-2 ">
          {interests?.map((interest) => {
            const isSelected = localInterests.includes(interest._id);

            return (
              <div
                className={`col-span-1 cursor-pointer space-y-2 h-32 pt-2 rounded-lg ${
                  isSelected ? "bg-[#E8F0FF]" : ""
                }`}
                key={interest._id}
              >
                <UpdateCard
                  isLoading={false}
                  updateHeader={interest.display_name || interest.interest_name}
                  imageName={interest.interest_name}
                  isOnboarding={true}
                  width={64}
                  isMini={true}
                  height={64}
                  onClick={() => handleClick(interest._id)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="fixed bottom-8 mx-auto max-w-md w-full">
        <div className="flex justify-center px-4">
          <ButtonControl
            buttonId="select-interests-button"
            size="lg"
            theme="primary"
            name={`Select ${localInterests.length} of 16`}
            onClick={() => handleClickbutton()}
            disabled={localInterests.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
export default OnboardingStep1;
