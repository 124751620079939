import { useEffect, useState } from "react";
import { useFeedData } from "../context/FeedDataContext";

const scrollLogos = [
  "scroll - allevents",
  "scroll - bookmyshow",
  "scroll - dineout",
  "scroll - eazy diner",
  "scroll - highape",
  "scroll - insider",
  "scroll - instagram",
  "scroll - magicpin",
  "scroll - meetup",
  "scroll - nearbuy",
  "scroll - things2do",
  "scroll - trove",
  "scroll - urbanaut",
  "scroll - zomato",
];

export default function LoadingComp({
  defaultText = "",
  primaryText = "Discovering",
  showSources = true,
}: {
  defaultText?: string;
  showSources?: boolean;
  primaryText?: string;
}) {
  const [randomNumber, setRandomNumber] = useState<number>();

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 2) + 1;
    setRandomNumber(randomNumber);
  }, []);

  const { selectedLocation } = useFeedData();

  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    // Set a timer to show the div after 4 seconds
    const timer = setTimeout(() => {
      setShowDiv(true);
    }, 6000);

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="flex flex-col items-center justify-center flex-1 relative">
      {!showSources && (
        <div className="w-max h-max rounded flex-col justify-end items-center inline-flex absolute top-0 left-1/2 transform -translate-x-1/2">
          <div className="flex-col justify-center items-center gap-1 flex text-center text-stone-900 text-base font-medium p-2 bg-white shadow rounded-lg">
            <div
              style={{
                letterSpacing: "-0.32px",
              }}
              className="text-secondary-text text-base font-medium"
            >
              {" "}
              {primaryText}
            </div>
            {/* {showSources && (
            <div
              className="text-base text-[#1e1e1e] font-medium"
              style={{
                letterSpacing: "-0.32px",
              }}
            >
              {searchText}
            </div>
          )} */}
            {/* <div className="text-special-blue">
            {defaultText ? defaultText : selectedLocation?.location_name}
          </div> */}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="5"
            viewBox="0 0 11 5"
            fill="none"
          >
            <path
              d="M6.20711 4.29289C5.81658 4.68342 5.18342 4.68342 4.79289 4.29289L0.5 5.04736e-07L10.5 0L6.20711 4.29289Z"
              fill="white"
            />
          </svg>
        </div>
      )}

      <img
        src="/animated/radar-loader.gif"
        alt="radar icon"
        className="h-28 w-28 mt-10 rounded-full"
      />
      {/* {showSources && (
        <div className="flex flex-col gap-2 mt-4 justify-center text-sm">
          <div className="flex items-center justify-center animate-pulse">
            <img
              src={"/images/sources/bookmyshow.png"}
              alt="image1"
              className="w-4 h-4 mr-1"
            />
            bookmyshow
          </div>
          <div className="flex items-center justify-center animate-pulse">
            <img
              src={"/images/sources/instagram.png"}
              alt="image2"
              className="w-4 h-4 mr-1"
            />
            instagram.com
          </div>
          <div className="animate-pulse">+ other sources on web</div>
        </div>
      )} */}
      {showSources && (
        <div className="scrollbar-hide overflow-x-hidden">
          <div className=" flex items-center gap-2 animate-infinite-scroll mt-3">
            {scrollLogos.map((val, index) => (
              <img
                alt={val}
                src={`/images/scroll-logos/${val}.png`}
                className="h-4 w-4"
                key={index}
              />
            ))}
            {[0, 1].map((t) =>
              scrollLogos.map((val, index) => (
                <img
                  alt={val}
                  src={`/images/scroll-logos/${val}.png`}
                  className="h-4 w-4"
                  key={index}
                />
              ))
            )}
          </div>
        </div>
      )}

      {showSources &&
        (randomNumber === 1 ? (
          <div className="flex items-center justify-start mt-5 gap-2 bg-[#00B078] bg-opacity-10 p-2 w-[312px]  rounded-lg">
            <img
              alt="light-bulb"
              className="h-8 w-[10%]"
              src="/images/lightbulb.svg"
            />
            <p
              className="text-[#10684C] flex items-center  justify-start text-xs font-450 "
              style={{
                letterSpacing: "-0.36px",
              }}
            >
              Your search is powered by&nbsp;<b>AI</b>roundly.
            </p>
          </div>
        ) : (
          <div className="flex items-center justify-start mt-5 gap-2 bg-[#00B078] bg-opacity-10 p-2 w-[312px]  rounded-lg">
            <img
              alt="light-bulb"
              className="h-8 w-[10%]"
              src="/images/lightbulb.svg"
            />
            <p
              className="text-[#10684C] flex items-center  justify-start text-xs font-450 w-full"
              style={{
                letterSpacing: "-0.36px",
              }}
            >
              You can search as specifically as you want. Ex: “beer places with
              chill vibe” , “romantic dinner with my date”.
            </p>
          </div>
        ))}

      {showSources && showDiv && (
        <div className="flex items-center justify-start mt-5 gap-2 bg-[#EBB376] bg-opacity-10 p-2 w-[312px]  rounded-lg">
          <img
            alt="light-bulb"
            className="h-8 w-[10%]"
            src="/images/clock.svg"
          />
          <p
            className="text-[#D4852E] w-full flex items-center  justify-start text-xs font-450 "
            style={{
              letterSpacing: "-0.36px",
            }}
          >
            Taking more time than we expected. We logged this delay. Thank you
            for helping us improve.
          </p>
        </div>
      )}

      {showSources && <div className="flex gap-2"></div>}
    </div>
  );
}
