import { useNavigate } from "react-router-dom";
import Percentage from "../components/Percentage";
import { useEffect, useState } from "react";

import { useAuth } from "../context/auth";
import { toast } from "react-toastify";
import { updateUser } from "../services/aroundly-apis";
import ButtonControl from "../components/controls/ButtonControl";

const family = ["Parents", "Friends", "Spouse", "Kids", "Colleagues", "Pet"];
const OnboardingStep2 = () => {
  const navigate = useNavigate();
  const { authUser, setAuthUser } = useAuth();
  const [localState, setLocalState] = useState<string[]>([]);

  const handleClick = (one: string) => {
    const isPresennt = localState.includes(one);
    if (isPresennt) {
      setLocalState(localState.filter((item) => item !== one));
    } else {
      setLocalState([...localState, one]);
    }
  };

  const updateData = async () => {
    const stepout = localState;
    let phone = "";
    if (authUser !== null && authUser !== undefined) {
      phone = authUser?.phone;
    }

    const data = await updateUser(phone, { stepout: stepout });
    return data;
  };

  const handleClickbutton = async () => {
    const data = await updateData();
    if (data.msg === "User Data successfully saved") {
      if (setAuthUser) {
        if (data && data.updatedUser) {
          setAuthUser({
            ...authUser,
            ...data.updatedUser,
          } as any);
        }
      }
      navigate("/app/onboarding/enable-location");
    } else {
      toast.error("Data is not saved successfully.");
    }
  };

  useEffect(() => {
    if (authUser?.stepout && authUser?.stepout.length > 0) {
      setLocalState(authUser?.stepout);
    }
  }, [authUser, navigate]);

  return (
    <div className=" h-dvh">
      <div className="pt-16">
        <Percentage step={2} total={4} />
      </div>
      <div className="mx-5  mt-[48px]">
        <div className="text-2xl font-bold">
          Who do you usually <br /> go out with?
        </div>
      </div>
      <div className="mt-8 mb-[36px]">
        <p className="text-black text-base mx-5"> Select one or many</p>

        <div className="mt-3 mx-5">
          {family.map((one, index) => (
            <div
              key={index}
              onClick={() => handleClick(one)}
              className={`min-w-20 ${
                localState.includes(one) ? "bg-[#E8F0FF]" : ""
              } h-12 p-2 justify-center flex mb-1 items-center  text-[#626569] rounded-lg text-sm cursor-pointer border border-[#DCDEE3]`}
            >
              {one}
            </div>
          ))}
        </div>
      </div>
      <div className="absolute bottom-8 mx-auto max-w-md w-full">
        <div className="flex justify-center px-4">
          <ButtonControl
            buttonId="group-type-button"
            size="lg"
            theme="primary"
            name="Next"
            onClick={() => handleClickbutton()}
            disabled={localState.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
export default OnboardingStep2;
