import { useState } from "react";
import { useFeedData } from "../context/FeedDataContext";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import LocationSelectModal from "./LocationSelectModal";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Mixpanel } from "../services/mixpanel";

export default function LocationPicker({
  fooddrink = false,
}: {
  fooddrink?: boolean;
}) {
  const { selectedLocation, locations, onLocationSelect } = useFeedData();
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const resultsType = searchParams.get("results_type");
  const query = searchParams.get("query");
  const navigate = useNavigate();
  //  results?results_type=search&query=gaming%20events
  return (
    <>
      {showModal && (
        <LocationSelectModal
          onClose={() => setShowModal(false)}
          selectedLocationOption={selectedLocation?.location_id}
          locationsList={locations}
          onSubmit={(locationDetails) => {
            Mixpanel.track("Location Changed", {
              old_location: selectedLocation?.location_id,
              new_location: locationDetails.location_id,
              ui_type: "location-picker",
            });
            onLocationSelect(locationDetails);
            // navigate(`/app/results?results_type=search&query=${query}`);
            setShowModal(false);
          }}
        />
      )}
      <button
        className="inline-flex justify-start leading-6 items-center gap-1 cursor-pointer w-full"
        onClick={() => setShowModal(true)}
      >
        {!fooddrink && <MapPinIcon className="w-4 h-4 text-special-blue" />}
        <div
          className={`text-right ${
            fooddrink
              ? "text-[#637381] font-inter  text-xs  font-medium drop-shadow-md"
              : "text-special-blue text-xs"
          }  truncate`}
        >
          {selectedLocation?.location_name}
        </div>
        <ChevronDownIcon
          className={`w-5 h-5 ${fooddrink ? "text-[#637381] " : "text-special-blue"}`}
        />
      </button>
    </>
  );
}
