import UpdateContent from "./UpdateContent";

const UpdateModal = ({
  updateId,
}: {
  updateId: string;
  toggleUpdateModal: (status: boolean, id: string) => void;
}) => {
  return (
    <div
      id="modal"
      style={{
        zIndex: 80,
      }}
      className="fixed left-0 right-0 top-0 bottom-0 bg-white z-50  max-w-md mx-auto  "
    >
      <div className="pb-16  overflow-y-scroll scrollbar-hide h-dvh">
        <UpdateContent updateId={updateId as string} />
      </div>
    </div>
  );
};
export default UpdateModal;
