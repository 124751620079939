import { useEffect, useState } from "react";
import moment from "moment"; // Import Moment.js
import { AroundlyPlaces, SocialMediaUpdate } from "../common/global.types";
import { DEFAULT_TIMEZONE } from "../common/global.constants";

const useForYouUpdates = (
  filteredData: AroundlyPlaces[],
  isEat?: boolean,
  showHandpicked?: boolean,
  relevance?: string
): SocialMediaUpdate[] => {
  const [forYouUpdates, setForYouUpdates] = useState<SocialMediaUpdate[]>([]);
  useEffect(() => {
    if (!filteredData) return;

    const updates = filteredData.flatMap((place) => {
      const placeUpdates = place.updates || [];
      return placeUpdates.map((update) => ({
        ...update,
        related_aroundly_place_ids: [place],
      }));
    });

    let filteredUpdates = updates.filter(
      (update) => update.update_type !== "general_update_generated"
    );

    if (isEat) {
      filteredUpdates = filteredUpdates.filter(
        (update) => update.update_type === "review"
      );

      const now = moment();
      const twoWeeksAgo = moment().subtract(7, "days");

      filteredUpdates = filteredUpdates.filter((update) => {
        const createdDate = moment(update.created_at);

        return createdDate.isAfter(twoWeeksAgo) && createdDate.isBefore(now);
      });
    }

    if (relevance === "upcomming") {
      filteredUpdates = filteredUpdates.sort((a, b) => {
        const dateA = moment(a.expires_at).tz(DEFAULT_TIMEZONE);
        const dateB = moment(b.expires_at).tz(DEFAULT_TIMEZONE);
        return dateA.diff(dateB);
      });
    }

    if (showHandpicked) {
      filteredUpdates = filteredUpdates.filter((u) => u.is_best === true);
    }

    setForYouUpdates(filteredUpdates);
  }, [filteredData, isEat, showHandpicked, relevance]);

  return forYouUpdates;
};

export default useForYouUpdates;
