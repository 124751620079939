import { SparklesIcon } from "@heroicons/react/24/outline";
import { InterestProps, SocialMediaUpdate } from "../common/global.types";
import Filters from "./Filters";
import MasonryComponent from "./MasonryComponent";

interface Props {
  isLoading: boolean;
  isUpdatesTab: boolean;
  width: number;
  selectedTime?: string;
  setSelectedtime?: React.Dispatch<React.SetStateAction<string>>;
  updateTypeToShow: string;
  lastSearchText?: string;
  searchText?: string | null;
  interests: InterestProps[];
  setIsUpdatesTab: React.Dispatch<React.SetStateAction<boolean>>;
  relevance: string;
  setRelevance: React.Dispatch<React.SetStateAction<string>>;
  isBest: boolean;
  setisBest: React.Dispatch<React.SetStateAction<boolean>>;
  resultsType: string | null;
  forYouUpdatesSearchData: SocialMediaUpdate[];
  showErrorModal: boolean;
  activeSection: string;
  showhandpicked?: boolean;
}
const ForyouComponent = ({
  isLoading,
  isUpdatesTab,
  width,
  selectedTime,
  setSelectedtime,
  updateTypeToShow,
  resultsType,
  searchText,
  interests,
  setIsUpdatesTab,
  relevance,
  setRelevance,
  isBest,
  setisBest,
  lastSearchText,
  showErrorModal,
  forYouUpdatesSearchData,
  activeSection,
  showhandpicked,
}: Props) => {
  return (
    <div>
      <div
        className={`pl-3  sticky py-2 top-[107px] mb-2  ${
          resultsType === "search" && lastSearchText !== searchText && "hidden"
        }  bg-white w-full  z-10`}
      >
        <Filters
          interests={interests}
          resultsType={resultsType}
          updateTypeToShow={updateTypeToShow}
          istemplate={isUpdatesTab}
          setIsTemplate={setIsUpdatesTab}
          relevance={relevance}
          setRelevance={setRelevance}
          selectedTime={selectedTime}
          setSelectedtime={setSelectedtime}
          isBest={isBest}
          setIsbest={setisBest}
          showhandpicked={showhandpicked}
        />
      </div>

      {!showErrorModal && forYouUpdatesSearchData?.length > 0 && (
        <div className="flex gap-1 text-xs mx-3 mb-2">
          <SparklesIcon className="text-[#00B078]  h-4 w-4" />
          <p className="text-[#00B078]">
            {forYouUpdatesSearchData?.length} Recommendations tailored to your
            saved
          </p>
        </div>
      )}

      {!isLoading && (
        <MasonryComponent
          updates={forYouUpdatesSearchData}
          isResults={true}
          showErrorModal={showErrorModal}
          selectedTime={selectedTime}
          setSelectedtime={setSelectedtime}
          showDateFooter={updateTypeToShow === "event" ? true : false}
        />
      )}
    </div>
  );
};
export default ForyouComponent;
