import common_axios from "./axios";
import { REGEX } from "./regex";
import { CONSTANTS } from "./global.constants";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { toast } from "react-toastify";
import { AroundlyPlaces } from "./global.types";

export const cleanString = (val: string) => {
  let stringArray = val.split(",");
  let cleanedArray = stringArray
    .map((item) => item.trim())
    .filter((item) => item !== "");
  let result = cleanedArray.join(", ");
  return result;
};

export const arrayToString = (arr: string[]) => {
  return arr.join(", ");
};

export const copy = async (text: string) => {
  try {
    await Clipboard.write({
      string: text,
    });
    return true;
  } catch (e) {
    console.log(e);
  }
};

export const shareLogic = async (text: string, url?: string) => {
  try {
    await Share.share({
      text,
      url,
    });
  } catch (e) {
    if ((e as any).code === "UNAVAILABLE") {
      await copy(`${text} ${url}`);
      toast.success("Copied. Share with a friend!");
    }
    console.log(e);
  }
};

export const ArrayToView = ({
  arr,
  after,
}: {
  arr: string[];
  after?: boolean;
}) => {
  return (
    <span>
      {arr.map((item, index) => {
        if (arr.length > 1 && index === arr.length - 1) {
          return <span key={index}> & {item}</span>;
        }
        if (arr.length > 1 && index !== 0) {
          return <span key={index}>, {item}</span>;
        }
        if (after && arr.length === 1 && index === 0) {
          return (
            <span key={index}>
              {after ? " & " : ""}
              {item}
            </span>
          );
        }
        return (
          <span key={index}>
            {after ? ", " : ""}
            {item}
          </span>
        );
      })}
    </span>
  );
};

export const getAge = (dateString: string | Date) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const textExists = (text: string, value: string) => {
  const arr = value.split(REGEX.space_comma);
  if (arr.includes(text)) {
    return true;
  } else {
    return false;
  }
};

export const removeTextFromString = (text: string, value: string) => {
  const arr = value.split(REGEX.space_comma);
  const newArr = arr.filter(function (item) {
    return item !== text;
  });
  return arrayToString(newArr);
};

// export const isSubscribed = async () => {
//   try {
//     return await common_axios.get(
//       "user-subscription/" + CONSTANTS.WHATSAPP_SUBSCRIPTION_TOPIC_ID
//     );
//   } catch (e) {
//     console.log(e);
//   }
// };
interface ImageURL {
  media_url: string;
  thumbnail_url: string;
}
const defaultImageUrl: ImageURL = {
  media_url: "",
  thumbnail_url: "",
};

export const getImageUrl = (updates: AroundlyPlaces[]) => {
  const imageUrl = updates?.reduce<ImageURL>((acc, data) => {
    if (acc.media_url || acc.thumbnail_url) return acc;

    const image = data.updates.find(
      (update) => update.media_metadata?.media_compressed.type === "IMAGE"
    );

    if (
      image &&
      (image.media_metadata?.media_original.url ||
        image.media_metadata?.media_compressed.url)
    ) {
      return {
        media_url: image.media_metadata?.media_compressed.url || "",
        thumbnail_url: image.media_metadata?.media_compressed.url || "",
      };
    }
    return acc;
  }, defaultImageUrl);

  return imageUrl;
};
