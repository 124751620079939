// "use client";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import MiniFeedCard from "./MiniFeedCard";
import {
  AroundlyPlaces,
  SocialMediaUpdate,
  UpdateType,
} from "../common/global.types";
import {
  UPDATE_TYPE_EVENT,
  UPDATE_TYPE_REVIEW,
} from "../common/global.constants";
import { EmptyCard } from "./Emptycard";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export default function UpdatesForYou({
  updates,
  updateType,
  updateText,
  isLoading,
  timezone,
  hideActionButtons = false,
  hideEmptyCard = false,
  showHeaders = false,
  onViewAllClick,
  hideCarousel = false,
  placeData,
}: {
  readonly updates: SocialMediaUpdate[];
  readonly updateType?: UpdateType;
  readonly updateText: string;
  readonly isLoading: boolean;
  readonly showHeaders?: boolean;
  readonly hideActionButtons?: boolean;
  readonly hideEmptyCard?: boolean;
  readonly hideCarousel?: boolean;
  readonly timezone: string;
  readonly onViewAllClick?: () => void;
  readonly placeid?: string;
  readonly placeData?: AroundlyPlaces;
}) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selindex, setSelIndex] = useState<number>(0);
  const location = useLocation();
  const handleDotClick = (index: number, last: number) => {
    if (scrollRef.current) {
      const cardWidth = scrollRef.current.children[0].clientWidth;
      const scrollLeft =
        (index === last - 1 && index * cardWidth + 39) || index * cardWidth;
      scrollRef.current.scrollLeft = scrollLeft;
      setSelIndex(index);
    }
  };

  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, updates.length);
  }, [updates]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const index = itemRefs.current.findIndex((itemRef) => {
          if (itemRef && scrollRef.current) {
            const containerLeft =
              scrollRef.current.getBoundingClientRect().left;
            const itemLeft = itemRef.getBoundingClientRect().left;
            return itemLeft >= containerLeft;
          }
          return false;
        });
        setSelIndex(index === -1 ? itemRefs.current.length - 1 : index);
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return (
    <div className=" w-full">
      {showHeaders && (
        <div className="flex items-center justify-between px-3">
          <div className={`mb-4 `}>
            <div className="text-xl font-medium text-black">{updateText}</div>
            <div className="text-xs text-gray-500">
              {updateType === UPDATE_TYPE_REVIEW
                ? "Bringing honest and best recommendations from locals"
                : updateType === UPDATE_TYPE_EVENT
                ? "Bringing unique to big events and experiences"
                : "Bringing limited time offers and promotions"}
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="animate-pulse bg-gray-200 rounded h-20 mx-3"></div>
      ) : (
        <div>
          <div
            ref={scrollRef}
            className="flex overflow-x-scroll scrollbar-hide w-full"
          >
            {updates.map((update, index) => (
              <div
                ref={(el) => (itemRefs.current[index] = el)}
                key={update._id + index}
                className={`${
                  updates.length === 1 ? "w-full px-3" : "w-[90%]  mr-1"
                } ${
                  index === 0 && updates.length !== 1 ? "ml-3" : ""
                }   flex-none`}
              >
                <MiniFeedCard
                  isfullView={false}
                  hideActionButtons={hideActionButtons}
                  timezone={timezone}
                  item={update}
                  isLast={index === updates.length - 1}
                  placeData={placeData}
                  updateText={updateText}
                  miniFeedCard={true}
                />
              </div>
            ))}

            {!hideEmptyCard && updates.length === 0 && (
              <EmptyCard locationName={""} />
            )}

            {updates.length > 8 ||
              ((location.pathname.includes("/app/category/food-and-drinks") ||
                location.pathname.includes("/app/feed")) && (
                <div
                  className="border cursor-pointer rounded-xl h-[336px]  mr-6 bg-opacity-10 text-special-blue text-sm p-4 min-w-fit items-center justify-end inline-flex"
                  onClick={onViewAllClick}
                >
                  View all
                  <ArrowRightIcon className="h-5 w-5 inline-block ml-2" />
                </div>
              ))}
          </div>
          {!hideCarousel && updates.length > 1 && (
            <div className=" flex items-center w-full justify-center mt-3 p-2">
              {updates.slice(0, 8).map((item, index) => (
                <div
                  key={index}
                  className={`${
                    selindex === index ? "bg-black" : "bg-gray-200"
                  } w-2 h-2 rounded-full ${
                    index === updates.slice(0, 8).length - 1 ? "" : "mr-3"
                  }   cursor-pointer`}
                  onClick={() =>
                    handleDotClick(index, updates.slice(0, 8).length)
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
