import { useNavigate } from "react-router-dom";
import { ShareIcon, CheckIcon } from "@heroicons/react/16/solid";
import { useCollabListsContext } from "../context/CollabListsContext";
import { CollabWishlistType, WishlistUpdate } from "../common/global.types";
import LoadMedia from "./LoadMedia";
import { shareLogic } from "../common/functions";
import { useAuth } from "../context/auth";
import { Mixpanel } from "../services/mixpanel";
import { EAT_CATEGORY, EXPERIENCES_CATEGORY } from "../common/global.constants";

export default function WishlistContainer({
  isSelectable = false,
  selectedWishlists = [],
  onSelect,
  sortedcollablist,
}: {
  selectedWishlists?: string[];
  isSelectable?: boolean;
  onSelect?: (id: string) => void;
  sortedcollablist?: CollabWishlistType[];
}) {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { toggleCreateNewModal, updateData, placeData } =
    useCollabListsContext();

  const onShare = async (wishlistInfo: CollabWishlistType) => {
    const shareText = `Checkout my wishlist of places - ${wishlistInfo?.name} -`;
    const urlToShare = `${process.env.REACT_APP_FRONTEND_URL}/app/wishlist/${wishlistInfo._id}?referral_id=${authUser?.referal_id}`;

    await shareLogic(shareText, urlToShare);
  };

  const handleWishlistNavigation = (wishlistData: CollabWishlistType) => {
    if (!wishlistData.is_default) {
      navigate(`/app/wishlist/${wishlistData._id}`);
    }

    if (wishlistData.name === EAT_CATEGORY) {
      navigate("/app/category/food-and-drinks");
    } else if (wishlistData.name === EXPERIENCES_CATEGORY) {
      navigate("/app/category/experiences");
    } else {
      navigate(`/app/wishlist/${wishlistData._id}`);
    }
  };
  return (
    <div className="grid grid-cols-2 gap-2 px-3">
      {sortedcollablist &&
        sortedcollablist.length > 0 &&
        sortedcollablist?.map((w) => {
          const updates = w.places.map((p) => p.updates).flat();

          return (
            <div
              key={w._id}
              onClick={() => {
                Mixpanel.track("Collaborative List Clicked", {
                  wishlist_id: w._id,
                  ui_type: "wishlist-container",
                });
                isSelectable && onSelect
                  ? onSelect(w._id)
                  : handleWishlistNavigation(w);
              }}
              className={`rounded-lg border border-gray-200 p-3 ${
                selectedWishlists.includes(w._id)
                  ? "bg-primary bg-opacity-5 border-primary"
                  : "bg-white"
              }`}
            >
              <div className="font-medium text-left truncate">{w.name}</div>
              <div className={`grid grid-cols-2 gap-1 h-32 mt-1`}>
                {[0, 1, 2, 3].map((item) => {
                  const update: WishlistUpdate = updates[item];

                  if (!update) {
                    return (
                      <div
                        className="bg-slate-500/10 rounded-xl overflow-hidden col-span-1 h-16"
                        key={item}
                      ></div>
                    );
                  }
                  return (
                    <div
                      key={update.update_id._id}
                      className="bg-white rounded-xl overflow-hidden col-span-1 max-h-16"
                    >
                      <LoadMedia
                        isMini
                        source={update.update_id.source}
                        showFixedHeight={false}
                        thumbnailKey={
                          update.update_id?.media_metadata?.media_compressed
                            .url as string
                        }
                        mediaKey={
                          update.update_id?.media_metadata?.media_compressed
                            .url as string
                        }
                        mediaType={
                          update.update_id?.media_metadata?.media_compressed
                            .type as any
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <div className="mt-2 tracking-tight text-start">
                <div className="flex items-center justify-start w-full">
                  <div className="grow">
                    <p className="text-xs mb-1">{w.places.length} Places</p>
                    <div className="flex items-center gap-1">
                      <img
                        className="w-4 h-4"
                        alt="profile-icon"
                        src={"/images/Male.svg"}
                      />
                      <div className="text-neutral-500 text-[10px]">
                        {w.owner_id.name}
                      </div>
                    </div>
                  </div>
                  {isSelectable ? (
                    <button
                      className={`rounded-full border h-6 w-6 ${
                        selectedWishlists.includes(w._id)
                          ? "border-primary bg-primary"
                          : ""
                      }`}
                    >
                      {selectedWishlists.includes(w._id) ? (
                        <CheckIcon className="h-5 w-5 flex-shrink-0 text-white" />
                      ) : (
                        <></>
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        Mixpanel.track("Share Clicked", {
                          wishlist_id: w._id,
                          share_type: "collab-list",
                          ui_type: "wishlist-container",
                        });
                        onShare(w);
                      }}
                    >
                      <ShareIcon className="h-5 w-5 flex-shrink-0" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      <button
        className="col-span-1 h-56 p-2 rounded-lg border flex-col justify-center items-center inline-flex"
        onClick={() => toggleCreateNewModal(true, updateData, placeData)}
      >
        <div className="self-stretch text-center text-zinc-800 text-lg font-medium">
          Create new +
        </div>
      </button>
    </div>
  );
}
