import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  performUserAction,
  performUserActionHistory,
} from "../services/aroundly-apis";
import { SocialMediaUpdate, SupportedActions } from "../common/global.types";
import { useState } from "react";
import { Mixpanel } from "../services/mixpanel";

type FeedCardProps = {
  readonly item: SocialMediaUpdate;
  readonly onClose: any;
};

export default function NotInterested({ item, onClose }: FeedCardProps) {
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const handleCheckboxClick = async (text: string) => {
    try {
      if (selectedReasons.includes(text)) {
        // If the clicked reason is already selected, remove it
        const updatedReasons = selectedReasons.filter(
          (reason) => reason !== text
        );
        setSelectedReasons(updatedReasons);
        // Make API call to remove the reason
        await performUserAction(
          item._id,
          SupportedActions.NOT_INTERESTED_UNDO,
          text
        );
        await performUserActionHistory(SupportedActions.NOT_INTERESTED_UNDO, {
          update_id: item._id,
          update_type: item.update_type,
        });
      } else {
        // If the clicked reason is not selected, add it

        const updatedReasons = [...selectedReasons, text];
        setSelectedReasons(updatedReasons);
        // Make API call to mark as not interested
        await performUserAction(
          item._id,
          SupportedActions.NOT_INTERESTED,
          text
        );
 
      }
    } catch (e) {
      console.log(e);
    }
  };
  const removeWholeObj = async () => {
    setSelectedReasons([]); // Reset selected reason to null
    try {
       await performUserAction(
        item._id,
        SupportedActions.NOT_INTERESTED_UNDO
      );
      await performUserActionHistory(SupportedActions.NOT_INTERESTED_UNDO, {
        update_id: item._id,
        update_type: item.update_type,
      });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      id="modal"
      className="fixed left-0 right-0 top-0 bottom-0 mx-auto bg-black bg-opacity-30 z-50"
    >
      <div className={`absolute inset-0 top-1/3  w-full font-inter `}>
        <div className=" border-gray-300 bg-white  rounded-lg px-3 py-2 max-w-xs mx-auto my-5">
          <div className="flex justify-between my-2">
            <div className="flex items-center">
              <CheckCircleIcon className="h-6 w-6 mr-1" />
            </div>

            <p
              onClick={removeWholeObj}
              className="text-primary font-bold cursor-pointer"
            >
              UNDO
            </p>
          </div>
          <p className="font-medium text-base leading-5">
            Thank you. What's the reason?
          </p>

          <div className="text-gray-500 py-2">
            <p className="font-400 text-xs leading-5 ">
              Your response helps us understand how we can improve your feed
            </p>
            {/* Map through your reasons and create checkboxes */}
            {[
              "Doesn't match my interests",
              "I've already done this kind of activity",
              "I've already been to this place before",
              "Other",
            ].map((reason) => (
              <div
                className="border-2 rounded-full flex justify-start items-center p-2 my-2"
                key={reason}
              >
                <div className="text-xl mr-2 flex items-center justify-center">
                  <input
                    className="rounded border-2 border-gray-500 focus:ring-gray-500 h-4 w-4 "
                    type="checkbox"
                    checked={selectedReasons.includes(reason)}
                    onClick={() => handleCheckboxClick(reason)}
                  ></input>
                </div>
                <p className="flex justify-center text-center text-xs leading-4 font-400">
                  {reason}
                </p>
              </div>
            ))}
          </div>
          <button
            onClick={() => onClose()}
            className="bg-primary px-6 py-1 text-center items-center text-base  font-semibold leading-6 mt-[2px]  text-white w-full rounded-full"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
