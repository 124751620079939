import { createContext, useContext, useState, useEffect } from "react";
import {
  AroundlyPlaces,
  CollabWishlistType,
  LocationType,
  WishlistPlace,
} from "../common/global.types";
import { useCollabListsContext } from "./CollabListsContext";
import { useFeedData } from "./FeedDataContext";
import { getCollabdata } from "../services/aroundly-apis";

interface YourSavedContextProps {
  allCollablists: AroundlyPlaces[][];
  loading: boolean;
  error: boolean;
}

const YourSavedContext = createContext<YourSavedContextProps>({
  allCollablists: [],
  loading: false,
  error: false,
});
const checkLength = (places: WishlistPlace[]) => {
  return places.reduce((total, u) => total + u.updates.length, 0);
};

export function YourSavedProvider({ children }: { children: React.ReactNode }) {
  const [allCollablists, setAllCollablists] = useState<AroundlyPlaces[][]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { collabLists } = useCollabListsContext();
  const { selectedLocation } = useFeedData();

  useEffect(() => {
    const getCollabData = async () => {
      setError(false);
      try {
        setLoading(true);
        const promises = collabLists.map((list) => {
          if (
            checkLength(list.places) > 0 ||
            list.is_name_searchable === true
          ) {
            return getCollabdata(list._id, selectedLocation as LocationType);
          } else {
            return [];
          }
        });
        const results = await Promise.all(promises);
        setAllCollablists(results);
      } catch (err) {
        setError(true);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    if (collabLists.length > 0 && selectedLocation) {
      getCollabData();
    }
  }, [collabLists, selectedLocation]); // Added dependencies here

  return (
    <YourSavedContext.Provider value={{ allCollablists, loading, error }}>
      {children}
    </YourSavedContext.Provider>
  );
}

// Custom hook to use the context
export const useYourSavedPageContext = () => useContext(YourSavedContext);
