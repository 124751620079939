const LearnMore = ({
  toggleImage,
}: {
  toggleImage: (status: boolean) => void;
}) => {
  return (
    <div className="p-2 mx-3 font-450  rounded-lg  justify-between items-center inline-flex  shadow-sm border mt-4">
      <div className="justify-start items-center gap-1 flex mr-10">
        <div className="text-black text-xs leading-none">
          Save lists with Friends and step out together. Click to learn more.
        </div>
      </div>
      <div
        onClick={() => {
          toggleImage(true);
        }}
        className="justify-end items-center cursor-pointer gap-2 flex flex-shrink-0"
      >
        <div className="text-center px-2 py-1 bg-white rounded  text-zinc-800 text-xs font-bold font-inter leading-normal">
          LEARN MORE
        </div>
      </div>
    </div>
  );
};
export default LearnMore;
