import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const CommunityFeedback = ({
  toggleShowCommunity,
}: {
  toggleShowCommunity: (status: boolean) => void;
}) => {
  return (
    <div
      id="modal"
      className={`fixed z-40 left-0 right-0 top-0 bottom-0 bg-black/90  max-w-md mx-auto`}
    >
      <div className="max-w-md w-full h-dvh overflow-y-scroll scrollbar-hide bg-white ">
        <div
          className="py-3 px-3 text-2xl font-medium flex justify-between items-center"
          style={{ letterSpacing: "-0.72px" }}
        >
          <p>Community Feedback</p>
          <XMarkIcon
            onClick={() => toggleShowCommunity(false)}
            className="h-7 w-7 text-[#9CAAC6] cursor-pointer"
          />
        </div>
        <div
          className="text-xs font-medium mx-3"
          style={{ letterSpacing: "-0.36px" }}
        >
          Help us build your friendly local discovery app 🙌
          <br /> Find what we are working on and our weekly updates below.
        </div>

        <div className="px-3 mb-4">
          <div className="p-2  max-w-md mt-[18px] w-full font-450  rounded-lg  justify-between items-center inline-flex  shadow-sm border">
            <div className="flex gap-1">
              <div className="rounded-full  p-2">
                <img
                  src="/images/GoogleDoc.svg"
                  alt=""
                  className="opacity-100"
                />
              </div>

              <div className="justify-start items-center gap-1 flex ">
                <div className="text-black text-xs leading-none">
                  Community Responses
                </div>
              </div>
            </div>

            <Link
              to="https://docs.google.com/spreadsheets/d/1JnOpmRCyONcpTGfpD7p2ovVyGLVKtnty-7jW4i9P4Q0/edit?usp=sharing"
              className=" justify-end px-6 py-1 border  bg-white  items-center cursor-pointer gap-2 flex flex-shrink-0 text-center    text-black leading-6 text-xs font-bold font-inter  rounded-full"
              target="_blank"
              rel="noreferrer"
            >
              VIEW
            </Link>
          </div>
        </div>
        <div className="px-3 mb-4">
          <div className="p-2  max-w-md  w-full font-450  rounded-lg  justify-between items-center inline-flex  shadow-sm border">
            <div className="flex gap-1">
              <div className="rounded-full p-2">
                <img
                  src="/images/Whatsapp-logo.png"
                  alt="whatsapp"
                  className="h-6 w-6"
                />
              </div>

              <div
                className="  font-450 leading-4  flex-col flex justify-evenly "
                style={{ letterSpacing: "-0.36px" }}
              >
                <div className="text-black text-xs">Tell us directly </div>
                <div className="text-secondary-text text-[10px] ">
                  Not a Whatsapp bot{" "}
                </div>
              </div>
            </div>

            <Link
              to="https://wa.me/+917093214324?text=Hi%20I%20have%20a%20suggestion"
              className=" justify-end px-6 py-1   bg-[#28B446]  items-center cursor-pointer gap-2 flex flex-shrink-0 text-center    text-white leading-6 text-xs font-bold font-inter  rounded-full"
              target="_blank"
              rel="noreferrer"
            >
              SEND
            </Link>
          </div>
        </div>

        <div className="h-full  max-w-md scrollbar-hide ">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSepTvadqC2zCWMqMOEcijq8YVsP5zIv7Uxy5zjmyKblz_29lA/viewform?embedded=true&hl=en"
            frameBorder="1"
            marginHeight={0}
            marginWidth={0}
            title="Unique"
            height="100%"
            width="100%"
            lang="en"
            className="scrollbar-hide"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default CommunityFeedback;
