import { AroundlyPlaces } from "../common/global.types";
import useShortAddress from "../hooks/useShortAddress";
import { useCollabListsContext } from "../context/CollabListsContext";
import ShareButton from "./shareBtn";
// import ErrorModal from "./ErrorModal";
import LikeButton from "./likeBtn";
import LoadMedia from "./LoadMedia";
type InstagramMediaType = "IMAGE" | "VIDEO" | "CAROUSEL_ALBUM";

const AroundlyPlaceComp = ({
  placeDetails,
}: {
  placeDetails: AroundlyPlaces;
}) => {
  const shortAddress = useShortAddress(placeDetails);
  const { toggleStoreModal, toggleUpdateModal } = useCollabListsContext();

  let image;
  if (placeDetails.updates.length > 0) {
    image = placeDetails.updates[0].media_metadata?.media_compressed.url;
  }

  return (
    <div
      onClick={() => {
        toggleUpdateModal(false);
        toggleStoreModal(true, placeDetails._id);
      }}
      className="bg-white rounded-xl p-2 flex flex-col gap-3"
    >
      {/* <div className="flex justify-between items-center text-center">
        <div className="items-center text-center gap-1 flex justify-center">
          <span className="text-xl  items-center text-center mt-[2px]">&#x1F6C8;</span>
          <span
            className="text-secondary-text text-xs font-inter font-400 items-center text-center"
            style={{
              letterSpacing: "-0.333px",
            }}
          >
            Matched <b>thai</b>
          </span>
        </div>
        <div className=" border-[#007AFF] border-b-2 text-[#007AFF]">(why?)</div>
      </div> */}
      <div className="flex gap-3">
        <div className="w-1/4">
          <LoadMedia
            mediaKey={image as string}
            mediaType={
              placeDetails.updates[0]?.media_metadata?.media_compressed
                .type as InstagramMediaType
            }
            thumbnailKey={image as string}
            fixed_height={6}
            fixed_width={6}
            aroundlyPlace={true}
            showFixedHeight={true}
            isMini={false}
          />
        </div>

        <div
          style={{ letterSpacing: "-0.333px" }}
          className="flex flex-col gap-1 w-3/4"
        >
          <div className="flex items-center gap-2">
            <p className="underline text-base font-medium cursor-pointer">
              {placeDetails.name}
            </p>
            <div className="flex items-center text-center gap-1">
              <span className="font-medium">4.5</span>
              <img alt="star" className="h-4 w-4" src="/images/star5.png" />
            </div>
          </div>
          <div className="text-secondary-text text-xs font-400 line-clamp-1">
            {Math.round(placeDetails.distance)} KM from you •{" "}
            {shortAddress.length > 0
              ? shortAddress
              : placeDetails?.google_maps_details.formatted_address}
          </div>
          {placeDetails?.known_for?.length > 0 && (
            <div className="text-xs font-400 text-secondary-text font-inter mt-1">
              <span className=" text-xs line-clamp-2">
                Known for <b>{placeDetails?.known_for?.join(", ")}</b>
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex gap-2  justify-start items-center  "
      >
        <LikeButton size="md" placeData={placeDetails} />
        <ShareButton
          size="md"
          showText={true}
          _id={placeDetails?._id}
          captionTitle={placeDetails.name}
          preventShare
        />
      </div>
    </div>
  );
};
export default AroundlyPlaceComp;
