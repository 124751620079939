type GetItOnType = "app-store" | "play-store";

const GET_IT_ON_LINKS: { [key: string]: string } = {
  "app-store": "https://testflight.apple.com/join/L1JB5STt", // Test flight link
  // "https://apps.apple.com/us/app/aroundly-your-local-discovery/id6502633805",
  "play-store": "https://play.google.com/apps/testing/com.abstudio.aroundly", // Join Open Testing Link
  // "https://play.google.com/store/apps/details?id=com.abstudio.aroundly",
};

export default function GetItOn({
  type,
  handleOnClick,
  size,
  isClickable = true,
}: {
  type: GetItOnType;
  handleOnClick?: () => void;
  isClickable?: boolean;
  size: "normal" | "large";
}) {
  const clickEvent = () => {
    if (isClickable) {
      window.open(GET_IT_ON_LINKS[type], "_blank");
    }
    if (handleOnClick) handleOnClick();
  };

  return (
    <button className="flex-shrink-0 mr-4" onClick={() => clickEvent()}>
      <img
        className={size === "large" ? "h-14" : "h-9"}
        alt={`download-on-${type}`}
        src={`/images/get-it-on-${type}.png`}
      />
    </button>
  );
}
