import {
  AroundlyPlaces,
  SocialMediaUpdate,
  SupportedActions,
} from "../common/global.types";
import { Mixpanel } from "../services/mixpanel";
import { performUserActionHistory } from "../services/aroundly-apis";
import { useCollabListsContext } from "../context/CollabListsContext";

type AboutThePlaceProps = {
  placeDetails: AroundlyPlaces;
  updateDetails?: SocialMediaUpdate;
};

export default function AboutThePlace({
  placeDetails,
  updateDetails,
}: AboutThePlaceProps) {
  const { toggleStoreModal, toggleUpdateModal } = useCollabListsContext();

  return (
    <div className="w-full border-y mt-4 py-2 justify-between items-center inline-flex mb-2">
      <div className="flex-col justify-start items-start gap-1 inline-flex">
        <div className="flex gap-2 items-center w-full">
          <div className="justify-center items-center gap-1 inline-flex">
            <div className="text-zinc-800 text-base font-medium font-inter">
              <div
                onClick={async () => {
                  Mixpanel.track("Store Clicked", {
                    place_id: placeDetails._id,
                    update_id: updateDetails?._id,
                    ui_type: "feed-card",
                  });
                  await performUserActionHistory(SupportedActions.VIEW_STORE, {
                    place_id: placeDetails._id,
                    update_id: updateDetails?._id,
                    ui_type: "feed-card",
                  });
                  toggleUpdateModal(false);
                  toggleStoreModal(true, placeDetails._id);
                }}
                className="underline cursor-pointer"
              >
                {placeDetails.name}{" "}
              </div>
            </div>
          </div>
          <div className="text-neutral-500 text-base font-normal font-inter">
            {placeDetails?.google_maps_details?.rating}⭐
          </div>
        </div>

        <span className="text-secondary-text text-xs leading-5 font-normal font-inter">
          {placeDetails?.google_maps_details.formatted_address.slice(0, 36)}
          ...
        </span>
      </div>
    </div>
  );
}
