import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

interface ImageUrl {
  thumbnail_url?: string;
  media_url?: string;
}

interface EventCategory {
  id: string;
  name: string;
}
interface Props {
  updateTypeToShow: string | null;
  interest: string | null;
  resultsType: string;
  imageUrl?: ImageUrl;
  eventCategories: EventCategory[];
  toggleBeforeModal?: (value: boolean) => void;
  cuisineType: string ;
  updateHeader: string;
}

const DiscoverGeneralComp = ({
  imageUrl,
  interest,
  resultsType,
  updateTypeToShow,
  cuisineType,
  updateHeader,
  toggleBeforeModal,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`w-full ${
        (!interest ||
          resultsType === "search" ||
          updateTypeToShow === "event" ||
          (cuisineType && cuisineType?.length > 0)) &&
        "hidden"
      } relative`}
    >
      <img
        src={imageUrl?.thumbnail_url || imageUrl?.media_url || ""}
        alt=""
        className=" object-cover h-[260px] w-full"
      />

      <div
        className="absolute bottom-0 left-0  text-white h-full  w-full "
        style={{
          background: `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 20.71%, #000 88.69%)`,
        }}
      >
        <div className="absolute items-center py-2 px-3 flex justify-between w-full ">
          <ArrowLeftIcon
            onClick={() => navigate(-1)}
            className="h-6 w-6 inline-block text-white font-bold cursor-pointer"
          />
        </div>
        <div
          className="text-2xl font-medium absolute top-14 mx-3 text-white"
          style={{ letterSpacing: "-0.72px" }}
        >
          Discover <br />
          {updateHeader}
        </div>
        <div
          className={`${
            updateHeader !== "Food & Drinks" && "hidden"
          } text-xs   font-400 text-[#dadada] absolute top-32 flex justify-between  ml-3`}
        >
          Find latest updates and place recommendations
          <br /> from Zomato, Swiggy, Instagram and more
        </div>
        <div
          onClick={() => {
            toggleBeforeModal && toggleBeforeModal(true);
            // navigate("/app/feed/search");
          }}
          className="shadow-md rounded-full absolute top-32 right-3 border border-solid border-[#DCDEE3] items-center flex justify-center bg-white h-9 w-9 cursor-pointer"
        >
          <MagnifyingGlassIcon className="h-6 w-6 text-secondary-text flex-shrink-0" />
        </div>
      </div>
    </div>
  );
};
export default DiscoverGeneralComp;
