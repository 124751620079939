import { Mixpanel } from "../services/mixpanel";
import { useEffect } from "react";

export default function BizComponent() {
    useEffect(() => {
        const update = () => {
            Mixpanel.track("Clicked: Biz link clicked");
            window.location.href = `https://anudeeep.wixsite.com/aroundly`;
        }
        update();
    }, []);

    return (<></>);
}