import {
  // useLayoutEffect,
  useRef,
  useState,
} from "react";
import { UpdateSource } from "../common/global.types";
// import { useLocation } from "react-router-dom";
// import { useCapacitorInfo } from "../context/CapacitorContext";

type InstagramMediaType = "IMAGE" | "VIDEO" | "CAROUSEL_ALBUM";

type Props = {
  readonly mediaKey: string;
  readonly mediaType: InstagramMediaType;
  readonly thumbnailKey: string;
  readonly isMini?: boolean;
  readonly showFixedHeight?: boolean;
  readonly isFullView?: boolean;
  readonly source?: UpdateSource;
  readonly fixed_width?: number;
  readonly fixed_height?: number;
  readonly aroundlyPlace?: boolean;
  readonly masonryCondition?: boolean;
};

export default function LoadMedia({
  mediaKey,
  mediaType,
  showFixedHeight = true,
  thumbnailKey,
  source,
  isMini = false,
  // isFullView = false,
  fixed_width,
  fixed_height,
  aroundlyPlace,
  masonryCondition,
}: Props) {
  // const [mediaUrl, setMediaUrl] = useState<string>("");
  // const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  const elementRef = useRef<HTMLDivElement>(null);
  // const [width, setWidth] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(false);

  // useLayoutEffect(() => {
  //   setWidth(elementRef?.current?.offsetWidth || window.screen.width);
  // }, []);

  // useEffect(() => {
  //   const getUrl = async () => {
  //     try {
  //       if (mediaKey && mediaKey.length > 0) {
  //         const { url } = await getMediaUrl(mediaKey);
  //         setMediaUrl(url);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   getUrl();
  // }, [mediaKey]);

  // useEffect(() => {
  //   const getUrl = async () => {
  //     if (thumbnailKey && thumbnailKey.length > 0) {
  //       const { url } = await getMediaUrl(thumbnailKey);
  //       setThumbnailUrl(url);
  //     }
  //   };

  //   getUrl();
  // }, [thumbnailKey]);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause();
        setPlaying(false);
      } else {
        videoRef.current.play();
        setPlaying(true);
      }
    }
  };

  const handleVideoEnd = () => {
    setPlaying(false);
  };
  return (
    <div className={`flex items-center h-full justify-center`} ref={elementRef}>
      {/* height: isMini
//               ? "12rem"
//               : isFullView
//               ? source === "paytm insider" || source === "bookmyshow"
//                 ? "100%"
//                 : width
//               : Math.floor(width / 2) */}
      {/* {mediaKey && (mediaType === "IMAGE" || mediaType === "CAROUSEL_ALBUM") ? (
        <img
          style={{
            width: isMini ? "14rem" : "100%",
            height: isMini
              ? "12rem"
              : source === "paytm insider" || source === "bookmyshow"
              ? "100%"
              : "100%",
            objectFit: "cover",
          }}
          src={mediaKey}
          alt="media url"
          width={0}
          height={0}
          sizes="100vw"
          className="w-full rounded-xl"
          loading="lazy"
        />
      ) : !mediaKey && thumbnailKey ? (
        <img
          style={{
            width: isMini ? "14rem" : "100%",
            height: isMini
              ? "12rem"
              : source === "paytm insider" || source === "bookmyshow"
              ? "100%"
              : "100%",
            objectFit: "cover",
          }}
          src={thumbnailKey}
          alt="media url"
          width={0}
          height={0}
          sizes="100vw"
          className="w-full rounded-xl "
          loading="lazy"
        />
      ) : (
        <div className="relative w-full h-full rounded-xl">
          <video
            ref={videoRef}
            src={mediaKey + "#t=0.003"}
            style={{
              maxWidth: isMini ? "14rem" : "100%",
              height: isMini ? "12rem" : "100%",
            }}
            className="w-full h-full object-contain rounded-xl bg-black"
            controls={false}
            autoPlay={playing}
          />
          <div
            className="absolute z-10 top-7 left-0 w-full h-1/2 flex items-end justify-center cursor-pointer"
            onClick={handlePlayPause}
          >
            <img
              alt={playing ? "pause" : "play"}
              src={`/images/${playing ? "pause" : "play"}.svg`}
              className="h-10 w-10"
            />
          </div>
        </div>
      )} */}
      <div className="relative w-full h-full">
        {mediaType === "VIDEO" && mediaKey ? (
          <>
            <video
              ref={videoRef}
              src={mediaKey}
              style={{
                maxWidth: isMini && !showFixedHeight ? "14rem" : "100%",
                height: isMini && !showFixedHeight ? "12rem" : "100%",
              }}
              poster={thumbnailKey}
              onEnded={handleVideoEnd}
              className={`w-full h-full object-contain ${
                isMini ? "rounded-t-lg" : ""
              } bg-[#000]`}
              controls={false}
              preload="none"
              loop
            />
            <div
              className="absolute z-auto top-[40%] bottom-0 left-0 w-full h-[50px] flex items-end justify-center cursor-pointer"
              onClick={handlePlayPause}
            >
              <img
                alt={playing ? "pause" : "play"}
                src={`/images/${playing ? "pause" : "play"}.svg`}
                className="h-10 w-10"
              />
            </div>
          </>
        ) : (
          <img
            style={{
              width: isMini && !showFixedHeight ? "14rem" : "100%",
              height:
                isMini && !showFixedHeight
                  ? "12rem"
                  : aroundlyPlace
                  ? `${fixed_height}rem`
                  : "100%",
              objectFit: "cover",
            }}
            src={mediaKey ? mediaKey : "/images/Media URL Static.png"}
            alt="media url"
            width={showFixedHeight ? fixed_width : 0}
            height={showFixedHeight ? fixed_height : 0}
            sizes="100vw"
            className={`w-full ${
              isMini && !masonryCondition
                ? "rounded-t-lg"
                : aroundlyPlace
                ? "rounded-xl"
                : ""
            }`}
            loading="lazy"
          />
        )}
      </div>
    </div>
  );
}

// import { useLayoutEffect, useRef, useState } from "react";
// import { UpdateSource } from "../common/global.types";

// type InstagramMediaType = "IMAGE" | "VIDEO" | "CAROUSEL_ALBUM";

// type Props = {
//   readonly mediaKey: string;
//   readonly mediaType: InstagramMediaType;
//   readonly thumbnailKey: string;
//   readonly isMini?: boolean;
//   readonly isFullView?: boolean;
//   readonly source: UpdateSource;
// };

// export default function LoadMedia({
//   mediaKey,
//   mediaType,
//   thumbnailKey,
//   source,
//   isMini = false,
//   isFullView = false,
// }: Props) {
//   // const [mediaUrl, setMediaUrl] = useState<string>("");
//   // const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
//   const elementRef = useRef<HTMLDivElement>(null);
//   const [width, setWidth] = useState(0);

//   useLayoutEffect(() => {
//     setWidth(elementRef?.current?.offsetWidth || window.screen.width);
//   }, []);

//   // useEffect(() => {
//   //   const getUrl = async () => {
//   //     try {
//   //       if (mediaKey && mediaKey.length > 0) {
//   //         const { url } = await getMediaUrl(mediaKey);
//   //         setMediaUrl(url);
//   //       }
//   //     } catch (e) {
//   //       console.log(e);
//   //     }
//   //   };

//   //   getUrl();
//   // }, [mediaKey]);

//   // useEffect(() => {
//   //   const getUrl = async () => {
//   //     if (thumbnailKey && thumbnailKey.length > 0) {
//   //       const { url } = await getMediaUrl(thumbnailKey);
//   //       setThumbnailUrl(url);
//   //     }
//   //   };

//   //   getUrl();
//   // }, [thumbnailKey]);

//   return (
//     <div
//       className={`flex items-center justify-center bg-[#000000] rounded-lg ${
//         isMini ? "shadow" : ""
//       } ${isFullView ? "border" : ""}`}
//       ref={elementRef}
//     >
//       {mediaKey && (mediaType === "IMAGE" || mediaType === "CAROUSEL_ALBUM") ? (
//         <img
//           style={{
//             maxWidth: isMini ? "14rem" : "100%",
//             height: isMini
//               ? "12rem"
//               : isFullView
//               laysInline
//               preload="none"
//               // autoPlay
//             />
//           )}
//         </div>
//       );
//     }   ? source === "paytm insider" || source === "bookmyshow"
//                 ? "100%"
//                 : width
//               : Math.floor(width / 2),
//           }}
//           src={mediaKey}
//           alt="media url"
//           width={0}
//           height={0}
//           sizes="100vw"
//           className={`w-full ${
//             isFullView ? "object-contain" : "object-cover"
//           } rounded-lg`}
//           loading="lazy"
//         />
//       ) : !mediaKey && thumbnailKey ? (
//         <img
//           style={{
//             maxWidth: isMini ? "14rem" : "100%",
//             height: isMini
//               ? "12rem"
//               : isFullView
//               ? source === "paytm insider" || source === "bookmyshow"
//                 ? "100%"
//                 : width
//               : Math.floor(width / 2),
//           }}
//           src={thumbnailKey}
//           alt="thumbnail url"
//           width={0}
//           height={0}
//           sizes="100vw"
//           className={`w-full ${
//             isFullView ? "object-contain" : "object-cover"
//           } rounded-lg`}
//           loading="lazy"
//         />
//       ) : (
//         <video
//           src={mediaKey}
//           style={{
//             maxWidth: isMini ? "14rem" : "100%",
//             height: isMini
//               ? "12rem"
//               : isFullView
//               ? source === "paytm insider" || source === "bookmyshow"
//                 ? "100%"
//                 : width
//               : Math.floor(width / 2),
//           }}
//           className="w-full object-contain"
//           poster={thumbnailKey}
//           controls
//           muted
//           playsInline
//           preload="none"
//           // autoPlay
//         />
//       )}
//     </div>
//   );
// }
