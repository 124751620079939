import {
  Link,
  // useNavigate
} from "react-router-dom";
import { useAuth } from "../context/auth";
import {
  // ArrowLeftIcon,
  ArrowRightStartOnRectangleIcon,
  PencilSquareIcon,
  // PencilSquareIcon,
} from "@heroicons/react/24/outline";
// import AroundlyShare from "./AroundlyShare";

export const Profile = () => {
  const { authUser, logout } = useAuth();
  // const navigate = useNavigate();

  return (
    <div className="px-3">
      <div className=" flex p-4 justify-between border rounded-lg ">
        <div className=" flex gap-2  justify-between">
          <div className="flex justify-between">
            <img
              src={`/images/${authUser?.gender}.svg`}
              alt="profile icon"
              height={64}
              width={64}
            />
          </div>
          <div className="flex gap-3 flex-col font-medium justify-center">
            {authUser?.name && (
              <div className={` leading-6  text-black`}>{authUser.name}</div>
            )}

            {authUser && (
              <div className={` leading-6   text-black`}>{authUser?.phone}</div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-start">
          <Link to={"/app/feed/edit"} className={` text-primary`}>
            <PencilSquareIcon className="h-6 w-6 text-black" />
          </Link>
          <button
            onClick={() => logout && logout()}
            className={` text-primary ml-5`}
          >
            <ArrowRightStartOnRectangleIcon className="h-6 w-6 text-red-500" />
          </button>
        </div>
      </div>
    </div>
  );
};
