
import { ClipLoader } from "react-spinners";

interface NewButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onNext: () => void;
  children: React.ReactNode;
  isGreen?: boolean;
  isAbsolute?: boolean;
  loading?: boolean;
}

export default function NewButton({
  onNext,
  children,
  className,
  isGreen,
  style,
  isAbsolute,
  loading = false,
  ...props
}: NewButtonProps) {
  return (
    <button
      disabled={loading}
      onClick={onNext}
      style={{ letterSpacing: "4px", ...style }}
      className={`circularStd text-white px-12 py-4 relative text-xl h-12 rounded-full flex justify-center items-center ${
        isGreen ? `bg-[#14AE5C]` : "bg-primary"
      } ${className}`}
      {...props}
    >
      {loading && (
        <div className="flex justify-center items-center absolute inset-0">
          <ClipLoader
            color={"#fff"}
            loading={loading}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <div
        className="transition-opacity flex items-center justify-center text-xl font-bold tracking-tighter4"
        style={{ opacity: !loading ? 1 : 0 }}
      >
        {children}
      </div>
    </button>
  );
}
