const Percentage = ({ step, total }: { step: number; total: number }) => {
  return (
    <div className="flex items-center mx-6">
      <div className="bg-[#EEEEEE] rounded-full h-3 grow">
        <div
          className={`bg-primary rounded-full h-3 `}
          style={{ width: `${(step / total) * 100}%` }}
        ></div>
      </div>
      <div className="ml-6">
        {step}/{total}
      </div>
    </div>
  );
};

export default Percentage;
