import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useFeedData } from "../context/FeedDataContext";
import { HomeIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { HomeIcon as HomeIconSolid } from "@heroicons/react/24/solid";
import { useCollabListsContext } from "../context/CollabListsContext";

type TabsProps = {
  readonly tabsList: {
    id: string;
    name: string;
    url: string;
    selected: any;
    unselected: any;
  }[];
};

export default function Tabs({ tabsList }: TabsProps) {
  const location = useLocation();
  const { showModal } = useFeedData();
  const navigate = useNavigate();
  const { toggleShowCommunity } = useCollabListsContext();
  return (
    <div
      className={`h-[80px] max-w-md w-full fixed bottom-0 z-20 bg-white border-t border-t-[#DCDEE3]  ${
        showModal === true && "hidden"
      }`}
    >
      <div className="flex justify-around px-6  items-center h-full">
        <div className="w-20">
          <div
            className={` ${
              location.pathname.includes("aroundme")
                ? "border-[#5D34EC]"
                : "border-black"
            } ${
              location.pathname === "/app/feed" ||
              location.pathname === "/app/feed/aroundme"
                ? "text-black"
                : "opacity-50"
            } border rounded-full border-solid flex gap-2 p-[2px] h-10  items-center`}
          >
            <div
              onClick={() => navigate("/app/feed")}
              className={` ${
                !location.pathname.includes("aroundme")
                  ? "bg-black text-white"
                  : "bg-white text-black"
              } flex h-8 w-8 cursor-pointer flex-col rounded-full   text-xs  justify-center items-center gap-1`}
            >
              <HomeIcon className="h-4 w-4" />
            </div>
            <div
              onClick={() => navigate("/app/feed/aroundme")}
              className={`h-8 w-8 rounded-full cursor-pointer ${
                location.pathname.includes("aroundme")
                  ? "bg-[#5D34EC]"
                  : "bg-white"
              } text-center items-center flex justify-center `}
            >
              <img
                src="/images/MapPin.png"
                className="h-auto w-4"
                alt="Mappin"
              />
            </div>
          </div>
          <p
            className={`${
              location.pathname === "/app/feed" ||
              location.pathname === "/app/feed/aroundme"
                ? "text-black"
                : "opacity-50"
            } text-xs font-450 items-center text-center pt-1 whitespace-nowrap`}
            style={{ letterSpacing: "0.36px" }}
          >
            {location.pathname.includes("/app/feed/aroundme")
              ? "AROUND ME"
              : "HOME"}
          </p>
        </div>
{/* 
        {tabsList.map((tab) => (
          <NavLink
            key={tab.id}
            to={tab.url}
            className={({ isActive }) =>
              ` h-full flex flex-col text-xs  justify-center items-center  gap-1 ${
                isActive ? "text-medium" : "opacity-50"
              }`
            }
            id={`navigation-link`}
            end
          >
            <div className="p-2">
              {location.pathname === tab.url ? (
                <tab.selected className="h6 w-6 inline-block" />
              ) : (
                <tab.unselected className="h6 w-6 inline-block" />
              )}
            </div>

            {tab.name}
          </NavLink>
        ))} */}

        <div
          onClick={() => toggleShowCommunity(true)}
          className=" h-full flex flex-col text-xs  justify-center items-center gap-1 cursor-pointer"
        >
          <div className="p-2">
            <img
              src="/images/CommunityIcon.svg"
              alt="whatsapp"
              className="h-6 w-6"
            />
          </div>

          <p
            className="text-[#28B446] text-xs font-450"
            style={{ letterSpacing: "-0.36px" }}
          >
            IDEAS?
          </p>
        </div>
      </div>
    </div>
  );
}


