import { createContext, useContext, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Device, DeviceId } from "@capacitor/device";
import { App } from "@capacitor/app";
import { AppUpdate } from "@capawesome/capacitor-app-update";

type PlatformType = "web" | "android" | "ios";
type OperatingSystemType = "ios" | "android" | "windows" | "mac" | "unknown";

interface CapacitorContextProps {
  platform: PlatformType;
  isNative: boolean;
  operatingSystem: OperatingSystemType;
  currentAppVersion: string;
  currentAppBuild: string;
  deviceId: DeviceId | undefined;
}

const CapacitorContext = createContext({} as CapacitorContextProps);

export function CapacitorProvider({ children }: { children: React.ReactNode }) {
  const [platform] = useState<PlatformType>(
    Capacitor.getPlatform() as PlatformType
  );
  const [operatingSystem, setOperatingSystem] =
    useState<OperatingSystemType>("unknown");
  const [currentAppVersion, setCurrentAppVersion] = useState("");
  const [deviceId, setDeviceId] = useState<DeviceId>();
  const [currentAppBuild, setCurrentAppBuild] = useState("0");
  const [isNative] = useState(Capacitor.isNativePlatform());
  // const [showAppUpdateModal, setShowAppUpdateModal] = useState(false);

  useEffect(() => {
    const checkCapacitorInfo = async () => {
      const deviceInfo = await Device.getInfo();
      const currentDeviceId = await Device.getId();

      setDeviceId(currentDeviceId);
      setOperatingSystem(deviceInfo.operatingSystem);

      if (isNative) {
        const appInfo = await App.getInfo();
        setCurrentAppVersion(`v${appInfo.version} (${appInfo.build})`);
        setCurrentAppBuild(appInfo.build);

        const appUpdateInfo = await AppUpdate.getAppUpdateInfo();

        if (appUpdateInfo.availableVersionCode) {
          if (
            appUpdateInfo.availableVersionCode >
            appUpdateInfo.currentVersionCode
          ) {
            if (Capacitor.getPlatform() === "android") {
              await AppUpdate.performImmediateUpdate();
            } else {
              await AppUpdate.openAppStore();
            }
          }
        }
      }
    };

    checkCapacitorInfo();
  }, [isNative]);

  return (
    <CapacitorContext.Provider
      value={{
        platform,
        isNative,
        deviceId,
        operatingSystem,
        currentAppVersion,
        currentAppBuild,
      }}
    >
      {children}
    </CapacitorContext.Provider>
  );
}

export const useCapacitorInfo = () => useContext(CapacitorContext);
