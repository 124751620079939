import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import LocationPicker from "./LocationPicker";
import OptionSelectControl from "./OptionsSelectControl";
import {
  TIMES_LIST,
  UPDATE_TYPE_EVENT,
  UPDATE_TYPE_OFFER,
} from "../common/global.constants";
import { Mixpanel } from "../services/mixpanel";
import IsBestCheckBox from "./Bestcheck";
import { useLocation, useSearchParams } from "react-router-dom";
import { InterestProps } from "../common/global.types";
import { useState } from "react";

const Filters = ({
  resultsType,
  interests,
  updateTypeToShow,
  istemplate,
  setIsTemplate,
  setRelevance,
  relevance,
  setSelectedtime,
  selectedTime,
  isBest,
  setIsbest,
  showhandpicked,
}: {
  resultsType: string | null;
  interests: InterestProps[];
  updateTypeToShow: string;
  setIsTemplate: (status: boolean) => void;
  istemplate: boolean;
  setRelevance?: (state: string) => void;
  relevance?: string;
  setSelectedtime?: (state: string) => void;
  selectedTime?: string;
  setIsbest?: (state: boolean) => void;
  isBest?: boolean;
  showhandpicked?: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="justify-start items-center gap-1 flex overflow-x-scroll scrollbar-hide">
      <OptionSelectControl
        selectedId={relevance as string}
        defaultText="Relevance"
        defaultPreText="Sort by:"
        listHeading="Sort Preference"
        items={[
          {
            id: "relevance",
            name: "Relevance",
          },
          {
            id: "distance",
            name: "Nearest",
          },
          {
            id: "upcomming",
            name: "Upcomming",
          },
        ]}
        onListSelect={(id) => {
          Mixpanel.track(`Sort by Option Clicked`, {
            old_option: searchParams.get("sort_by") || "",
            new_option: id,
            ui_type: "results-page-sort-filter",
          });
          setRelevance && setRelevance(id);
          window.scrollTo(0, 0);
        }}
      />

      {selectedTime && (
        <OptionSelectControl
          selectedId={selectedTime || ""}
          defaultText="This Week"
          listHeading="Time"
          items={TIMES_LIST}
          onListSelect={(id) => {
            setSelectedtime && setSelectedtime(id);
            window.scrollTo(0, 0);
          }}
        />
      )}

      {/* {resultsType === "search" &&
        interests.length > 0 &&
        updateTypeToShow === "event" && (
          <OptionSelectControl
            selectedId={searchParams.get("interest") || ""}
            defaultText="All"
            listHeading="Category"
            defaultPreText="Category:"
            items={[
              {
                id: "all",
                name: "All",
              },
              ...interests.map((i) => ({
                id: i._id,
                name: i.interest_name,
              })),
            ]}
            onListSelect={(id) => {
              Mixpanel.track(`Category Option Selected`, {
                old_option: searchParams.get("interest") || "",
                new_option: id,
                ui_type: "results-page-category-filter",
              });
              if (id === "all") {
                searchParams.delete("interest");
              } else {
                searchParams.set("interest", id);
              }
              setSearchParams(searchParams);
              window.scrollTo(0, 0);
            }}
          />
        )} */}

      {resultsType === "search" &&
        interests?.length > 0 &&
        updateTypeToShow !== "event" &&
        resultsType !== "search" && (
          <OptionSelectControl
            selectedId={searchParams.get("interest") || ""}
            defaultText="All"
            listHeading="Category"
            defaultPreText="Category:"
            items={[
              {
                id: "all",
                name: "All",
              },
              ...interests.map((i) => ({
                id: i._id,
                name: i.interest_name,
              })),
            ]}
            onListSelect={(id) => {
              Mixpanel.track(`Category Option Selected`, {
                old_option: searchParams.get("interest") || "",
                new_option: id,
                ui_type: "results-page-category-filter",
              });
              if (id === "all") {
                searchParams.delete("interest");
              } else {
                searchParams.set("interest", id);
              }
              setSearchParams(searchParams);
              window.scrollTo(0, 0);
            }}
          />
        )}

      {/* { (
          <OptionSelectControl
            selectedId={isBest || ""}
            defaultText="Is Best"
            listHeading=""
            items={[
              {
                id: "true",
                name: "Handpicked",
              },
              {
                id: "false",
                name: "All",
              },
            ]}
            onListSelect={(id) => {
              Mixpanel.track(`Is Best Option Clicked`, {
                old_option: searchParams.get("is_best") || "",
                new_option: id,
                ui_type: "results-page-isbest-filter",
              });
              searchParams.set("is_best", id);
              setSearchParams(searchParams);
            }}
          />
        )} */}
      {showhandpicked && (
        <IsBestCheckBox
          isBest={isBest as boolean}
          setisBest={setIsbest as (state: boolean) => void}
        />
      )}
    </div>
  );
};
export default Filters;
