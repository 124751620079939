import { useNavigate } from "react-router-dom";
import GetItOnContainer from "../components/GetItOnContainer";
import { HeaderWithLogoAndName } from "../components/HeaderLogoWithName";

export const MissedRegistration = ({ isMissed }: { isMissed?: boolean }) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    setTimeout(() => {
      navigate("/login");
    }, 10000);
  };

  return (
    <div className="px-3 mt-2 py-2 bg-primary bg-opacity-5 rounded-xl">
      {isMissed ? (
        <div className="text-zinc-800 text-base font-bold leading-snug">
          Missed completing your pre-registration? <br /> Click below to
          complete it
        </div>
      ) : (
        <div>
          <div className="text-zinc-800 text-base font-bold leading-snug">
            Choose your preferred store and click
          </div>
          <div className="text-base font-bold leading-snug">
            {" "}
            <span className="text-primary">Pre-register</span> or{" "}
            <span className="text-primary">Pre-order</span>
          </div>
        </div>
      )}
      <div className="justify-start items-start gap-4 inline-flex mt-4">
        <GetItOnContainer handleOnClick={handleOnClick} />
      </div>
    </div>
  );
};

export default function CompleteRegistration() {
  return (
    <div className="bg-white h-dvh">
      <div className="w-full flex items-center justify-between px-2">
        <HeaderWithLogoAndName preventClick />
      </div>
      <div className="py-12 px-3">
        <div className="text-[2.5rem] font-medium leading-[3rem] mb-4">
          Complete your <br />
          Pre-registration
        </div>
        <MissedRegistration />
      </div>
    </div>
  );
}
