import { useNavigate } from "react-router-dom";
// import { UPDATE_TYPE_EVENT } from "../common/global.constants";
import { Mixpanel } from "../services/mixpanel";
import { useState } from "react";
import { useFeedData } from "../context/FeedDataContext";

const CategoryCard = ({
  navigateTo,
  imgSrc,
  label,
  isComingSoon,
  eatupdateslength,
  eventsUpdtateslength,
  showDot,
}: {
  navigateTo?: string;
  imgSrc: string;
  label: any;
  isComingSoon?: boolean;
  eatupdateslength?: number;
  eventsUpdtateslength?: number;
  showDot?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="border rounded-lg border-[#DCDEE3] border-solid min-h-32 cursor-pointer p-2"
      onClick={() => {
        if (navigateTo) {
          navigate(navigateTo);
          Mixpanel.track("Home - Category Click", {
            label,
            ui_type: "eat-offers-events",
          });
        }
      }}
    >
      <img
        src={imgSrc}
        alt={label}
        className={`${
          isComingSoon ? "opacity-20" : ""
        } w-[84px] h-16 mb-2 rounded-lg border border-[#DCDEE3]`}
      />
      {isComingSoon && (
        <div
          style={{
            // bottom: "26px",
            left: "12px",
            top: "28%",
            letterSpacing: "-0.36px",
          }}
          className="absolute h-5 whitespace-nowrap bg-[#F0FFFA] text-xs text-secondary-text leading-4 rounded-full px-1 border-[0.5px] border-[#DCDEE3]"
        >
          Coming soon
        </div>
      )}
      <p
        className={`${
          isComingSoon ? "opacity-20" : ""
        } flex justify-center text-xs`}
        style={{
          letterSpacing: "-0.333px",
        }}
      >
        {label}
      </p>
      {label !== "Shopping" && (
        <p
          className={`${
            showDot ? "text-[#007AFF]" : "text-secondary-text"
          } text-[10px] text-center`}
        >
          {showDot && <span className="text-[#F1511B]">• </span>}
          {label === "Eat"
            ? eatupdateslength && eatupdateslength > 10
              ? "10+"
              : eatupdateslength
            : eventsUpdtateslength && eventsUpdtateslength > 10
            ? "10+"
            : eventsUpdtateslength}{" "}
          updates
        </p>
      )}
    </div>
  );
};

const EatOffersEvent = ({
  eventsUpdtateslength,
  eatupdateslength,
  notificationeat,
  notificationexp,
}: {
  eventsUpdtateslength: number;
  eatupdateslength: number;
  notificationexp: boolean;
  notificationeat: boolean;
}) => {
  return (
    <div className={`mx-3 flex justify-between gap-3 rounded-xl`}>
      <CategoryCard
        navigateTo="/app/category/food-and-drinks"
        imgSrc="/images/categories/Food & Drinks.png"
        label="Eat"
        eatupdateslength={eatupdateslength}
        showDot={notificationeat}
      />
      <CategoryCard
        navigateTo={`/app/category/experiences`}
        imgSrc="/images/categories/Business & Tech.png"
        label="Experiences"
        eventsUpdtateslength={eventsUpdtateslength}
        showDot={notificationexp}
      />
      <div className="relative">
        <CategoryCard
          // navigateTo={`/app/results?update_type=${UPDATE_TYPE_OFFER}&time=week`}
          imgSrc="/images/categories/Shopping & Lifestyle.png"
          label="Shopping"
          isComingSoon={true}
        />
      </div>
      {/* <div className="relative">
        <CategoryCard
          // navigateTo="/app/results"
          imgSrc="/images/categories/Art & Craft.png"
          isComingSoon={true}
          label="Explore all"
        />
      </div> */}
    </div>
  );
};

export default EatOffersEvent;
