import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Mixpanel } from "../services/mixpanel";
import { useNavigate } from "react-router-dom";
import { AroundlyPlaces, LocationType } from "../common/global.types";

interface CuratedData {
  new_addition: AroundlyPlaces[];
  popular_cuisines: { cuisine_count: number; cuisine_name: string }[];
  public_favourites: AroundlyPlaces[];
  top_rated: AroundlyPlaces[];
  trending_now: AroundlyPlaces[];
  upcoming_events: AroundlyPlaces[];
}

const Guide = ({
  data,
  showViewMore,
  sliceLength,
  selectedLocation,
  setShowViewMore,
}: {
  data: CuratedData | undefined;
  setShowViewMore: (status: boolean) => void;
  showViewMore: boolean;
  sliceLength: number;
  selectedLocation: LocationType | null;
}) => {
  const navigate = useNavigate();
  return (
    <div className="mt-4 mx-3">
      <div className="text-base font-bold leading-5">
        Popular cuisines in {selectedLocation?.location_name}
      </div>
      <div className="mt-3 flex flex-wrap gap-1 pr-12">
        {data?.popular_cuisines
          ?.sort((a, b) => b.cuisine_count - a.cuisine_count)
          .slice(
            0,
            !showViewMore
              ? sliceLength
              : Math.min(16, data.popular_cuisines.length)
          )
          .map((val, index) => (
            <div
              key={index}
              onClick={() => {
                Mixpanel.track(`Popular Cuisines Option Clicked`, {
                  cuisineName: val.cuisine_name,
                  item_position: index + 1,
                  location: selectedLocation?.location_id,
                });
                navigate(
                  `/app/results?interest=6508dcea7d90ea7d81e8a01d&results_type=category&cuisine=${val.cuisine_name}`
                );
              }}
              className="py-2 cursor-pointer px-3 w-1/4 flex justify-center capitalize flex-grow items-center rounded-3xl border border-solid text-xs font-medium border-[#DCDEE3]"
            >
              {val.cuisine_name}
            </div>
          ))}
        <div
          onClick={() => setShowViewMore(!showViewMore)}
          className="py-2 px-3 cursor-pointer flex justify-center capitalize flex-grow items-center rounded-3xl border border-solid text-xs font-medium border-[#DCDEE3]"
        >
          {showViewMore ? "View Less" : "View More"}
          {showViewMore ? (
            <ChevronUpIcon className="ml-1 font-medium h-4 w-4 text-black" />
          ) : (
            <ChevronDownIcon className="ml-1 font-medium h-4 w-4 text-black" />
          )}
        </div>
      </div>
    </div>
  );
};
export default Guide;
