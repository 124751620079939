import {
  MagnifyingGlassIcon,
  ShareIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { ArrowLeftIcon as ArrowLeftIconSolid } from "@heroicons/react/16/solid";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useFeedData } from "../context/FeedDataContext";
import { EmptyCard } from "../components/Emptycard";
import {
  AroundlyPlaces,
  LocationType,
  SocialMediaUpdate,
} from "../common/global.types";
import { Mixpanel } from "../services/mixpanel";
import { useAuth } from "../context/auth";
import PastFeedCard from "../components/PastFeedCard";

import LoadingComp from "../components/LoadingComp";
// import { useResultsPageContext } from "../context/ResultsPageContext";
import { useFoodandDrinks } from "../context/FoodAndDrinks";
import CategoryCard from "../components/CategoryCard";
import MapComp from "../components/MapComp";
import Guide from "../components/Guide";
import ForYouPlacesComp from "../components/ForYouPlacesComp";
import MasonryComponent from "../components/MasonryComponent";
import Filters from "../components/Filters";
import useForYouUpdates from "../hooks/useForYouUpdates";
import SavedToggle from "../components/SavedToggle";
import ButtonControl from "../components/controls/ButtonControl";
import { shareLogic } from "../common/functions";
import useUpcomingLength from "../hooks/useUpcomingLength";
import PlacesTab from "../components/PlacesTab";
import { useCollabListsContext } from "../context/CollabListsContext";
import { useLocationInfo } from "../context/LocationContext";
import { getDistanceFromLatLonInKm } from "../common/distance-utils";
import EmptySave from "../components/EmptySave";
import PlacesComponent from "../components/PlacesComponent";
import SavedComponent from "../components/SavedComponent";
import ForyouComponent from "../components/ForyouComponent";

const FoodAndDrinks = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showViewMore, setShowViewMore] = useState(false);
  const { loading, authUser } = useAuth();
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isUpdatesTab, setIsUpdatesTab] = useState(true);
  const resultsType = searchParams.get("results_type");
  const updateTypeToShow = "review";
  const [savedrelevance, setsavedRelevance] = useState("relevance");

  const [placesrelevance, setplacesRelevance] = useState("relevance");

  const {
    toggleBeforeModal,
    interests,
    selectedLocation,
    updates,
    handleSearchText,
  } = useFeedData();

  const [filteredWishlistPlacedata, setFilteredWishlistData] = useState<
    AroundlyPlaces[]
  >([]);
  const [isBest, setisBest] = useState(false);
  const [notificationeat, setnotificationeat] = useState(false);
  const [sortedPlaces, setSortedPlaces] = useState<AroundlyPlaces[]>([]);
  const { collabLists, setSelectedWishlist, showErrorCollabdata } =
    useCollabListsContext();
  const {
    favorites,
    upcommingEvents,
    newAdd,
    topRated,
    trendingNow,
    data,
    showErrorModal,
    loadingCuration,
  } = useFoodandDrinks();

  useEffect(() => {
    setSelectedWishlist("Eat");

    return () => {
      setSelectedWishlist("");
    };
  }, [setSelectedWishlist]);

  const [activeSection, setActiveSection] = useState<
    "saved" | "foryou" | "guide" | "places"
  >("foryou");
  const [iswhite, setIsBGWhite] = useState(false);
  const { userCurrentPosition } = useLocationInfo();

  useEffect(() => {
    if (!loading && !authUser) {
      navigate("/login");
    }
  }, [authUser, loading, navigate]);

  useEffect(() => {
    if (placesrelevance === "distance" && userCurrentPosition) {
      const tempData = updates.map((place) => ({
        ...place,
        distance: getDistanceFromLatLonInKm(
          userCurrentPosition.coords.latitude,
          userCurrentPosition.coords.longitude,
          place.geo_location.coordinates[1],
          place.geo_location.coordinates[0]
        ),
      }));
      tempData.sort((a, b) => a.distance - b.distance);
      setSortedPlaces(tempData);
    } else {
      setSortedPlaces(updates);
    }
  }, [placesrelevance, updates, userCurrentPosition]);

  const initialData = useForYouUpdates(
    sortedPlaces,
    true,
    isBest,
    placesrelevance
  );

  //   useEffect(()=>{
  // initialData
  //   },[initialData])

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, initialData?.length);
  }, [initialData]);

  const sliceLength = data?.popular_cuisines
    ? Math.min(data.popular_cuisines?.length, 8)
    : 0;

  useEffect(() => {
    let wishListData = collabLists.find(
      (u) => u.is_default === true && u.name === "Eat"
    );

    let places: AroundlyPlaces[] | undefined = wishListData?.places.flatMap(
      (val) => ({
        ...val.place_id,
        updates: val.updates.map((u) => u.update_id),
      })
    );
    if (userCurrentPosition) {
      places = places?.map((place) => ({
        ...place,
        distance: getDistanceFromLatLonInKm(
          userCurrentPosition.coords.latitude,
          userCurrentPosition.coords.longitude,
          place.geo_location.coordinates[1],
          place.geo_location.coordinates[0]
        ),
      }));
    }

    if (savedrelevance === "distance") {
      let tempData = places;
      tempData = tempData && tempData.sort((a, b) => a.distance - b.distance);
      setFilteredWishlistData(tempData as AroundlyPlaces[]);
    } else {
      setFilteredWishlistData(places as AroundlyPlaces[]);
    }
  }, [userCurrentPosition, collabLists, savedrelevance]);

  const wishlistupdateData = useForYouUpdates(
    filteredWishlistPlacedata,
    false,
    isBest,
    savedrelevance
  );

  useEffect(() => {
    const handleScroll = () => {
      setIsBGWhite(window.scrollY > 275);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const { width: screenWidth } = window.screen;
    setWidth(screenWidth);
  }, []);

  const onShare = async () => {
    Mixpanel.track("Share Clicked", {
      ui_type: "eat-page",
      share_type: "eat",
    });
    const shareText = `Checkout my Eat page`;
    const urlToShare = `${process.env.REACT_APP_FRONTEND_URL}/app/category/food-and-drinks?referral_id=${authUser?.referal_id}`;

    await shareLogic(shareText, urlToShare);
  };

  const upcomingLength = useUpcomingLength(wishlistupdateData);

  useEffect(() => {
    const notificationEvent = JSON.parse(localStorage.getItem("Eat") as string);

    setnotificationeat(notificationEvent);

    return () => {
      localStorage.setItem("Eat", JSON.stringify(false));
    };
  }, []);

  return (
    <div
      className={`min-h-screen ${
        activeSection === "guide" ? "bg-white" : "bg-slate-100"
      } `}
    >
      <div className="w-full relative">
        <img
          src="/images/EatImage.png"
          alt=""
          className="object-cover h-[260px] w-full"
        />
        {/* <MapComp hideLocation height={260} /> */}

        <div
          className="absolute bottom-0 left-0  h-full w-full"
          style={{
            background: `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 2.92%, #000 60.23%)`,
          }}
        >
          <div className="absolute bottom-0 left-0  h-full w-full">
            <div
              className={`${
                iswhite ? "bg-white" : ""
              } flex justify-between px-3 py-2 fixed bg- max-w-md z-10 w-full top-0`}
            >
              <div>
                <ButtonControl
                  buttonId="back-button"
                  size="lg"
                  theme="white"
                  icon={{ component: ArrowLeftIconSolid }}
                  iconPlacement="start"
                  onClick={() => {
                    Mixpanel.track("Back Button Clicked", {
                      ui_type: "food-and-drinks-page",
                      location_id: selectedLocation?.location_id,
                    });
                    navigate(-1);
                  }}
                />
              </div>

              <div className="flex gap-1">
                <ButtonControl
                  buttonId="search-button"
                  size="lg"
                  theme="white"
                  icon={{ component: MagnifyingGlassIcon }}
                  iconPlacement="start"
                  onClick={() => {
                    Mixpanel.track("Search Clicked", {
                      ui_type: "food-and-drinks-page",
                      location_id: selectedLocation?.location_id,
                    });
                    handleSearchText("");
                    toggleBeforeModal && toggleBeforeModal(true);
                  }}
                />
                <ButtonControl
                  buttonId=""
                  size="lg"
                  theme="white"
                  name=""
                  icon={{ component: ShareIcon }}
                  iconPlacement="end"
                  onClick={onShare}
                />
              </div>
            </div>
            <div className="absolute bottom-12 left-3">
              <CategoryCard category="category" query="Eat" />
            </div>
          </div>
        </div>
      </div>
      {loadingCuration && (
        <div className="min-h-[calc(100vh-184px)] relative m-auto flex justify-start items-start mt-20">
          <LoadingComp primaryText="Loading" showSources={false} />
        </div>
      )}
      {!loadingCuration && (
        <div className="sticky top-[64px] z-10 py-1 bg-white">
          <ForYouPlacesComp
            tabsToShow={[
              { name: "Saved", id: "saved", showDot: false },
              { name: "For you", id: "foryou", showDot: notificationeat },
              { name: "Guide", id: "guide", showDot: false },
            ]}
            activeTabId={activeSection}
            setActiveTab={(tab) => {
              Mixpanel.track("Tab Clicked", {
                ui_type: tab.id,
                has_red_dot: tab.showDot,
                page: "food-and-drinks-page",
              });
              setActiveSection(tab.id);
            }}
            page="food"
          />
        </div>
      )}

      {activeSection === "guide" && (
        <div>
          <Guide
            data={data}
            selectedLocation={selectedLocation}
            setShowViewMore={setShowViewMore}
            showViewMore={showViewMore}
            sliceLength={sliceLength}
          />
          <hr className="my-6 border-0" />
          <Section
            title="Trending now"
            placeData={(data && data.trending_now) as AroundlyPlaces[]}
            data={trendingNow}
            selectedLocation={selectedLocation}
            secondryText="These are recently raved about by the locals"
          />
          <Section
            title="Top rated"
            secondryText="These are rated 4.0 and greater here by locals"
            placeData={(data && data.top_rated) as AroundlyPlaces[]}
            data={topRated}
            selectedLocation={selectedLocation}
          />

          <Section
            title="Public favorites"
            placeData={(data && data.public_favourites) as AroundlyPlaces[]}
            data={favorites}
            selectedLocation={selectedLocation}
            secondryText="These are the critically acclaimed by the locals"
          />
          <Section
            title="New additions"
            placeData={(data && data.new_addition) as AroundlyPlaces[]}
            data={newAdd}
            selectedLocation={selectedLocation}
            secondryText="These are newly opened in the last 3 months"
          />
        </div>
      )}

      {activeSection === "places" && (
        <PlacesComponent
          interests={interests}
          isBest={isBest}
          isUpdatesTab={isUpdatesTab}
          relevance={placesrelevance}
          resultsType={resultsType}
          setIsUpdatesTab={setIsUpdatesTab}
          setRelevance={setplacesRelevance}
          setisBest={setisBest}
          updateTypeToShow={updateTypeToShow}
          width={width}
          filteredData={upcommingEvents}
          showErrorModal={showErrorModal}
        />
      )}

      {!loadingCuration && activeSection === "foryou" && (
        <ForyouComponent
          interests={interests}
          isBest={isBest}
          isLoading={loadingCuration}
          isUpdatesTab={isUpdatesTab}
          relevance={placesrelevance}
          resultsType={resultsType}
          setIsUpdatesTab={setIsUpdatesTab}
          setRelevance={setplacesRelevance}
          setisBest={setisBest}
          updateTypeToShow={updateTypeToShow}
          width={width}
          showErrorModal={showErrorModal}
          forYouUpdatesSearchData={initialData}
          activeSection={activeSection}
          showhandpicked={true}
        />
      )}

      {activeSection === "saved" && (
        <SavedComponent
          forYouUpdatesWishlistData={wishlistupdateData}
          interests={interests}
          isBest={isBest}
          isUpdatesTab={isUpdatesTab}
          relevance={savedrelevance}
          resultsType={resultsType}
          setIsUpdatesTab={setIsUpdatesTab}
          setRelevance={setsavedRelevance}
          setisBest={setisBest}
          updateTypeToShow={updateTypeToShow}
          width={width}
          wishlistData={filteredWishlistPlacedata}
          upcomingLength={upcomingLength}
          showError={showErrorCollabdata}
          isResults={false}
          showhandpicked={true}
        />
      )}
      <div className="pb-4"></div>
    </div>
  );
};

const Section = ({
  title,
  data,
  selectedLocation,
  placeData,
  secondryText,
}: {
  title: string;
  data: SocialMediaUpdate[];
  selectedLocation: LocationType | null;
  placeData: AroundlyPlaces[];
  secondryText?: string;
}) => {
  return (
    <div className="">
      <div className="text-base font-bold leading-5 mx-3">
        {title} in {selectedLocation?.location_name}
      </div>
      <div className="text-xs text-secondary-text leading-5 mx-3">
        {secondryText}
      </div>
      <div className="flex overflow-x-scroll scrollbar-hide w-full mt-3 gap-3">
        {data.slice(0, 8).map((update: SocialMediaUpdate, index: number) => (
          <PastFeedCard
            isfullview={false}
            updateType={update.update_type}
            placeData={
              (update.related_aroundly_place_ids &&
                update.related_aroundly_place_ids[0]) as AroundlyPlaces
            }
            timezone={selectedLocation?.timezone as string}
            item={update}
            isLast={index === data?.length - 1}
            key={index}
            isfirst={index === 0}
          />
        ))}
        {data?.length === 0 && <EmptyCard locationName={""} />}
        {/* {data.length >= 8 && (
          <div
            className="border ml-2 cursor-pointer rounded   mr-6 bg-opacity-10 text-primary text-sm p-4 min-w-fit items-center justify-end inline-flex"
            onClick={() => {
              Mixpanel.track("View All Clicked", {
                ui_type: title,
                page: "food-and-drinks-page",
                location: selectedLocation?.location_id,
              });
              navigate(
                `/app/results?interest=${FOOD_AND_BEV_INTEREST_ID}&food_type=${title}`
              );
            }}
          >
            View all
            <ArrowRightIcon className="h-5 w-5 inline-block " />
          </div>
        )} */}
      </div>

      <hr className="my-6 border-0" />
    </div>
  );
};

export default FoodAndDrinks;
