import { Outlet } from "react-router-dom";
import { FeedDataProvider } from "../context/FeedDataContext";
import { CollabListsProvider } from "../context/CollabListsContext";
import { LocationProvider } from "../context/LocationContext";
import { ResultsPageProvider } from "../context/ResultsPageContext";
import { FoodAndDrinksProvider } from "../context/FoodAndDrinks";
import { YourSavedProvider } from "../context/Yoursaved";

export default function MainLayout() {
  return (
    <LocationProvider>
      <FeedDataProvider>
        <CollabListsProvider>
          <YourSavedProvider>
            <ResultsPageProvider>
              <FoodAndDrinksProvider>
                <Outlet />
              </FoodAndDrinksProvider>
            </ResultsPageProvider>
          </YourSavedProvider>
        </CollabListsProvider>
      </FeedDataProvider>
    </LocationProvider>
  );
}
