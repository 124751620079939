import { useSearchParams } from "react-router-dom";
import LocationPicker from "./LocationPicker";
import OptionSelectControl from "./OptionsSelectControl";
import { TIMES_LIST } from "../common/global.constants";

const FooterComp = ({
  isEvent,
  activeSection,
  setSelectedtime,
  selectedTime,
  showDateFooter = true,
}: {
  isEvent?: boolean;
  activeSection?: "saved" | "foryou" | "guide" | "places";
  setSelectedtime?: (status: string) => void;
  selectedTime?: string;
  showDateFooter?: boolean;
}) => {
  return (
    <div className={`pb-32 pt-3`}>
      <div className="p-3 rounded-xl  mx-3 bg-white ">
        <div
          className="text-center text-sm font-medium   "
          style={{ letterSpacing: "-0.42px" }}
        >
          End of results in
        </div>
        <div className="py-2 flex gap-3 items-center justify-center">
          {showDateFooter && (
            <OptionSelectControl
              selectedId={selectedTime || ""}
              defaultText="This Week"
              listHeading="Time"
              items={TIMES_LIST}
              onListSelect={(id) => {
                setSelectedtime && setSelectedtime(id);
                window.scrollTo(0, 0);
              }}
            />
          )}
          <div className="px-3 py-2 rounded-full bg-special-blue bg-opacity-10 text-special-blue justify-center items-center gap-1 flex text-nowrap">
            <LocationPicker />
          </div>
        </div>
        <div
          className="text-secondary-text text-xs font-450 flex items-center w-full justify-center"
          style={{ letterSpacing: "-0.36px" }}
        >
          Change to discover more
        </div>
      </div>
    </div>
  );
};
export default FooterComp;
