import { useNavigate } from "react-router-dom";
import { SocialMediaUpdate } from "../common/global.types";
import UpdateImage from "../components/UpdateImage";
import { useAuth } from "../context/auth";
import { FOOD_AND_BEV_INTEREST_ID } from "../common/global.constants";
import { useFeedData } from "../context/FeedDataContext";

type UpdateCardProps = {
  readonly isLoading: boolean;
  readonly updatesList?: SocialMediaUpdate[];
  readonly imageName?: string;
  readonly updateHeader: string;
  readonly width: number;
  readonly height: number;
  readonly isOnboarding?: boolean;
  readonly isMini?: boolean;
  readonly onClick: () => any;
};
export default function UpdateCard({
  isLoading,
  updatesList,
  updateHeader,
  imageName,
  width,
  height,
  isOnboarding = false,
  onClick,
  isMini = false,
}: UpdateCardProps) {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { toggleBeforeModal } = useFeedData();
  return (
    <div
      className={`rounded  ${
        isMini ? "flex flex-col items-center" : "h-32 border"
      } cursor-pointer min-w-16`}
      onClick={() => {
        onClick();
      }}
    >
      {isLoading ? (
        <div className="animate-pulse bg-gray-200 rounded h-20"></div>
      ) : updatesList && updatesList.length > 0 && !isMini ? (
        <UpdateImage
          mediaKey={
            updatesList[0]?.social_media_update_details.thumbnail_url
              ? updatesList[0]?.social_media_update_details.thumbnail_url
              : updatesList[0]?.social_media_update_details.media_url
          }
          isRoundedFully={false}
          width={width}
          height={height}
          shouldContainObject={true}
        />
      ) : isMini ? (
        <img
          src={`/images/categories/${imageName}.png`}
          alt={`${updateHeader}`}
          className="rounded-lg object-cover"
          style={{ width, height }}
          width={width}
          height={height}
        />
      ) : (
        <img
          src="/images/null-image.png"
          alt="nothing to show"
          className="rounded-lg object-cover"
          style={{ width, height }}
          width={width}
          height={height}
        />
      )}
      <div className={`px-2 pb-2 pt-1 ${isMini ? "text-center" : ""}`}>
        <div
          className={`${
            isMini ? "text-xs font-normal" : "truncate"
          } text-black`}
        >
          {updateHeader}
        </div>
        {!isMini ? (
          <div className={`text-xs text-gray-500`}>
            {isLoading ? (
              <div className="animate-pulse bg-gray-200 rounded h-4"></div>
            ) : (
              `${updatesList && updatesList.length} around you`
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
