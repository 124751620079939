import { Preferences } from "@capacitor/preferences";
import { Device } from "@capacitor/device";

export const getTokenFromStorage = async () => {
  try {
    const token = await Preferences.get({
      key: "token",
    });
    return token.value;
  } catch (e) {
    console.log(e);
  }
};

export const setTokenToStorage = async (token: string) => {
  try {
    return await Preferences.set({
      key: "token",
      value: token,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getNotificationTokenFromStorage = async () => {
  try {
    const token = await Preferences.get({
      key: "notification_token",
    });
    return token.value;
  } catch (e) {
    console.log(e);
  }
};

export const setNotificationTokenToStorage = async (token: string) => {
  try {
    return await Preferences.set({
      key: "notification_token",
      value: token,
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeNotificationTokenFromStorage = async () => {
  try {
    return await Preferences.remove({
      key: "notification_token",
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeTokenFromStorage = async () => {
  try {
    return await Preferences.remove({
      key: "token",
    });
  } catch (e) {
    console.log(e);
  }
};

export const setDeviceId = async () => {
  try {
    const device_id = await Device.getId();
    console.log(device_id.identifier);
    await Preferences.set({
      key: "device_id",
      value: device_id.identifier,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getDeviceId = async () => {
  try {
    const deviceId = await Preferences.get({
      key: "device_id",
    });
    if (!deviceId.value) await setDeviceId();
    const getUpdatedDeviceId = await Preferences.get({
      key: "device_id",
    });
    return getUpdatedDeviceId.value;
  } catch (e) {
    console.log(e);
  }
};

export const setReferIdToStorage = async (referral_id: string) => {
  try {
    await Preferences.set({
      key: "referral_id",
      value: referral_id,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getReferIdFromStorage = async () => {
  try {
    const referral_id = await Preferences.get({
      key: "referral_id",
    });
    return referral_id.value;
  } catch (e) {
    console.log(e);
  }
}

export const setLocationToStorage = async (location: string) => {
  try {
    await Preferences.set({
      key: "location",
      value: location,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getLocationFromStorage = async () => {
  try {
    const location = await Preferences.get({
      key: "location",
    });
    return location.value;
  } catch (e) {
    console.log(e);
  }
};
