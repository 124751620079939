import { useEffect, useState } from "react";
import { AroundlyPlaces } from "../common/global.types";

const useShortAddress = (placeDetails: AroundlyPlaces) => {
  const [shortAddress, setShortAddress] = useState("");
  useEffect(() => {
    const addressComponents =
      placeDetails?.google_maps_details?.address_components || [];
    const addressParts = [
      addressComponents[2]?.long_name,
      addressComponents[3]?.long_name,
      addressComponents[4]?.long_name,
    ].filter((part) => part);



    const address = addressParts.join(" ");
    setShortAddress(address);
  }, [placeDetails?.google_maps_details?.address_components]);


  return shortAddress;
};
export default useShortAddress;