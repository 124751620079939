import { useNavigate, useSearchParams } from "react-router-dom";
import EventComponent from "../components/EventComponent";
import { useFeedData } from "../context/FeedDataContext";
import ForYouPlacesComp from "../components/ForYouPlacesComp";
import { act, useEffect, useState } from "react";
import Filters from "../components/Filters";
import { useAuth } from "../context/auth";
import { getImageUrl, shareLogic } from "../common/functions";
import { DEFAULT_TIMEZONE, eventCategories } from "../common/global.constants";
import MasonryComponent from "../components/MasonryComponent";
import {
  AroundlyPlaces,
  SocialMediaUpdate,
  UpdateType,
} from "../common/global.types";
import { useLocationInfo } from "../context/LocationContext";
import { getDistanceFromLatLonInKm } from "../common/distance-utils";
import SavedToggle from "../components/SavedToggle";
import LoadingComp from "../components/LoadingComp";
import { Mixpanel } from "../services/mixpanel";
import useUpcomingLength from "../hooks/useUpcomingLength";
import PlacesTab from "../components/PlacesTab";
import { useCollabListsContext } from "../context/CollabListsContext";
import EmptySave from "../components/EmptySave";
import { SparklesIcon } from "@heroicons/react/24/outline";
import filterBystate from "../utils/filterBystate";

const Event = () => {
  const [filteredData, setFilteredData] = useState<SocialMediaUpdate[]>([]);
  const [wishlistupdateData, setwishlistupdateData] = useState<
    SocialMediaUpdate[]
  >([]);

  const [limitedData, setLimitedData] = useState<SocialMediaUpdate[]>([]);
  const [searchParams] = useSearchParams();
  const interest = searchParams.get("interest");
  const resultsType = searchParams.get("results_type");
  const [relevance, setRelevance] = useState("relevance");

  const updateTypeToShow =
    searchParams.get("update_type") || "general_update_generated";
  const {
    updates,
    toggleBeforeModal,
    interests,
    feedLoading,
    handleSearchText,
  } = useFeedData();

  const { collabLists, setSelectedWishlist } = useCollabListsContext();
  const [sortedUpdates, setSortedUpdates] = useState<AroundlyPlaces[]>([]);
  const [wishlistplaceData, setwishlistPlaceData] = useState<AroundlyPlaces[]>(
    []
  );
  const [isBest, setisBest] = useState(false);
  const [selectedTime, setSelectedtime] = useState("week");
  const [showlessData, setShowlessdata] = useState(true);
  const { authUser } = useAuth();
  const [isUpdatesTab, setIsUpdatesTab] = useState(true);

  const imageUrl = getImageUrl(updates);
  const { userCurrentPosition } = useLocationInfo();
  const [activeSection, setActiveSection] = useState<
    "saved" | "foryou" | "guide" | "places"
  >("foryou");

  const [notificationexp, setnotificationexp] = useState(false);
  useEffect(() => {
    setSelectedWishlist("Experiences");

    return () => {
      setSelectedWishlist("");
    };
  }, [setSelectedWishlist]);

  useEffect(() => {
    let tempData = [...updates];

    if (relevance === "distance") {
      tempData.sort((a, b) => a.distance - b.distance);
    } else {
    }
    setSortedUpdates(tempData);
  }, [relevance, updates]);

  useEffect(() => {
    const updates = sortedUpdates.flatMap((place) => {
      const placeUpdates = place.updates || [];
      return placeUpdates.map((update) => ({
        ...update,
        related_aroundly_place_ids: [place],
      }));
    });

    let filteredUpdates: SocialMediaUpdate[] = updates.filter(
      (update) => update.update_type === "event"
    );

    if (isBest) {
      filteredUpdates = updates.filter((update) => update.is_best === true);
    }

    filteredUpdates = filterBystate(
      filteredUpdates,
      selectedTime,
      false,
      relevance
    );

    setFilteredData(filteredUpdates);
  }, [sortedUpdates, selectedTime, isBest, relevance]);

  useEffect(() => {
    const filteredUpdates =
      filteredData &&
      filteredData?.filter(
        (update) =>
          update.interest_categories &&
          update.interest_categories.some(
            (interestId) =>
              authUser &&
              authUser.interests &&
              authUser.interests.includes(interestId._id)
          )
      );

    setLimitedData(filteredUpdates);
  }, [authUser, filteredData]);

  useEffect(() => {
    let wishListData = collabLists.find(
      (u) => u.is_default === true && u.name === "Experiences"
    );

    let places: AroundlyPlaces[] | undefined = wishListData?.places.flatMap(
      (val) => ({
        ...val.place_id,
        updates: val.updates.map((u) => u.update_id),
      })
    );

    if (userCurrentPosition) {
      places = places?.map((place) => ({
        ...place,
        distance: getDistanceFromLatLonInKm(
          userCurrentPosition.coords.latitude,
          userCurrentPosition.coords.longitude,
          place.geo_location.coordinates[1],
          place.geo_location.coordinates[0]
        ),
      }));
    }

    if (relevance === "distance") {
      let tempData = places;
      tempData = tempData && tempData.sort((a, b) => a.distance - b.distance);
      setwishlistPlaceData(tempData as AroundlyPlaces[]);
    } else {
      setwishlistPlaceData(places as AroundlyPlaces[]);
    }
  }, [userCurrentPosition, collabLists, relevance]);

  useEffect(() => {
    let updates: SocialMediaUpdate[] =
      (wishlistplaceData &&
        wishlistplaceData.flatMap((place) => {
          const placeUpdates = place.updates || [];
          return placeUpdates.map((update) => ({
            ...update,
            related_aroundly_place_ids: [place],
          }));
        })) ||
      [];
    if (isBest) {
      updates = updates.filter((update) => update.is_best === true);
    }
    updates = filterBystate(updates, selectedTime, false, relevance);
    setwishlistupdateData(updates);
  }, [selectedTime, wishlistplaceData, isBest, relevance]);

  const upcomingLength = useUpcomingLength(wishlistupdateData);

  useEffect(() => {
    const notificationEvent = JSON.parse(localStorage.getItem("Exp") as string);

    setnotificationexp(notificationEvent);

    return () => {
      localStorage.setItem("Exp", JSON.stringify(false));
    };
  }, []);

  return (
    <div className="min-h-screen bg-slate-100">
      <EventComponent
        interest={interest}
        updateTypeToShow={updateTypeToShow}
        resultsType={resultsType as string}
        eventCategories={eventCategories}
        imageUrl={imageUrl}
        toggleBeforeModal={toggleBeforeModal}
        showImg={true}
        authUser={authUser}
        handleSearchText={handleSearchText}
      />

      {feedLoading && (
        <div className="min-h-[calc(100vh-184px)] relative m-auto flex justify-start items-start mt-20">
          <LoadingComp primaryText="Loading" showSources={false} />
        </div>
      )}

      {!feedLoading && (
        <div className="sticky top-[64px] z-10 mb-2">
          <ForYouPlacesComp
            tabsToShow={[
              { name: "Saved", id: "saved", showDot: false },
              { name: "For You", id: "foryou", showDot: notificationexp },
            ]}
            activeTabId={activeSection}
            setActiveTab={(tab) => {
              Mixpanel.track("Tab Clicked", {
                ui_type: tab.id,
                has_red_dot: tab.showDot,
                page: "experiences-page",
              });
              setActiveSection(tab.id);
            }}
            page="event"
          />
          <div className={`pl-3 py-2 top-0 bg-white w-full z-0`}>
            <Filters
              interests={interests}
              resultsType={resultsType}
              updateTypeToShow={"event"}
              istemplate={isUpdatesTab}
              setIsTemplate={setIsUpdatesTab}
              relevance={relevance}
              setRelevance={setRelevance}
              setSelectedtime={setSelectedtime}
              selectedTime={selectedTime}
              isBest={isBest}
              setIsbest={setisBest}
              showhandpicked={true}
            />
          </div>
        </div>
      )}
      {activeSection === "saved" && wishlistplaceData?.length > 0 && (
        <SavedToggle
          places={wishlistplaceData?.length || 0}
          upcoming={upcomingLength}
          updates={wishlistupdateData?.length || 0}
          istemplate={isUpdatesTab}
          setIsTemplate={(isUpdate) => {
            Mixpanel.track("Places/Updates Toggle Clicked", {
              ui_type: "collab-wishlist-page",
              toggle_type: isUpdate ? "updates" : "places",
            });
            setIsUpdatesTab(isUpdate);
          }}
        />
      )}

      <div className="w-full max-w-md flex flex-col gap-2">
        {!feedLoading && activeSection === "foryou" && (
          <>
            {
              <div className="flex gap-1 text-xs mx-3">
                <SparklesIcon className="text-[#00B078]  h-4 w-4" />
                <p className="text-[#00B078]">
                  {showlessData ? limitedData?.length : filteredData?.length}{" "}
                  Recommendations tailored to your saved
                </p>
              </div>
            }
            <MasonryComponent
              updates={showlessData ? limitedData : filteredData}
              isResults={false}
              ispageScrolled={false}
              activeSection={activeSection}
              setShowlessdata={setShowlessdata}
              showlessData={showlessData}
              selectedTime={selectedTime}
              updateType={updateTypeToShow as UpdateType}
              setSelectedtime={setSelectedtime}
            />
          </>
        )}

        {activeSection === "saved" && (
          <>
            {wishlistplaceData?.length > 0 ? (
              !isUpdatesTab ? (
                <PlacesTab data={wishlistplaceData} />
              ) : (
                <MasonryComponent
                  updates={wishlistupdateData}
                  isResults
                  selectedTime={selectedTime}
                  updateType={updateTypeToShow as UpdateType}
                  setSelectedtime={setSelectedtime}
                />
              )
            ) : (
              <div className="mx-3">
                <EmptySave />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Event;
