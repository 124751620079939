import { useAuth } from "../context/auth";
import { useCollabListsContext } from "../context/CollabListsContext";
import WishlistContainer from "./WishlistContainer";
import { useState } from "react";
import { imageName } from "../common/global.constants";
import { Profile } from "./Profile";
import LearnMore from "./LearnMore";
import SavedHeader from "./SavedHeader";
import WishlistEmptyState from "./WishlistEmptyState";
import AroundlyShare from "./AroundlyShare";
import SavedCircleBar from "./SavedCircleBar";

export default function Wishlist() {
  const { collabLists } = useCollabListsContext();
  const { authUser } = useAuth();
  const [showimg, setShowimg] = useState(false);
  const [isSaved, setIsSaved] = useState(true);

  const toggleImage = (state: boolean) => {
    setShowimg(state);
  };
  return (
    <>
      {showimg ? (
        <WishlistEmptyState
          authUser={authUser}
          collabLists={collabLists}
          imageName={imageName}
          toggleImage={toggleImage}
        />
      ) : (
        <div className=" pb-24 min-h-screen">
          <SavedHeader />
          <div className="flex flex-col gap-4">
            <Profile />
            <SavedCircleBar isSaved={isSaved} setIsSaved={setIsSaved} />
            {isSaved ? (
              <div>
                <WishlistContainer sortedcollablist={collabLists} />
                <LearnMore toggleImage={toggleImage} />
              </div>
            ) : (
              <div>
                <div>{authUser && <AroundlyShare />}</div>
                <LearnMore toggleImage={toggleImage} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
