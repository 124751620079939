import { useCapacitorInfo } from "../context/CapacitorContext";
import Appversion from "./Appversion";

const SavedHeader = () => {
  const { currentAppVersion } = useCapacitorInfo();
  return (
    <div className=" text-2xl font-medium flex items-center p-3">
      <div className="flex-grow">Profile</div>
      <Appversion currentAppVersion={currentAppVersion} />
    </div>
  );
};
export default SavedHeader;
