import { ReactElement, memo } from "react";
import LoadingSVG from "../custom-icons/LoadingSVG";

type ButtonTheme = "primary" | "secondary" | "white";

type ButtonSize = "sm" | "md" | "lg";

type ButtonControlDetails = {
  name?: string;
  buttonId: string;
  size: ButtonSize;
  fillType?: "solid" | "outline";
  icon?: { component: any } | null;
  iconPlacement?: "start" | "end";
  theme: ButtonTheme;
  buttonType?: "button" | "submit";
  disabled?: boolean;
  onClick: any;
  isLoading?: boolean;
  isBlockButton?: boolean;
  iconColor?: string;
};

/**
 * Function which returns the css classes for a button based on the theme.
 * @param theme - Theme of the button.
 * @returns - Classes of button.
 */
const getThemeClasses = (theme: ButtonTheme): string => {
  let themeClasses = "";

  if (theme === "primary") {
    themeClasses = "bg-primary text-white border-[0.5px] border-primary";
  } else if (theme === "secondary") {
    themeClasses =
      "border-[0.5px] bg-[#007AFF] border-[#007AFF] bg-special-blue text-white";
  } else if (theme === "white") {
    themeClasses = "bg-white text-black border-[0.5px] border-gray-300";
  }

  return themeClasses;
};

/**
 * Function which returns the css classes for a button based on the size.
 * @param size - Size of the button.
 * @param isBlockButton - Flag to make the button block size.
 * @returns - Classes of button.
 */
const getSizeClasses = (size: ButtonSize, isBlockButton: boolean): string => {
  let sizeClasses = "";

  if (size === "sm") {
    sizeClasses =
      "py-1 px-1 text-xs leading-6 font-medium tracking-wide rounded-lg";
  } else if (size === "md") {
    sizeClasses =
      "py-3 px-3 text-xs leading- font-medium tracking-wide rounded-lg";
  } else if (size === "lg") {
    sizeClasses =
      "py-3 px-3 text-sm leading-6 font-medium tracking-wide rounded-xl";
  }

  return sizeClasses + (isBlockButton ? " w-full" : "");
};

/**
 * Button Component which renders the UI based on the props.
 *
 * @param props - Button Component props.
 * @returns - Button Component HTML.
 */
function ButtonControl(props: ButtonControlDetails): ReactElement {
  const {
    name = "",
    buttonId = "",
    size = "sm",
    icon = null,
    iconPlacement = "start",
    theme = "primary",
    buttonType = "button",
    disabled = false,
    onClick,
    isBlockButton = false,
    isLoading = false,
    iconColor,
  } = props;

  const renderIcon = (icon: { component: any }, iconColor: string) => {
    const classNameMap = {
      sm: `h-4 w-4 inline-block text-${iconColor}`,
      md: `h-5 w-5 inline-block text-${iconColor}`,
      lg: `h-6 w-6 inline-block text-${iconColor}`,
      full: `h-6 w-6 inline-block text-${iconColor}`,
    };
    return <icon.component className={classNameMap[size]} />;
  };

  return (
    <button
      className={`flex flex-1 whitespace-nowrap items-center justify-center font-inter
        ${getThemeClasses(theme)} ${getSizeClasses(size, isBlockButton)} ${
        name && size === "lg" ? "px-6" : ""
      } cursor-pointer ${
        buttonId === "delete-button" &&
        disabled === false &&
        "text-[#f1511b] border-[#f1511b] font-semibold"
      } disabled:bg-white disabled:cursor-not-allowed`}
      type={buttonType}
      aria-label={name}
      id={buttonId}
      disabled={disabled || isLoading}
      name={name}
      onClick={onClick}
    >
      {icon && iconPlacement === "start" ? (
        <span className={`${name ? "mr-1" : ""}`}>
          {renderIcon(icon, iconColor as string)}
        </span>
      ) : null}
      <span>{isLoading ? <LoadingSVG /> : name}</span>
      {icon && iconPlacement === "end" ? (
        <span className={`${name ? "ml-1" : ""}`}>
          {renderIcon(icon, iconColor as string)}
        </span>
      ) : null}
    </button>
  );
}

export default memo(ButtonControl);
