import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Mixpanel from "mixpanel-browser"; // Ensure Mixpanel is properly set up

const IsBestCheckBox = ({
  isBest,
  setisBest,
}: {
  setisBest: (state: boolean) => void;
  isBest: boolean;
}) => {
  const [selected, setSelected] = useState(isBest);

  useEffect(() => {
    setSelected(isBest);
  }, [isBest]);

  const handleCheckboxChange = (event: any) => {
    const { checked } = event.target;

    Mixpanel.track("Is Best Option Clicked", {
      old_option: isBest ? "true" : "",
      new_option: checked,
      ui_type: "results-page-isbest-filter",
    });

    setisBest(checked);

    setSelected(checked);
  };

  return (
    <label
      htmlFor="is_best"
      className={`flex items-center text-xs text-center gap-1 border rounded-full py-2 px-3 ${
        selected
          ? "bg-special-blue bg-opacity-10 text-special-blue"
          : "bg-white text-stone-900"
      }`}
    >
      <p className="mr-1">Handpicked</p>

      <input
        type="checkbox"
        className="rounded outline-none focus:outline-none"
        id="is_best"
        checked={selected}
        onChange={handleCheckboxChange}
      />
    </label>
  );
};

export default IsBestCheckBox;
