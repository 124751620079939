import {
  AroundlyPlaces,
  SocialMediaUpdate,
  SupportedActions,
  UpdateType,
} from "../common/global.types";
import moment from "moment";
import "moment-timezone";
import LoadMedia from "../components/LoadMedia";
import { Mixpanel } from "../services/mixpanel";
import {
  Link,
  // useLocation, useNavigate
} from "react-router-dom";
import {
  UPDATE_TYPE_EVENT,
  UPDATE_TYPE_OFFER,
} from "../common/global.constants";
import LikeButton from "./likeBtn";
import { useFeedData } from "../context/FeedDataContext";
import { useEffect, useRef, useState } from "react";
import SourceModal from "./SourceModal";
import NotInterested from "./NotInterested";
import {
  performUserAction,
  performUserActionHistory,
} from "../services/aroundly-apis";
import { debounce } from "lodash";
import { useCollabListsContext } from "../context/CollabListsContext";
import HandPicked from "./HandPicked";

export default function PastFeedCard({
  item,
  isLast,
  updateType,
  timezone,
  placeData,
  isfirst,
  container_width,
  home,
  showFixedHeight = false,
  isfullview,
}: {
  readonly item: SocialMediaUpdate;
  readonly isLast: boolean;
  readonly isfirst?: boolean;
  readonly updateType: UpdateType;
  readonly timezone: string;
  readonly placeData: AroundlyPlaces;
  readonly container_width?: number;
  readonly home?: boolean;
  readonly isfullview?: boolean;
  readonly showFixedHeight?: boolean;
}) {
  const { sources } = useFeedData();
  const [showSourceModal, setShowSourceModal] = useState(false);
  const [showNotInterestOptions, setShowNotInterestedOptions] = useState(false);
  const { selectedLocation } = useFeedData();
  const [buttonPosition, setButtonPosition] = useState({
    top: "0px",
  });
  const { toggleStoreModal, toggleUpdateModal, showStore } =
    useCollabListsContext();
  const imgSrc = sources.filter((source) => {
    if (item.source === "paytm insider") {
      return source.source_key.includes("paytm");
    } else {
      return item.source === source.source_key;
    }
  });

  const notOnClick = async () => {
    Mixpanel.track("Not Interested Clicked", {
      update_id: item._id,
      ui_type: "past-feed-card",
      update_type: item.update_type,
    });
    setShowSourceModal(false);
    setShowNotInterestedOptions(!showNotInterestOptions);
    try {
      await performUserAction(item._id, SupportedActions.NOT_INTERESTED);
      await performUserActionHistory(SupportedActions.NOT_INTERESTED, {
        update_id: item._id,
        ui_type: "past-feed-card",
        update_type: item.update_type,
        is_recommended: home,
        location_slug: selectedLocation?.location_id,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const width_ratio =
    (container_width as number) /
    (item.media_metadata?.media_compressed.width as number);

  const postRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [timeInView, setTimeInView] = useState(0);
  const [hasCalledApi, setHasCalledApi] = useState(false); // New state to track API call

  useEffect(() => {
    const currentRef = postRef.current;

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
          setTimeInView(0); // Reset the timer if the element goes out of view
        }
      });
    };

    const observer = new IntersectionObserver(callback, { threshold: 1 });
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (isVisible && !hasCalledApi) {
      timer = setInterval(() => {
        setTimeInView((prevTime) => {
          const newTime = prevTime + 1;
          if (newTime >= 5) {
            if (timer) clearInterval(timer);
            if (home) {
              callApi();
            }
            setHasCalledApi(true); // Set the flag to true after the API call
          }
          return newTime;
        });
      }, 1000);
    } else {
      if (timer) clearInterval(timer);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isVisible, hasCalledApi]);

  const callApi = debounce(async () => {
    Mixpanel.track("Post viewed for 5sec", {
      update_id: item._id,
      ui_type: "past-feed-card",
      viewType: "5sec",
      is_recommended: home,
      update_type: item.update_type,
    });

    await performUserActionHistory(SupportedActions.SCROLL_VIEW_POST, {
      update_id: item._id,
      ui_type: "past-feed-card",
      update_type: item.update_type,
      is_recommended: home,
      location_slug: selectedLocation?.location_id,
    });
  }, 200); //0.2 sec

  const condition =
    (container_width as number) >
    (item.media_metadata?.media_compressed.height || 0);

  const backgroundStyle: React.CSSProperties = condition
    ? {
        backgroundImage: `url(${item.media_metadata?.media_compressed.url})`,
        filter: "blur(8px) brightness(75%)",
        height: container_width,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 0,
        transform: "scale(1.2)",
        width: "100%",
      }
    : {};

  const containerStyle: React.CSSProperties = {
    position: "relative",
    minHeight: container_width,
    overflow: "hidden",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    display: condition ? "flex" : "",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  };
  return (
    <>
      <div
        ref={postRef}
        className={`border rounded-lg bg-white ${isfirst && "ml-3"}  ${
          isLast ? "mr-3" : ""
        }`}
      >
        <div
          style={containerStyle}
          onClick={async (e) => {
            e.stopPropagation();
            Mixpanel.track("Post Clicked", {
              update_id: item._id,
              ui_type: "past-feed-card",
              ui_area: "media",
            });
            await performUserActionHistory(SupportedActions.VIEW_POST, {
              update_id: item._id,
              ui_type: "past-feed-card",
              ui_area: "media",
              is_recommended: home,
              location_slug: selectedLocation?.location_id,
            });
            // navigate(`/app/update/${item._id}`);
            toggleUpdateModal(true, item._id);
          }}
        >
          {condition && <div style={backgroundStyle} />}

          <LoadMedia
            isMini
            showFixedHeight={showFixedHeight}
            source={item.source}
            mediaKey={
              isfullview
                ? (item?.media_metadata?.media_original.url as string)
                : (item?.media_metadata?.media_compressed.url as string)
            }
            thumbnailKey={
              isfullview
                ? (item?.media_metadata?.media_original.url as string)
                : (item?.media_metadata?.media_compressed.url as string)
            }
            mediaType={
              isfullview
                ? (item?.media_metadata?.media_original.type as string)
                : (item?.media_metadata?.media_compressed.type as any)
            }
            fixed_width={item.media_metadata?.media_original.width as number}
            fixed_height={
              item.media_metadata?.media_compressed.height as number
            }
            masonryCondition={condition}
          />
          <HandPicked pastFeedCard={true} item={item} />

          {!showStore && (
            <button
              onClick={(event) => {
                event.stopPropagation();
                Mixpanel.track("Post Source Clicked");
                setButtonPosition({
                  top: `${event.currentTarget.offsetTop}px`,
                });
                setShowSourceModal(true);
              }}
              className="flex items-center gap-1 absolute top-3 right-2 text-ellipsis text-[10px]  text-[#dadada] capitalize"
            >
              <img
                src={
                  imgSrc[0]?.source_logo
                    ? imgSrc[0].source_logo
                    : item.source === "aroundly"
                    ? "/images/aroundly.svg"
                    : `/images/sources/${item.source}.png`
                }
                alt={`logo of ${item.source}`}
                width={12}
                height={12}
              />

              {!home &&
                (item.source === "instagram"
                  ? item.social_media_handle
                  : item.source)}
            </button>
          )}
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="absolute bottom-2 right-2 bg-white rounded-full"
          >
            <LikeButton item={item} placeData={placeData} isMini size="md" />
          </div>
        </div>
        <div className="px-1 pb-2">
          {(updateType === UPDATE_TYPE_OFFER ||
            updateType === UPDATE_TYPE_EVENT) && (
            <div className="text-xs text-gray-500 mt-1">
              {updateType === UPDATE_TYPE_OFFER ? "Until " : ""}
              {moment(item?.expires_at).tz(timezone).format("Do MMM")}
            </div>
          )}
          {/* <button
          className="flex items-center"
        >
          <Image
            src={`/static/${
              item.source === "aroundly"
                ? "images/aroundly.svg"
                : `icons/${item.source}.png`
            }`}
            alt={`logo of ${item.source}`}
            className="grayscale"
            width={16}
            height={16}
          />
          <InformationCircleIcon className="h-5 w-5 inline-block text-gray-500 ml-1" />
        </button> */}
          <div
            id="source-link"
            style={{
              letterSpacing: "-0.48px",
            }}
            className={`text-black text-base leading-tight   font-medium not-italic  my-1`}
            onClick={async (e) => {
              e.stopPropagation();
              Mixpanel.track("Post Clicked", {
                update_id: item._id,
                ui_type: "past-feed-card",
                ui_area: "caption-title",
              });
              await performUserActionHistory(SupportedActions.VIEW_POST, {
                update_id: item._id,
                ui_type: "past-feed-card",
                ui_area: "media",
                is_recommended: home,
                location_slug: selectedLocation?.location_id,
              });
              // navigate(`/app/update/${item._id}`);
              toggleUpdateModal(true, item._id);
            }}
          >
            {item.caption_title}
          </div>
          {placeData && (
            <div className={`font-inter max-w-fit text-black`}>
              <div className="flex text-xs leading-none tracking-tight font-normal">
                <div
                  onClick={async (e) => {
                    e.stopPropagation();
                    Mixpanel.track("Store Clicked", {
                      update_id: item._id,
                      ui_type: "past-feed-card",
                      place_id: placeData?._id,
                    });
                    await performUserActionHistory(
                      SupportedActions.VIEW_STORE,
                      {
                        update_id: item._id,
                        ui_type: "past-feed-card",
                        ui_area: "media",
                        is_recommended: home,
                        location_slug: selectedLocation?.location_id,
                      }
                    );
                    toggleStoreModal(true, placeData?._id);
                  }}
                  // to={`/app/store/${item?.related_aroundly_place_ids[0]?._id}`}
                  className="text-secondary-text  cursor-pointer truncate w-36 leading-tight"
                >
                  {placeData?.name}
                </div>
                <span className="mx-1"> &bull; </span>
                <span className="text-nowrap text-secondary-text">
                  {placeData?.google_maps_details?.rating}⭐
                </span>
              </div>
            </div>
          )}
          {updateType === UPDATE_TYPE_EVENT && item.price !== undefined && (
            <div className="flex justify-between items-center mt-2">
              <div className="text-zinc-700 text-lg leading-none font-medium">
                ₹ {item.price}
              </div>
            </div>
          )}
          {showStore && (
            <Link
              to={item?.source_link}
              className="flex items-center gap-1 text-[10px] mt-2 text-gray-500 capitalize"
              target="_blank"
            >
              <img
                src={`/images/${
                  item.source === "aroundly"
                    ? "aroundly.svg"
                    : `sources/${item.source}.png`
                }`}
                alt={`logo of ${item.source}`}
                width={12}
                height={12}
              />
              {item.source === "instagram"
                ? item.social_media_handle
                : item.source}
            </Link>
          )}
        </div>
        {/* {(item.update_type === UPDATE_TYPE_EVENT || item.update_type === UPDATE_TYPE_OFFER) && (
        <div className="mt-4 flex items-center text-zinc-700">
          <CalendarIcon className="h-4 w-4 inline-block mr-1" />
          <div
            className={`text-sm leading-none tracking-tight font-normal ${poppins.className}`}
          >
            {moment(item?.expires_at).format("Do MMM")}
          </div>
        </div>
      )}
      <div className="flex items-center mt-4 text-zinc-700">
        <Image
          src="/static/images/aroundly.svg"
          alt={`logo of aroundly`}
          className="mr-2 border rounded-full p-[0.5px]"
          width={16}
          height={16}
        />
      </div>
      <div className="mb-1 mt-4 flex justify-between items-center relative">
        <div className="flex gap-2">
          {item?.related_aroundly_place_ids && (
            <Link
              className="flex items-center py-2 px-4 justify-center border-[0.5px] border-black rounded-3xl"
              href={`https://www.google.com/maps/search/?api=1&query=${item.related_aroundly_place_ids[0].google_maps_details?.formatted_address}&query_place_id=${item.related_aroundly_place_ids[0].place_id}`}
              rel="noreferrer"
              target="_blank"
            >
              <PaperAirplaneIcon className="h-5 w-5 inline-block text-black -rotate-45" />{" "}
              <div
                className={`ml-1 text-xs leading-6 text-black font-inter`}
              >
                Go
              </div>
            </Link>
          )}
        </div>
        <div className="flex items-center gap-2">
          {item?.cta_links?.slice(0, 1).map((link: CtaLink) => (
            <Button
              key={link.text}
              label={link.text}
              href={link.url}
              size="sm"
            ></Button>
          ))}
        </div>
      </div> */}
      </div>
      {showSourceModal && (
        <SourceModal
          onModalClose={() => setShowSourceModal(false)}
          item={item}
          position={buttonPosition}
          notOnClick={notOnClick}
        />
      )}
      {showNotInterestOptions && (
        <NotInterested
          item={item}
          onClose={() => setShowNotInterestedOptions(false)}
        />
      )}
    </>
  );
}
