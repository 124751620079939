import { BookmarkIcon } from "@heroicons/react/24/solid";
import { useFeedData } from "../context/FeedDataContext";
import { AroundlyPlaces, SocialMediaUpdate } from "../common/global.types";
import { useCollabListsContext } from "../context/CollabListsContext";

const Tooltip = ({
  infoModalHandler,
  item,
  placeData,
  closeCount,
  handleCloseCnt,
}: {
  infoModalHandler: (status: boolean) => void;
  readonly item: SocialMediaUpdate;
  readonly placeData?: AroundlyPlaces;
  readonly closeCount?: number;
  readonly handleCloseCnt?: (cnt: number) => void;
}) => {
  const { selectedLocation } = useFeedData();
  const { collabLists, toggleWishTrayModal, toggleCreateNewModal } =
    useCollabListsContext();

  return (
    <div className="">
      <div className="rounded-md p-2 border w-auto bg-white max-w-xs md:max-w-md lg:max-w-lg">
        <div>
          <div className="flex gap-2">
            <div className="flex-1 min-w-0">
              <p
                className="text-[#1E1E1E] font-medium text-base"
                style={{ letterSpacing: "-0.48px" }}
              >
                Save to stay updated
                <br />
                and discover similar updates
              </p>
            </div>
            <BookmarkIcon className="h-6 w-6 flex-shrink-0" />
          </div>
          <p
            className="text-secondary-text text-xs font-450 pt-2 whitespace-nowrap truncate"
            style={{ letterSpacing: "-0.36px" }}
          >
            We’ll recommend you more of these in
            <span className="text-primary">
              {" "}
              {selectedLocation?.location_name}
            </span>
          </p>
        </div>
        <div className="flex gap-3 mt-2">
          <div
            onClick={() => {
              infoModalHandler(false);
              const newCount = (closeCount as number) + 1;
              handleCloseCnt && handleCloseCnt(newCount as number);
              localStorage.setItem("saveCount", String(newCount));
              localStorage.setItem("lastClosedsave", new Date().toDateString());
            }}
            className="min-w-20 w-1/2 border border-[#DCDEE3] px-3 py-2 items-center text-center flex justify-center rounded-full h-8"
          >
            <button className="leading-6 whitespace-nowrap rounded-full text-xs font-medium">
              Not now
            </button>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              infoModalHandler(false);

              const newCount = (closeCount as number) + 1;
              handleCloseCnt && handleCloseCnt(newCount as number);
              localStorage.setItem("saveCount", String(newCount));
              localStorage.setItem("lastClosedsave", new Date().toDateString());

              if (collabLists.length > 0) {
                toggleWishTrayModal(
                  true,
                  item,
                  (placeData as AroundlyPlaces) ||
                    (item.related_aroundly_place_ids &&
                      item.related_aroundly_place_ids[0])
                );
              } else {
                toggleCreateNewModal(
                  true,
                  item,
                  (placeData as AroundlyPlaces) ||
                    (item.related_aroundly_place_ids &&
                      item.related_aroundly_place_ids[0])
                );
              }
            }}
            className="bg-primary w-1/2 min-w-20 px-3 py-2 items-center text-center flex justify-center rounded-full h-8"
          >
            <button className="leading-6 rounded-full text-white text-xs font-medium">
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="items-start flex ml-4 min-w-52">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="6"
          viewBox="1 0 17 6"
          fill="black"
        >
          <path
            d="M6.20711 4.29289C5.81658 4.68342 5.18342 4.68342 4.79289 4.29289L0.5 5.04736e-07L10.5 0L6.20711 4.29289Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
export default Tooltip;
