const EmptyMasonryCard = ({ text }: { text: string }) => {
  return (
    <div className="py-3 px-2  bg-white flex flex-col gap-[10px] rounded-lg mx-3 border border-[#DCDEE3]">
      <p
        className="text-base font-450 items-center text-center"
        style={{ letterSpacing: "-0.48px" }}
      >
        {text}
      </p>
    </div>
  );
};
export default EmptyMasonryCard;
