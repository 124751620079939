import { Link } from "react-router-dom";
import ShareButton from "./shareBtn";
import { AroundlyPlaces } from "../common/global.types";
import { Mixpanel } from "../services/mixpanel";
import { useAuth } from "../context/auth";
import { getDistanceFromLatLonInKm } from "../common/distance-utils";
import { useLocationInfo } from "../context/LocationContext";
import { useEffect, useState } from "react";
import { useCollabListsContext } from "../context/CollabListsContext";

function cleanString(str: string) {
  return str?.split(",").map((s) => s.trim());
}

const RenderPlace = ({ placeDetails }: { placeDetails: AroundlyPlaces }) => {
  const { userCurrentPosition } = useLocationInfo();
  const { toggleStoreModal } = useCollabListsContext();
  const [uniquecuisines, setUniquecuisines] = useState<string>();

  // const distance = getDistanceFromLatLonInKm(
  //   userCurrentPosition.coords.latitude,
  //   userCurrentPosition.coords.longitude,
  //   placeDetails.geo_location.coordinates[1],
  //   placeDetails.geo_location.coordinates[0]
  // );

  useEffect(() => {
    const placeCategory =
      placeDetails?.place_category !== undefined
        ? cleanString(placeDetails.place_category)
        : "";
    const cuisine =
      placeDetails?.swiggy_dine_out_details?.cuisine?.length > 0
        ? cleanString(placeDetails.swiggy_dine_out_details.cuisine)
        : "";

    let cuisineAndCategory = "";

    if (placeCategory.length > 0) {
      cuisineAndCategory += placeCategory;
    }

    if (cuisine.length > 0) {
      if (placeCategory.length > 0) {
        cuisineAndCategory += ",";
      }
      cuisineAndCategory += cuisine;
    }

    const arrayofcuisine = cuisineAndCategory?.split(",");
    const uniquecuisines = arrayofcuisine.reduce((acc, option) => {
      if (!acc.includes(option)) {
        acc.push(option);
      }
      return acc;
    }, [] as string[]);

    setUniquecuisines(uniquecuisines.join(", "));
  }, [
    placeDetails?.place_category,
    placeDetails?.swiggy_dine_out_details?.cuisine,
  ]);

  return (
    <div className="border-l-2 border-solid border-primary px-3">
      <div className="items-center gap-1 flex">
        {/* <Link
        onClick={() => {
          Mixpanel.track("Store Clicked", {
            place_id: placeDetails?._id,
            ui_type: "place-compo",
          });
        }}
        to={`/app/store/${placeDetails?._id}`}
        className="underline cursor-pointer text-base font-medium"
      >
        {placeDetails?.name}{" "}
      </Link> */}
        <div
          onClick={() => {
            Mixpanel.track("Store Clicked", {
              place_id: placeDetails?._id,
              ui_type: "place-compo",
            });
            toggleStoreModal(true, placeDetails?._id);
          }}
          className="underline cursor-pointer text-base font-medium"
        >
          {placeDetails?.name}{" "}
        </div>
        <div className="text-black text-base font-normal font-inter">
          {placeDetails?.google_maps_details?.rating}⭐
        </div>
      </div>

      <div>
        {placeDetails?.distance && (
          <span className="text-neutral-500 text-xs font-normal font-inter">
            {Math.round(placeDetails.distance)} km from you •
          </span>
        )}
        <span className="text-secondary-text text-xs font-normal font-inter">
          {" "}
          {placeDetails?.google_maps_details?.formatted_address?.slice(0, 30)}
          ...
        </span>
      </div>

      {/* {placeDetails?.known_for?.length > 0 && (
        <div className="text-xs font-400 text-secondary-text font-inter mt-1">
          <span>Known for </span>
          <span
            className="font-bold text-xs truncate"
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {placeDetails?.known_for?.join(", ")?.length > 50
              ? `${placeDetails?.known_for?.join(", ")?.substring(0, 40)}...`
              : placeDetails?.known_for?.join(", ")}
          </span>
        </div>
      )} */}
      {/* const placeCategory = placeDetails?.place_category
      ? cleanString(placeDetails.place_category)
      : "";

    const cuisine = placeDetails?.swiggy_dine_out_details?.cuisine
      ? cleanString(placeDetails.swiggy_dine_out_details.cuisine)
      : ""; */}

      <div className=" text-secondary-text capitalize text-xs font-inter mt-1 w-full line-clamp-1">
        {uniquecuisines}
      </div>
    </div>
  );
};

const Placecompo = ({
  item,
  hideActionButtons = false,
}: {
  item: any;
  restype?: string;
  hideActionButtons?: boolean;
}) => {
  const { authUser } = useAuth();
  // const { locations } = useFeedData();
  return (
    <div className="w-full justify-between items-start flex flex-col  mt-2">
      <div>
        <RenderPlace placeDetails={item} />
      </div>
      {!hideActionButtons && (
        <div className="justify-end items-center gap-2 flex mt-2">
          <div className=" rounded-full  justify-end items-center gap-2 flex">
            <Link
              id="google-place-link"
              rel="noreferrer"
              to={`https://www.google.com/maps/search/?api=1&query=${item?.google_maps_details?.formatted_address}&query_place_id=${item?.place_id}`}
              className="no-underline "
              target="_blank"
            >
              <div
                onClick={() => {
                  Mixpanel.track(`Direction button clicked`, {
                    locationDetail: item?.google_maps_details,
                    userId: authUser?._id,
                    ui_type: "place-compo",
                    place_id: item?._id,
                  });
                }}
                className="p-2 rounded-full min-w-[80px] bg-primary border border-zinc-200 flex justify-around"
              >
                <img
                  alt="direction"
                  src="/images/directions.svg"
                  className=" h-6 w-6 mx-1"
                />
                <span className="text-xs text-white font-medium leading-6 items-start font-inter w-full">
                  GO
                </span>
              </div>
            </Link>
            <div className="border rounded-full p-2">
              <ShareButton
                isStore={true}
                _id={item?._id}
                captionTitle={item?.name}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Placecompo;
