import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import { useState } from "react";
import {  updateUser } from "../services/aroundly-apis";
import { toast } from "react-toastify";

const AroundlyPage = () => {
  const navigate=useNavigate()
  const {authUser,setAuthUser}=useAuth()
  const [loading, setLoading] = useState(false);


  const onVerifyButtonClick = async () => {
    try {
      setLoading(true);
  
      if (!authUser) {
        console.error("No authenticated user found.");
        setLoading(false);
        return;
      }
  
      let resp;
  
      try {
        resp = await updateUser(authUser.phone, { is_onboarding_completed: true });
      } catch (error) {
        console.error("Failed to update user:", error);
        setLoading(false);
        return;
      }
  
  
      const authUserObj = { ...resp.updatedUser,  };
  


      if (resp.msg === "User Data successfully saved") {
        if (setAuthUser) {
          setAuthUser(authUserObj);
        }
        navigate("/app/feed");
      }
      else{
        toast.error("An unexpected error occurred")
      }

    } catch (e) {
      console.error("An unexpected error occurred:", e);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="h-dvh">
        <div className="p-3 flex justify-between">
        <img onClick={()=>{
          navigate("/app/feed")
        }} src="/images/aroundly.svg" alt="background" className="h-8 w-8 self-center cursor-pointer"></img>
 
          <XMarkIcon onClick={()=>{
          navigate("/app/feed")
        }} className="h-6 w-6 cursor-pointer" />
        </div>

      <img
        alt="aroundly description"
        className="px-3 pt-6"
        src="/images/about-aroundly.png"
      />
    
      { authUser && authUser.is_onboarding_completed ?
       ( <div className="  max-w-md fixed bottom-8 mx-auto ">
        <a
          href="https://wa.me/+917093214324?text=Hi%20I%20have%20a%20suggestion"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="px-3 pt-6"
            alt="whatsapp-desc"
            src="/images/whatsapp.png"
          />
        </a>
      </div>):
      (<div className="absolute  bottom-8 mx-auto max-w-md w-full  flex justify-center rounded-full flex-1  h-12">
        <button
          className={`bg-primary flex items-center justify-center leading-6 px-6 py-1 w-[90%] rounded-full  text-white text-base font-semibold shadow`}
          onClick={()=>{
            onVerifyButtonClick()
          }}
        >
          Get discovering <ArrowRightIcon className="h-4 w-4 ml-1"/>
        </button>
      </div> )}



    </div>
  );
};
export default AroundlyPage;
