import { MagnifyingGlassIcon, ShareIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon as ArrowLeftIconSolid } from "@heroicons/react/16/solid";

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Mixpanel } from "../services/mixpanel";
import MapComp from "./MapComp";
import CategoryCard from "./CategoryCard";
import ButtonControl from "./controls/ButtonControl";
import { shareLogic } from "../common/functions";
import { UserProps } from "../common/global.types";
import EventShowMore from "./EventShowMore";

interface ImageUrl {
  thumbnail_url?: string;
  media_url?: string;
}

interface EventCategory {
  id: string;
  name: string;
}

interface Props {
  updateTypeToShow: string | null;
  interest: string | null;
  resultsType: string;
  imageUrl?: ImageUrl;
  eventCategories: EventCategory[];
  toggleBeforeModal?: (value: boolean) => void;
  showImg: boolean;
  authUser: UserProps | null | undefined;
  handleSearchText: (state: string) => void;
}

const EventComponent: React.FC<Props> = ({
  updateTypeToShow,
  interest,
  resultsType,
  imageUrl,
  eventCategories,
  toggleBeforeModal,
  showImg,
  authUser,
  handleSearchText,
}) => {
  const navigate = useNavigate();
  const [iswhite, setIsBGWhite] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsBGWhite(window.scrollY > 275);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onShare = async () => {
    Mixpanel.track("Share Clicked", {
      ui_type: "event-page",
      share_type: "event",
    });
    const shareText = `Checkout my Eat page`;
    const urlToShare = `${process.env.REACT_APP_FRONTEND_URL}/app/category/experiences?referral_id=${authUser?.referal_id}`;

    await shareLogic(shareText, urlToShare);
  };

  return (
    <div>
      <div className={`w-full relative`}>
        {showImg ? (
          <img
            src="/images/event.jpg"
            alt=""
            className="object-cover h-[260px] w-full"
          />
        ) : (
          <MapComp height={260} />
        )}

        <div
          className="absolute bottom-0 left-0  h-full w-full"
          style={{
            background: `linear-gradient(238deg, rgba(0, 0, 0, 0.00) 2.92%, #000 60.23%)`,
          }}
        >
          <div
            className={`${
              iswhite ? "bg-white" : ""
            } flex justify-between px-3 py-2 fixed bg- max-w-md z-10 w-full top-0`}
          >
            <div className="">
              <ButtonControl
                buttonId=""
                size="lg"
                theme="white"
                name=""
                icon={{ component: ArrowLeftIconSolid }}
                iconPlacement="end"
                onClick={() => navigate(-1)}
              />
            </div>

            <div className="flex gap-1">
              <ButtonControl
                buttonId=""
                size="lg"
                theme="white"
                name=""
                icon={{ component: MagnifyingGlassIcon }}
                iconPlacement="end"
                onClick={() => {
                  Mixpanel.track("Search Clicked");
                  handleSearchText("");
                  toggleBeforeModal && toggleBeforeModal(true);
                }}
              />
              <ButtonControl
                buttonId=""
                size="lg"
                theme="white"
                name=""
                icon={{ component: ShareIcon }}
                iconPlacement="end"
                onClick={onShare}
              />
            </div>
          </div>
          <div className="absolute bottom-12 left-3">
            <CategoryCard category="category" query="Experiences" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventComponent;
