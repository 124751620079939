import { useState } from "react";
import { useCapacitorInfo } from "../context/CapacitorContext";
import GetItOn from "./GetItOn";
// import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../context/auth";

export default function GetItOnContainer({
  handleOnClick,
  isClickable = true,
  size = "normal",
  isAlignLeft = false,
}: {
  handleOnClick?: () => void;
  isClickable?: boolean;
  size?: "normal" | "large";
  isAlignLeft?: boolean;
}) {
  const [showRedirectionText, setShowRedirectionText] = useState(false);
  const { authUser } = useAuth();
  const { operatingSystem, platform } = useCapacitorInfo();
  return (
    <>
      {platform === "web" && (
        <div
          className={`items-start gap-2   ${
            authUser || !isAlignLeft ? "justify-center" : "justify-start"
          } `}
        >
          {/* <div className="flex gap-4 items-center justify-center"> */}

          {operatingSystem !== "ios" && (
            <GetItOn
              type="play-store"
              size={size}
              isClickable={authUser ? true : false}
              handleOnClick={() => {
                if (!authUser) {
                  setShowRedirectionText(true);
                }
              }}
            />
          )}
          {operatingSystem !== "android" && (
            <GetItOn
              type="app-store"
              size={size}
              isClickable={authUser ? true : false}
              handleOnClick={() => {
                if (!authUser) {
                  setShowRedirectionText(true);
                }
              }}
            />
          )}
          {/* </div> */}

          {/* <div className={`flex items-start mt-3 ${authUser && "hidden"}`}>
            <div className="text-xs font-medium text-secondary-text">
              We’ll send the Play Store or App Store link to your
              <br /> registered number on
              <span className="text-primary"> July 3rd 9:00 AM</span>
            </div>
          </div> */}
          {showRedirectionText && (
            <div className="text-xs font-medium text-secondary-text  mt-3">
              We’ll send the Play Store or App Store link to your
              <br /> registered number after joining.
            </div>
          )}
        </div>
      )}
    </>
  );
}
