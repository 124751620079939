import { Mixpanel } from "../services/mixpanel";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

export default function WaComponent() {
  let navigate = useNavigate();
  const { whatsAppUpdateId } = useParams();

  useEffect(() => {
    const update = () => {
      if (whatsAppUpdateId === "daily-update") {
        Mixpanel.track("Clicked: Daily WhatsApp Message");
        navigate(`/app/feed`);
      } else if (whatsAppUpdateId?.includes("post-")) {
        const postId = whatsAppUpdateId.split("post-")[1];
        Mixpanel.track("Clicked: WhatsApp Post Link", { updateId: postId });
        navigate(`/app/update/${postId}`, { replace: true });
      } else {
        Mixpanel.track("Clicked: Default WhatsApp Update");
        navigate(`/app/feed`);
      }
    };

    update();
  }, [whatsAppUpdateId, navigate]);

  return <></>;
}
