import { toast } from "react-toastify";

import Percentage from "../components/Percentage";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import { useFeedData } from "../context/FeedDataContext";
import { useEffect, useState } from "react";
import { updateUser } from "../services/aroundly-apis";
import { CityProps } from "../common/global.types";
import ButtonControl from "../components/controls/ButtonControl";

const OnboardingStep4 = () => {
  const { authUser, loading, setAuthUser } = useAuth();
  const { cities } = useFeedData();
  const navigate = useNavigate();
  const [localState, setLocalState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const handleClick = (one: string) => {
    setLocalState(one);
  };

  useEffect(() => {
    if (!loading && authUser) {
      setSelectedCity(authUser.city);
      setLocalState(authUser.city);
    }
  }, [authUser, loading]);

  const updateCity = async () => {
    try {
      if (!loading && authUser) {
        return await updateUser(authUser.phone, {
          city: selectedCity === undefined ? localState : selectedCity,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClickbutton = async () => {
    const data = await updateCity();
    if (data.msg === "User Data successfully saved") {
      if (setAuthUser) {
        if (data.updatedUser && data.updatedUser.city) {
          setAuthUser({
            ...data.updatedUser,
          } as any);
        }
      }
      navigate("/app/onboarding/user-details");
    } else {
      toast.error("Data is not saved successfully.");
    }
  };

  return (
    <div className="h-dvh">
      <div className="pt-16">
        <Percentage step={3} total={4} />
      </div>

      <div className="mx-5  mt-[48px]">
        <div className="text-2xl font-bold">Select your city</div>
      </div>
      <div className="mt-20 mb-[36px]">
        <div className="mt-3 mx-5">
          {cities?.map((one: CityProps, index) => (
            <div
              key={one.name}
              onClick={() => handleClick(one.name)}
              className={`min-w-20 ${
                localState?.includes(one.name) ? "bg-[#E8F0FF]" : ""
              } h-12 p-2 justify-center flex mb-1 items-center  text-[#626569] rounded-lg text-sm cursor-pointer border border-[#DCDEE3]`}
            >
              {one.name}
            </div>
          ))}
        </div>
      </div>

      <div className="absolute bottom-8 mx-auto max-w-md w-full">
        <div className="flex justify-center px-4">
          <ButtonControl
            buttonId="update-city-button"
            size="lg"
            theme="primary"
            name="Next"
            onClick={() => handleClickbutton()}
            disabled={!localState || localState?.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
export default OnboardingStep4;
