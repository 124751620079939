import { useEffect, useState } from "react";
import { SocialMediaUpdate } from "../common/global.types";

const useUpcomingLength = (updates: SocialMediaUpdate[]) => {
  const [upcomingLength, setUpcomingLength] = useState(0);

  useEffect(() => {
    const upcoming =updates && updates.filter(
      (u) => u.update_type === "event" || u.update_type === "offer"
    ).length;
    setUpcomingLength(upcoming);
  }, [updates]);

  return upcomingLength;
};

export default useUpcomingLength;
