import { createContext, useContext, useState } from "react";
import { AroundlyPlaces } from "../common/global.types";

interface ResultsPageContextProps {
  searchResultsData: AroundlyPlaces[];
  setSearchResultsData: (data: AroundlyPlaces[]) => void;
  pastUpdateType: string;
  setPastUpdateType: (data: string) => void;
}

const ResultsPageContext = createContext({
  searchResultsData: [],
  setSearchResultsData: () => {},
  setPastUpdateType: () => {},
  pastUpdateType: "",
} as ResultsPageContextProps);

export function ResultsPageProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [searchResultsData, setSearchResultsData] = useState<AroundlyPlaces[]>(
    []
  );
  const [pastUpdateType, setPastUpdateType] = useState("");

  return (
    <ResultsPageContext.Provider
      value={{
        searchResultsData,
        setSearchResultsData,
        pastUpdateType,
        setPastUpdateType,
      }}
    >
      {children}
    </ResultsPageContext.Provider>
  );
}

export const useResultsPageContext = () => useContext(ResultsPageContext);
