import { useState } from "react";
import ListModal from "./ListModal";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Mixpanel } from "../services/mixpanel";

type Item = {
  id: string;
  name: string;
};
// Time
//Category
type OptionSelectControlProps = {
  selectedId: string;
  items: Item[];
  defaultText: string;
  defaultPreText?: string;
  listHeading: string;
  onListSelect: (id: string) => void;
  onClick?: () => void;
  onDismiss?: () => void;
};

export default function OptionSelectControl({
  selectedId,
  defaultText,
  listHeading,
  items,
  defaultPreText,
  onClick,
  onDismiss,
  onListSelect,
}: OptionSelectControlProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      {showModal && (
        <ListModal
          items={items}
          heading={listHeading}
          onSelect={(id) => {
            onListSelect(id);
            setShowModal(false);
          }}
          selectedItemId={selectedId}
          onClose={() => {
            onDismiss && onDismiss();
            setShowModal(false);
          }}
        />
      )}
      <button
        onClick={() => {
          Mixpanel.track("Options Modal Opened", {
            options_type: listHeading,
            ui_type: "options-select-control",
          });
          setShowModal(true);
          if (onClick) onClick();
        }}
        className={`px-3 py-2 rounded-full ${
          selectedId
            ? "bg-special-blue bg-opacity-10 text-special-blue"
            : "border bg-white text-stone-900"
        } justify-center items-center gap-1 flex text-nowrap`}
      >
        {defaultPreText && (
          <span className="text-neutral-500 text-xs whitespace-nowrap">
            {defaultPreText}
          </span>
        )}
        <span className="text-xs truncate">
          {selectedId && items.length
            ? items.filter((i) => i.id === selectedId)[0]?.name
            : defaultText}
        </span>
        <ChevronDownIcon className="h-5 w-5" />
      </button>
    </div>
  );
}
