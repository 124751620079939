import { BookmarkIcon } from "@heroicons/react/24/outline";

const EmptySave = () => {
  return (
    <div className="py-3 px-2  bg-white flex flex-col gap-[10px] rounded-lg  border border-[#DCDEE3] mt-2">
      <div className="flex justify-center items-center text-center gap-1">
        <BookmarkIcon className="h-6 w-6" />
        <span>Save</span>
      </div>
      <p
        className="text-base font-450 items-center text-center"
        style={{ letterSpacing: "-0.48px" }}
      >
        It’s empty here. Start saving to get personalized search recommendations
        anywhere you are
      </p>
    </div>
  );
};
export default EmptySave;
