import { useCollabListsContext } from "../context/CollabListsContext";
import Store from "../pages/store.page";

const StoreModal = ({
  storeId,
}: {
  storeId: string;
  toggleStoreModal: (status: boolean, id: string) => void;
}) => {
  const { showStore } = useCollabListsContext();
  return (
    <div
      style={{
        zIndex: 50,
      }}
      id="modal"
      //   className="fixed left-0 right-0 top-0 bottom-0 bg-white   max-w-md mx-auto flex items-center justify-center"

      className="fixed left-0 right-0 top-0 bottom-0 bg-white max-w-md mx-auto overflow-y-scroll scrollbar-hide"
    >
      <Store storeId={storeId as string} />
    </div>
  );
};
export default StoreModal;
