import { MapPinIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Mixpanel } from "../services/mixpanel";
import { AROUND_ME_DISTANCE_IN_KM } from "../common/global.constants";
import { HeaderWithLogoAndName } from "./HeaderLogoWithName";
import { useLocationInfo } from "../context/LocationContext";
import Badge from "./Badge";

interface CheckboxButtonProps {
  option: any;
  type: string;
}

type LocationSelectModalProps = {
  selectedLocationOption: any;
  locationsList: any[];
  onSubmit: (data: any) => void;
  onClose: () => void;
};

export default function LocationSelectModal({
  selectedLocationOption,
  locationsList,
  onSubmit,
  onClose,
}: LocationSelectModalProps) {
  const { aroundMeLocation } = useLocationInfo();

  const RadioButton = ({ option, type }: CheckboxButtonProps) => {
    const isAroundMe = option.location_id === "around_me";

    const handleClick = () => {
      Mixpanel.track("Location: Changed", {
        old_location: selectedLocationOption,
        new_location: option.location_id,
      });
      onSubmit(option);
    };

    return (
      <label
        htmlFor={`default-checkbox-${option.location_id}`}
        className={`flex items-center justify-between py-4 px-3 rounded border mt-2 cursor-pointer ${
          selectedLocationOption === option.location_id
            ? "border-primary"
            : "border-gray-300"
        } ${isAroundMe ? "bg-gray-100" : "hover:border-primary"} `}
        onClick={!isAroundMe ? handleClick : undefined}
      >
        <MapPinIcon className="h-4 w-4 inline-block mr-2 text-gray-500" />
        <label
          htmlFor={`default-checkbox-${option.location_id}`}
          className="me-3 text-base font-light leading-normal tracking-tight text-gray-500 grow cursor-pointer"
        >
          {/* {option.location_id === "bengaluru" ? "All of" : ""}{" "} */}
          <span className="flex items-center">
            {option.location_name}{" "}
            {isAroundMe ? `(< ${AROUND_ME_DISTANCE_IN_KM} km)` : ""}
            {isAroundMe ? (
              <span className="ml-3">
                <Badge text="Coming soon" />
              </span>
            ) : (
              ""
            )}
          </span>
        </label>
        <input
          id={`default-checkbox-${option.location_id}`}
          type={type}
          checked={selectedLocationOption === option.location_id}
          onChange={!isAroundMe ? handleClick : undefined}
          value={option.location_id}
          disabled={isAroundMe}
          className={`w-5 h-5 text-primary border-gray-500 rounded-full ${
            isAroundMe ? "bg-gray-400" : "focus:ring-primary focus:ring-1"
          } cursor-pointer`}
        />
      </label>
    );
  };

  return (
    <div
      id="modal"
      className="fixed left-0 right-0 top-0 bottom-0 bg-black/90 z-50 max-w-md mx-auto"
    >
      <div
        className={`absolute w-full h-full bg-white overflow-y-scroll scrollbar-hide`}
      >
        <form className={`px-3 py-2 flex flex-col items-center`}>
          <div className="flex items-center justify-between w-full max-w-lg">
            <HeaderWithLogoAndName preventClick />
            <button className="cursor-pointer mr-2" onClick={() => onClose()}>
              <XMarkIcon className="h-6 w-6 inline-block text-black" />
            </button>
          </div>

          <div className="grow w-full max-w-lg flex flex-col justify-center mt-6">
            <div className="font-medium text-2xl leading-normaly mt-8 mb-3 text-black">
              Select location
            </div>

            {!locationsList.length && <div>No locations found</div>}
            {locationsList.length > 0 && (
              <>
                <RadioButton
                  key={locationsList[0].location_id}
                  type="radio"
                  option={locationsList[0]}
                />
                <RadioButton
                  key={aroundMeLocation?.location_id}
                  type="radio"
                  option={aroundMeLocation}
                />
                <hr className="mt-4 my-2" />
                {locationsList
                  .slice(1, locationsList.length)
                  .sort((a, b) =>
                    a.location_name.localeCompare(b.location_name)
                  )
                  .map((locationOption) => (
                    <RadioButton
                      key={locationOption.location_id}
                      type="radio"
                      option={locationOption}
                    />
                  ))}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
