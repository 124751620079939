import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import {
  ArrowLeftIcon,
  // ArrowRightStartOnRectangleIcon,
  CheckCircleIcon,
  // PencilSquareIcon,
  // SparklesIcon,
} from "@heroicons/react/24/outline";
import CustomisePreference from "./CustomizePreference";

const Edit = () => {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  // if (!authUser) {
  //   navigate("/login");
  // }
  return (
    <div className="flex-1 flex flex-col h-screen">
      <div className={`sticky top-0 bg-white p-3 shadow-sm`}>
        <div className="justify-start items-center gap-1 flex text-2xl  font-medium">
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="text-zinc-700 mr-8 cursor-pointer"
          >
            {" "}
            <ArrowLeftIcon className="h-7 w-7" />
          </div>

          <div className="flex justify-center items-center font-medium text-xl leading-6  hover:text-opacity-80">
            Edit Profile
          </div>
        </div>
      </div>

      <div className=" p-4 rounded-lg m-3 flex flex-col border">
        <div className="flex  justify-between ">
          <img
            src={`/images/${authUser?.gender}.svg`}
            alt="profile icon"
            height={64}
            width={64}
          />
          {/* <div className="text-primary font-inter font-semibold text-base leading-5">
            Save
          </div> */}
        </div>
        {authUser?.email && (
          <div className={` leading-6 mt-4 text-black`}>{authUser.email}</div>
        )}
        {authUser && (
          <div className="leading-6 mt-4 text-black">
            <h1 className="font-inter font-medium text-[10px] leading-3 text-[#72757C]">
              Name
            </h1>
            <div className="mt-1 flex border p-3 border-[#DCDEE3] rounded-lg">
              <input
                className={`focus:ring-0 p-0 border-none grow  `}
                disabled
                autoFocus
                defaultValue={authUser?.name}
              />
            </div>
          </div>
        )}
        {authUser && (
          <div className="leading-6 mt-4 text-black">
            <h1 className="font-inter font-medium text-[10px] leading-3 text-[#72757C]">
              Phone
            </h1>
            <div className="mt-1 flex border p-3 border-[#DCDEE3] rounded-lg">
              <input
                className={`focus:ring-0 p-0 border-none grow  `}
                disabled
                autoFocus
                defaultValue={authUser?.phone}
              />
              <div className="text-primary flex justify-center items-center">
                <span className="text-xs mr-1 font-medium leading-4 font-inter">
                  Verified
                </span>
                <CheckCircleIcon className="h-4 w-4" />
              </div>
            </div>
          </div>
        )}
        <CustomisePreference />
      </div>

      {/* <Subscription /> */}
    </div>
  );
};
export default Edit;
