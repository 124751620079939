// import GtagPathView from "../../components/GtagPageView";
import UpdateContent from "../components/UpdateContent";

export default function Update({ updateId }: { updateId?: string }) {
  return (
    <div className="text-black leading-none pb-16  overflow-y-scroll scrollbar-hide h-dvh">
      <UpdateContent updateId={updateId as string} />
    </div>
  );
}
