import { useFeedData } from "../context/FeedDataContext";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import LoadingComp from "../components/LoadingComp";
import {
  getCollabdata,
  getSingleWishlistData,
  getWishlistData,
  updateWishlist,
} from "../services/aroundly-apis";
import {
  AroundlyPlaces,
  CollabWishlistType,
  LocationType,
  SocialMediaUpdate,
  UserProps,
} from "../common/global.types";
import { useAuth } from "../context/auth";
import { Mixpanel } from "../services/mixpanel";
import {
  ShareIcon,
  UserPlusIcon,
  PencilSquareIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { ArrowLeftIcon as ArrowLeftIconSolid } from "@heroicons/react/24/solid";
import { useLocationInfo } from "../context/LocationContext";
import { getDistanceFromLatLonInKm } from "../common/distance-utils";
import {
  getReferIdFromStorage,
  setReferIdToStorage,
} from "../services/local-storage.service";

import Filters from "../components/Filters";
import ForYouPlacesComp from "../components/ForYouPlacesComp";
import MasonryComponent from "../components/MasonryComponent";
import MapComp from "../components/MapComp";
import { shareLogic } from "../common/functions";
import { useCollabListsContext } from "../context/CollabListsContext";
import SavedToggle from "../components/SavedToggle";
import ButtonControl from "../components/controls/ButtonControl";
import CategoryCard from "../components/CategoryCard";
import useUpcomingLength from "../hooks/useUpcomingLength";
import Collabs from "../components/Collabs";
import JoinModal from "../components/JoinModal";
import JoinedModal from "../components/JoinedModal";
import PlacesTab from "../components/PlacesTab";
import EmptySave from "../components/EmptySave";
import { useYourSavedPageContext } from "../context/Yoursaved";
import moment from "moment";
import { DEFAULT_TIMEZONE } from "../common/global.constants";

export default function CollabWishlistt() {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateTypeToShow =
    searchParams.get("update_type") || "general_update_generated";
  const resultsType = searchParams.get("results_type");
  let searchText = searchParams.get("query");
  const { userCurrentPosition } = useLocationInfo();
  const { showModal, interests, lastSearchText } = useFeedData();

  const [activeSection, setActiveSection] = useState<
    "saved" | "foryou" | "guide" | "places"
  >("foryou");

  const [isUpdatesTab, setIsUpdatesTab] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { wishlistId } = useParams();
  // const { selectedLocation } = useFeedData();
  const {
    allCollablists,
    loading: collabdataLoading,
    error: personalisationError,
  } = useYourSavedPageContext();

  const { authUser, loading } = useAuth();
  const [openCollabs, setOpenCollabs] = useState(false);
  const [wishListData, setWishListData] = useState<CollabWishlistType>();
  const [wishlistdataLoading, setWishlistDataLoading] = useState(false);
  const [isWhite, setIsWhite] = useState(false);

  const { toggleEditModal, collabLists, setCollabLists, setSelectedWishlist } =
    useCollabListsContext();
  const [forYouUpdatesSavedData, setforYouUpdatesSavedData] = useState<
    SocialMediaUpdate[]
  >([]);

  const [
    forYouUpdatesPersonalisationData,
    setforYouUpdatesPersonalisationData,
  ] = useState<SocialMediaUpdate[]>([]);

  const [openJoin, setOpenJoin] = useState(false);
  const [openJoined, setOpenJoined] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [filteredPersonalisationData, setFilteredPersonalisationData] =
    useState<AroundlyPlaces[]>([]);
  const [personalisationData, setPersonalisationData] = useState<
    AroundlyPlaces[]
  >([]);
  const [filteredSavedData, setFilteredSavedData] = useState<AroundlyPlaces[]>(
    []
  );
  const [relevance, setRelevance] = useState("relevance");

  const [wishlistError, setWishlisterror] = useState(false);
  const [notificationsaved, setnotificationsaved] = useState(false);
  const fetchWishlistData = useCallback(async (id: string) => {
    try {
      setWishlistDataLoading(true);
      const wishlistData = await getSingleWishlistData(id);
      if (wishlistData.is_deleted) {
        // Show a toast message (you can use a toast library here)
        // Example: toast.error("This wishlist has been deleted.");
        // Redirect to a well-designed 404 not found page
        navigate("/app/feed/wishlist");
        return;
      }

      setWishListData(wishlistData);
    } catch (e) {
      console.error(e);
      setWishlisterror(true);
    } finally {
      setWishlistDataLoading(false);
    }
  }, []);

  useEffect(() => {
    if (wishlistId) {
      fetchWishlistData(wishlistId);
    }
  }, [wishlistId, fetchWishlistData, collabLists]);

  const removeKeyFromSearchParams = useCallback(
    (key: string) => {
      searchParams.delete(key);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onInvite = async () => {
    const inviteText = `Let's add the best places to visit together! - ${wishListData?.name} -`;
    const urlToShare = `${process.env.REACT_APP_FRONTEND_URL}/app/wishlist/${wishlistId}?referral_id=${authUser?.referal_id}&is_invited=true`;

    Mixpanel.track("Wishlist Invite Clicked", {
      wishlist_id: wishlistId,
      ui_type: "collab-wishlist-page",
    });

    await shareLogic(inviteText, urlToShare);
  };

  const onShare = async () => {
    const shareText = `Checkout my wishlist of places - ${wishListData?.name} -`;
    const urlToShare = `${process.env.REACT_APP_FRONTEND_URL}/app/wishlist/${wishlistId}?referral_id=${authUser?.referal_id}`;

    Mixpanel.track("Share Clicked", {
      wishlist_id: wishlistId,
      ui_type: "collab-wishlist-page",
      share_type: "wishlist",
    });

    await shareLogic(shareText, urlToShare);
  };

  const isInvited = searchParams.get("is_invited");

  const isNotOwner = useCallback(
    (id: string) => wishListData?.owner_id._id !== id,
    [wishListData]
  );
  const isNotCollaborator = useCallback(
    (id: string) =>
      wishListData?.collaborators.every((c) => c.user_id._id !== id),
    [wishListData?.collaborators]
  );

  const handleCollaboratorAdd = async (collaboratorId: string) => {
    const referralId = (await getReferIdFromStorage()) as string;
    try {
      const updatedData = await updateWishlist(wishlistId as string, {
        type: "add",
        collaborator_id: collaboratorId,
        referral_id: referralId,
      });

      Mixpanel.track("Collaborator Joined", {
        wishlist_id: wishlistId,
        collaborator_id: collaboratorId,
        ui_type: "collab-wishlist-page",
        referral_id: referralId,
      });

      const data = await getWishlistData();
      setCollabLists(data);
      setWishListData(updatedData);
      removeKeyFromSearchParams("is_invited");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setSelectedWishlist(wishListData?.name || "");

    return () => {
      setSelectedWishlist("");
    };
  }, [setSelectedWishlist, wishListData?.name]);

  useEffect(() => {
    const findIndexById = (array: CollabWishlistType[], id: string) => {
      return array.findIndex((item) => item._id === id);
    };

    const index = findIndexById(collabLists, wishlistId as string);
    const data = allCollablists[index];
    setPersonalisationData(data);
  }, [allCollablists, collabLists, wishlistId]);

  useEffect(() => {
    let tempData = personalisationData;

    if (userCurrentPosition) {
      tempData = tempData?.map((place: AroundlyPlaces) => ({
        ...place,
        distance: getDistanceFromLatLonInKm(
          userCurrentPosition.coords.latitude,
          userCurrentPosition.coords.longitude,
          place.geo_location.coordinates[1],
          place.geo_location.coordinates[0]
        ),
      }));
    }

    tempData?.forEach((place) => {
      place.updates.forEach((updateInside) => {
        updateInside.related_aroundly_place_ids = [place];
      });
    });

    if (relevance === "distance") {
      tempData.sort((a, b) => a.distance - b.distance);
    }

    setFilteredPersonalisationData(tempData);
  }, [userCurrentPosition, personalisationData, relevance]);

  useEffect(() => {
    let updates = filteredPersonalisationData?.flatMap((place) => {
      return place.updates?.map((update) => ({
        ...update,
        related_aroundly_place_ids: [place],
      }));
    });

    if (relevance === "upcoming") {
      updates = updates.sort((a, b) => {
        const dateA = moment(a.expires_at).tz(DEFAULT_TIMEZONE);
        const dateB = moment(b.expires_at).tz(DEFAULT_TIMEZONE);
        return dateA.diff(dateB);
      });
    }

    setforYouUpdatesPersonalisationData(updates);
  }, [filteredPersonalisationData, updateTypeToShow, relevance]);

  useEffect(() => {
    const places = wishListData?.places?.map((val) => ({
      ...val.place_id,
      updates: val.updates.map((u) => u.update_id),
    }));

    let tempData = places || [];

    if (userCurrentPosition) {
      tempData = tempData.map((place) => ({
        ...place,
        distance: getDistanceFromLatLonInKm(
          userCurrentPosition.coords.latitude,
          userCurrentPosition.coords.longitude,
          place.geo_location.coordinates[1],
          place.geo_location.coordinates[0]
        ),
      }));
    }

    if (relevance === "distance") {
      tempData.sort((a, b) => a.distance - b.distance);
    }

    setFilteredSavedData(tempData);
  }, [userCurrentPosition, wishListData?.places, relevance]);

  useEffect(() => {
    let updates = filteredSavedData.flatMap((place) => {
      return place.updates.map((update) => ({
        ...update,
        related_aroundly_place_ids: [place],
      }));
    });

    if (relevance === "upcoming") {
      updates.sort((a, b) => {
        const dateA = moment(a.expires_at).tz(DEFAULT_TIMEZONE);
        const dateB = moment(b.expires_at).tz(DEFAULT_TIMEZONE);
        return dateA.diff(dateB);
      });
    }

    setforYouUpdatesSavedData(updates);
  }, [filteredSavedData, updateTypeToShow, relevance]);

  const upcomingLength = useUpcomingLength(forYouUpdatesSavedData);

  useEffect(() => {
    const hasShownDiv = localStorage.getItem("hasShownDiv");
    if (
      !hasShownDiv &&
      collabLists.length === 1 &&
      authUser?._id === wishListData?.owner_id._id
    ) {
      setShowInfo(true);
      localStorage.setItem("hasShownDiv", "true");
    }
  }, [collabLists.length, authUser?._id, wishListData?.owner_id._id]);

  useEffect(() => {
    const referralId = searchParams.get("referral_id");
    if (referralId) {
      setReferIdToStorage(referralId);
      removeKeyFromSearchParams("referral_id");
    }
  }, [searchParams, removeKeyFromSearchParams]);

  useEffect(() => {
    if (!loading && authUser) {
      if (!wishlistdataLoading && wishListData) {
        if (
          isInvited &&
          isNotOwner(authUser._id) &&
          isNotCollaborator(authUser._id)
        ) {
          setOpenJoin(true);
        } else if (
          isInvited &&
          isNotOwner(authUser._id) &&
          wishListData.collaborators.some((u) => u.user_id._id === authUser._id)
        ) {
          setOpenJoined(true);
          removeKeyFromSearchParams("is_invited");
        }
      }
    }
  }, [
    isInvited,
    authUser,
    wishlistdataLoading,
    wishListData,
    isNotOwner,
    isNotCollaborator,
    loading,
    removeKeyFromSearchParams,
  ]);

  useEffect(() => {
    if (!loading && !authUser) {
      navigate(`/login?redirect=${location.pathname + location.search}`);
    }
  }, [authUser, loading, location, navigate]);

  useEffect(() => {
    const handleScroll = () => {
      setIsWhite(window.scrollY > 260);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (wishlistId) {
      let savedId = JSON.parse(localStorage.getItem(wishlistId) as string);
      setnotificationsaved(savedId as boolean);
    }
    return () => {
      if (wishlistId) {
        localStorage.setItem(wishlistId, JSON.stringify(false));
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-slate-100 max-w-md w-full">
      <div className={`  max-w-md w-full  fixed items-center top-0  z-10`}>
        <div
          className={` flex   ${
            isWhite ? "bg-white" : ""
          }  items-center justify-between gap-1 p-3  w-full`}
        >
          <div className="">
            <ButtonControl
              buttonId=""
              size="lg"
              theme="white"
              name=""
              icon={{ component: ArrowLeftIconSolid }}
              iconPlacement="end"
              onClick={() => {
                Mixpanel.track("Back Clicked", {
                  wishlist_id: wishlistId,
                  ui_type: "collab-wishlist-page",
                });

                navigate(-1);
              }}
            />
          </div>

          <div className="flex gap-1">
            <ButtonControl
              buttonId=""
              size="lg"
              theme="white"
              name=""
              icon={{
                component: PencilSquareIcon,
              }}
              iconPlacement="end"
              onClick={(e: any) => {
                e.stopPropagation();
                Mixpanel.track("Wishlist Edit Clicked", {
                  wishlist_id: wishlistId,
                  ui_type: "collab-wishlist-page",
                });
                toggleEditModal(true, wishListData as CollabWishlistType);
              }}
            />

            <ButtonControl
              buttonId=""
              size="lg"
              theme="white"
              name=""
              icon={{ component: ShareIcon }}
              iconPlacement="end"
              onClick={onShare}
            />
          </div>
        </div>
      </div>

      <div className="relative">
        <MapComp height={260} hideLocation />

        <div className="absolute bottom-5 w-full max-w-md">
          <div className="flex justify-between gap-4 mx-3">
            <CategoryCard
              category="Your Saved"
              query={wishListData?.name as string}
              collabListData={wishListData}
              loggedInUserData={authUser as UserProps}
              dataLoading={wishlistdataLoading}
            />

            <div className="flex items-end py-1">
              {authUser?._id === wishListData?.owner_id._id && (
                <div className="items-end flex justify-end">
                  <ButtonControl
                    buttonId="wishlist-invite-button"
                    size="md"
                    theme="secondary"
                    name="Invite Circle"
                    icon={{ component: UserPlusIcon }}
                    iconPlacement="start"
                    onClick={onInvite}
                  />
                </div>
              )}

              {isInvited &&
                authUser &&
                isNotOwner(authUser?._id) &&
                isNotCollaborator(authUser._id) && (
                  <ButtonControl
                    buttonId="join-button"
                    size="md"
                    theme="secondary"
                    name="Join"
                    icon={{ component: UserPlusIcon }}
                    iconPlacement="start"
                    onClick={() => {
                      Mixpanel.track("Collaborator Join Click", {
                        wishlist_id: wishlistId,
                        collaborator_id: authUser?._id,
                        ui_type: "collab-wishlist-page",
                      });
                      handleCollaboratorAdd(authUser?._id as string);
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </div>

      {collabdataLoading && (
        <div className="min-h-[calc(100vh-184px)] relative m-auto flex justify-start items-start mt-20">
          <LoadingComp showSources={false} />
        </div>
      )}

      {!collabdataLoading && (
        <div className="bg-white sticky top-[74px] z-10 py-1 mb-2">
          <ForYouPlacesComp
            tabsToShow={[
              { name: "Saved", showDot: false, id: "saved" },
              {
                name: "For You",
                showDot: notificationsaved as boolean,
                id: "foryou",
              },
              { name: "Places", showDot: false, id: "places" },
            ]}
            activeTabId={activeSection}
            setActiveTab={(tab) => {
              Mixpanel.track("Tab Clicked", {
                ui_type: tab.id,
                has_red_dot: tab.showDot,
                page: "collab-wishlist-page",
              });
              setActiveSection(tab.id);
            }}
            page="saved"
          />

          <div
            className={`pl-3  py-2 top-0 ${showModal ? "z-0 " : "z-30 "} ${
              resultsType === "search" &&
              lastSearchText !== searchText &&
              "hidden"
            }  bg-white w-full  z-0`}
          >
            <Filters
              interests={interests}
              resultsType={resultsType}
              updateTypeToShow={updateTypeToShow}
              istemplate={isUpdatesTab}
              setIsTemplate={setIsUpdatesTab}
              setRelevance={(data) => {
                Mixpanel.track("Sort by Option Clicked", {
                  old_option: relevance,
                  new_option: data,
                  ui_type: "collab-wishlist-page",
                });
                setRelevance(data);
              }}
              relevance={relevance}
            />
          </div>
        </div>
      )}
      {!collabdataLoading &&
        activeSection === "saved" &&
        filteredSavedData?.length > 0 && (
          <SavedToggle
            places={filteredSavedData?.length || 0}
            upcoming={upcomingLength}
            updates={forYouUpdatesSavedData?.length || 0}
            istemplate={isUpdatesTab}
            setIsTemplate={(isUpdate) => {
              Mixpanel.track("Places/Updates Toggle Clicked", {
                ui_type: "collab-wishlist-page",
                toggle_type: isUpdate ? "updates" : "places",
              });
              setIsUpdatesTab(isUpdate);
            }}
          />
        )}

      {!collabdataLoading && activeSection === "foryou" && (
        <>
          {!personalisationError &&
            forYouUpdatesPersonalisationData?.length > 0 && (
              <div className="flex gap-1 text-xs mx-3 pb-2">
                <SparklesIcon className="text-[#00B078]  h-4 w-4" />
                <p className="text-secondary-text">
                  <span className="text-[#00B078]  ">
                    {forYouUpdatesPersonalisationData?.length} new
                  </span>{" "}
                  recommendations tailored to your saved
                </p>
              </div>
            )}
          <MasonryComponent
            updates={forYouUpdatesPersonalisationData as SocialMediaUpdate[]}
            isResults={false}
            showErrorModal={personalisationError}
            showDateFooter={false}
          />
        </>
      )}

      {!collabdataLoading && activeSection === "places" && (
        <PlacesTab
          showError={personalisationError}
          data={filteredPersonalisationData}
        />
      )}

      {activeSection === "saved" && (
        <>
          {filteredSavedData?.length > 0 ? (
            <div className="w-full max-w-md flex flex-col gap-2">
              {isUpdatesTab ? (
                <MasonryComponent
                  updates={forYouUpdatesSavedData as SocialMediaUpdate[]}
                  isResults={false}
                  showErrorModal={wishlistError}
                  showDateFooter={false}
                />
              ) : (
                <PlacesTab showError={wishlistError} data={filteredSavedData} />
              )}
            </div>
          ) : (
            <div className="mx-3">
              <EmptySave />
            </div>
          )}
        </>
      )}

      {openCollabs && (
        <Collabs
          data={wishListData as CollabWishlistType}
          onClose={() => setOpenCollabs(false)}
        />
      )}
      {openJoin && (
        <JoinModal
          data={wishListData as CollabWishlistType}
          onClose={() => setOpenJoin(false)}
          onJoin={() => {
            handleCollaboratorAdd(authUser?._id as string);
          }}
        />
      )}
      {openJoined && (
        <JoinedModal
          data={wishListData as CollabWishlistType}
          onClose={() => setOpenJoined(false)}
        />
      )}
    </div>
  );
}
