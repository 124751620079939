import NewButton from "../components/newButton";
import { useAuth } from "../context/auth";
import { useState } from "react";
// import { formatPhoneNumberIntl } from "react-phone-number-input";
import { 
  // useLocation,
   useNavigate, useSearchParams } from "react-router-dom";
import { loginVerify } from "../services/aroundly-apis";
// import { storeNotificationTokenToDb } from "../common/notification";
import { fetchOnboardingStep } from "../common/onboarding-util";
import { useCapacitorInfo } from "../context/CapacitorContext";

export default function VerifyUi({
  phone,
  onClose,
}: {
  phone: string;
  onClose: () => any;
}) {
  const navigate = useNavigate();
  const { isNative } = useCapacitorInfo();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuthUser } = useAuth();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");
  const invited = searchParams.get("is_invited");
  const wholeUrl = redirectUrl + "&is_invited=" + invited;

  const onVerifyButtonClick = async () => {
    try {
      setLoading(true);
      setError(false);
      const resp = await loginVerify(phone, otp, isNative);
      const authUserObj = { ...resp.user };
      if (setAuthUser) {
        setAuthUser(authUserObj);
        // TODO: uncomment below line when notification token saving issue is fixed
        // await storeNotificationTokenToDb();
      }
      setLoading(false);
      
      document.body.style.overflow="unset";

      if (
        authUserObj &&
        authUserObj.is_onboarding_completed &&
        redirectUrl &&
        invited
      ) {
        navigate(wholeUrl);
      } else if (isNative && resp.user.is_onboarding_enabled) {
        navigate(fetchOnboardingStep(authUserObj));
      } else {
        if (!resp.user.is_onboarding_enabled) {
          // navigate("/complete-registration");
          navigate("/");
        } else {
          navigate("/login");
          onClose();
        }
      }
    } catch (e) {
      setLoading(false);
      setError(true);
      console.log(e);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm h-svh">
      <div className="flex justify-center items-center shadow-lg mx-8">
        <div className="px-4 py-12 z-50 bg-white rounded-lg">
          <div
            style={{ letterSpacing: "-1.2px" }}
            className={`text-black text-4xl font-medium`}
          >
            Verify OTP
          </div>
          <div className="mt-2 whitespace-break-spaces self-start text-left">
            <span className={`font-medium text-gray-700 `}>
              We sent it to {phone} via SMS
            </span>
            <button
              style={{ letterSpacing: "-0.333px" }}
              className={` text-primary ml-2`}
              onClick={() => onClose()}
            >
              Edit
            </button>
          </div>
          <div className="flex flex-col">
            <input
              aria-label="otp"
              className={`mt-6 h-12 w-full rounded focus:outline-none focus:border-none text-black text-xl`}
              value={otp}
              placeholder="XXXX"
              type="tel"
              maxLength={4}
              autoFocus
              onChange={(e) => setOtp(e.target.value)}
            />
            {error && (
              <p className="text-[#D98E1F] mt-2 text-sm">
                Entered OTP is invalid or expired
              </p>
            )}
            <NewButton
              loading={loading}
              className="mt-4 h-12"
              onNext={onVerifyButtonClick}
            >
              VERIFY
            </NewButton>
          </div>
          <div
            className={`text-xs font-medium leading-4 text-tertiary-text tracking-tight mt-4`}
            // htmlFor="alert-checkbox"
          >
            By continuing, you agree to the Terms & Privacy policy of Aroundly.
          </div>
        </div>
      </div>
    </div>
  );
}
