// import { Mixpanel } from "../services/mixpanel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
// import { debounce } from "lodash";
import { UPDATE_TYPE_EVENT } from "../common/global.constants";
import { useFeedData } from "../context/FeedDataContext";
import { useAuth } from "../context/auth";
import { Mixpanel } from "../services/mixpanel";
import {
  AroundlyPlaces,
  // CollabWishlistType,
  // LocationType,
  SocialMediaUpdate,
} from "../common/global.types";
import { filterDataBySearchParams } from "../utils/filterUpdates.util";
import { BottomTray } from "../components/BottomTrayLogin";
import { fetchOnboardingStep } from "../common/onboarding-util";
import { useCollabListsContext } from "../context/CollabListsContext";
import EatOffersEvent from "../components/EatoffersEvent";
import HomeNav from "../components/HomeNav";
import { useCapacitorInfo } from "../context/CapacitorContext";
import MapComp from "../components/MapComp";
import SearchButton from "../components/SearchButton";
// import { useFoodandDrinks } from "../context/FoodAndDrinks";
import CollabListCard from "./CollabListCard";
import useForYouUpdates from "../hooks/useForYouUpdates";
// import { useYourSavedPageContext } from "../context/Yoursaved";
import LocationSelectModal from "../components/LocationSelectModal";
import EmptySave from "../components/EmptySave";
import { ChevronDownIcon as ChevronDownIconSolid } from "@heroicons/react/16/solid";
import { MapPinIcon } from "@heroicons/react/24/outline";

const LOCATIONS_STR = "locations";

const getSocialMediaUpdate = (data: AroundlyPlaces[]) => {
  let filteredData: SocialMediaUpdate[] = [];

  data?.forEach((placedata) => {
    const updatesfiltered = placedata.updates.filter(
      (u: SocialMediaUpdate) => u.update_type === UPDATE_TYPE_EVENT
    );

    updatesfiltered.forEach((update) => {
      update.related_aroundly_place_ids = [placedata];
    });

    filteredData = filteredData.concat(updatesfiltered);
  });
  filteredData = filteredData.sort((a, b) => {
    const dataA = new Date(a.expires_at);
    const dataB = new Date(b.expires_at);
    return Number(dataA) - Number(dataB);
  });
  return filteredData;
};

export default function HomePage() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("");

  const [searchParams] = useSearchParams();
  const {
    selectedLocation,
    updates: places,
    onLocationSelect,
    locations,
  } = useFeedData();
  const { collabLists } = useCollabListsContext();

  const { authUser, loading } = useAuth();

  useEffect(() => {
    const lastVisit = localStorage.getItem("lastVisit");
    const today = new Date().toISOString().split("T")[0];

    if (lastVisit !== today) {
      localStorage.setItem("Eat", JSON.stringify(true));
      localStorage.setItem("Exp", JSON.stringify(true));
      collabLists.forEach((u) => {
        const places = u?.places?.map((val) => ({
          ...val.place_id,
          updates: val.updates.map((u) => u.update_id),
        }));

        let totalupdates = places.reduce((u, ui) => {
          const updatesLength = Array.isArray(ui.updates)
            ? ui.updates.length
            : 0;
          return u + updatesLength;
        }, 0);

        if (totalupdates > 0) {
          localStorage.setItem(u._id, JSON.stringify(true));
        }
      });
      if (collabLists.length > 0) {
        localStorage.setItem("lastVisit", today);
      }
    }
  }, [collabLists]);

  let normalUpdate: SocialMediaUpdate[] = [];

  places?.forEach((place) => {
    let singleUpdate = place.updates;
    singleUpdate.forEach((updateInside: SocialMediaUpdate) => {
      updateInside.related_aroundly_place_ids = [place];
      // updateInside.rating = update.google_maps_details.rating;
      // updateInside.placeid = update._id;
      // updateInside.placeName = update.name;
    });
    normalUpdate.push(...singleUpdate);
  });

  normalUpdate = normalUpdate.sort((a, b) => {
    const dataA = new Date(a.expires_at);
    const dataB = new Date(b.expires_at);
    return Number(dataA) - Number(dataB);
  });

  let data = places?.map((placeData) => {
    let filteredUpdates = filterDataBySearchParams(
      placeData.updates,
      searchParams
    );
    return { ...placeData, updates: filteredUpdates };
  });

  let filteredData: SocialMediaUpdate[] = [];

  const weekData = getSocialMediaUpdate(data);
  const anytimeData = getSocialMediaUpdate(places);

  if (weekData.length > 0) {
    filteredData = weekData;
  } else {
    filteredData = anytimeData;
  }

  const [width, setWidth] = useState(0);
  // const defaultTime = "week";
  // const defaultUpdateType = UPDATE_TYPE_EVENT;
  const [showTray, setShowTray] = useState(false);
  // const [ispageScrolled, setIspageScrolled] = useState(false);
  // const { upcommingEvents } = useFoodandDrinks();

  // const handleMasonryInterestClick = useCallback(
  //   (id: string) => {
  //     setSelectedId(id);
  //     if (!id) {
  //       setInterestValue(personalisationData);
  //     } else {
  //       if (personalisationData !== undefined) {
  //         const filteredUpdate = personalisationData.filter((u) =>
  //           u.interest_categories?.some((i: any) => i === id)
  //         );
  //         setInterestValue(filteredUpdate);
  //       }
  //     }
  //   },
  //   [personalisationData]
  // );

  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  // const reviewUpdates = normalUpdate.filter(
  //   (u) =>
  //     u.interest_categories?.some((i) =>
  //       subscription?.interests?.includes(i._id)
  //     ) ||
  //     u.interest_categories?.some((i) => i._id === FOOD_AND_BEV_INTEREST_ID)
  // );

  // const eventUpdates = normalUpdate.filter((u) =>
  //   u.interest_categories?.some((i) => subscription?.interests?.includes(i._id))
  // );

  // const offerUpdates = normalUpdate.filter((u) =>
  //   u.interest_categories?.some((i) => subscription?.interests?.includes(i._id))
  // );

  const [showBeforeModal, setBeforeModal] = useState(false);
  const toggleBeforeModal = () => {
    Mixpanel.track("Search Button Clicked", {
      ui_type: "home-search-box",
      location_id: selectedLocation?.location_id,
    });
    setBeforeModal(!showBeforeModal);
  };

  // const selectedTimelineId = searchParams.get("time") || "week";

  // const remainDataFromSub = interests.filter(
  //   (data) => !dataOfSub?.some((sub) => sub._id === data._id)
  // );

  // const extractUpdatesWithPlaceId = (places: WishlistPlace[]) => {
  //   return places.flatMap((place) =>
  //     place.updates.map((update) => update.update_id)
  //   );
  // };

  // useEffect(() => {
  //   if (authUser && !loading) {
  //     setSubscription(authUser.subscription);
  //   }
  // }, [loading, authUser]);

  // useEffect(() => {
  //   const matchingInterests = interests?.filter((interest) =>
  //     auth?.interests.includes(interest._id)
  //   );
  //   setDataOfSub(matchingInterests);
  // }, [interests, subscription?.interests]);

  const handleClose = () => {
    setShowTray(!showTray);
  };

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, filteredData.length);
  }, [filteredData]);

  useEffect(() => {
    if (!loading && authUser?.is_onboarding_enabled) {
      navigate(fetchOnboardingStep(authUser));
    }
  }, [loading, authUser, navigate]);

  const contentRef = useRef<HTMLDivElement>(null);

  // const containerRef = useRef<HTMLDivElement>(null);

  // const calculateScrollRatio = (length: number): number => {
  //   const baseRatio = 0.5;
  //   const increment = 0.1;
  //   const maxRatio = 0.85;
  //   const ratio = baseRatio + Math.floor(length / 100) * increment;
  //   return Math.min(ratio, maxRatio);
  // };

  // const handleScroll = debounce(() => {
  //   const element = contentRef.current;
  //   if (element) {
  //     const { scrollTop, scrollHeight, clientHeight } = element;
  //     const scrollRatio = calculateScrollRatio(previous_updates?.length);
  //     const halfwayPoint = scrollHeight * scrollRatio;
  //     if (
  //       scrollTop + clientHeight >= halfwayPoint &&
  //       !ispageScrolled &&
  //       selectedCollabListData === undefined &&
  //       personalisationData.length > 0
  //     ) {
  //       onScrollEnd();
  //     }
  //   }
  // }, 500);

  // const onScrollEnd = () => {
  //   getPersonalisationFunc(previous_updates, selectedLocation as LocationType);
  //   if (ispageScrolled === false) setIspageScrolled(true);
  // };

  const { currentAppVersion } = useCapacitorInfo();

  const setAppversion = useCallback(async () => {
    Mixpanel.people.set({
      APP_VERSION: currentAppVersion,
    });
  }, [currentAppVersion]);

  useEffect(() => {
    setAppversion();
  }, [setAppversion]);

  const eatupdateslength = useForYouUpdates(places, true).length;

  const eventsUpdtateslength = useForYouUpdates(places)?.length;

  const nonDefaultCollabLists = collabLists?.filter((item) => !item.is_default);

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        const homediv = contentRef.current.offsetWidth;
        setWidth(homediv);
      }
    };

    window.addEventListener("resize", handleResize);

    // Get the initial width
    handleResize();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="h-dvh pb-20 flex flex-col min-w-full overflow-x-hidden overflow-y-scroll scrollbar-hide"
      // onScroll={handleScroll}
    >
      <div ref={contentRef} className="relative">
        <HomeNav />

        {/* <MapComp height={360} /> */}

        <div
          onClick={() => {
            Mixpanel.track("Location Picker Clicked", {
              ui_type: "category-card-header",
            });
            setSelectedTab(LOCATIONS_STR);
          }}
          className="text-white text-2xl shadow-custom font-medium flex gap-1 items-center justify-center absolute bottom-28 w-full"
          style={{
            zIndex: 1,
            letterSpacing: "-0.72px",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <MapPinIcon className="h-6 w-6" />
          <span>{selectedLocation?.location_name}</span>
          <ChevronDownIconSolid className="h-6 w-6" />
        </div>

        <div
          className="absolute bottom-0 left-0  h-full w-full"
          style={{
            zIndex: 0,
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00)25%, rgba(0, 0, 0, 0.80)100%)`,
          }}
        ></div>
        <img
          src={selectedLocation?.image_link}
          alt="location_image"
          style={{
            height: width,
          }}
          className="object-cover w-full"
        />

        <div className="absolute bottom-0  left-0 right-0  flex flex-col items-center ">
          <div className="text-black flex flex-col gap-2 items-center justify-center rounded-xl mt-2  w-full">
            <div className="w-[90%]">
              <SearchButton
                toggleBeforeModal={toggleBeforeModal}
                showBeforeModal={showBeforeModal}
                location="home"
              />
            </div>
            <div
              className="leading-normal text-black text-[10px] flex justify-center items-center text-center px-1 mt-2 py-1 rounded-t-xl bg-[#FAF1E8] w-full border-t"
              style={{ letterSpacing: "-0.36px" }}
            >
              Welcome Aroundly: One App for All Your Stepping Out
            </div>
          </div>
        </div>
      </div>

      <div className=" pt-3 pb-4 flex  flex-col">
        <p
          className="text-xs font-medium leading-5 mx-3 pb-1"
          style={{ letterSpacing: "-0.36px" }}
        >
          Where to, in {selectedLocation?.location_name}?
        </p>
        <div>
          <EatOffersEvent
            eatupdateslength={eatupdateslength}
            eventsUpdtateslength={eventsUpdtateslength}
            notificationeat={JSON.parse(localStorage.getItem("Eat") as string)}
            notificationexp={JSON.parse(localStorage.getItem("Exp") as string)}
          />
        </div>

        <div className="pt-4">
          <p
            className="text-xs font-medium leading-5 mx-3"
            style={{ letterSpacing: "-0.36px" }}
          >
            Your Saved
          </p>
          <div className="flex gap-1 overflow-x-scroll scrollbar-hide my-1 px-3">
            {nonDefaultCollabLists?.length > 0 ? (
              nonDefaultCollabLists.map((wishlist) => (
                <CollabListCard key={wishlist._id} wishlistData={wishlist} />
              ))
            ) : (
              <EmptySave />
            )}
          </div>
        </div>
      </div>
      {selectedTab === LOCATIONS_STR && (
        <LocationSelectModal
          onClose={() => setSelectedTab("")}
          selectedLocationOption={selectedLocation?.location_id}
          locationsList={locations}
          onSubmit={(locationDetails) => {
            onLocationSelect(locationDetails);
            setSelectedTab("");
          }}
        />
      )}

      {!authUser && <BottomTray showTray={showTray} toggle={handleClose} />}
    </div>
  );
}
