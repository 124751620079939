import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import {
  AroundlyPlaces,
  SocialMediaUpdate,
  SupportedActions,
} from "../common/global.types";
import { useEffect, useState } from "react";
import { useCollabListsContext } from "../context/CollabListsContext";
import { Mixpanel } from "../services/mixpanel";
import { performUserActionHistory } from "../services/aroundly-apis";
import ButtonControl from "./controls/ButtonControl";

export default function LikeButton({
  item,
  placeData,
  size = "lg",
  isMini = false,
}: {
  readonly item?: SocialMediaUpdate;
  readonly placeData?: AroundlyPlaces;
  readonly isMini?: boolean;
  readonly size?: "sm" | "md" | "lg";
}) {
  const [liked, setLiked] = useState(false);

  const { collabLists, toggleCreateNewModal, toggleWishTrayModal } =
    useCollabListsContext();

  useEffect(() => {
    if (collabLists?.length > 0) {
      let isLikedplace = collabLists.some((obj) =>
        obj.places.some((secobj) => secobj.place_id._id === placeData?._id)
      );
      let isLiked = collabLists.some((obj) =>
        obj.places.some((place) =>
          place.updates.some((update) => update.update_id._id === item?._id)
        )
      );

      setLiked(isLiked || isLikedplace);
    }
  }, [collabLists, item?._id, placeData?._id]);

  const HeartIconComponent = liked ? BookmarkIconSolid : BookmarkIcon;
  return (
    <div className="max-w-fit">
      <ButtonControl
        buttonId="like-button"
        size={size}
        theme={"white"}
        name={isMini ? "" : "Save"}
        icon={{ component: HeartIconComponent }}
        iconColor={liked ? "special-blue" : "black"}
        iconPlacement="start"
        onClick={async (e: any) => {
          e.stopPropagation();
          Mixpanel.track("Wishlist Button Clicked", {
            update_id: item?._id,
            update_type: item?.update_type,
            ui_type: "like-button",
          });
          await performUserActionHistory(SupportedActions.WISHLIST, {
            update_id: item?._id,
            update_type: item?.update_type,
            ui_type: "like-button",
          });
          if (collabLists.length > 0) {
            toggleWishTrayModal(
              true,
              item,
              (placeData as AroundlyPlaces) ||
                (item?.related_aroundly_place_ids &&
                  item?.related_aroundly_place_ids[0])
            );
          } else {
            toggleCreateNewModal(
              true,
              item,
              (placeData as AroundlyPlaces) ||
                (item?.related_aroundly_place_ids &&
                  item?.related_aroundly_place_ids[0])
            );
          }
        }}
      />
      {/* <button>
        <HeartIconComponent className="text-special-blue"/>
        checking
      </button> */}
    </div>
  );
}
