export interface AvatarProps {
  name: string;
  _id: string;
  image: string;
}
export interface Birthdate {
  day: string;
  month: string;
  year: number;
}

export interface UserProps {
  name?: string;
  email?: string;
  avatar_id?: AvatarProps;
  phone: string;
  created_at: Date;
  modified_at: Date;
  created_by?: string;
  modified_by?: string;
  date_of_birth?: Date;
  is_onboarding_enabled?: boolean;
  is_onboarding_completed?: boolean;
  role: "admin" | "customer";
  _id: string;
  referal_id?: string;
  gender: string;
  stepout: string[];
  no_of_friends: number;
  interests: string[];
  city: string;
}

// export interface UserSubscriptionProps {
//   _id: string;
//   user_id: string;
//   topic_id: string;
//   status: string;
//   interests: string[];
//   city: string;
//   created_by: string;
//   modified_by: string;
//   created_at: Date;
//   modified_at: Date;
// }

// export interface UserDataWithSubscriptionProps extends UserProps {
//   subscription?: UserSubscriptionProps;
// }

export interface CityProps {
  name: string;
  _id: string;
}

export type NavTypeProps =
  | "text-only"
  | "text-logo"
  | "name-logo"
  | "name-city-logo"
  | "location-select";

export type MoodsMapType = {
  [key: string]: { _id: string; interest_name: string }[];
};

export type LocationType = {
  location_name: string;
  location_id: string;
  radius: number;
  latitude: number;
  longitude: number;
  timezone: string;
  image_link?: string;
};

export interface WishlistCountItem {
  id: string;
  count: number;
} // Assuming string keys and number values

export interface InterestProps {
  _id: string;
  interest_name: string;
  display_name?: string;
  icon?: string;
  subinterests?: string[];
  moods?: string[];
  order?: number;
}

export type UpdateSource =
  | "instagram"
  | "tiktok"
  | "twitter"
  | "bookmyshow"
  | "aroundly"
  | "paytm insider"
  | "bookmyshow";

export type CtaLink = {
  url: string;
  source: string;
  type: string;
  text: string;
  price: number;
};

export interface Sources {
  source_key: string;
  source_logo: string;
  source_label: string;
  _id: string;
}

export type UpdateType =
  | "offer"
  | "event"
  | "review"
  | "general_update_generated";

export type EventType =
  | "Parties & Celebrations"
  | "Family-Friendly Events"
  | "Dining Experiences"
  | "Outdoor & Adventure"
  | "Cultural Events"
  | "Relaxing Retreats";

export type EventMood =
  | "Festive & Lively"
  | "Relaxed & Casual"
  | "Cultural & Traditional"
  | "Romantic & Intimate"
  | "Adventurous & Exciting";

export type GroupType =
  | "Friends"
  | "Couples"
  | "Family"
  | "Individuals"
  | "Professionals";
export type SkillLevel = "Beginner" | "Intermediate" | "Advanced" | "All";

export type AgeGroup = "Kids" | "Teenagers" | "Adults" | "Seniors";

export interface SocialMediaUpdate {
  _id: string;
  social_media_update_id: string;
  social_media_handle: string;
  is_relevant?: boolean;
  source: UpdateSource;
  event_mood: EventMood;
  business_id?: Business;
  price?: number;
  rating?: number;
  placeid?: string;
  placeName?: string;
  related_aroundly_place_ids?: AroundlyPlaces[];
  is_published?: boolean;
  social_media_update_details: any;
  interest_categories?: InterestProps[];
  update_type: UpdateType;
  source_link: string;
  saves?: FavoriteProps[];
  created_at: string;
  modified_at: string;
  timestamp?: Date;
  save?: FavoriteProps;
  local_media_url?: string;
  cta_links: CtaLink[];
  caption_summary: string;
  caption_title: string;
  expires_at: Date;
  group_type?: GroupType[];
  skill_level?: SkillLevel;
  age_group?: AgeGroup;
  sub_categories?: string[];
  is_best?: boolean;
  aroundly_generated?: boolean;
  media_metadata?: {
    media_original: {
      format: string;
      height: number;
      key: string;
      type: string;
      width: number;
      url: string;
    };
    media_compressed: {
      format: string;
      height: number;
      key: string;
      type: string;
      width: number;
      url: string;
    };
  };
  event_type: string[];
}

interface NotInterestedArray {
  update_id: string;
  additional_info: string[];
}

export interface UserAction {
  update_id: string;
  not_interested?: NotInterestedArray[];
}

export enum SupportedActions {
  WISHLIST = "wishlist-add",
  NOT_INTERESTED = "not-interested",
  NOT_INTERESTED_UNDO = "not-interested-undo",
  WISHLIST_REMOVE = "wishlist-remove",
  SHARE = "share",
  SHARE_POST = "share-post",
  DIRECTIONS = "directions",
  VIEW_POST = "view-post",
  VIEW_STORE = "view-store",
  BOOK_POST = "book-post",
  SHARE_STORE = "share-store",
  SHARE_RESULTS = "share-results",
  SCROLL_VIEW_POST = "scroll-view-post",
}
export interface FavoriteProps {
  _id: string;
  status: boolean;
  user_id: UserProps;
  update_id: SocialMediaUpdate;
}

// export interface SubscriptionTopics {
//   _id: string;
//   topic_name: String;
//   created_at: Date;
//   modified_at: Date;
//   created_by: String;
//   modified_by: String;
// }

export interface SearchResultProps {
  name: String;
  prompt_used: String;
  place_results: String[];
  user_id: string;
  selected_preferences: {
    activities: String[];
    people: String[];
    location: String;
  };
  created_at: Date;
  modified_at: Date;
  created_by: String;
  modified_by: String;
  _id: string;
}

export interface PlacesProps {
  name: string;
  google_place_id: string;
  geo_location: {
    type: "Point";
    coordinates: Number[];
  };
  created_at: Date;
  modified_at: Date;
  created_by: string;
  modified_by: string;
  images: string[];
  address: string;
  favorite?: FavoriteProps;
  _id: string;
  rating?: number;
}

export interface AroundlyPlaces {
  created_at?: Date;
  name: string;
  modified_at?: Date;
  place_type: string;
  place_id: string;
  place_category: string;
  geo_location: {
    type: string;
    coordinates: number[];
  };
  google_maps_details: GooglePlace;
  _id: string;
  socials?: any;
  distance: number;
  swiggy_dine_out_details: {
    cuisine: string;
    url: string;
    best_selling_items: string;
    price_for_two: string;
    reviews_count: string;
    rating: string;
  };
  known_for: string[];
  updates: SocialMediaUpdate[];
}

export interface GoogleAutoSuggest {
  description: string;
  matched_substrings: string;
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  types: string[];
}

export interface GooglePlace {
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
  icon: string;
  additional_details: {
    offerings: string[];
    dining_options: string[];
    atmosphere: string[];
    crowd: string[];
  };
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  formatted_phone_number: string;
  photos: {
    height: number;
    html_attributions: string[];
    photo_reference: string;
    width: number;
  };
  reviews: {
    author_name: string;
    author_url: string;
    language: string;
    original_language: string;
    profile_photo_url: string;
    rating: number;
    relative_time_description: string;
    text: string;
    time: number;
  }[];
  vicinity: string;
  name: string;
  types: string[];
  opening_hours: {
    open_now: true;
    periods: [
      {
        open: {
          day: number;
          time: string;
        };
      }
    ];
    weekday_text: string[];
  };
  rating: number;
  locality: string;
}

export interface LoginProps {
  email: string;
  phone?: string | null;
}

export interface Business {
  _id: string;
  name: string;
  locations: AroundlyPlaces[];
  icon: string;
  ratings: number;
  reviews: {
    author_name: string;
    author_url: string;
    language: string;
    original_language: string;
    profile_photo_url: string;
    rating: number;
    relative_time_description: string;
    text: string;
    time: number;
  }[];
  quality_score: string;
  description: string;
  events: any;
  offers: any;
  images: string[];
  category: string;
  business_type: string;
  guide: string;
  avg_spend: string;
  Vibe: string;
  match_score: string;
  phone: string;
  email: string;
  socials: {
    url: string;
    source: "Instagram" | "Bookmyshow" | "Zomato";
    type: string;
    text: string;
  }[];
  rush_hours: any;
  timings: any;
  safety: string;
  safety_industry_rating: string;
  branches: any;
  is_verified_by_aroundly: boolean;
}

export type WishlistUpdate = {
  update_id: SocialMediaUpdate;
  added_by: string;
  added_at: string;
};

export type WishlistPlace = {
  place_id: AroundlyPlaces;
  updates: WishlistUpdate[];
  added_by: string;
  added_at: string;
};

export interface CollabWishlistType {
  _id: string;
  owner_id: { _id: string; name: string };
  name: string;
  collaborators: {
    user_id: { _id: string; name: string };
    added_by: string;
    added_at: string;
  }[];
  places: WishlistPlace[];
  is_deleted: boolean;
  is_default: boolean;
  is_name_searchable: boolean;
}
