import {
  CollabWishlistType,
  SocialMediaUpdate,
  SupportedActions,
  UpdateType,
  WishlistPlace,
} from "../common/global.types";
import {
  ArrowLeftIcon,
  CalendarIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import {
  UPDATE_TYPE_EVENT,
  UPDATE_TYPE_MAP,
  UPDATE_TYPE_OFFER,
} from "../common/global.constants";
import moment from "moment";
import LikeButton from "./likeBtn";
import ShareButton from "./shareBtn";
import { useEffect, useState } from "react";
import LoadMedia from "./LoadMedia";
import "moment-timezone";
import SourceModal from "./SourceModal";
import NotInterested from "./NotInterested";
import AboutThePlace from "./AboutThePlace";
import BookingOptions from "./BookingOptions";
import {
  performUserAction,
  performUserActionHistory,
} from "../services/aroundly-apis";
import { DirectionBtn } from "./Directionbtn";
import Tooltip from "./Tooltip";
import { useCollabListsContext } from "../context/CollabListsContext";
import { Mixpanel } from "../services/mixpanel";
import BookBotCard from "./BookBotCard";
import HandPicked from "./HandPicked";
import ButtonControl from "./controls/ButtonControl";
import { useNavigate } from "react-router-dom";

type FeedCardProps = {
  readonly item: SocialMediaUpdate;
  readonly favouriteScreen?: boolean;
  readonly isRelevant?: boolean;
  readonly hideSave?: boolean;
  readonly isLoggedIn?: boolean;
  readonly isFullView?: boolean;
  readonly timezone: string;
  readonly onUpdateTypeSelect?: (type: UpdateType) => void;
  readonly onCategorySelect?: (category: string) => void;
};
const checkLength = (places: CollabWishlistType) => {
  return places.places.reduce((total, u) => total + u.updates.length, 0);
};

export default function FeedCard({
  item,
  favouriteScreen,
  isRelevant,
  hideSave = false,
  isLoggedIn,
  isFullView = false,
  timezone,
  onUpdateTypeSelect,
  onCategorySelect,
}: FeedCardProps) {
  const [showSourceModal, setShowSourceModal] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const { toggleStoreModal, toggleUpdateModal } = useCollabListsContext();
  const infoModalHandler = (state: boolean) => {
    setShowInfo(state);
  };
  const navigate = useNavigate();
  const [buttonPosition, setButtonPosition] = useState({
    top: "0px",
  });

  const [showNotInterestOptions, setShowNotInterestedOptions] = useState(false);

  const notOnClick = async () => {
    Mixpanel.track("Not Interested Clicked", {
      update_id: item._id,
      ui_type: "feed-card",
      update_type: item.update_type,
    });
    setShowSourceModal(false);
    setShowNotInterestedOptions(!showNotInterestOptions);
    try {
      await performUserAction(item._id, SupportedActions.NOT_INTERESTED);
      await performUserActionHistory(SupportedActions.NOT_INTERESTED, {
        update_id: item._id,
        ui_type: "feed-card",
        update_type: item.update_type,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const { collabLists, showUpdate } = useCollabListsContext();
  const [closeCount, setCloseCount] = useState(0);
  const [savedCount, setsavedCount] = useState(0);

  useEffect(() => {
    const totalSavedCount = collabLists.reduce((total, u) => {
      return total + checkLength(u);
    }, 0);

    setsavedCount(totalSavedCount);
  }, [collabLists]);

  useEffect(() => {
    const saveCount = localStorage.getItem("saveCount") || 0;
    const lastClosedsave = localStorage.getItem("lastClosedsave");
    if (saveCount && lastClosedsave) {
      const today = new Date().toDateString();
      if (lastClosedsave !== today && parseInt(saveCount) >= 2) {
        localStorage.setItem("saveCount", "0");
        setCloseCount(0);
      } else {
        setCloseCount(Number(saveCount));
      }
    }

    if (parseInt(saveCount as string) >= 2) {
      infoModalHandler(false);
    } else {
      infoModalHandler(true);
    }
  }, []);

  const handleCloseCnt = (cnt: number) => {
    setCloseCount(cnt);
  };

  const [botBookTray, setBookTray] = useState(false);
  const handleBookTray = (status: boolean) => {
    setBookTray(status);
  };
  return (
    <div>
      <div className={`${isFullView ? "grow h-full" : ""} w-full max-w-md`}>
        <div className="w-full relative max-w-md">
          <div className="absolute top-3 z-10 left-3">
            <ButtonControl
              buttonId="save-unsave-button"
              size="lg"
              theme={"white"}
              icon={{
                component: ArrowLeftIcon,
              }}
              iconColor={"black"}
              onClick={() => {
                if (showUpdate) {
                  toggleUpdateModal(false);
                } else {
                  navigate("/");
                }
              }}
            />
          </div>
          <LoadMedia
            isFullView={isFullView}
            source={item.source}
            // thumbnailKey={item?.social_media_update_details?.thumbnail_url}
            // mediaKey={item?.social_media_update_details?.media_url}
            // mediaType={item?.social_media_update_details?.media_type}
            mediaKey={item?.media_metadata?.media_original.url as string}
            thumbnailKey={item?.media_metadata?.media_compressed.url as string}
            mediaType={item?.media_metadata?.media_original.type as any}
          />

          <HandPicked item={item} />
        </div>
        <div className="px-3">
          <div className="flex items-center w-full flex-wrap my-2 ">
            <div className="flex items-center grow">
              {item?.interest_categories &&
              item?.interest_categories?.length > 0 ? (
                <button
                  onClick={() =>
                    item?.interest_categories &&
                    item?.interest_categories?.length > 0 &&
                    onCategorySelect &&
                    onCategorySelect(item?.interest_categories[0]._id)
                  }
                  className={`text-xs text-black px-1 mr-1 rounded font-inter font-normal border border-gray-200 ${
                    isRelevant ? "bg-secondary" : ""
                  } bg-opacity-10`}
                >
                  {item?.interest_categories[0]?.interest_name}
                </button>
              ) : (
                ""
              )}

              <button
                onClick={() =>
                  onUpdateTypeSelect && onUpdateTypeSelect(item.update_type)
                }
                className={`text-xs text-black px-1 mr-1 rounded font-inter font-normal border border-gray-200 ${
                  isRelevant ? "bg-secondary" : ""
                } bg-opacity-10`}
              >
                {UPDATE_TYPE_MAP[item.update_type]}
              </button>
            </div>

            <button
              onClick={(event) => {
                Mixpanel.track("Post Source Clicked", {
                  update_id: item._id,
                  update_type: item.update_type,
                  ui_type: "feed-card",
                  source: item.source,
                });
                const rect = event.currentTarget.getBoundingClientRect();
                setButtonPosition({
                  top: `${rect.top}px`,
                });
                setShowSourceModal(true);
              }}
              className="flex items-center"
            >
              <span className="text-xs text-gray-500 mr-1">
                {item.source === "instagram" ? item.social_media_handle : ""}
              </span>
              <img
                src={`/images/${
                  item.source === "aroundly"
                    ? "aroundly.svg"
                    : `sources/${item.source}.png`
                }`}
                alt={`logo of ${item.source}`}
                width={16}
                height={16}
              />
              <InformationCircleIcon className="h-5 w-5 inline-block text-gray-500 ml-1" />
            </button>
          </div>
          {showSourceModal && (
            <SourceModal
              onModalClose={() => setShowSourceModal(false)}
              item={item}
              position={buttonPosition}
              notOnClick={notOnClick}
            />
          )}
          <div
            id="source-link"
            className={`text-black text-xl leading-6 tracking-tight font-medium not-italic overflow-hidden `}
            onClick={() => {
              localStorage.setItem("postId", item._id);
              // !isFullView && navigate(`/app/update/${item._id}`);
              !isFullView && toggleUpdateModal(true, item._id);
            }}
          >
            {item.caption_title}
          </div>
          {isFullView ? (
            <p
              className={`text-zinc-700 text-base leading-6 font-normal tracking-tight my-2 `}
            >
              {item.caption_summary}
            </p>
          ) : (
            <p
              className={`text-zinc-700 text-sm leading-5 font-normal tracking-tight m-2`}
              onClick={() => {
                // !isFullView && navigate(`/app/update/${item._id}`)
                !isFullView && toggleUpdateModal(true, item._id);
              }}
            >
              {item.caption_summary}
            </p>
          )}

          {(item.update_type === UPDATE_TYPE_EVENT ||
            item.update_type === UPDATE_TYPE_OFFER) && (
            <div className="py-1 flex items-center text-zinc-700">
              <CalendarIcon className="h-4 w-4 inline-block mr-2" />
              <div
                className={`text-sm leading-none tracking-tight font-normal font-inter`}
              >
                {moment(item?.expires_at).tz(timezone).format("Do MMM")}
              </div>
            </div>
          )}
          <div className=" mt-3">
            <div className="  text-xs" style={{ letterSpacing: "-0.33px" }}>
              <ol>
                {item.group_type && item.group_type?.length > 0 && (
                  <li>
                    <span className="font-medium text-secondary-text">
                      Good to go with
                    </span>
                    <br />
                    <div className="ml-3 font-450 text-base">
                      {item.group_type.join(", ")}
                    </div>
                  </li>
                )}
                {item.event_mood && item.event_mood.length > 0 && (
                  <li className="my-3">
                    <span className="font-medium text-secondary-text">
                      Event mood
                    </span>
                    <br />
                    <div className="ml-3 font-450 text-base">
                      {item.event_mood}
                    </div>
                  </li>
                )}
              </ol>
            </div>
          </div>

          {item?.related_aroundly_place_ids && (
            <AboutThePlace
              updateDetails={item}
              placeDetails={item.related_aroundly_place_ids[0]}
            />
          )}

          {isFullView ? <BookingOptions item={item} /> : ""}
        </div>
        <div
          className={`grid grid-cols-4 gap-4 bg-white h-20 ${
            isFullView
              ? " fixed bottom-0 border-t w-full max-w-md p-3"
              : "mb-1 mt-4 "
          }`}
          style={
            isFullView
              ? { boxShadow: "0px -1px 4px 0px rgba(0, 0, 0, 0.075)" }
              : {}
          }
        >
          {savedCount <= 2 && showInfo && (
            <>
              <button className="fixed inset-0 bg-black opacity-30 z-20"></button>
              <div className="absolute bottom-16  left-2 z-30  rounded-full">
                <Tooltip
                  infoModalHandler={infoModalHandler}
                  item={item}
                  placeData={
                    item?.related_aroundly_place_ids &&
                    item?.related_aroundly_place_ids[0]
                  }
                  closeCount={closeCount}
                  handleCloseCnt={handleCloseCnt}
                />
              </div>
            </>
          )}

          <div className="flex col-span-2 justify-start gap-2 items-center">
            {!hideSave && (
              <LikeButton
                item={item}
                placeData={
                  item?.related_aroundly_place_ids &&
                  item?.related_aroundly_place_ids[0]
                }
                isMini
              />
            )}
            <ShareButton
              _id={item?._id}
              captionTitle={item.caption_title}
              captionSummary={item.caption_summary}
              preventShare
            />
            <DirectionBtn
              placeDetails={
                item.related_aroundly_place_ids &&
                item.related_aroundly_place_ids[0]
              }
              isMini
            />
          </div>
          <div className="col-span-2 justify-end flex items-center">
            <div className="max-w-fit">
              {item?.cta_links.length > 0 ? (
                item?.cta_links?.map((link, index) => (
                  <ButtonControl
                    key={index}
                    buttonId="book-button"
                    size="lg"
                    theme="primary"
                    name={link.text.toUpperCase()}
                    icon={{ component: ArrowUpRightIcon }}
                    iconPlacement="end"
                    onClick={async () => {
                      Mixpanel.track(`Book Button: Clicked`, {
                        href: link.url,
                        post_id: item?._id,
                        post_source: item?.source,
                      });
                      await performUserActionHistory(
                        SupportedActions.BOOK_POST,
                        {
                          ctalink: link.url,
                          update_id: item?._id,
                          post_source: item?.source,
                        }
                      );
                      window.open(link.url, "_blank");
                    }}
                  />
                ))
              ) : (
                <ButtonControl
                  buttonId="book-button"
                  size="lg"
                  theme="primary"
                  name="BOOK"
                  icon={{ component: ArrowUpRightIcon }}
                  iconPlacement="end"
                  onClick={() => handleBookTray(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {showNotInterestOptions && (
        <NotInterested
          item={item}
          onClose={() => setShowNotInterestedOptions(false)}
        />
      )}
      {botBookTray && (
        <BookBotCard
          handleBookTray={handleBookTray}
          placeDetails={
            item?.related_aroundly_place_ids &&
            item?.related_aroundly_place_ids[0]
          }
          item={item}
        />
      )}
    </div>
  );
}
