import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { SocialMediaUpdate, SupportedActions } from "../common/global.types";
import { useFeedData } from "../context/FeedDataContext";
import { performUserAction } from "../services/aroundly-apis";
import { useState } from "react";
import { Mixpanel } from "../services/mixpanel";

type SourceModalProps = {
  readonly onModalClose: () => any;
  readonly notOnClick?: () => any;
  readonly item: SocialMediaUpdate;
  readonly position: { top: string };
};

export default function SourceModal({
  onModalClose,
  item,
  position,
  notOnClick,
}: SourceModalProps) {
  const { userUpdate } = useFeedData();

  return (
    <>
      <div
      style={{
        zIndex:60
      }}
        id="modal"
        className="fixed left-0 right-0 top-0 bottom-0 mx-auto bg-black bg-opacity-30 "
      >
        <div
          className={`absolute inset-0 top-1/3 w-full font-inter`}
          // style={{ top: position.top }}
        >
          <div className="max-w-xs mx-auto my-5">
            <div className="bg-white py-3 px-[9px] rounded-lg shadow">
              <div className="mb-2">
                <span className="font-medium  text-xs leading-6">
                  About this post
                </span>
                <XMarkIcon
                  className="h-5 w-5 inline-block float-right cursor-pointer"
                  onClick={() => onModalClose()}
                />
              </div>

              <div className="text-sm">
                <div className="grid grid-cols-12 mb-2">
                  <StarIcon className="h-4 w-4 text-yellow-400 col-span-1" />
                  <span className="ml-2 text-sm col-span-11">
                    This post is enhanced by{" "}
                    <span className="font-bold">AI</span>
                    roundly
                  </span>
                </div>
                <div className="grid grid-cols-12 mb-2">
                  <StarIcon className="h-4 w-4 text-yellow-400 col-span-1" />
                  <span className="ml-2 col-span-11">
                    Source:{" "}
                    <a
                      onClick={() => {
                        Mixpanel.track("Post Source Accessed", {
                          update_id: item._id,
                          update_type: item.update_type,
                          source: item.source,
                          source_link: item.source_link,
                        });
                      }}
                      id="source-link"
                      href={
                        item.source === "aroundly" &&
                        item?.related_aroundly_place_ids
                          ? `/app/store/${item?.related_aroundly_place_ids[0]?._id}`
                          : item.source_link
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={`text-primary underline tracking-tight font-normal not-italic`}
                    >
                      This post is from{" "}
                      <span className="capitalize">{item.source}</span>
                    </a>
                  </span>
                </div>
                <div className="grid grid-cols-12 mb-2">
                  <StarIcon className="h-4 w-4 text-yellow-400 col-span-1" />
                  <span className="ml-2 col-span-11">
                    {`This post is personalized based on your interests and
            what's popular`}
                  </span>
                </div>
                <div className="grid grid-cols-12 mb-2">
                  <StarIcon className="h-4 w-4 text-yellow-400 col-span-1" />
                  <span className="ml-2 col-span-11">
                    {`This is a recommendation, not an ad. Only ads are paid,
            and theyll always be labelled with 'Sponsored' or 'Ad'`}
                  </span>
                </div>
              </div>
              <button
                className="flex col-span-2 text-center items-center justify-start"
                onClick={notOnClick}
              >
                {userUpdate?.not_interested?.filter(
                  (i) => i.update_id === item._id
                ) &&
                userUpdate?.not_interested?.filter(
                  (i) => i.update_id === item._id
                )[0] ? (
                  <>
                    <ExclamationTriangleIcon className="h-6 w-6 mr-1 text-red-500" />
                    <p className="text-red-500 text-xs">
                      Marked Not Interested
                    </p>
                  </>
                ) : (
                  <div className="py-1 text-[#FF511B] flex  items-center ">
                    <XMarkIcon className="h-6 w-6  mr-1" />
                    <p className="text-xs font-400  leading-6 items-center">
                      Not interested in this post
                    </p>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
