import { createContext, useContext, useEffect, useState } from "react";
import {
  AroundlyPlaces,
  LocationType,
  SocialMediaUpdate,
} from "../common/global.types";
import { getCuration } from "../services/aroundly-apis";
import { useFeedData } from "./FeedDataContext";
import { filterDataBySearchParams } from "../utils/filterUpdates.util";
import { UPDATE_TYPE_EVENT } from "../common/global.constants";
import { useSearchParams } from "react-router-dom";
import { useLocationInfo } from "./LocationContext";
import { getDistanceFromLatLonInKm } from "../common/distance-utils";

interface CuratedData {
  new_addition: AroundlyPlaces[];
  popular_cuisines: { cuisine_count: number; cuisine_name: string }[];
  public_favourites: AroundlyPlaces[];
  top_rated: AroundlyPlaces[];
  trending_now: AroundlyPlaces[];
  upcoming_events: AroundlyPlaces[];
}

interface FoodAndDrinksContextProps {
  data?: CuratedData;
  topRated: SocialMediaUpdate[];
  trendingNow: SocialMediaUpdate[];
  favorites: SocialMediaUpdate[];
  newAdd: SocialMediaUpdate[];
  showErrorModal: boolean;
  upcommingEvents: AroundlyPlaces[];
  loadingCuration: boolean;
  updatesLength: number;
  setupdatesLength: (state: number) => void;
}

const FoodAndDrinksContext = createContext({
  data: {
    new_addition: [],
    popular_cuisines: [],
    public_favourites: [],
    top_rated: [],
    trending_now: [],
    upcoming_events: [],
  },
  topRated: [],
  trendingNow: [],
  favorites: [],
  newAdd: [],
  showErrorModal: false,
  upcommingEvents: [],
  loadingCuration: false,
  updatesLength: 0,
  setupdatesLength: (val: number) => {},
} as FoodAndDrinksContextProps);

export function FoodAndDrinksProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const [data, setData] = useState<CuratedData>();
  const [topRated, setTopRated] = useState<SocialMediaUpdate[]>([]);
  const [trendingNow, setTrendingNow] = useState<SocialMediaUpdate[]>([]);
  const [favorites, setFavorites] = useState<SocialMediaUpdate[]>([]);
  const [newAdd, setNewAdd] = useState<SocialMediaUpdate[]>([]);
  const [searchParams] = useSearchParams();
  const { selectedLocation } = useFeedData();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [upcommingEvents, setUpcommingEvents] = useState<AroundlyPlaces[]>([]);
  const { userCurrentPosition } = useLocationInfo();
  const [updatesLength, setupdatesLength] = useState(0);
  const [loadingCuration, setLoadingCuration] = useState(false);
  const fetchData = async (location: LocationType) => {
    setLoadingCuration(true);
    setShowErrorModal(false);
    try {
      const curatedData = await getCuration(location);
      setData(curatedData);
      setLoadingCuration(false);
    } catch (error) {
      setShowErrorModal(true);
      setLoadingCuration(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      fetchData(selectedLocation);
    }
  }, [selectedLocation]);

  const processData = (
    sourceData: AroundlyPlaces[],
    updateType: string | null = null
  ): SocialMediaUpdate[] => {
    let updates: SocialMediaUpdate[] = [];

    sourceData.forEach((place) => {
      const filteredUpdates = updateType
        ? place.updates.filter((u) => u.update_type === updateType)
        : place.updates;

      filteredUpdates.forEach((u) => {
        u.related_aroundly_place_ids = [
          {
            ...place,
          },
        ] as any;
      });
      updates = updates.concat(filteredUpdates);
    });
    return updates.sort(
      (a, b) =>
        new Date(a.expires_at).getTime() - new Date(b.expires_at).getTime()
    );
  };

  useEffect(() => {
    if (data?.top_rated) {
      setTopRated(processData(data.top_rated));
    }
    if (data?.trending_now) {
      setTrendingNow(processData(data.trending_now));
    }
    if (data?.public_favourites) {
      setFavorites(processData(data.public_favourites));
    }
    if (data?.new_addition) {
      setNewAdd(processData(data.new_addition));
    }
  }, [data]);

  useEffect(() => {
    if (data?.upcoming_events) {
      let tempData = data.upcoming_events;
      if (userCurrentPosition) {
        tempData = tempData.map((place) => ({
          ...place,
          distance: getDistanceFromLatLonInKm(
            userCurrentPosition.coords.latitude,
            userCurrentPosition.coords.longitude,
            place.geo_location.coordinates[1],
            place.geo_location.coordinates[0]
          ),
        }));
      }

      setUpcommingEvents(tempData);
    }
  }, [data?.upcoming_events, searchParams, userCurrentPosition]);

  return (
    <FoodAndDrinksContext.Provider
      value={{
        data,
        upcommingEvents,
        topRated,
        favorites,
        newAdd,
        trendingNow,
        showErrorModal,
        loadingCuration,
        updatesLength,
        setupdatesLength,
      }}
    >
      {children}
    </FoodAndDrinksContext.Provider>
  );
}
export const useFoodandDrinks = () => useContext(FoodAndDrinksContext);
