import moment from "moment";
import { SocialMediaUpdate } from "../common/global.types";
import { DEFAULT_TIMEZONE } from "../common/global.constants";

const filterBystate = (
  filteredUpdates: SocialMediaUpdate[],
  selectedTime: string,
  isResults: boolean,
  relevance?: string
): SocialMediaUpdate[] => {
  if (!filteredUpdates) return [];

  if (selectedTime === "anytime") {
    return filteredUpdates;
  }
  // Sort updates by expires_at date
  let sortedUpdates = filteredUpdates;
  if (relevance === "upcomming") {
    sortedUpdates = filteredUpdates.sort((a, b) => {
      const dateA = moment(a.expires_at).tz(DEFAULT_TIMEZONE);
      const dateB = moment(b.expires_at).tz(DEFAULT_TIMEZONE);
      return dateA.diff(dateB);
    });
  }

  const now = moment().tz(DEFAULT_TIMEZONE);
  let startOfRange: moment.Moment, endOfRange: moment.Moment;

  if (selectedTime === "today") {
    startOfRange = now.clone().startOf("day");
    endOfRange = now.clone().endOf("day");
  } else if (selectedTime === "tomorrow") {
    startOfRange = now.clone().add(1, "days").startOf("day");
    endOfRange = now.clone().add(1, "days").endOf("day");
  } else if (selectedTime === "week") {
    startOfRange = now.clone().startOf("isoWeek");
    endOfRange = now.clone().endOf("isoWeek");
  }

  return sortedUpdates.filter((u: SocialMediaUpdate) => {
    const expiresAt = moment(u.expires_at).tz(DEFAULT_TIMEZONE);
    return (
      expiresAt.isSameOrAfter(startOfRange) &&
      expiresAt.isSameOrBefore(endOfRange)
    );
  });
};

export default filterBystate;
