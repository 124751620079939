import ToggleTemplate from "./ToggleTemplate";

const SavedToggle = ({
  setIsTemplate,
  istemplate,
  places,
  updates,
  upcoming
}: {
  istemplate: boolean;
  setIsTemplate: (status: boolean) => void;
  places: number;
  updates: number;
  upcoming:number
}) => {
  return (
    <div className="bg-white mx-3 my-2 p-2 rounded-lg flex justify-between items-center text-center">
      <div
        className="text-secondary-text text-xs font-450"
        style={{ letterSpacing: "-0.333px" }}
      >
        {upcoming} Upcoming. {updates} Updates. {places} Places
      </div>
      <ToggleTemplate istemplate={istemplate} setIsTemplate={setIsTemplate} />
    </div>
  );
};
export default SavedToggle;
