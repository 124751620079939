import { XMarkIcon } from "@heroicons/react/24/outline";
import { CollabWishlistType, UserProps } from "../common/global.types";
import { memo } from "react";

const WishlistEmptyState = ({
  toggleImage,
  authUser,
  collabLists,
  imageName,
}: {
  toggleImage: (status: boolean) => void;
  authUser: UserProps | null | undefined;
  collabLists: CollabWishlistType[];
  imageName: string[];
}) => {
  return (
    <div className="relative">
      <div
        onClick={() => {
          toggleImage(false);
        }}
        className={`${
          (!authUser) && "hidden"
        } absolute top-4  right-4`}
      >
        <XMarkIcon
          className="h-8 w-8 inline-block  text-white cursor-pointer"
          // onClick={() => setValue("caption", "")}
        />
      </div>
      <div className="h-screen">
        <img
          src={"/images/aroundly-wishlist.png"}
          className={"object-contain w-full pb-12"}
          // style={{ height: "calc(100vh - 70px)" }}
          alt="wishlist dummy"
        />
        <div className="relative py-3 z-10 flex flex-col items-center overflow-x-hidden ">
          <div className="scrollbar-hide flex items-center gap-2 animate-infinite-scroll">
            {imageName.map((value, index) => (
              <img
                className="h-auto w-auto max-h-[212px]"
                key={index}
                alt="card"
                src={`/images/${value}.png`}
              />
            ))}
            {[0, 1, 2, 3].map((t) =>
              imageName.map((value, index) => (
                <img
                  className="h-auto w-auto max-h-[212px]"
                  alt="card0"
                  key={index}
                  src={`/images/${value}.png`}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(WishlistEmptyState);
